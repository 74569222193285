import { Theme, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import React from 'react';
import { getHeaderDate } from 'src/app/common/utils';
import LanguageSwitch from './language-switch';
import QuickUserToggler from './quick-user-toggler';

export default function HeaderRight() {
  const { classes } = useStyles();
  return (
    <div className={classes.wrapper}>
      <div className={classes.dateWrapper}>
        <Typography className={classes.text}>Today</Typography>
        <Typography className={classes.date}>{getHeaderDate()}</Typography>
      </div>
      <LanguageSwitch />
      <QuickUserToggler />
    </div>
  );
}

const useStyles = makeStyles()((theme: Theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: 10,
  },
  dateWrapper: {
    paddingLeft: 13,
    paddingRight: 13,
    height: 40,
    padding: '12px 8px',
    backgroundColor: '#F3F6F9',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: 10,
    marginRight: 10,
  },
  text: {
    color: '#7E8299',
    marginRight: theme.spacing(1),
    fontSize: '12px',
  },
  date: {
    color: '#3699FF',
    fontSize: '12px',
  },
}));
