import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  formContainer: {
    position: 'relative',
    minHeight: '100%',
    backgroundColor: 'white',
    marginLeft: 24,
    marginRight: 24,
  },
  container: {
    padding: 20,
    marginBottom: 20,
    borderRadius: 5,
    backgroundColor: 'white',
  },
  backdrop: {
    position: 'fixed',
    zIndex: 0,
    color: 'white',
    backgroundColor: `white !important`,
    opacity: '0.5 !important',
  },
  backdropIcon: {
    top: '30vh',
    position: 'absolute',
  },
  warningTitle: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 20,
    padding: '16px 24px',
  },
  warningTxt: {
    fontSize: 16,
    fontFamily: 'Poppins',
    padding: '0px 24px',
  },
  btnDialog: {
    backgroundColor: '#4386E5',
    color: 'white',
    width: 68,
    height: 44,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    marginTop: 30,
    marginBottom: 24,
    borderRadius: 4,
    fontWeight: 'bold',
  },
}));
