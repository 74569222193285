import React, { useEffect, useRef } from 'react';
import * as echarts from 'echarts';

function Chart({ options, style, isExport }) {
  const chartRef = useRef(null);
  let chartInstance = null;

  function renderChart() {
    try {
      const renderedInstance = echarts.getInstanceByDom(chartRef.current);
      if (renderedInstance) {
        chartInstance = renderedInstance;
      } else {
        chartInstance = echarts.init(chartRef.current, null, {
          renderer: isExport ? 'svg' : 'canvas',
        });
      }
      chartInstance.setOption(options);
    } catch (error) {
      console.error('error', error.message);
      chartInstance && chartInstance.dispose();
    }
  }

  function resizeHandler() {
    chartInstance.resize();
  }

  useEffect(() => {
    renderChart();

    return () => {
      chartInstance && chartInstance.dispose();
    };
  }, []);

  useEffect(() => {
    window.addEventListener('resize', resizeHandler);
    return () => window.removeEventListener('resize', resizeHandler);
  }, []);

  return (
    <div>
      <div style={style} ref={chartRef} />
    </div>
  );
}

export default Chart;
