import {
  OverviewDimension,
  DimensionDataState,
  DimensionDataItem,
  DimensionFilterState,
  DimensionFilterItem,
  DownlineItem,
} from 'src/app/modules/ManagerDashboard/types';

type PruLeadsOverviewPageState = {
  overviewDimension?: OverviewDimension;
  dimensionDataState?: DimensionDataState;
  dimensionFilterState?: DimensionFilterState;
  changedDimensionFilter?: string;
};

type SetOverviewDimensionAction = {
  type: 'SET_OVERVIEW_DIMENSION';
  payload: {
    value: OverviewDimension;
  };
};

type SetDimensionDataStateAction = {
  type: 'SET_DIMENSION_DATA_STATE';
  payload: {
    value: DimensionDataState;
  };
};

type SetDimensionDataItemByKeyAction = {
  type: 'SET_DIMENSION_DATA_ITEM_BY_KEY';
  payload: {
    dimensionKey: string;
    value: DimensionDataItem;
  };
};

type SetDimensionFilterStateAction = {
  type: 'SET_DIMENSION_FILTER_STATE';
  payload: {
    value: DimensionFilterState;
  };
};

type SetDimensionFilterItemByKeyAction = {
  type: 'SET_DIMENSION_FILTER_ITEM_BY_KEY';
  payload: {
    dimensionKey: string;
    value: DimensionFilterItem;
  };
};

type SetSelectedDownlineAction = {
  type: 'SET_SELECTED_DOWNLINE';
  payload: {
    dimensionKey: string;
    value: DownlineItem;
  };
};

type SetChangedDimensionFilterAction = {
  type: 'SET_CHANGED_DIMENSION_FILTER';
  payload: {
    value?: string;
  };
};

type PruLeadsOverviewPageAction =
  | SetOverviewDimensionAction
  | SetDimensionDataStateAction
  | SetDimensionDataItemByKeyAction
  | SetDimensionFilterStateAction
  | SetDimensionFilterItemByKeyAction
  | SetSelectedDownlineAction
  | SetChangedDimensionFilterAction;

export const pageReducer = (
  state: PruLeadsOverviewPageState,
  action: PruLeadsOverviewPageAction,
): PruLeadsOverviewPageState => {
  switch (action.type) {
    case 'SET_OVERVIEW_DIMENSION':
      return {
        ...state,
        overviewDimension: action.payload.value,
      };
    case 'SET_DIMENSION_DATA_STATE':
      return {
        ...state,
        dimensionDataState: action.payload.value,
      };
    case 'SET_DIMENSION_DATA_ITEM_BY_KEY':
      return {
        ...state,
        dimensionDataState: {
          ...state.dimensionDataState,
          [action.payload.dimensionKey]: action.payload.value,
        },
      };
    case 'SET_DIMENSION_FILTER_STATE':
      return {
        ...state,
        dimensionFilterState: action.payload.value,
      };
    case 'SET_DIMENSION_FILTER_ITEM_BY_KEY':
      return {
        ...state,
        dimensionFilterState: {
          ...state.dimensionFilterState,
          [action.payload.dimensionKey]: action.payload.value,
        },
        changedDimensionFilter: action.payload.dimensionKey,
      };
    case 'SET_SELECTED_DOWNLINE':
      return {
        ...state,
        dimensionFilterState: {
          ...state.dimensionFilterState,
          [action.payload.dimensionKey]: {
            ...(state.dimensionFilterState || {})[action.payload.dimensionKey],
            downline: action.payload.value,
          },
        },
        changedDimensionFilter: action.payload.dimensionKey,
      };
    case 'SET_CHANGED_DIMENSION_FILTER':
      return {
        ...state,
        changedDimensionFilter: action.payload.value,
      };
    default:
      return state;
  }
};
