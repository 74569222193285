export const prospectCategoryEndpoint = 'agents/me/prospect-categories';
export const managerDashboardEndpoint = 'agents/me/manager-dashboard';

export const leadOverviewEndpoint = 'leads/overview';
export const leadProspectEndpoint = 'leads/prospects';
export const leadEndpoint = 'leads/leads';

// prospect
export const categoryEndpoint = 'category';
export const sourceEndpoint = 'source';

// leads
export const updateEndpoint = 'status-update';
export const summaryEndpoint = 'cumulative';
