import React, { FC } from 'react';
import { Switch, Redirect, RouteComponentProps } from 'react-router-dom';
import PruRoute from '../../../../common/components/PruRoute';
import { AGENCY_CAMPAIGN_BASE_PATH, AGENCY_CAMPAIGN_DASHBOARD_PATH } from '../../constants';
import { NOT_FOUND_ERROR_PAGE_PATH } from '../../../../common/constants';
import DashboardListPage from './List/DashboardListingPage';
import { GAData } from '../../../../common/ga/redux/gaSlice';


export const dashboardPath = `${AGENCY_CAMPAIGN_BASE_PATH}${AGENCY_CAMPAIGN_DASHBOARD_PATH}`;

const dashboardPageGAData: GAData = {
  module: 'PRULeads',
  feature: 'Dashboard',
  journey: 'view_dashboard',
  stage: 'view',
  screen_name: 'DashboardPage',
};

const BDMDashboardRoutes: FC<RouteComponentProps> = (props) => {
  return (
    <Switch>
      <PruRoute path={`${dashboardPath}`} component={DashboardListPage} gaData={dashboardPageGAData} />
      <Redirect to={NOT_FOUND_ERROR_PAGE_PATH} />
    </Switch>
  );
};

export default BDMDashboardRoutes;
