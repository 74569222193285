import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { Divider } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { AwardItem } from '../../types/agent-profile-types';

type AwardListProps = {
  mappedLocale: string;
  badges: AwardItem[];
  awards: AwardItem[];
  multiColumn?: boolean;
};

const BADGE_COLUMN_GAP = 12;

const MULTI_COLUMN_TOP_BOTTOM_MARGIN = 30;
const MULTI_COLUMN_AWARD_MIN_HEIGHT = 60;
const VERTICAL_DIVIDER_WIDTH = 1;
const VERTICAL_DIVIDER_HEIGHT = 58;
const VERTICAL_DIVIDER_SIDE_MARGIN = 20;

const useStyles = makeStyles()((theme) => ({
  badgeListContainer: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    columnGap: BADGE_COLUMN_GAP,
    marginBottom: 16,
  },
  badge: {
    width: `calc((100% - ${BADGE_COLUMN_GAP * 4 + 1}px) / 5)`,
    maxWidth: 56,
  },
  multiColumnAwardListContainer: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  multiColumnAwardItemContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    width: `calc((100% - ${(VERTICAL_DIVIDER_WIDTH + VERTICAL_DIVIDER_SIDE_MARGIN * 2) * 2}px) / 3)`,
    minHeight: MULTI_COLUMN_AWARD_MIN_HEIGHT,
    margin: `${MULTI_COLUMN_TOP_BOTTOM_MARGIN}px 0`,
  },
  horizontalDivider: {
    width: '100%',
  },
  verticalDivider: {
    width: VERTICAL_DIVIDER_WIDTH,
    height: VERTICAL_DIVIDER_HEIGHT,
    margin: `${MULTI_COLUMN_TOP_BOTTOM_MARGIN}px ${VERTICAL_DIVIDER_SIDE_MARGIN}px`,
  },
  awardTitle: {
    fontSize: 16,
  },
  caption: {
    fontSize: 14,
    color: '#BABABA',
  },
  bottomSpacing: {
    marginBottom: 12,
  },
}));

const AwardList: FC<AwardListProps> = ({ mappedLocale, badges, awards, multiColumn }) => {
  const { classes } = useStyles();
  const intl = useIntl();
  const Translation = (id: string, payload?: any) => intl.formatMessage({ id }, payload);

  const getYearUnit = (year: number) => {
    if (year > 1) {
      return Translation('agentProfile.ap_years_label');
    } else {
      return Translation('agentProfile.ap_year_label');
    }
  };

  return (
    <div>
      <div className={classes.badgeListContainer}>
        {badges.map((badge) => (badge?.image ? <img src={badge.image} alt="" className={classes.badge} /> : <></>))}
      </div>
      {!multiColumn ? (
        <div>
          {awards.map((award) =>
            award ? (
              <>
                <Divider className={classes.bottomSpacing} />
                <div className={classes.bottomSpacing}>
                  <div className={classes.awardTitle}>{award.awardName[mappedLocale] || ''}</div>
                  {award.awardYear && award.awardYear !== '' && (
                    <div className={classes.caption}>
                      {`${award.awardYear} ${award.isGroupedYear ? getYearUnit(Number(award.awardYear)) : ''}`}
                    </div>
                  )}
                </div>
              </>
            ) : (
              <></>
            ),
          )}
        </div>
      ) : (
        <div className={classes.multiColumnAwardListContainer}>
          {awards.map((award, index) =>
            award ? (
              <>
                {index % 3 === 0 && <Divider className={classes.horizontalDivider} />}
                {index % 3 !== 0 && <Divider className={classes.verticalDivider} orientation="vertical" />}
                <div className={classes.multiColumnAwardItemContainer}>
                  <div className={classes.awardTitle}>{award.awardName[mappedLocale] || ''}</div>
                  {award.awardYear && award.awardYear !== '' && (
                    <div className={classes.caption}>
                      {`${award.awardYear} ${award.isGroupedYear ? getYearUnit(Number(award.awardYear)) : ''}`}
                    </div>
                  )}
                </div>
              </>
            ) : (
              <></>
            ),
          )}
        </div>
      )}
    </div>
  );
};

export default AwardList;
