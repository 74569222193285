import { Alert, Button } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { get as lodashGet } from 'lodash';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { ModalService } from 'src/app/common/components/pru-modal/modal-service';
import { Translation } from 'src/app/i18n';
import { AgencyCampaignTypeStatusEnum } from 'src/app/modules/AgencyCampaign/constants';
import { AlertType, appendAlertItem } from '../../../../../../../redux/common/commonSlice';
import { toAbsoluteUrl } from 'src/app/common/utils';
import { MANDATORY_FIELD_ERROR_TEXT } from '../../../../../../common/constants';
import { createBlobAsAgent, getBlobAsAgent } from '../../../../../../common/network/crud/resourceCrud';
import { fileUpload } from '../../../../../../common/resource/resource-utils';
import { convertArrayToObject } from '../../../../../../common/utils/common-utils';
import {
  CampaignDetailPageStep,
  campaignSectionFieldItem,
  CampaignStructureItem,
} from '../../../../types/campaign-types';
// import FileUpload from "react-mui-fileuploader"
// import classes from './EventDocument.module.css';

const useStyles = makeStyles()((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  sectionTitle: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    paddingLeft: 20,
    paddingRight: 20,
    height: 50,
    marginBottom: 8,
    fontWeight: 600,
    alignItems: 'center',
  },
  attachmentContainer: {
    background: '#FFFFFF',
    // width: '300px',
    minHeight: 250,
    margin: 5,
    display: 'flex',
    flexDirection: 'column',
    padding: 15,
  },
  attachmentTitle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '5px',
    fontWeight: 600,
    height: '20%',
  },
  mandatory: {
    color: 'red',
  },
  binIcon: {
    width: 20,
    height: 20,
    margin: 10,
  },
  imageContainer: {
    // flex: 1,
    width: 300,
    border: '10px solid #F4F4F4',
    // justifyContent: 'center',
    // alignContent: 'center',
    margin: 10,
    borderRadius: 10,
    background: '#F4F4F4',
    marginRight: 10,
  },
  fileText: {
    textDecoration: 'underline',
    color: '#38527E',
    marginBottom: 8,
    padding: '5px',
  },
  uploadAreaOuterContainer: {
    borderRadius: '8px',
    background: '#F4F4F4',
    display: 'flex',
    flex: 1,
    height: 160,
    width: 280,
  },
  uploadArea: {
    border: '2px dashed #C1C1C1',
    borderRadius: '5px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },
  uploadAreaError: {
    border: '2px dashed #f018a6',
    borderRadius: '5px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },
  uploadButton: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  errorText: {
    fontSize: 10,
    color: '#f018a6',
    paddingLeft: 14,
  },
  viewBtn: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 10,
    background: '#F4F4F4',
    justifyContent: 'space-between',
  },
  card: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    background: '#F4F4F4',
    justifyContent: 'flex-start',
    backgroundColor: '#fff',
  },
  imgPreview: { width: '100%', height: '120px', textAlign: 'center', borderRadius: 8 },
  filePreview: {
    width: '100%',
    height: '120px',
    textAlign: 'center',
    background: '#fff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 8,
    textOverflow: 'ellipsis',
    wordBreak: 'break-all',
  },
}));

interface EventDocumentProps {
  campaignTypeStructureData: CampaignStructureItem;
  campaign: any;
  formDispatch: (data: any) => void;
  formState: any;
  sectionKey: string;
  errorState: any;
  disabledEdit?: boolean;
  validationHandler: any;
  onSubmitDocuments: Function;
  disabled?: boolean;
}

function getMimeType(file: File) {
  if (file.type) {
    return file.type;
  }
  const mime = {
    doc: 'application/msword',
    docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    dotx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
    docm: 'application/vnd.ms-word.document.macroEnabled.12',
    dotm: 'application/vnd.ms-word.template.macroEnabled.12',
    xls: 'application/vnd.ms-excel',
    xlt: 'application/vnd.ms-excel',
    xla: 'application/vnd.ms-excel',
    xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    xltx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
    xlsm: 'application/vnd.ms-excel.sheet.macroEnabled.12',
    xltm: 'application/vnd.ms-excel.template.macroEnabled.12',
    xlam: 'application/vnd.ms-excel.addin.macroEnabled.12',
    xlsb: 'application/vnd.ms-excel.sheet.binary.macroEnabled.12',
  };
  type MimeTypes = keyof typeof mime;
  const fileName = file.name;
  const fileExtension = (fileName?.split('.')?.pop()?.toLowerCase() || '') as MimeTypes;
  return mime[fileExtension];
}

const isDataChanged = (data: any) => {
  for (const key in data) {
    if (Object.prototype.hasOwnProperty.call(data, key)) {
      const resourceIds = lodashGet(data, `${key}.resourceIds`, []);
      if (resourceIds.find((item: any) => !item.submitDate)) {
        return true;
      }
    }
  }
  return false;
};

const getTranslations = () => {
  return {
    are_you_sure_to_submit: Translation('are_you_sure_to_submit'),
    please_make_sure_all_the_documents_are_correct: Translation('please_make_sure_all_the_documents_are_correct'),
  };
};

const EventDocument: React.FC<EventDocumentProps> = ({
  sectionKey,
  campaignTypeStructureData,
  campaign,
  formDispatch,
  formState,
  errorState,
  disabledEdit,
  validationHandler,
  onSubmitDocuments,
  disabled = false,
}) => {
  const { classes } = useStyles();
  const intl = useIntl();
  const dispatch = useDispatch();
  const Translation = (id: string) => intl.formatMessage({ id });
  const [submitted, setDataSubmmitted] = useState(false);
  const [canSubmit, setCanSubmit] = useState(false);
  const [uploadEventDocumentProgress, setUploadEventDocumentProgress] = useState<number>();
  const eventDocumentData = campaignTypeStructureData.sections.filter((item) => item.key == sectionKey)[0];

  const isMandatoryFieldsSatisfied = useMemo(() => {
    const mandatoryFields = eventDocumentData.sectionFields.filter(
      (item) => item.rules?.[0]?.key === 'mandatory' && item.rules?.[0]?.value === true,
    );
    return mandatoryFields.every((item) => formState[sectionKey]?.[item.key]?.resourceIds?.length);
  }, [eventDocumentData, formState, sectionKey]);

  useEffect(() => {
    setCanSubmit(
      !disabled &&
        isMandatoryFieldsSatisfied &&
        !submitted &&
        [AgencyCampaignTypeStatusEnum.APPROVED, AgencyCampaignTypeStatusEnum.ACTIVE].includes(
          campaign.campaignStatus,
        ) &&
        moment(new Date()).isAfter(campaign.startDate),
    );
  }, [submitted, formState, campaign.campaignStatus, isMandatoryFieldsSatisfied]);
  const uploadEventDocument = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const fieldId: string = e.target.getAttribute('id') ?? '';
    const arrFile: File[] = lodashGet(e, 'target.files', []);
    if (arrFile.length > 0) {
      let arrResource: unknown[] = [],
        arrResourceIds: unknown[] = [];
      for (let index = 0; index < arrFile.length; index++) {
        const file: File = arrFile[index];
        const mimeType = getMimeType(file);
        if (!mimeType) {
          return dispatch(
            appendAlertItem([
              {
                severity: AlertType.ERROR,
                title: Translation('app.button.submitError'),
                content: Translation('common.fileNotSupport'),
              },
            ]),
          );
        }
        try {
          const createBlobRes = await createBlobAsAgent(
            { mimeType, accessLevel: 'anonymous', module: 'agencyCampaign', originalFilename: file.name },
            dispatch,
          );
          await fileUpload(createBlobRes[0].url, file, setUploadEventDocumentProgress);
          const blobDetail = await getBlobAsAgent({ resourceIds: createBlobRes[0].blobId }, dispatch);
          const result = blobDetail[0];
          if (result) {
            validationHandler.onDismissErrorHandler(`${sectionKey}_${fieldId}`, result);
            arrResource.push(result);
            arrResourceIds.push({ id: result.blobId });
            // formDispatch({ type: "MODIFY_FIELD", payload: { section: sectionKey, field: fieldId, value: {resources:[result],resourceIds:[result.blobId] } } });
          }
        } catch (err) {
        } finally {
          setUploadEventDocumentProgress(undefined);
        }
      }

      const source: any[] = lodashGet(formState, `${sectionKey}.${fieldId}.resources`, []);
      const sourceId: any[] = lodashGet(formState, `${sectionKey}.${fieldId}.resourceIds`, []);
      formDispatch({
        type: 'MODIFY_FIELD',
        payload: {
          section: sectionKey,
          field: fieldId,
          value: { resources: [...source, ...arrResource], resourceIds: [...sourceId, ...arrResourceIds] },
        },
      });
    }
  };

  const removeFile = (fieldId: string, index: number) => {
    const arrSource: any[] = lodashGet(formState, `${sectionKey}.${fieldId}.resources`, []);
    const arrSourceId: any[] = lodashGet(formState, `${sectionKey}.${fieldId}.resourceIds`, []);

    arrSource.splice(index, 1);
    arrSourceId.splice(index, 1);
    // trigger warning: 'This field is mandatory' when no attachment
    const value = arrSource.length > 0 ? { resources: arrSource, resourceIds: arrSourceId } : undefined;
    formDispatch({
      type: 'MODIFY_FIELD',
      payload: {
        section: sectionKey,
        field: fieldId,
        value: value,
      },
    });
  };

  const viewFile = (url: string) => {
    if ((window as any).ReactNativeWebView) {
      (window as any).ReactNativeWebView.postMessage(
        `{ "action" : "loadurlwithtarget", "payload" : { "target" : "_blank", "url" : "${encodeURIComponent(url)}" } }`,
      );
    } else {
      window.open(url, '_blank');
    }
  };
  const _renderEventDocumentSection = (fieldItem: campaignSectionFieldItem) => {
    let ruleObject = convertArrayToObject(fieldItem.rules, 'title');
    const source: any[] = lodashGet(formState, `${sectionKey}.${fieldItem.key}.resources`, []);
    const sourceIdMapSubmitDate: any = {};
    const sourceIds: any[] = lodashGet(formState, `${sectionKey}.${fieldItem.key}.resourceIds`, []);
    for (let index = 0; index < sourceIds.length; index++) {
      const resource: any = sourceIds[index];
      sourceIdMapSubmitDate[resource.id] = resource.submitDate;
    }

    const allSubmitted = Boolean(sourceIds?.length && sourceIds.every((item: any) => item.submitDate));
    setDataSubmmitted(allSubmitted);
    const txtUpload =
      source.length > 0
        ? Translation('agencyCampaign.create.uploadMore')
        : Translation('agencyCampaign.create.clickToUpload');
    const uploadIconUrl =
      source.length > 0
        ? toAbsoluteUrl('/media/icon/upload_more.png')
        : toAbsoluteUrl('/media/icon/upload_attachment.png');
    return (
      <div className={classes.attachmentContainer} key={fieldItem.key}>
        <div className={classes.attachmentTitle}>
          {Translation(`agencyCampaign.field.${fieldItem.key}`)}
          {ruleObject.Mandatory && ruleObject.Mandatory.value ? <span className={classes.mandatory}>*</span> : null}
        </div>
        <div className={classes.card}>
          {source.map((item: any, index: number) => {
            const filePreview =
              item.mimeType.indexOf('image') > -1 ? (
                <img src={item.url} alt="image" className={classes.imgPreview} />
              ) : (
                <div className={classes.filePreview}>
                  <span> {item.originalFilename}</span>
                </div>
              );

            return (
              <div className={classes.imageContainer} key={index}>
                {/* <img src={item.url} alt="image" style={{ width: '100%', height: '120px', textAlign: 'center' }} /> */}
                {filePreview}
                <div className={classes.viewBtn}>
                  <Button variant="outlined" color="inherit" size="small" onClick={() => viewFile(item.url)}>
                    {Translation('app.button.view')}
                  </Button>

                  <Button
                    variant="outlined"
                    color="inherit"
                    size="small"
                    disabled={sourceIdMapSubmitDate[item._id]}
                    onClick={() => removeFile(fieldItem.key, index)}
                  >
                    {Translation('app.button.remove')}
                  </Button>
                </div>
              </div>
            );
          })}
          {!allSubmitted && (
            <div className={classes.imageContainer}>
              <div className={classes.uploadAreaOuterContainer} key={fieldItem.title}>
                <div
                  className={
                    formState.stepPopulated.includes(CampaignDetailPageStep.EVNET_DOCUMENT) &&
                    errorState.mandatory[`${sectionKey}_${fieldItem.key}`]
                      ? classes.uploadAreaError
                      : classes.uploadArea
                  }
                >
                  <input
                    id={`${fieldItem.key}`}
                    hidden
                    type="file"
                    accept="audio/*,video/*,image/*,application/*, application/pdf,.pdf,application/csv, .csv, .doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    onClick={(e) => {
                      const element = e.target as HTMLInputElement;
                      element.value = '';
                    }}
                    onChange={uploadEventDocument}
                  />

                  <div className={classes.uploadButton}>
                    <div onClick={() => document.getElementById(`${fieldItem.key}`)!.click()}>
                      <img
                        src={uploadIconUrl}
                        alt={'Upload attachment'}
                        style={{ width: 46, height: 46, margin: 10 }}
                      />
                    </div>
                    <div>{txtUpload}</div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        {formState.stepPopulated.includes(CampaignDetailPageStep.EVNET_DOCUMENT) &&
          errorState.mandatory[`${sectionKey}_${fieldItem.key}`] && (
            <span className={classes.errorText}>{MANDATORY_FIELD_ERROR_TEXT}</span>
          )}
      </div>
    );
  };
  const cachedRenderEventSection = useMemo(() => {
    return eventDocumentData.sectionFields.map((fieldItem) => {
      return _renderEventDocumentSection(fieldItem);
    });
  }, [eventDocumentData.sectionFields, formState]);

  if (lodashGet(eventDocumentData, 'sectionFields', []).length < 1) {
    return (
      <div className={classes.container}>
        <div className={classes.sectionTitle}>{Translation('agencyCampaign.create.files')}</div>

        <div className={classes.container} style={{ background: '#fff' }}>
          <span className="tw-font-semibold tw-pl-5 tw-pt-6">
            {Translation('agencyCampaign.create.noEventDocumentRequired')}
          </span>
        </div>
      </div>
    );
  }

  return (
    <div className={classes.container}>
      <div className={classes.sectionTitle}>
        {`${Translation('agencyCampaign.create.file')} ( ${
          formState[sectionKey] ? Object.keys(formState[sectionKey]).length : 0
        } )`}
        <Button
          disabled={!canSubmit}
          variant="contained"
          color="secondary"
          size="large"
          onClick={() => {
            formDispatch({ type: 'STEP_POPULATE', payload: CampaignDetailPageStep.EVNET_DOCUMENT });
            ModalService.confirm({
              title: getTranslations().are_you_sure_to_submit,
              description: getTranslations().please_make_sure_all_the_documents_are_correct,
              onConfirm: () => {
                onSubmitDocuments();
              },
            });
          }}
        >
          {Translation('app.button.submit')}
        </Button>
      </div>
      <>
        {![
          AgencyCampaignTypeStatusEnum.ACTIVE + '',
          AgencyCampaignTypeStatusEnum.COMPLETED + '',
          AgencyCampaignTypeStatusEnum.APPROVED + '',
        ].includes(campaign.campaignStatus) && (
          <Alert className="tw-mb-2" severity="info" color="warning">
            {Translation('campaign_should_be_approved_to_submit_the_section')}
          </Alert>
        )}
        {moment(campaign.startDate).isAfter(moment(new Date())) && (
          <Alert className="tw-mb-2" severity="info" color="warning">
            {Translation('should_pass_campaign_start_date')}
          </Alert>
        )}
      </>

      <div className={classes.container} style={{ background: '#fff' }}>
        {cachedRenderEventSection}
      </div>
    </div>
  );
};

export default EventDocument;
