import { makeStyles } from 'tss-react/mui';
import { ValueOf } from 'src/app/common/types/common-types';
import {
  DashboardFilterDef,
  DialogState,
  FilterTypeEnum,
  OptionToDateFilterEnum,
  FilterContentEnum,
} from '../../types/dashboard-filter-types';

type FilterBoxProps<T> = {
  filterDef: DashboardFilterDef<T>[];
  filterState: T;
  handleSelectFilter: (filter: DashboardFilterDef<T>, value: ValueOf<T>, isConfirm?: boolean) => void;
  setDialogState: (newDialogState: DialogState) => void;
};

const useStyles = makeStyles()((theme) => ({
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 14,
    fontWeight: 600,
  },
  boxContainer: {
    display: 'flex',
    alignItems: 'center',
    columnGap: 4,
    padding: 3,
    backgroundColor: '#F5F5F5',
    borderRadius: 8,
  },
  box: {
    padding: '4px 16px',
    borderRadius: 8,
    cursor: 'pointer',
  },
  defaultBox: {
    color: '#999999',
  },
  selectedBox: {
    color: '#E8192C',
    backgroundColor: '#FFFFFF',
  },
}));

const FilterBox = <T extends Record<string, unknown>>({
  filterDef,
  filterState,
  handleSelectFilter,
  setDialogState,
}: FilterBoxProps<T>) => {
  const { classes } = useStyles();
  return (
    <div className={classes.rowContainer}>
      {filterDef.map((filter) => (
        <div key={`filter-${filter.keyIndex}`} className={classes.boxContainer}>
          {filter.options.map((option, optionIndex) => (
            <div
              key={`filter-${filter.keyIndex}-option-${optionIndex}`}
              className={`
                  ${classes.box}
                  ${filterState[filter.keyIndex] === option.value ? classes.selectedBox : classes.defaultBox}
                `}
              onClick={() => {
                if (filter.type === FilterTypeEnum.OPTION_TO_DATE && option.value === OptionToDateFilterEnum.CUSTOM) {
                  handleSelectFilter(filter, option.value);
                  setDialogState({
                    open: true,
                    filterContent: {
                      content: FilterContentEnum.SELECT_WEEK,
                      dateFromKey: filter.dateFromKey,
                      dateToKey: filter.dateToKey,
                    },
                  });
                } else {
                  handleSelectFilter(filter, option.value, true);
                }
              }}
            >
              {option.displayName}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default FilterBox;
