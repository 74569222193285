import { Box, ClickAwayListener, Fade, Paper, Popper, PopperPlacementType } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import React, { ReactElement } from 'react';

interface Props {
  content: ReactElement;
  children: ReactElement;
  open: boolean;
  onClose?: (e: Event) => void;
  arrow?: boolean;
  fontColor?: string;
  backgroundColor?: string;
  placement?: PopperPlacementType;
}

const useStyles = makeStyles<Partial<Props>>()((
  theme,
  { arrow, fontColor = '#000000', backgroundColor = theme.palette.background.paper },
) => {
  return {
    popoverRoot: {
      backgroundColor: backgroundColor,
      maxWidth: 1000,
      color: fontColor,
    },
    content: {
      padding: 0,
      marginTop: 6,
    },
    // Copied from https://github.com/mui/material-ui/blob/master/docs/data/material/components/popper/ScrollPlayground.js
    popper: {
      zIndex: 2000,
      '& > div': {
        position: 'relative',
      },
      '&[data-popper-placement*="bottom"]': {
        '& > div': {
          marginTop: arrow ? 2 : 0,
        },
        '& .MuiPopper-arrow': {
          top: 0,
          left: 0,
          marginTop: '-0.9em',
          width: '3em',
          height: '1em',
          '&::before': {
            borderWidth: '0 1em 1em 1em',
            borderColor: `transparent transparent ${backgroundColor} transparent`,
          },
        },
      },
      '&[data-popper-placement*="top"]': {
        '& > div': {
          marginBottom: arrow ? 2 : 0,
        },
        '& .MuiPopper-arrow': {
          bottom: 0,
          left: 0,
          marginBottom: '-0.9em',
          width: '3em',
          height: '1em',
          '&::before': {
            borderWidth: '1em 1em 0 1em',
            borderColor: `${backgroundColor} transparent transparent transparent`,
          },
        },
      },
      '&[data-popper-placement*="right"]': {
        '& > div': {
          marginLeft: arrow ? 2 : 0,
        },
        '& .MuiPopper-arrow': {
          left: 0,
          marginLeft: '-0.9em',
          height: '3em',
          width: '1em',
          '&::before': {
            borderWidth: '1em 1em 1em 0',
            borderColor: `transparent ${backgroundColor} transparent transparent`,
          },
        },
      },
      '&[data-popper-placement*="left"]': {
        '& > div': {
          marginRight: arrow ? 2 : 0,
        },
        '& .MuiPopper-arrow': {
          right: 0,
          marginRight: '-0.9em',
          height: '3em',
          width: '1em',
          '&::before': {
            borderWidth: '1em 0 1em 1em',
            borderColor: `transparent transparent transparent ${backgroundColor}`,
          },
        },
      },
    },
    // Copied from https://github.com/mui/material-ui/blob/master/docs/data/material/components/popper/ScrollPlayground.js
    arrow: {
      position: 'absolute',
      fontSize: 7,
      width: '3em',
      height: '3em',
      '&::before': {
        content: '""',
        margin: 'auto',
        display: 'block',
        width: 0,
        height: 0,
        borderStyle: 'solid',
      },
    },
  };
});

const RichTooltip = ({
  placement = 'top',
  arrow = true,
  fontColor,
  backgroundColor,
  open,
  onClose = () => {},
  content,
  children,
}: Props) => {
  const { classes } = useStyles({ arrow, fontColor, backgroundColor });
  const [arrowRef, setArrowRef] = React.useState<HTMLElement | null>(null);
  const [childNode, setChildNode] = React.useState<HTMLElement | null>(null);

  return (
    <div>
      {React.cloneElement(children, { ...children.props, ref: setChildNode })}
      <Popper
        open={open}
        anchorEl={childNode}
        placement={placement}
        transition
        className={classes.popper}
        modifiers={[
          {
            name: 'preventOverflow',
            enabled: true,
            options: {
              rootBoundary: 'window',
            },
          },
          {
            name: 'arrow',
            enabled: true,
            options: {
              element: arrowRef,
            },
          },
        ]}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper>
              <ClickAwayListener onClickAway={onClose}>
                <Paper className={classes.popoverRoot}>
                  {arrow ? <span className={`${classes.arrow} MuiPopper-arrow`} ref={setArrowRef} /> : null}
                  <Box className={classes.content}>{content}</Box>
                </Paper>
              </ClickAwayListener>
            </Paper>
          </Fade>
        )}
      </Popper>
    </div>
  );
};

export default RichTooltip;
