import { FC, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { NOT_FOUND_ERROR_PAGE_PATH } from 'src/app/common/constants';
import { LayoutSplashScreen } from 'src/app/layout';
import { useLang } from 'src/app/i18n';
import { AgentProfileItem, AwardItem, LocationState } from 'src/app/modules/AgentProfile/types/agent-profile-types';
import { moveSelectedAwardsToTop } from 'src/app/modules/AgentProfile/common/utils/list-utils';
import {
  localeMapToBackend,
  localeMapToPromotionBackend,
} from 'src/app/modules/AgentProfile/common/utils/locale-utils';
import {
  fetchAgentProfile,
  getAgentAwards,
  UpdateProfileBody,
} from 'src/app/modules/AgentProfile/network/agentProfileCrud';
import AgentProfileCreateMainForm from './components/AgentProfileCreateMainForm';

const AgentProfileCreateMainPage: FC<RouteComponentProps> = ({ history, location }) => {
  const dispatch = useDispatch();
  const lang = useLang();
  const mappedLocale = localeMapToBackend[lang] || lang;
  const promotionMappedLocale = localeMapToPromotionBackend[lang] || lang;
  // const defaultLang = window.envConfig['PRUFORCE_DEFAULT_LANGUAGE'];

  const [agentProfile, setAgentProfile] = useState<AgentProfileItem>();
  const [updateProfileBody, setUpdateProfileBody] = useState<UpdateProfileBody>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [awardList, setAwardList] = useState<AwardItem[]>([]);

  const receivedLocationState = location.state as LocationState;
  const createPageRefreshed = receivedLocationState?.createPageRefreshed ? true : false;
  const [createPageRefreshDataUpdated, setCreatePageRefreshDataUpdated] = useState<boolean>(
    receivedLocationState?.createPageRefreshDataUpdated ? true : false,
  );

  const reloadData = async () => {
    let agentProfileData: any = undefined;
    let awardListData: any = undefined;
    setIsLoading(true);
    try {
      const res = await fetchAgentProfile(mappedLocale, dispatch);
      agentProfileData = res;
      const awardListRes = await getAgentAwards(mappedLocale, dispatch);
      awardListData = moveSelectedAwardsToTop(res.awards, awardListRes.awards);
    } catch (err) {
      history.push(NOT_FOUND_ERROR_PAGE_PATH);
    } finally {
      setIsLoading(false);
      return {
        agentProfileData,
        awardListData,
      };
    }
  };

  useEffect(() => {
    if ((window as any).ReactNativeWebView) {
      (window as any).ReactNativeWebView.postMessage(
        `{ "action": "loadheaderprops", "payload": { "title": "profilePage", "backTarget": "app" } }`,
      );
    }
  }, []);

  useEffect(() => {
    // First time in Create Page reset language to default
    // if (!createPageRefreshed && lang !== defaultLang) {
    //   setLanguage(defaultLang);
    // }
    if (receivedLocationState?.initAgentProfile) {
      let newAgentProfile = { ...receivedLocationState.initAgentProfile };
      if (createPageRefreshed && !createPageRefreshDataUpdated) {
        // reloadData().then(({ agentProfileData, awardListData }) => {
        //   if (agentProfileData?.awards) {
        //     newAgentProfile.awards = agentProfileData.awards;
        //   }
        //   if (agentProfileData?.awardBadges) {
        //     newAgentProfile.awardBadges = agentProfileData.awardBadges;
        //   }
        //   setAwardList(awardListData);
        // });
        setCreatePageRefreshDataUpdated(true);
      }
      setAgentProfile(newAgentProfile);
      if (receivedLocationState?.updateProfileBody) {
        setUpdateProfileBody(receivedLocationState.updateProfileBody);
      }
    } else {
      reloadData().then(({ agentProfileData, awardListData }) => {
        setAgentProfile(agentProfileData);
        setAwardList(awardListData);
      });
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {isLoading || !agentProfile ? (
        <LayoutSplashScreen />
      ) : (
        <AgentProfileCreateMainForm
          agentProfile={agentProfile}
          updateProfileBody={updateProfileBody}
          createPageRefreshed={createPageRefreshed}
          createPageRefreshDataUpdated={createPageRefreshDataUpdated}
          awardList={awardList}
          mappedLocale={mappedLocale}
          promotionMappedLocale={promotionMappedLocale}
          currentLang={lang}
        />
      )}
    </>
  );
};

export default AgentProfileCreateMainPage;
