/* eslint-disable react-hooks/exhaustive-deps */
import Form, { useForm } from '@common/components/Form';
import { Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useCommonStyles } from '@common/styles/common-styles';
import { get } from 'lodash';
import { regionLocale } from 'src/app/i18n';
import { LibraryPublishStatus, PublishStatus, YesAndNo } from '../../../../constants';
import InformationSection from './Information';
import NotificationMessage from './NotificationMessage';
import PublishSettings from './PublishSettings';
import RegionSettingsSection from './RegionSettings';
import { useStyles } from './styles';
import { ResourceLibraryFormState } from '../../../../types';
import { fetchAllColumns } from '@/app/modules/ResourceLibrary/network/resourceCrud';
import PruDialog from '@/app/common/components/PruDialog';

const ENABLE_DESIGNATION = window.envConfig['ENABLE_DESIGNATION'];
const ENABLE_ZONE = window.envConfig['ENABLE_ZONE'];

export const renderDisplayStatusField = (rowData: any): string => {
  switch (rowData.status) {
    case LibraryPublishStatus.PublishToSetOnly:
      return 'component.status.publishedToSetOnly';
    case LibraryPublishStatus.Publish:
      return 'component.status.published';
    case LibraryPublishStatus.Unpublished:
      return 'component.status.unpublished';
    case LibraryPublishStatus.Draft:
    default:
      return 'component.status.draft';
  }
};

interface FormListProp {
  disabled: boolean;
  onSave: (formData: any, columnOptions: ColumnOptions) => void;
  blockName: string;
  categoryType: string;
  moduleType: string;
  blockType: string;
  columnType: string;
  initialValues: any;
  isEdit: boolean;
  history: any;
  location?: any;
  match?: any;
  currentId?: string;
  columnIdDefault?: string;

  loadingFun?: () => void;
}

export type ColumnOptions = {
  label: string;
  value: string;
  id: string;
}[];

const FormList: React.FC<FormListProp> = (props) => {
  const { classes } = useStyles();
  const { classes: commonClasses } = useCommonStyles();
  const { disabled, onSave, initialValues, isEdit, history, moduleType, currentId, columnIdDefault } = props;
  const [columnOptions, setColumnOptions] = useState<ColumnOptions>([]);
  const regionLocales = regionLocale;

  const [publishItems, setPublishItems] = useState<any[]>([]);
  const [objErrorValidateDialog, setObjErrorValidateDialog] = useState<{ isOpen: boolean; message: string }>({
    isOpen: false,
    message: '',
  });
  // eslint-disable-next-line
  const [form] = useForm();
  const intl = useIntl();
  const Translation = (id: string, variable?: Record<string, string>) => intl.formatMessage({ id }, variable);

  const isAdd = isEdit || disabled ? false : true;

  useEffect(() => {
    getDisplayStatus();
  }, [initialValues?.status]);

  useEffect(() => {
    // getColumnOptionList();
    if (initialValues?.moduleColumn?.length === 0 || isAdd) {
      initialValues.moduleColumn = [
        {
          column: columnIdDefault,
          weight: null,
        },
      ];
    }
    if (!initialValues?.designations) {
      initialValues.designations = [];
    }

    if (!initialValues?.zones) {
      initialValues.zones = [];
    }

    if (initialValues?.agendaList?.length === 0) {
      initialValues.agendaList = [
        {
          time: null,
          content: '',
        },
      ];
    }

    form.setFieldsValue({ ...initialValues });
    getDisplayStatus();
    // eslint-disable-next-line
  }, []);

  const getColumnOptionList = async () => {
    let options: any[] = [];
    let resultList = await fetchAllColumns(moduleType);
    resultList.map((item: any) => {
      const displayLabel = regionLocales.map((localItem, index) =>
        item.name[localItem] ? `${index > 0 ? ` (` : ``}${item.name[localItem]}${index > 0 ? `)` : ``}` : ``,
      );
      return options.push({ label: displayLabel, value: item.id, id: item.id });
    });
    setColumnOptions(options as ColumnOptions);
  };

  const onFinish = (data: ResourceLibraryFormState) => {
    const formData = form.getFieldsValue(true);
    formData.agent = 'designation';
    formData.removedMaterials = [];
    if (ENABLE_DESIGNATION !== 'true') {
      formData.designations = [];
    }
    if (ENABLE_ZONE !== 'true') {
      formData.zones = [];
    }
    onSave(formData, columnOptions);
  };

  const onSaveDraft = () => {
    const formData = form.getFieldsValue(true);
    formData.agent = 'designation';
    if (ENABLE_DESIGNATION !== 'true') {
      formData.designations = [];
    }
    if (ENABLE_ZONE !== 'true') {
      formData.zones = [];
    }
    form
      .validateFields()
      .then((res) => {
        onSave(formData, columnOptions);
      })
      .catch((err) => {
        const { errorFields } = err;
        if (errorFields?.length <= 0) {
          onSave(formData, columnOptions);
        }
      });

    return false;
  };

  const onCancel = () => {
    history.goBack();
  };

  const getDisplayStatus = (selectedType: string = '') => {
    // const isPublishedItem =
    // initialValues.status === LibraryPublishStatus.Publish || LibraryPublishStatus.PublishToSetOnly;
    const isDraft = initialValues.status === LibraryPublishStatus.Draft;
    // const isUnpublished = initialValues.status === LibraryPublishStatus.Unpublished;

    let displayStatus: any = [];

    if (isAdd || isDraft) {
      displayStatus = displayStatus.concat([PublishStatus.Draft, PublishStatus.Published]);
    } else {
      displayStatus = displayStatus.concat([PublishStatus.Published, PublishStatus.Unpublished]);
    }

    setPublishItems(displayStatus);
  };

  const onSubmitForm = async (getFieldValue: any) => {
    const status = getFieldValue('status');

    if (status === 'Draft') {
      onSaveDraft();
      return;
    }

    // validate before publishing resources
    const eventInvitation = getFieldValue('eventInvitation');
    if (eventInvitation === YesAndNo.yes) {
      const typeOfViews = getFieldValue('typeOfViews');
      if (typeOfViews?.length > 1) {
        setObjErrorValidateDialog({
          isOpen: true,
          message: Translation('warning.resource.limit_typeOfView_for_event'),
        });
        return;
      }
    }

    status === 'Unpublished' ? submitFormAnyWay() : form.submit();
  };

  const submitFormAnyWay = () => {
    onSaveDraft();
  };

  const closeErrorValidateDialog = () => {
    let status = false;
    if (status !== objErrorValidateDialog.isOpen) {
      setObjErrorValidateDialog({
        isOpen: status,
        message: objErrorValidateDialog.message,
      });
    }
  };

  return (
    <div>
      <Form form={form} onFinish={onFinish} initialValues={{ ...initialValues }}>
        <div className={classes.container}>
          <div className={classes.headerContainer}>
            <div className={classes.rowContainer}>
              <div className={commonClasses.header}>
                {isEdit
                  ? Translation('component.formTitle.edit')
                  : disabled
                    ? Translation('component.formTitle.detail')
                    : Translation('component.formTitle.add')}
              </div>
            </div>
            <Button className={classes.btnBack} variant="contained" onClick={() => history.goBack()}>
              {Translation('app.button.back')}
            </Button>
          </div>

          <InformationSection disabled={disabled} form={form} />

          <RegionSettingsSection form={form} moduleType={moduleType} disabled={disabled} />

          {/* Not show notification message */}
          {false && (
            <NotificationMessage
              disabled={disabled}
              form={form}
              initialValue={get(initialValues, 'notificationMessage', '')}
            />
          )}

          <PublishSettings
            disabled={disabled}
            columnOptions={columnOptions}
            publishItems={publishItems}
            onCancel={onCancel}
            onSubmit={onSubmitForm}
            form={form}
            moduleType={moduleType}
          />
        </div>
      </Form>

      <PruDialog
        open={objErrorValidateDialog.isOpen}
        canCloseDialog={true}
        hideConfirmBtn={true}
        onCancel={closeErrorValidateDialog}
        canncelBtnText={Translation('global.text.okay')}
        onOk={() => {}}
      >
        <span style={{ color: 'red' }}>{objErrorValidateDialog.message || ''}</span>
      </PruDialog>
    </div>
  );
};

export default FormList;
