import Create from './Create';
import {
  RECRUIT_BASE_PATH,
  RECRUIT_LIBRARY_BASE_PATH,
  RESOURCE_BASE_PATH,
} from '@/app/modules/ResourceLibrary/constants';
import { EModuleTypes } from '../constants';

export const SystemConfig = {
  blockName: 'component.formTitle.recruit-material',
  blockType: 'recruitMaterial',
  libraryBasePath: `${RESOURCE_BASE_PATH}${RECRUIT_BASE_PATH}${RECRUIT_LIBRARY_BASE_PATH}`,
  categoryType: 'recruitMaterialCategory',
  columnType: 'recruitMaterialColumn',
  addSuccessText: 'global.submit.success',
  columnModuleType: 'productionInfo',
  moduleType: EModuleTypes.Recruit,
};

const RecruitLibraryCreateContainer = (props: any) => {
  return <Create {...props} {...SystemConfig} />;
};

export default RecruitLibraryCreateContainer;
