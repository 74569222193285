import React, { FC, PropsWithChildren, useEffect } from 'react';
import { decodeJWT, getToken } from '../../modules/Auth/network/authCrud';
import { authenticate, setTokenInfo } from '../../modules/Auth/network/authSlice';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { LayoutSplashScreen } from 'src/app/layout';
import { useUrlQueryReader } from '../utils/hook-utils';
import { fetchAppConfigs } from '../network/crud/configsCurd';
import { preFillZero } from '../utils/common-utils';
import { ROLLOUT_VERSION, takeLoginEvent, updateUserProperties } from '../ga/ga';
import { getUserLBU } from '../ga/utils';
import { useIntl } from 'react-intl';

const SingleSignOn: FC<PropsWithChildren> = ({ children }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const intl = useIntl();
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const authorizationCode = params.get('authorizationCode');
  const staffSelectedAgentCode = params.get('staffSelectedAgentCode');

  const excludeParams = ['authorizationCode', 'staffSelectedAgentCode'];
  const query = useUrlQueryReader(excludeParams);

  useEffect(() => {
    const login = async (code: string) => {
      if (code) {
        try {
          const response = await getToken(code, dispatch);
          const { accessToken, expiresIn, refreshToken } = response;
          const appConfigs = await fetchAppConfigs(accessToken, dispatch);
          if (accessToken && expiresIn && refreshToken && appConfigs) {
            const tokenInfo = await decodeJWT(accessToken);
            window.localStorage.setItem('jwt', accessToken);
            dispatch(authenticate(accessToken));
            dispatch(setTokenInfo(tokenInfo));
            if (staffSelectedAgentCode) {
              window.localStorage.setItem('staffSelectedAgentCode', staffSelectedAgentCode);
            }
            const expireDate = moment(new Date()).add(Number(expiresIn), 's').toDate();
            const abondonSession = moment(new Date()).add(1, 'd').toDate();
            window.localStorage.setItem('expireDate', expireDate.toISOString());
            window.localStorage.setItem('abondonSession', abondonSession.toISOString());
            window.localStorage.setItem('refreshToken', refreshToken);
            window.localStorage.setItem('appConfigs', JSON.stringify(appConfigs));

            takeLoginEvent(preFillZero(tokenInfo.sub), {
              module: 'Auth',
              feature: 'Login',
              journey: 'agent_login',
              stage: 'login_success',
              screen_name: 'SingleSignOnPage',
              method: 'sso',
              status: 'Success',
            });

            updateUserProperties({
              user_id: preFillZero(tokenInfo.sub),
              user_lbu: getUserLBU(appConfigs),
              country: tokenInfo.region,
              language: intl.locale,
              Agent: preFillZero(tokenInfo.sub),
              rollout: ROLLOUT_VERSION,
              user_role: tokenInfo.role,
            });

            let path = history.location.pathname;
            path += query;
            const noHeader = params.get('noHeader');
            if (noHeader !== null) {
              sessionStorage.setItem('noHeader', 'true');
            }
            history.push(path);
          }
        } catch (err) {
          history.push('/');
        }
      }
    };

    if (authorizationCode) {
      login(authorizationCode);
    }
    // eslint-disable-next-line
  }, []);

  return <>{authorizationCode ? <LayoutSplashScreen /> : children}</>;
};

export default SingleSignOn;
