import { useState } from 'react';
import { ClickAwayListener, Tooltip } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { CardHeaderProps } from '../../types/dashboard-card-types';
import { ArrowRightIcon, InfoIcon } from '../../Icons';

const useStyles = makeStyles()((theme) => ({
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 55,
    padding: '18px 20px',
    borderBottom: '1px solid #F0F0F0',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  headerIcon: {
    marginRight: 10,
  },
  title: {
    fontSize: 16,
    fontWeight: 600,
    marginRight: 8,
  },
  tooltip: {
    backgroundColor: '#333333',
  },
  arrow: {
    color: '#333333',
  },
}));

const CardHeader = ({ headerIcon, title, tooltipContent, handleRedirect }: CardHeaderProps) => {
  const { classes } = useStyles();
  const [showTooltip, setShowTooltip] = useState<boolean>(false);

  const handleCloseTooltip = () => {
    setShowTooltip(false);
  };

  const handleOpenTooltip = () => {
    setShowTooltip(true);
  };

  return (
    <div className={classes.headerContainer}>
      <div className={classes.titleContainer}>
        {headerIcon && <span className={classes.headerIcon}>{headerIcon}</span>}
        <span className={classes.title}>{title}</span>
        {tooltipContent && (
          <ClickAwayListener onClickAway={handleCloseTooltip}>
            <Tooltip
              classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
              arrow
              open={showTooltip}
              onClose={handleCloseTooltip}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              title={tooltipContent}
            >
              <span>
                <InfoIcon onClick={handleOpenTooltip} />
              </span>
            </Tooltip>
          </ClickAwayListener>
        )}
      </div>
      {handleRedirect && (
        <div>
          <ArrowRightIcon onClick={handleRedirect} />
        </div>
      )}
    </div>
  );
};

export default CardHeader;
