import React, { FC, PropsWithChildren, useMemo, Children, isValidElement, cloneElement } from "react";
import { RouteComponentProps } from "react-router-dom";
import { getDayStart, getDayEnd } from "../utils/time-utils";

type ParamsProviderProps = {
  acceptKeys: string[]
}

export type ParamsProps = {
  initialParams?: Record<string, string>;
  onChangeQueryParams?: <T extends Record<string, unknown>>(obj: T, exclude?: (keyof T)[]) => void;
}

const ParamsProvider: FC<PropsWithChildren<RouteComponentProps & ParamsProviderProps>> = ({ history, acceptKeys, children }) => {

  const onChangeQueryParams = (obj: Record<string, unknown>) => {
    let queryPath = "";
    acceptKeys.forEach(key => {
      const param = obj[key];
      if (typeof param === 'object') {
        if (param) {
          if (key.toLowerCase().includes('from')) {
            queryPath += `${key}=${getDayStart(param as Date).toISOString()}&`;
          }
          if (key.toLowerCase().includes('to')) {
            queryPath += `${key}=${getDayEnd(param as Date).toISOString()}&`;
          }
        }
      } else {
        queryPath += (param !== undefined && param !== null && param !== "") ? `${key}=${param}&` : '';
      }
    });
    queryPath = queryPath.slice(0, -1);
    history.push({
      search: queryPath
    });
  }

  const childrenWithProps = useMemo(() => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    let results: Record<string, string> = {};
    acceptKeys.forEach(key => {
      let value =  params.get(key);
      if (value) {
        results[key] = value;
      }
    });
    return Children.map(children, child => {
      if (isValidElement<ParamsProps>(child)) {
        return cloneElement(child, { initialParams: results, onChangeQueryParams });
      }
    });
  // eslint-disable-next-line
  }, []);

  return (
    <>
      {childrenWithProps}
    </>
  )
}

export default ParamsProvider;