import React, { FC, useState, useEffect, useMemo, useRef, useCallback } from 'react';
import ChartsEmbedSDK from "@mongodb-js/charts-embed-dom";
import { DashboardLibrary, TopDashboardDetail, TopDashboards } from '../../../../types/dashboard-types';
import { fetchDashboardJWT } from 'src/app/modules/AgencyCampaign/network/dashboardCrud';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import { TokenInfo } from '../../../../../Auth/types/auth-types';

const baseUrl = window.envConfig["REACT_APP_MONGODB_CHART_BASE_URL"];
const chartSubscriptionKey = window.envConfig["REACT_APP_MONGODB_CHART_SUBSCRIPTION_KEY"];

type ChartListProps = {
  filter: any;
  myFilter: any;
  onRefresh?: boolean,
  refreshdata: () => void;
  setExcelData: (data: any) => void;
  setMyFilter: (data: any) => void;
};

const DashboardDetailPage: FC<ChartListProps> = ({ filter, myFilter, onRefresh, refreshdata, setExcelData, setMyFilter }) => {
  const user = useSelector<RootState, TokenInfo | undefined>(state => state.auth.user);
  const agentCode = user?.sub || '';
  const defaultFilter = { agentCode };

  const sdk = new ChartsEmbedSDK({
    baseUrl,
    getUserToken: async function () {
      return await fetchDashboardJWT(user?.sub, chartSubscriptionKey)
    }
  });
  var chartID = "";


  for (var i = 0; i < DashboardLibrary.report.length; i++) {
    var object = DashboardLibrary.report[i]
    if (object.chartName == filter.chartName) {
      chartID = object.chartID;
      if (object.chartType == filter.chartType) {
        chartID = object.chartID;
        break;
      }
    }
    // chartID = ""

  }

  console.log("chartName", filter.chartName);
  console.log("chartID", chartID);

  const chartDiv = useRef<HTMLDivElement>(null);
  const chartDivLeft = useRef<HTMLDivElement>(null);
  const chartDivRight = useRef<HTMLDivElement>(null);
  const [rendered, setRendered] = useState(false);
  const [chart, reRender] = useState(sdk.createChart({
    chartId: chartID,
    height: 800,
    theme: "light",
    filter: defaultFilter,
  }));

  const [chartLeft, setChartLeft] = useState(sdk.createChart({
    chartId: "62e80a26-41d9-4e11-8e7f-5b0ac5e73277",
    height: 800,
    theme: "light",
    filter: defaultFilter,
  }));

  const [chartRight, setChartRight] = useState(sdk.createChart({
    chartId: "6267bd98-d717-4757-8bff-7d34608e2384",
    height: 800,
    theme: "light",
    filter: defaultFilter,
  }));

  const [isShowChart, setIsShowChart] = useState<Boolean>(false);

  const getTopCharts = (topChartsName: TopDashboardDetail) => {
    let isChart3 = TopDashboards.get(filter.chartName)?.type == "Unique" ? true : false;
    let isChart2 = TopDashboards.get(filter.chartName)?.type == "Among" ? true : false;

    if (chartDivLeft.current) {
      let left = sdk.createChart({
        chartId: topChartsName.dashboardLeft,
        height: isChart3 ? 400 : 200,
        width: isChart3 ? 800 : 200,
        theme: "light",
        filter: defaultFilter,
      });
      setChartLeft(left);
      left.render(chartDivLeft.current).then(() => setRendered(true)).catch(err => console.log("Error during get data.", err));
    }
    if (chartDivRight.current && topChartsName.dashboardRight) {
      let right = sdk.createChart({
        chartId: topChartsName.dashboardRight,
        height: isChart2 ? 400 : 200,
        width: isChart2 ? 800 : 200,
        theme: "light",
        filter: defaultFilter,
      });
      setChartRight(right);
      right.render(chartDivRight.current).then(() => setRendered(true)).catch(err => console.log("Error during get data.", err));
    }
  }



  useEffect(() => {
    if (chartDiv.current) {
      chart.render(chartDiv.current).then(() => {
        setRendered(true);
        setExcelData(chart.getData());
        chart.getData().then((res) => console.log("documents" + JSON.parse(JSON.stringify(res)).documents.length));
      }
      ).catch(err => console.log("Error during Charts rendering.", err));
    } else {
      console.log("Error")
    }
  }, [chartDiv, chart, rendered]);

  useEffect(() => {

    switch (filter.filter) {
      case 'Assigned Date':
        setMyFilter({
          ...myFilter,
          assignedDate: filter.value != "" ? filter.value : undefined,
        });
        break;
      case 'Existing Customer':
        setMyFilter({
          ...myFilter,
          type: filter.value != "" ? filter.value : undefined,
        });
        break;

      default:
        setMyFilter({
          assignedDate: undefined,
          type: undefined,
        });
        break;
    }

    console.log("MyFilter");
    console.log(myFilter);
  }, [chart, filter.filter, filter.value, rendered])

  useEffect(() => {

    let obj = JSON.parse(JSON.stringify(myFilter));
    obj.agentCode = agentCode;

    if (obj?.type) {
      myFilter.type != "All" ?
        obj.type = myFilter.type == "Yes" ? "existingCustomer" : myFilter.type == "No" ? { $ne: "existingCustomer" } : myFilter.type
        : delete obj.type;
    }

    if (obj?.assignedDate) {   // pls put the date type to be filtered here
      obj.$and = [{ assignedDate: { $gte: new Date(myFilter.assignedDate[0]) } }, { assignedDate: { $lte: new Date(myFilter.assignedDate[1]) } }];
      delete obj.assignedDate;
    }

    chart.setFilter(obj).catch(err => console.log("Error while filtering.", err));
    chartLeft.setFilter(obj).catch(err => console.log("Error while filtering.", err));
    chartRight.setFilter(obj).catch(err => console.log("Error while filtering.", err));

  }, [chart, filter.value, rendered, myFilter]);

  useEffect(() => {
    reRender(sdk.createChart({ chartId: chartID, height: 800, theme: "light", filter: defaultFilter }));

    if (TopDashboards.get(filter.chartName) && filter.chartType == "Table") {
      setIsShowChart(true);
      getTopCharts(TopDashboards.get(filter.chartName));
    }
    else {
      setIsShowChart(false);
    }
  }, [filter.chartName, filter.chartType, isShowChart]);

  useEffect(() => {
    if (chartDiv.current) {
      chart.render(chartDiv.current).then(() => {
        setRendered(true);
        setMyFilter({
          assignedDate: undefined,
          type: undefined,
        });
        setExcelData(chart.getData());
        chart.getData().then((res) => console.log("documents" + JSON.parse(JSON.stringify(res)).documents.length));
      })
        .catch(err => console.log("Error during get data.", err));
    } else {
      console.log("Error")
    }
  }, [filter.chartName, filter.chartType, rendered]);


  return (
    <>
      {isShowChart && (
        <div className="top" style={TopDashboards.get(filter.chartName)?.type == "Among" ? { marginBottom: 20 } : { marginBottom: 20, display: "flex", flexDirection: "row" }}>
          <div className="chart" ref={chartDivLeft} />
          {TopDashboards.get(filter.chartName)?.dashboardRight &&
            <div className="chart" ref={chartDivRight} style={TopDashboards.get(filter.chartName)?.type == "Among" ? { marginTop: 20 } : { marginLeft: 20 }} />
          }
        </div>
      )}
      <div className="chart" ref={chartDiv} />
    </>
  );

};

export default DashboardDetailPage;
