import { Dispatch } from "react";
import { PlatformDashboard } from "../types/dashboard-types";
const jose = require('jose');

export type ChartListParam = {
  chartName: string;
  chartType: string;
  filter: string;
  value: string;
};

export type DashboardBody = {
  name: string;
  ios: PlatformDashboard;
  android: PlatformDashboard;
};

export const createDashboard = async (id: string, dispatch?: Dispatch<any>): Promise<void> => {

}

export const updateDashboard = async (id: string, body: DashboardBody, dispatch?: Dispatch<any>): Promise<void> => {

};

export const fetchDashboardItem = async (id: string, dispatch?: Dispatch<any>): Promise<any> => {

};

export const fetchDashboardList = async (params: ChartListParam, dispatch?: Dispatch<any>): Promise<any> => {

};

export const fetchChartList = async (params: ChartListParam, dispatch?: Dispatch<any>): Promise<any> => {
};

export const deleteDashboard = async (id: string, dispatch?: Dispatch<any>): Promise<void> => {
}

export const fetchDashboardJWT = async (user: any, password: any): Promise<any> => {
  const secret = new TextEncoder().encode(password);
  const alg = 'HS256';
  return new jose.SignJWT({ username: user })
    .setProtectedHeader({ alg, typ: 'JWT' })
    .setIssuedAt()
    .setExpirationTime('1h')
    .sign(secret);
};
