import { FC, useState, useEffect } from 'react';
import moment, { Moment } from 'moment';
import PruPicker from '../PruPicker';
import { weekPickerStyles } from './styles';

type WeekPickerProps = {
  initDateFrom?: Date;
  initDateTo?: Date;
  startYear: number;
  weekFormat: string;
  isIsoWeek?: boolean;
  onChange: (week: string, year: string) => void;
};

const WeekPicker: FC<WeekPickerProps> = ({ initDateFrom, initDateTo, startYear, weekFormat, isIsoWeek, onChange }) => {
  const now = new Date();
  const currentYear = moment(now).year();

  const [week, setWeek] = useState<string>();
  const [year, setYear] = useState<string>();
  const [weekOptionList, setWeekOptionList] = useState<string[]>([]);
  const [yearOptionList, setYearOptionList] = useState<string[]>([]);
  const [isYearChanged, setIsYearChanged] = useState<boolean>(false);

  const dateFromDayOfWeek = isIsoWeek ? 1 : 0;
  const dateToDayOfWeek = isIsoWeek ? 0 : 6;

  const getDateByDayOfWeek = (date: Moment, dayOfWeek: number, method: 'add' | 'subtract') => {
    const day = date.day();
    let diffDays = 0;
    if (day === dayOfWeek) {
      return date.toDate();
    } else if (day > dayOfWeek) {
      diffDays = 7 - (day - dayOfWeek);
    } else {
      diffDays = dayOfWeek - day;
    }
    return method === 'add' ? date.add(diffDays, 'day').toDate() : date.subtract(7 - diffDays, 'day').toDate();
  };

  const generateWeekOptionList = (year: number, init?: boolean): string => {
    let newWeekOptionList: string[] = [];
    const isCurrentYear = year === currentYear;
    const isStartYear = year === startYear;
    const dateFromNowByYear = moment(now).set('year', year);
    if (!isStartYear) {
      dateFromNowByYear.startOf('year');
    }
    const dateFrom = getDateByDayOfWeek(moment(dateFromNowByYear), dateFromDayOfWeek, isStartYear ? 'subtract' : 'add');
    const dateTo = isCurrentYear
      ? getDateByDayOfWeek(moment(now), dateToDayOfWeek, 'add')
      : getDateByDayOfWeek(moment(dateFromNowByYear.endOf('year')), dateToDayOfWeek, 'add');

    const weekCount = moment(dateTo).diff(dateFrom, 'week');

    for (let i = 0; i <= weekCount; i++) {
      const startDateOfWeek = moment(dateFrom).add(i, 'week');
      const endDateOfWeek = moment(startDateOfWeek).endOf(isIsoWeek ? 'isoWeek' : 'week');
      const startDateOfWeekStr = startDateOfWeek.format(weekFormat);
      const endDateOfWeekStr = endDateOfWeek.format(weekFormat);
      newWeekOptionList.push(`${startDateOfWeekStr}-${endDateOfWeekStr}`);
    }
    setWeekOptionList(newWeekOptionList);
    const newWeek = isCurrentYear ? newWeekOptionList[newWeekOptionList.length - 1] : newWeekOptionList[0];
    // const newWeek = newWeekOptionList[Math.floor(newWeekOptionList.length / 2)];
    if (!init || !initDateFrom || !initDateTo) {
      setWeek(newWeek);
    }
    if (isYearChanged) {
      setIsYearChanged(false);
    }
    return newWeek;
  };

  const handleChangeWeek = (value: string) => {
    if (year) {
      setWeek(value);
      onChange(value, year);
    }
  };

  const handleChangeYear = (value: string) => {
    setYear(value);
    setIsYearChanged(true);
  };

  const initSelection = () => {
    if (!initDateFrom || !initDateTo) {
      setYear(currentYear.toString());
      generateWeekOptionList(currentYear, true);
    } else {
      const selectedYear = moment(initDateFrom).year();
      const initDateFromStr = moment(initDateFrom).format(weekFormat);
      const initDateToStr = moment(initDateTo).format(weekFormat);
      setYear(selectedYear.toString());
      generateWeekOptionList(selectedYear, true);
      setWeek(`${initDateFromStr}-${initDateToStr}`);
    }
  };

  const initData = () => {
    const newYearOptionList = [];
    for (let i = startYear; i <= currentYear; i++) {
      newYearOptionList.push(i.toString());
    }
    setYearOptionList(newYearOptionList);
    initSelection();
  };

  useEffect(() => {
    initData();
  }, []);

  useEffect(() => {
    if (isYearChanged && year) {
      const newWeek = generateWeekOptionList(parseInt(year));
      onChange(newWeek, year);
    }
  }, [isYearChanged]);

  return (
    <>
      {week && year ? (
        <div style={weekPickerStyles.container}>
          <PruPicker
            value={week}
            options={weekOptionList}
            onChange={handleChangeWeek}
            pickerStyle={weekPickerStyles.weekPicker}
            highlightStyle={weekPickerStyles.highlight}
          />
          <PruPicker
            value={year}
            options={yearOptionList}
            onChange={handleChangeYear}
            pickerStyle={weekPickerStyles.yearPicker}
            highlightStyle={weekPickerStyles.highlight}
          />
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default WeekPicker;
