export enum LinkType {
  Url = 'url',
  UrlWithVideo = 'urlWithVideo',
  UrlWithInfor = 'urlWithInfor',
}

export enum LibraryPublishStatus {
  Publish = 'Published',
  Unpublished = 'Unpublished',
  Draft = 'Draft',
  PublishToSetOnly = 'PublishedToSetOnly',
}

export const PublishStatus: { [item: string]: any } = {
  Draft: {
    label: 'resouce.common.draft',
    key: 'Draft',
  },
  Published: {
    label: 'resouce.common.publish',
    key: 'Published',
  },
  PublishedToSetOnly: {
    label: 'resouce.common.publish-to-set-only',
    key: 'PublishedToSetOnly',
  },
  Unpublished: {
    label: 'resouce.common.unpublish',
    key: 'Unpublished',
  },
};

export enum RegisterPageType {
  'template' = 'template',
  'insert-url' = 'url',
}

export enum YesAndNo {
  'yes' = 'Y',
  'no' = 'N',
}

export enum ResourceType {
  file = 'file',
  link = 'link',
  content = 'content',
  text = 'text',
  materialSet = 'materialSet',
  picture = 'picture',
}

export const linkOptions = [
  { value: LinkType.Url, label: 'url' },
  { value: LinkType.UrlWithVideo, label: 'urlWithVideo' },
  { value: LinkType.UrlWithInfor, label: 'urlWithInfor' },
];

export enum EQuickQuote {
  Default = 'none',
  Hcr = 'hcr',
  Edu = 'edu',
  OptimalProtect = 'optimal-protect',
  Amassment = 'amassment',
  HealthInsurance = 'health-insurance',
  FlexibleInvestment = 'flexible-investment',
}

export const quickQuoteOptions = [
  { value: EQuickQuote.Default, label: 'None' },
  { value: EQuickQuote.Hcr, label: 'PRU - Hành Trang Vui Khỏe' },
  { value: EQuickQuote.Edu, label: 'PRU - Hành Trang Trưởng Thành' },
  { value: EQuickQuote.OptimalProtect, label: 'PRU - Bảo Vệ Tối Ưu' },
  { value: EQuickQuote.Amassment, label: 'PRU - Chủ Động Cuộc Sống' },
  { value: EQuickQuote.HealthInsurance, label: 'PRU - An Vui' },
  { value: EQuickQuote.FlexibleInvestment, label: 'PRU - Đầu tư linh hoạt' },
];

export enum EModuleTypes {
  Recruit = 'Recruit',
  Sale = 'Sales',
  Promotion = 'Promotion',
}

export enum EViewType {
  AllTraditionalAgents = 'allTraditionalAgents',
  AgentLeaders = 'agentLeaders',
  PruventureLeaders = 'pruventureLeaders',
  Pruventure = 'pruventure',
  GroupOfUsers = 'groupOfUsers',
}

export enum LibraryPublishedMode {
  ALL = 'ALL',
  PUBLISHED = 'Published',
  UNPUBLISHED = 'Unpublished',
  DRAFT = 'Draft',
  PUBLISHEDTOSETONLY = 'PublishedToSetOnly',
}

export const LIBRARY_DEFAULT_CONFIG = {
  eventAddress: '',
  eventLocation: '',
  coverpage: null,
  name: '',
  introduction: '',
  tag: [],
  file: null,
  link: '',
  canShared: 'Y',
  sharedTitle: '',
  sharedCoverpage: null,
  sharedIntroduction: '',
  // quickQuote: QuickQuote.Hcr,
  linkType: LinkType.Url,
  videos: [],
  allowedForEProfile: 'N',
  prodcutType: '',
  targetReceiver: 'all',
};

const env = window.envConfig['REACT_APP_ENV'];

interface EventConfigInterface {
  [key: string]: any;
}

export const EVENT_TEMPLATE_CONFIG: EventConfigInterface = {
  TEMPLATE_1: {
    id: 'AD_EVENT_BOP_1',
    label: '1 - BOP thường (Đứng, hình ảnh trên)',
    url: env === 'prod' ? 'https://pru-force-bdm-bop.webflow.io/' : 'https://pru-force-bdm-bop-uat.webflow.io/',
  },
  TEMPLATE_2: {
    id: 'AD_EVENT_BOP_2',
    label: '2 - BOP đặc biệt (Đứng, hình ảnh giữa)',
    url:
      env === 'prod'
        ? 'https://pru-force-bdm-bop.webflow.io/no-title'
        : 'https://pru-force-bdm-bop-uat.webflow.io/no-title',
  },
  TEMPLATE_3: {
    id: 'AD_EVENT_BOP_3',
    label: '3 - BOP đặc biệt (Đứng, fix tên chương trình)',
    url:
      env === 'prod'
        ? 'https://pru-force-bdm-bop.webflow.io/no-banner'
        : 'https://pru-force-bdm-bop-uat.webflow.io/no-banner',
  },
  TEMPLATE_4: {
    id: 'AD_EVENT_BOP_4',
    label: '4 - BOP thường (Ngang, hình ảnh bên trái)',
    url:
      env === 'prod'
        ? 'https://pru-force-bdm-bop.webflow.io/landscape'
        : 'https://pru-force-bdm-bop-uat.webflow.io/landscape',
  },
};

export enum ProductType {
  'Conventional' = 'conventional',
  'Sharia' = 'sharia',
}

export const CHECK_IN_URL =
  env === 'prod'
    ? 'https://prudential-event-check-in-page-vbop.webflow.io/'
    : 'https://prudential-event-check-in-page-vbop-dev.webflow.io/';

export const FEEDBACK_URL = 'https://forms.office.com/r/FYNi31qh7n?origin=lprLink';

export const defaultTextColorOptions = [{ value: '#FFFFFF', label: '#FFFFFF' }];
