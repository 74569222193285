import { FC, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, IconButton } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { AddCircle, Info } from '@mui/icons-material';
import { isPhone } from 'src/app/common/constants';
import { AGENT_PROFILE_PATH } from 'src/app/modules/AgentProfile/constants';
import {
  AgentProfileItem,
  AwardItem,
  LocationState,
  FromPageEnum,
  AboutMeDisplayStatusEnum,
} from 'src/app/modules/AgentProfile/types/agent-profile-types';
import { updateAgentProfile, UpdateProfileBody } from 'src/app/modules/AgentProfile/network/agentProfileCrud';
import InputForm from '../../../common/components/InputForm';
import RichTooltip from 'src/app/common/components/RichTooltip/RichTooltip';
import ItemCard from '../../../common/components/ItemCard';
import { regionLocale } from 'src/app/i18n/utils/i18n-utils';
import AwardList from '../../../common/components/AwardList';
import WhitePlayer from '../../../common/components/WhitePlayer';
import { EditIcon, ArrowRightIcon } from 'src/app/modules/AgentProfile/common/icons';
import { translateAllParameters } from '../../../common/utils/i18n-utils';
import { getAboutDataTemplateMapping } from '../../../common/utils/data-utils';
import { get } from 'lodash';
import { Options } from '../../../common/components/toggle-options/toggle-options';
import GAClickFragment from '../../../../../common/ga/components/GAClickFragment';
import { takeUIClickEvent } from '../../../../../common/ga/ga';

type AgentProfileCreateMainFormProps = {
  agentProfile: AgentProfileItem;
  updateProfileBody: UpdateProfileBody;
  createPageRefreshed: boolean;
  createPageRefreshDataUpdated: boolean;
  awardList: AwardItem[];
  mappedLocale: string;
  promotionMappedLocale: string;
  currentLang: string;
};

const INNER_WIDTH = window.innerWidth;
const SECTION_WIDTH = 20;
const SECTION_MARGIN = 8;
const PROMOTION_CARD_COLUMN_GAP = 29;

const iPadViewContainerStyle = {
  display: 'flex',
  columnGap: 8,
};

const useStyles = makeStyles()((theme) => ({
  container: {
    ...(() => (isPhone ? {} : iPadViewContainerStyle))(),
  },
  subContainer: {
    width: isPhone ? '100%' : 'calc(50% - 4px)',
  },
  header: {
    fontSize: 16,
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    '&:before': {
      content: "''",
      display: 'inline-block',
      height: 16,
      width: 3,
      backgroundColor: 'red',
      marginRight: 10,
      marginLeft: 10,
    },
  },
  sectionContainer: {
    padding: SECTION_WIDTH,
    marginBottom: SECTION_MARGIN,
    backgroundColor: theme.palette.common.white,
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 15,
  },
  tooltip: {
    maxWidth: window.innerWidth - 32,
    paddingInline: 16,
    paddingBlock: 8,
  },
  tooltipContent: {
    color: '#7D8699',
    marginLeft: 4,
    width: 16,
    height: 16,
  },
  iconButton: {
    padding: 8,
    marginTop: -8,
    width: 43,
  },
  aboutMeContainer: {
    padding: 10,
  },
  addIcon: {
    color: '#E8192C',
    height: 24,
    width: 24,
    marginBottom: 10,
  },
  emptyAddContainer: {
    padding: 10,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  intouchMeContainer: {
    paddingLeft: 10,
    display: 'flex',
    flexDirection: 'row',
  },
  intouchMeIcon: {
    borderRadius: 50,
    borderWidth: 2,
    borderColor: '#F0F0F0',
    padding: 10,
    marginRight: 30,
  },
  viewMore: {
    fontSize: 14,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  disabled: {
    color: 'rgba(0, 0, 0, 0.26)',
  },
  imgCircleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  imgCircle: {
    width: 150,
    height: 150,
    borderRadius: '50%',
    objectFit: 'cover',
  },
  inputContainer: {
    marginLeft: 10,
  },
  footerContainer: {
    textAlign: 'center',
    margin: '30px 0',
  },
  button: {
    color: theme.palette.common.white,
    backgroundColor: '#E8192C',
    height: 48,
    width: '100%',
    fontSize: 16,
  },
  iPadViewPromotionContainer: {
    display: 'flex',
    alignItems: 'center',
    columnGap: PROMOTION_CARD_COLUMN_GAP,
  },
  promotionCard: {
    width: '100%',
  },
  iPadViewNoPromotion: {
    justifyContent: 'center',
  },
  addMsgContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontSize: 14,
    fontWeight: 500,
    color: '#E8192C',
  },
  playerContainer: {
    borderRadius: 10,
    paddingInline: 10,
    paddingBlock: 5,
  },
  iPadViewPlayerContainer: {
    borderRadius: 10,
    maxWidth: INNER_WIDTH * 0.8,
    paddingBlock: 5,
  },
}));

const AgentProfileCreateMainForm: FC<AgentProfileCreateMainFormProps> = ({
  agentProfile,
  updateProfileBody,
  createPageRefreshed,
  createPageRefreshDataUpdated,
  awardList,
  mappedLocale,
  promotionMappedLocale,
  currentLang,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { classes } = useStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const { profilePictureUrl, designation, license, phone, email } = agentProfile.agentDetail;

  const hasAwards = agentProfile.awards?.length !== 0 || agentProfile.awardBadges?.length !== 0;
  const hasPromotions =
    agentProfile.promotions[promotionMappedLocale] && agentProfile.promotions[promotionMappedLocale].length !== 0;

  const [showToolTips, setShowToolTips] = useState<boolean>(false);

  const passingLocationState: LocationState = {
    initAgentProfile: agentProfile,
    from: FromPageEnum.CREATE,
    updateProfileBody,
    createPageRefreshed,
    createPageRefreshDataUpdated,
  };

  const [isIPadAwardViewMore, setIsIPadAwardViewMore] = useState<boolean>(false);

  const handleNext = () => {
    const secondLang = regionLocale.filter((locale) => locale !== currentLang)[0];
    history.push(`${AGENT_PROFILE_PATH}/create?noHeader&lang=${secondLang}`, {
      ...passingLocationState,
      createPageRefreshed: true,
    });
    window.location.reload();
  };

  const handleSubmit = async () => {
    await updateAgentProfile(updateProfileBody, dispatch);
    history.push(`${AGENT_PROFILE_PATH}/create-success?noHeader`);
  };

  const resolveAboutMeParameter = (text: string) =>
    translateAllParameters(getAboutDataTemplateMapping(agentProfile), text);

  // if display entry: Leave Us Your Contact
  const displayReferralLink = useMemo(() => {
    const referralLinkOptions = get(agentProfile, 'referralLinkOptions', []);
    if (!referralLinkOptions || referralLinkOptions.length < 1) {
      return false;
    }
    return referralLinkOptions.some((item: Options) => item.isOn === '1' && item.status === '1');
  }, [agentProfile]);

  return (
    <>
      <div className={classes.container}>
        <div className={classes.subContainer}>
          <div className={classes.sectionContainer}>
            <div className={classes.headerContainer}>
              <div className={classes.header}>{Translation(`agentProfile.ap_profile_picture_label`)}</div>
            </div>
            <div className={classes.imgCircleContainer}>
              <img src={profilePictureUrl} alt="" className={classes.imgCircle} />
            </div>
          </div>
          <div className={classes.sectionContainer} style={{ paddingBottom: 10 }}>
            <div className={classes.headerContainer}>
              <div className={classes.header}>
                {Translation(`agentProfile.ap_basic_information_header_title`)}
                <RichTooltip
                  open={showToolTips}
                  fontColor="white"
                  backgroundColor="#333333"
                  placement="bottom"
                  content={
                    <div className={classes.tooltip}>{Translation(`agentProfile.ap_contact_staff_message`)}</div>
                  }
                  onClose={() => setShowToolTips(!showToolTips)}
                >
                  <div onClick={() => setShowToolTips(!showToolTips)}>
                    <Info className={classes.tooltipContent} />
                  </div>
                </RichTooltip>
              </div>
            </div>
            <div className={classes.inputContainer}>
              <InputForm
                title={Translation(`agentProfile.ap_name_label`)}
                mandatory={true}
                readOnly={true}
                defaultValue={agentProfile.name[mappedLocale]}
              ></InputForm>
              <InputForm
                title={Translation(`agentProfile.ap_achievements_label`)}
                mandatory={true}
                readOnly={true}
                defaultValue={agentProfile.achievements.join(', ')}
              ></InputForm>
              <InputForm
                title={Translation(`agentProfile.ap_position_label`)}
                mandatory={true}
                readOnly={true}
                defaultValue={designation[mappedLocale] ?? ''}
              ></InputForm>
              <InputForm
                title={Translation(`agentProfile.ap_gi_license_label`)}
                mandatory={true}
                readOnly={true}
                defaultValue={
                  license.gi === 'Y'
                    ? Translation('agentProfile.getInTouchWithMe.yes')
                    : Translation('agentProfile.getInTouchWithMe.no') ?? ''
                }
              ></InputForm>
              <InputForm
                title={Translation(`agentProfile.ap_ia_license_label`)}
                mandatory={true}
                readOnly={true}
                defaultValue={license.hkfi ?? ''}
              ></InputForm>
              <InputForm
                title={Translation(`agentProfile.ap_mpf_license_label`)}
                mandatory={true}
                readOnly={true}
                defaultValue={license.mpf ?? ''}
              ></InputForm>
              <InputForm
                title={Translation(`agentProfile.ap_sfc_license_label`)}
                mandatory={true}
                readOnly={true}
                defaultValue={license.sfc ?? ''}
              ></InputForm>
            </div>
          </div>
          <div className={classes.sectionContainer}>
            <div className={classes.headerContainer}>
              <div className={classes.header}>{Translation(`agentProfile.ap_get_in_touch_with_me_header_title`)}</div>
              <IconButton
                className={classes.iconButton}
                onClick={() => {
                  takeUIClickEvent({
                    journey: 'create_agent_profile',
                    stage: 'edit_contact_info',
                    object_name: 'Edit',
                    object_type: 'BUTTON',
                  });
                  history.push(`${AGENT_PROFILE_PATH}/getInTouchWithMe?noHeader`, passingLocationState);
                }}
              >
                <EditIcon />
              </IconButton>
            </div>
            <div className={classes.inputContainer}>
              <InputForm
                title={Translation(`agentProfile.ap_contact_method_phone_label`)}
                mandatory={true}
                readOnly={true}
                defaultValue={phone.mobile ?? ''}
              ></InputForm>
              <InputForm
                title={Translation(`agentProfile.ap_contact_method_email_label`)}
                mandatory={true}
                readOnly={true}
                defaultValue={email ?? ''}
              ></InputForm>
              {agentProfile.enableWhatsapp && (
                <InputForm
                  title={Translation(`agentProfile.ap_contact_method_whatsapp_label`)}
                  mandatory={true}
                  readOnly={true}
                  defaultValue={phone.mobile ?? ''}
                ></InputForm>
              )}
            </div>
            {displayReferralLink && (
              <div className={`${classes.viewMore} ${classes.disabled}`}>
                {Translation('agentProfile.leave-us-your-contact')} <ArrowRightIcon disabled />
              </div>
            )}
          </div>
        </div>
        <div className={classes.subContainer}>
          <div className={classes.sectionContainer}>
            <div className={classes.headerContainer}>
              <div className={classes.header}>{Translation(`agentProfile.ap_about_me_header_title`)}</div>
              {agentProfile.myAboutMe && agentProfile.aboutMeDisplayStatus === AboutMeDisplayStatusEnum.SHOW && (
                <IconButton
                  className={classes.iconButton}
                  onClick={() => {
                    takeUIClickEvent({
                      journey: 'create_agent_profile',
                      stage: 'edit_self_introduction',
                      object_name: 'Edit',
                      object_type: 'BUTTON',
                    });
                    history.push(`${AGENT_PROFILE_PATH}/aboutMe?noHeader`, passingLocationState);
                  }}
                >
                  <EditIcon />
                </IconButton>
              )}
            </div>
            {agentProfile.myAboutMe && agentProfile.aboutMeDisplayStatus === AboutMeDisplayStatusEnum.SHOW ? (
              <div className={classes.aboutMeContainer}>
                {resolveAboutMeParameter(agentProfile.myAboutMe?.[mappedLocale])}
              </div>
            ) : (
              <div className={classes.emptyAddContainer}>
                <button
                  type="button"
                  className={classes.addMsgContainer}
                  onClick={() => {
                    takeUIClickEvent({
                      journey: 'create_agent_profile',
                      stage: 'edit_self_introduction',
                      object_name: 'SelectTemplate',
                      object_type: 'BUTTON',
                    });
                    history.push(`${AGENT_PROFILE_PATH}/aboutMe?noHeader`, passingLocationState);
                  }}
                >
                  <AddCircle className={classes.addIcon} />
                  {agentProfile.aboutMeDisplayStatus === AboutMeDisplayStatusEnum.HIDE
                    ? Translation('agentProfile.ap_no_template_icon')
                    : Translation('agentProfile.ap_add_template_icon')}
                </button>
              </div>
            )}
          </div>
          {agentProfile.myHighlight && (
            <div className={classes.sectionContainer}>
              <div className={classes.headerContainer}>
                <div className={classes.header}>{Translation(`agentProfile.ap_my_highlight_header_title`)}</div>
              </div>
              <WhitePlayer
                className={isPhone ? classes.playerContainer : classes.iPadViewPlayerContainer}
                url={agentProfile.myHighlight?.[mappedLocale] || agentProfile.myHighlight?.enUs}
              />
            </div>
          )}
          <div className={classes.sectionContainer}>
            <div className={classes.headerContainer}>
              <div className={classes.header}>{Translation(`agentProfile.ap_my_awards_header_title`)}</div>
              {hasAwards && (
                <IconButton
                  className={classes.iconButton}
                  onClick={() => {
                    takeUIClickEvent({
                      journey: 'create_agent_profile',
                      stage: 'edit_awards',
                      object_name: 'Edit',
                      object_type: 'BUTTON',
                    });
                    history.push(`${AGENT_PROFILE_PATH}/award?noHeader`, passingLocationState);
                  }}
                >
                  <EditIcon />
                </IconButton>
              )}
            </div>
            {hasAwards ? (
              <>
                <AwardList
                  mappedLocale={mappedLocale}
                  badges={agentProfile.awardBadges}
                  awards={isIPadAwardViewMore ? awardList : agentProfile.awards}
                />
                <div
                  className={`${classes.viewMore} ${classes.disabled}`}
                  // onClick={() => {
                  //   if (isPhone) {
                  //     history.push(`${AGENT_PROFILE_PATH}/award-view-more?noHeader`, passingLocationState);
                  //   } else {
                  //     setIsIPadAwardViewMore(!isIPadAwardViewMore);
                  //   }
                  // }}
                >
                  {Translation('agentProfile.ap_view_more_label')} <ArrowRightIcon disabled />
                </div>
              </>
            ) : (
              <div className={classes.emptyAddContainer}>
                <button
                  className={classes.addMsgContainer}
                  type="button"
                  onClick={() => {
                    takeUIClickEvent({
                      journey: 'create_agent_profile',
                      stage: 'add_awards',
                      object_name: 'AddAwards',
                      object_type: 'BUTTON',
                    });
                    history.push(`${AGENT_PROFILE_PATH}/award?noHeader`, passingLocationState);
                  }}
                >
                  <AddCircle className={classes.addIcon} />
                  {Translation('agentProfile.ap_add_award_icon')}
                </button>
              </div>
            )}
          </div>
          <div className={classes.sectionContainer}>
            <div className={classes.headerContainer}>
              <div className={classes.header}>{Translation(`agentProfile.ap_promotions_header_title`)}</div>
              {hasPromotions && (
                <IconButton
                  className={classes.iconButton}
                  onClick={() => {
                    takeUIClickEvent({
                      journey: 'create_agent_profile',
                      stage: 'add_promotions',
                      object_name: 'Edit',
                      object_type: 'BUTTON',
                    });
                    history.push(`${AGENT_PROFILE_PATH}/promotion?noHeader`, passingLocationState);
                  }}
                >
                  <EditIcon />
                </IconButton>
              )}
            </div>
            <div className={`${!hasPromotions ? classes.iPadViewNoPromotion : ''}`}>
              {hasPromotions ? (
                agentProfile.promotions[promotionMappedLocale].map((promotion) => (
                  <div className={classes.promotionCard}>
                    <ItemCard
                      disableSelect
                      item={promotion}
                      image={promotion.coverpage?.url}
                      name={promotion.name || '-'}
                      labels={promotion.tag}
                    />
                  </div>
                ))
              ) : (
                <div className={classes.emptyAddContainer}>
                  <button
                    type="button"
                    className={classes.addMsgContainer}
                    onClick={() => {
                      takeUIClickEvent({
                        journey: 'create_agent_profile',
                        stage: 'add_promotions',
                        object_name: 'SelectPromotions',
                        object_type: 'BUTTON',
                      });
                      history.push(`${AGENT_PROFILE_PATH}/promotion?noHeader`, passingLocationState);
                    }}
                  >
                    <AddCircle className={classes.addIcon} />
                    {Translation('agentProfile.ap_add_promotion_icon')}
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className={classes.footerContainer}>
        {createPageRefreshDataUpdated || regionLocale.length !== 2 ? (
          <GAClickFragment
            journey="create_agent_profile"
            stage="create_agent_profile"
            object_name="Submit"
            object_type="BUTTON"
          >
            <Button variant="contained" color="secondary" className={classes.button} onClick={() => handleSubmit()}>
              {Translation('app.button.submit')}
            </Button>
          </GAClickFragment>
        ) : (
          <GAClickFragment
            journey="create_agent_profile"
            stage="create_agent_profile"
            object_name="Next"
            object_type="BUTTON"
          >
            <Button variant="contained" color="secondary" className={classes.button} onClick={() => handleNext()}>
              {Translation('agentProfile.ap_next_button')}
            </Button>
          </GAClickFragment>
        )}
      </div>
    </>
  );
};

export default AgentProfileCreateMainForm;
