import React, { FC } from 'react';
import { Switch, Redirect, RouteComponentProps } from 'react-router-dom';
import PruRoute from 'src/app/common/components/PruRoute';
import { NOT_FOUND_ERROR_PAGE_PATH } from 'src/app/common/constants';
import { AGENT_PROFILE_PATH } from '../constants';
import AgentProfileEditMainPage from './Edit/AgentProfileEditMainPage';
import AgentProfileEditAboutMePage from './AboutMe/AgentProfileEditAboutMePage';
import AgentProfileEditGetInTouchWithMePage from './GetInTouchWithMe/AgentProfileEditGetInTouchWithMePage';
import AgentProfileEditAwardPage from './Award/Edit/AgentProfileEditAwardPage';
import AgentProfileEditPromotionPage from './Promotion/AgentProfileEditPromotionPage';
import AgentProfileCreateMainPage from './Create/AgentProfileCreateMainPage';
import AgentProfileAwardViewMorePage from './Award/ViewMore/AgentProfileAwardViewMorePage';
import SubmitSuccessPage from './PostSubmit/SubmitSuccessPage';
import createSuccessPage from './PostSubmit/CreateSuccessPage';
import { GAData } from '../../../common/ga/redux/gaSlice';

const createAgentProfileGAData: GAData = {
  module: 'AgentProfile',
  feature: 'AgentProfileSite',
  journey: 'create_agent_profile',
  stage: 'create_agent_profile',
  screen_name: 'CreateAgentProfilePageScreen',
};

const editAgentProfileGAData: GAData = {
  module: 'AgentProfile',
  feature: 'AgentProfileSite',
  journey: 'edit_agent_profile',
  stage: 'view_agent_profile',
  screen_name: 'EditAgentProfilePageScreen',
};

const submitSuccessGAData: GAData = {
  module: 'AgentProfile',
  feature: 'AgentProfileSite',
  journey: 'edit_agent_profile',
  stage: 'save_success',
  screen_name: 'SubmitSuccessPageScreen',
};

const createSuccessGAData: GAData = {
  module: 'AgentProfile',
  feature: 'AgentProfileSite',
  journey: 'create_agent_profile',
  stage: 'create_success',
  screen_name: 'CreateSuccessPageScreen',
};

const AgentProfileRoutes: FC<RouteComponentProps> = () => {
  return (
    <Switch>
      <PruRoute
        exact
        gaData={createAgentProfileGAData}
        path={`${AGENT_PROFILE_PATH}/create`}
        component={AgentProfileCreateMainPage}
      />
      <PruRoute
        exact
        gaData={editAgentProfileGAData}
        path={`${AGENT_PROFILE_PATH}/edit`}
        component={AgentProfileEditMainPage}
      />
      <PruRoute exact path={`${AGENT_PROFILE_PATH}/aboutMe`} component={AgentProfileEditAboutMePage} />
      <PruRoute
        exact
        path={`${AGENT_PROFILE_PATH}/getInTouchWithMe`}
        component={AgentProfileEditGetInTouchWithMePage}
      />
      <PruRoute exact path={`${AGENT_PROFILE_PATH}/award`} component={AgentProfileEditAwardPage} />
      <PruRoute exact path={`${AGENT_PROFILE_PATH}/award-view-more`} component={AgentProfileAwardViewMorePage} />
      <PruRoute
        exact
        path={`${AGENT_PROFILE_PATH}/promotion`}
        component={AgentProfileEditPromotionPage}
      />
      <PruRoute
        exact
        gaData={submitSuccessGAData}
        path={`${AGENT_PROFILE_PATH}/submit-success`}
        component={SubmitSuccessPage}
      />
      <PruRoute
        exact
        gaData={createSuccessGAData}
        path={`${AGENT_PROFILE_PATH}/create-success`}
        component={createSuccessPage}
      />
      <Redirect to={NOT_FOUND_ERROR_PAGE_PATH} />
    </Switch>
  );
};

export default AgentProfileRoutes;
