import { makeStyles } from 'tss-react/mui';
import React, { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { RadioButtonChecked, RadioButtonUnchecked } from '@mui/icons-material';
import { useLang } from 'src/app/i18n';
import { localeMapToBackend } from 'src/app/modules/AgentProfile/common/utils/locale-utils';
import { I18nDataStringItem, AboutMeTemplateItem, AboutMeDisplayStatusEnum } from '../../types/agent-profile-types';

type SelectItemProps = {
  selectedTemplate?: string;
  enableMyAboutMe?: boolean;
  myAboutMe?: I18nDataStringItem;
  showRadio?: boolean;
  templates: AboutMeTemplateItem[];
  selectedIndex?: string;
  aboutMeDisplayStatus?: AboutMeDisplayStatusEnum;
  setSelectedIndex: (string: string | undefined) => void;
  setAboutMeDisplayStatus: (status: AboutMeDisplayStatusEnum | undefined) => void;
};

const SelectItem: FC<SelectItemProps> = ({
  selectedTemplate,
  enableMyAboutMe,
  myAboutMe,
  showRadio,
  templates,
  selectedIndex,
  aboutMeDisplayStatus,
  setSelectedIndex,
  setAboutMeDisplayStatus,
}) => {
  const { classes } = useStyles();
  const lang = useLang();
  const mappedLocale = localeMapToBackend[lang] || lang;
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  useEffect(() => {
    if (aboutMeDisplayStatus === AboutMeDisplayStatusEnum.HIDE) {
      setSelectedIndex(undefined);
    } else if (selectedTemplate) {
      setSelectedIndex(selectedTemplate);
    } else {
      setSelectedIndex(templates.find((template) => template.isDefault === true)?.id || templates[0]?.id);
    }
  }, []);

  return (
    <div>
      {/* Only show custom about me if enableMyAboutMe is true */}
      {enableMyAboutMe ? (
        <div className={classes.sectionContainer}>
          {aboutMeDisplayStatus !== AboutMeDisplayStatusEnum.HIDE ? (
            <div className={classes.iconButton}>
              <RadioButtonChecked style={{ color: '#E8192C', width: 24, height: 24 }} />
            </div>
          ) : (
            <div
              className={classes.iconButton}
              onClick={() => setAboutMeDisplayStatus(AboutMeDisplayStatusEnum.SHOW)}
            >
              <RadioButtonUnchecked style={{ color: '#BABABA', width: 24, height: 24 }} />
            </div>
          )}
          <div className={classes.label}>{myAboutMe?.[mappedLocale]}</div>
        </div>
      ) : (
        <>
          {templates.map((template: AboutMeTemplateItem, index) => (
            <div className={classes.sectionContainer} key={template.templateCode}>
              {showRadio !== false && selectedIndex === template.id ? (
                <div className={classes.iconButton}>
                  <RadioButtonChecked style={{ color: '#E8192C', width: 24, height: 24 }} />
                </div>
              ) : (
                <div
                  className={classes.iconButton}
                  onClick={() => {
                    setSelectedIndex(template.id);
                    setAboutMeDisplayStatus(AboutMeDisplayStatusEnum.SHOW);
                  }}
                >
                  <RadioButtonUnchecked style={{ color: '#BABABA', width: 24, height: 24 }} />
                </div>
              )}
              <div className={classes.label}>{template.content[mappedLocale]}</div>
            </div>
          ))}
        </>
      )}
      <div className={classes.sectionContainer}>
        {selectedIndex === undefined && aboutMeDisplayStatus === AboutMeDisplayStatusEnum.HIDE ? (
          <div className={classes.iconButton}>
            <RadioButtonChecked style={{ color: '#E8192C', width: 24, height: 24 }} />
          </div>
        ) : (
          <div
            className={classes.iconButton}
            onClick={() => {
              setSelectedIndex(undefined);
              setAboutMeDisplayStatus(AboutMeDisplayStatusEnum.HIDE);
            }}
          >
            <RadioButtonUnchecked style={{ color: '#BABABA', width: 24, height: 24 }} />
          </div>
        )}
        <div className={classes.label}>{Translation('agentProfile.ap_no_template_option')}</div>
      </div>
    </div>
  );
};

export default SelectItem;

const useStyles = makeStyles()((theme) => ({
  iconButton: {
    padding: 8,
  },
  sectionContainer: {
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
    borderBottomWidth: 1,
    borderBottomColor: '#F0F0F0',
  },
  label: {
    padding: 16,
  },
}));
