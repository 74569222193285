import { Fab, Icon, Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import React, { useCallback, useEffect, useState } from 'react';

export default function ScrollTop() {
  const { classes } = useStyles();
  const [showScroll, setShowScroll] = useState(false);

  const checkScrollTop = useCallback(() => {
    if (!showScroll && window.scrollY > 200) {
      setShowScroll(true);
    } else if (showScroll && window.scrollY <= 200) {
      setShowScroll(false);
    }
  }, [showScroll]);

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    window.addEventListener('scroll', checkScrollTop);
    return () => {
      window.removeEventListener('scroll', checkScrollTop);
    };
  }, [checkScrollTop]);

  return showScroll ? (
    <Fab color="primary" size="small" onClick={scrollTop} className={classes.wrapper}>
      <Icon className={`fa fa-arrow-up ${classes.icon}`} />
    </Fab>
  ) : null;
}

const useStyles = makeStyles()((theme: Theme) => ({
  wrapper: {
    width: 36,
    height: 36,
    minHeight: 36,
    borderRadius: '0.42rem',
    position: 'fixed',
    right: 20,
    bottom: 40,
    opacity: 0.3,
    backgroundColor: '#3699FF',
    '&:hover': {
      opacity: 1,
      backgroundColor: '#3699FF',
    },
    [theme.breakpoints.down('md')]: {
      width: 30,
      height: 30,
      minHeight: 30,
      bottom: 30,
      right: 15,
    }
  },
  icon: {
    color: '#ffffff',
    fontSize: 18,
  },
}));
