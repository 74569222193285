import { FC } from 'react';
import { Switch, Redirect, RouteComponentProps } from 'react-router-dom';
import PruRoute from 'src/app/common/components/PruRoute';
import { REPORT_BASE_PATH, EVENT_BASE_PATH, BOP_REPORT_BASE_PATH } from '../../../constants';

import { NOT_FOUND_ERROR_PAGE_PATH } from 'src/app/common/constants';
import BopReport from './index';

const BopReportPage: FC<RouteComponentProps> = () => {
  return (
    <Switch>
      <PruRoute exact path={`${REPORT_BASE_PATH}${EVENT_BASE_PATH}${BOP_REPORT_BASE_PATH}`} component={BopReport} />
      <Redirect to={NOT_FOUND_ERROR_PAGE_PATH} />
    </Switch>
  );
};

export default BopReportPage;
