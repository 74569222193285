import React, { FC, useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Button, Dialog, FormControlLabel, Checkbox, IconButton } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Close } from '@mui/icons-material';
import CircleCheckedFilled from '@mui/icons-material/CheckCircle';
import CircleUnchecked from '@mui/icons-material/RadioButtonUnchecked';

export type SortState = {
  sortBy?: string;
  sortOrder?: number;
};

type SortDialogProps = {
  showDialog: boolean;
  initialSortState: SortState;
  sortState: SortState;
  setShowDialog: (showSortDialog: boolean) => void;
  handleSort: (sortState: SortState) => void;
};

const DIALOG_HEIGHT = 408;
const HEADER_HEIGHT = 58;
const FOOTER_HEIGHT = 78;

const useStyles = makeStyles()((theme) => ({
  paper: {
    width: '100%',
    height: DIALOG_HEIGHT,
    borderRadius: '12px 12px 0 0',
    margin: 0,
    position: 'absolute',
    bottom: 0,
  },
  header: {
    height: HEADER_HEIGHT,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  title: {
    fontSize: 18,
    fontWeight: 600,
    marginLeft: 20,
  },
  closeButton: {
    marginRight: 4,
  },
  body: {
    padding: '0 24px',
  },
  sectionSpacing: {
    marginBottom: 24,
  },
  label: {
    color: '#999999',
    fontSize: 14,
  },
  option: {
    fontSize: 14,
    fontFamily: 'Arial',
  },
  footer: {
    width: '100%',
    height: FOOTER_HEIGHT,
    padding: '0 20px',
    display: 'flex',
    textAlign: 'center',
    position: 'absolute',
    bottom: 0,
  },
  button: {
    width: '100%',
    height: 48,
    fontSize: 16,
    fontFamily: 'Arial',
  },
  backButton: {
    backgroundColor: 'white',
    marginRight: 12,
  },
}));

const SortDialog: FC<SortDialogProps> = ({ showDialog, initialSortState, sortState, setShowDialog, handleSort }) => {
  const { classes } = useStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const [state, setState] = useState<SortState>({ ...sortState });

  useEffect(() => {
    if (showDialog) {
      setState({ ...sortState });
    }
  }, [showDialog]);

  const handleReset = () => {
    setState({ ...initialSortState });
  };

  const handleApply = () => {
    handleSort(state);
  };

  const handleClose = () => {
    setShowDialog(false);
  };

  return (
    <Dialog classes={{ paper: classes.paper }} open={showDialog} onClose={handleClose}>
      <div>
        <div className={classes.header}>
          <div className={classes.title}>{Translation('app.sort')}</div>
          <IconButton className={classes.closeButton} onClick={handleClose}>
            <Close />
          </IconButton>
        </div>
        <div className={classes.body}>
          <div className={classes.sectionSpacing}>
            <div className={classes.label}>{Translation('agentProfile.ap_award_name_label')}</div>
            <div>
              <FormControlLabel
                style={{ margin: '2px 0' }}
                control={
                  <Checkbox
                    icon={<CircleUnchecked />}
                    checkedIcon={<CircleCheckedFilled />}
                    checked={state.sortBy === 'name' && state.sortOrder === 1}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setState({
                          sortBy: 'name',
                          sortOrder: 1,
                        });
                      }
                    }}
                  />
                }
                label={<span className={classes.option}>{Translation('app.sort.aToZ')}</span>}
                labelPlacement="end"
              />
            </div>
            <div>
              <FormControlLabel
                style={{ margin: '2px 0' }}
                control={
                  <Checkbox
                    icon={<CircleUnchecked />}
                    checkedIcon={<CircleCheckedFilled />}
                    checked={state.sortBy === 'name' && state.sortOrder === -1}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setState({
                          sortBy: 'name',
                          sortOrder: -1,
                        });
                      }
                    }}
                  />
                }
                label={<span className={classes.option}>{Translation('app.sort.zToA')}</span>}
                labelPlacement="end"
              />
            </div>
          </div>

          <div>
            <div className={classes.label}>{Translation('agentProfile.ap_awarded_year_label')}</div>
            <div>
              <FormControlLabel
                style={{ margin: '2px 0' }}
                control={
                  <Checkbox
                    icon={<CircleUnchecked />}
                    checkedIcon={<CircleCheckedFilled />}
                    checked={state.sortBy === 'awardYear' && state.sortOrder === 1}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setState({
                          sortBy: 'awardYear',
                          sortOrder: 1,
                        });
                      }
                    }}
                  />
                }
                label={<span className={classes.option}>{Translation('app.sort.asc')}</span>}
                labelPlacement="end"
              />
            </div>
            <div>
              <FormControlLabel
                style={{ margin: '2px 0' }}
                control={
                  <Checkbox
                    icon={<CircleUnchecked />}
                    checkedIcon={<CircleCheckedFilled />}
                    checked={state.sortBy === 'awardYear' && state.sortOrder === -1}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setState({
                          sortBy: 'awardYear',
                          sortOrder: -1,
                        });
                      }
                    }}
                  />
                }
                label={<span className={classes.option}>{Translation('app.sort.desc')}</span>}
                labelPlacement="end"
              />
            </div>
          </div>
        </div>
        <div className={classes.footer}>
          <Button variant="contained" color="inherit" className={`${classes.button} ${classes.backButton}`} onClick={handleReset}>
            {Translation('app.button.reset')}
          </Button>
          <Button variant="contained" color="secondary" className={classes.button} onClick={handleApply}>
            {Translation('app.button.apply')}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default SortDialog;
