import React, { CSSProperties, FC, useEffect, useRef, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import ReactPlayer from 'react-player';

type WhitePlayerProps = {
  className?: string;
  url: string;
};

const useStyles = makeStyles<{ videoWidth: number }>()((theme, { videoWidth }) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  videoContainer: {
    position: 'absolute',
    display: 'flex',
    width: videoWidth,
  },
  playIcon: {
    alignSelf: 'center',
    margin: 'auto',
    width: 42,
    height: 42,
    color: '#FFFFFF',
  },
}));

const WhitePlayer: FC<WhitePlayerProps> = ({ className, url }) => {
  const { PUBLIC_URL } = process.env;
  const videoRef = useRef<any>();
  const videoPlayerRef = useRef<any>();
  const [videoWidth, setvideoWidth] = useState(0);
  const { classes } = useStyles({ videoWidth });

  useEffect(() => {
    if (videoRef && videoRef.current) {
      setvideoWidth(videoRef.current.offsetWidth);
    }
  }, [videoRef]);

  useEffect(() => {
    setTimeout(() => {
      if (videoPlayerRef && videoPlayerRef.current) {
        videoPlayerRef.current.seekTo(0);
      }
    }, 1500);
  }, [videoPlayerRef]);

  return (
    <div id="video-myHighlight" className={classes.container} ref={videoRef}>
      <meta name="referrer" content="no-referrer"></meta>
      <div className={classes.videoContainer}></div>
      <ReactPlayer
        controls
        ref={videoPlayerRef}
        width={'100%'}
        height={'auto'}
        className={className}
        url={url}
        onStart={() => {
          var videoEle: any = document.getElementById('video-myHighlight-player');
          if (videoEle) {
            if (videoEle.requestFullscreen) {
              videoEle.requestFullscreen();
            } else if (videoEle.mozRequestFullScreen) {
              /* Firefox */
              videoEle.mozRequestFullScreen();
            } else if (videoEle.webkitRequestFullscreen) {
              /* Chrome, Safari and Opera */
              videoEle.webkitRequestFullscreen();
            } else if (videoEle.msRequestFullscreen) {
              /* IE/Edge */
              videoEle.msRequestFullscreen();
            } else if (videoEle.oRequestFullscreen) {
              videoEle.oRequestFullscreen();
            }
          }
        }}
        config={{
          file: {
            attributes: {
              style: { width: '100%', height: 'auto', objectFit: 'contain' },
              id: 'video-myHighlight-player',
              'x5-video-player-fullscreen': true,
            },
          },
        }}
      />
    </div>
  );
};

export default WhitePlayer;
