import React from 'react';
import Select from 'react-select';
import { SurveyQuestionDropdown } from 'survey-react-ui';

export class DropdownSelect extends SurveyQuestionDropdown {
  constructor(props) {
    super(props);
    this.language = props.children.language;
  }

  get options() {
    const options = this.question.visibleChoices
      .map((choice) => {
        if (!this.question.value && choice.value !== 'Prudential') {
          return {
            value: choice.value,
            label: this.language === 'en' ? choice.locTextValue.values.en : choice.locTextValue.values.zh,
            language: choice.locTextValue.values,
          };
        } else if (this.question.value) {
          if (this.question.value === 'Prudential' && choice.value === 'Prudential') {
            return {
              value: choice.value,
              label: this.language === 'en' ? choice.locTextValue.values.en : choice.locTextValue.values.zh,
              language: choice.locTextValue.values,
            };
          } else if (this.question.value !== 'Prudential' && choice.value !== 'Prudential') {
            return {
              value: choice.value,
              label: this.language === 'en' ? choice.locTextValue.values.en : choice.locTextValue.values.zh,
              language: choice.locTextValue.values,
            };
          }
        }
      })
      .filter((value) => value);

    return options;
  }

  filterOption(option, rawInput) {
    if (!rawInput) {
      return true;
    } else {
      if (
        rawInput.match('^[A-Za-z]*(\\s[A-Za-z]*)*$') &&
        option.data.language.en.toLowerCase().search(rawInput.toLowerCase()) != -1
      ) {
        return true;
      } else if (rawInput.match('^[\u4e00-\u9fa5]*$') && option.data.language.zh.search(rawInput) != -1) {
        return true;
      } else {
        return false;
      }
    }
  }

  noOptionsMessage() {
    return '';
  }

  get selectedOption() {
    return this.options.filter((o) => o.value == this.question.value);
  }

  // onSelectChange(selectedOption) {
  //   console.log('this,', selectedOption);
  //   this.setValueCore(selectedOption.value);
  //   this.selectedOption = selectedOption.value;
  // }

  renderElement() {
    if (this.isDisplayMode) {
      return (
        <div id={this.question.inputId} className={this.question.getControlClass()} disabled>
          {this.question.displayValue || this.question.placeholder}
        </div>
      );
    }

    return (
      <Select
        id={this.question.inputId}
        value={this.selectedOption}
        options={this.options}
        placeholder={this.question.placeholder}
        required={this.question.isRequired}
        menuPortalTarget={document.querySelector('body')}
        isSearchable={true}
        onChange={(selectedOption) => {
          this.setValueCore(selectedOption.value);
        }}
        onInputChange={this.onInputChange}
        filterOption={this.filterOption}
        noOptionsMessage={this.noOptionsMessage}
      />
    );
  }
}
