import { createSlice } from "@reduxjs/toolkit";

export enum AlertType {
  ERROR = "error",
  WARNING = "warning",
  INFO = "info",
  SUCCESS = "success",
}

export type AlertItem = {
  severity: AlertType;
  title?: string;
  content?: string;
};

export type CommonState = {
  alertState: AlertItem[];
};

const commonState: CommonState = {
  alertState: [],
};

type AddAlertAction = {
  payload: AlertItem[];
};

type DismissAlertAction = {
  payload: {
    index: number;
  };
};

export const commonSlice = createSlice({
  name: "common",
  initialState: commonState,
  reducers: {
    authenticateFailed: (state: CommonState) => {
      return {
        ...state,
        alertState: [
          ...state.alertState,
          {
            severity: AlertType.ERROR,
            content: "Authenciation failed, please login again",
          },
        ],
      };
    },
    appendAlertItem: (state: CommonState, action: AddAlertAction) => {
      return {
        ...state,
        alertState: [...state.alertState, ...action.payload],
      };
    },
    dismissAlertItem: (state: CommonState, action: DismissAlertAction) => {
      let currentAlert = [...state.alertState];
      currentAlert.splice(action.payload.index, 1);
      return {
        ...state,
        alertState: currentAlert,
      };
    },
  },
});

export const {
  authenticateFailed,
  appendAlertItem,
  dismissAlertItem,
} = commonSlice.actions;
