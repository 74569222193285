import React, { FC, useEffect, useState } from 'react';
import { toAbsoluteUrl } from 'src/app/common/utils';
import { ApiErrorResp, GetProtectionReviewWebForm, ProtectionReviewActivityEnum } from '../types/type';
import { RouteComponentProps, useParams } from 'react-router-dom';
import { getSurvetItem } from '../network/crud';
import { ReviewWebFormPage } from './ReviewWebFormPage';
import moment from 'moment';
import { Loading } from './component/Loading';
import { useIntl } from 'react-intl';
import { ErrorPage, ErrorProps } from './component/ErrorPage';
import { setLanguage } from 'src/app/i18n';
import GetCss from '../GetCss';

export const ReviewWeb: FC<RouteComponentProps> = ({ history, location }) => {
  const { resultId, language } = useParams<{ resultId: string; language: 'en' | 'zh' }>();
  const isPreview = location.pathname.split('/').includes('preview');
  const [surveyResult, setSurveyResult] = useState<GetProtectionReviewWebForm>();
  const [loadingDisplayMode, setLoadingDisplayMode] = useState<'flex' | 'none'>('none');
  const [hasError, setHasError] = useState<boolean>(false);
  const [errorProps, setErrProps] = useState<ErrorProps>({ code: '', message: '' });
  const [policyCount, setPolicyCount] = useState<number>(1);

  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  if (intl.locale !== language && (intl.locale !== 'zh-Hant' || language != 'zh')) {
    setLanguage(language === 'en' ? language : 'zh-Hant');
  }

  const loadData = () => {
    if (hasError) {
      setHasError(false);
      setLoadingDisplayMode('flex');
    }
    getSurvetItem(isPreview, isPreview ? undefined : resultId)
      .then((data) => {
        console.log('get survey item', data.result);
        data.result.template.surveyTemplate.logo = toAbsoluteUrl('/media/pruforce-logo/survey_default_logo.png');
        data.result.template.surveyTemplate.locale = language;

        const dependentEle = data.result.template.surveyTemplate.pages[0].elements[0].elements;
        for (let i = 0; i < dependentEle.length; i++) {
          if (dependentEle[i].name === 'dependentsInformation' && dependentEle[i].templateElements) {
            for (let j = 0; j < dependentEle[i].templateElements.length; j++) {
              if (dependentEle[i].templateElements[j].name === 'dependentYear') {
                data.result.template.surveyTemplate.pages[0].elements[0].elements[i].templateElements[j].title = {
                  en:
                    dependentEle[i].templateElements[j].title.en +
                    ` <div style='margin-left: 4px'><img style='width: 16px; height: 16px' id='mousehandel' src=${toAbsoluteUrl(
                      '/media/insurance-summary/popupdesc.png',
                    )}></img><div style='display: none;' id='showDescription'><div class='triangle'></div><div class='showDescription'><text class='description'>${Translation(
                      'reviewWebForm.popup.description',
                    )}</text></div></div></div>`,
                  zh:
                    dependentEle[i].templateElements[j].title.zh +
                    `<div style='margin-left: 4px'><img style='width: 16px; height: 16px' id='mousehandel' src=${toAbsoluteUrl(
                      '/media/insurance-summary/popupdesc.png',
                    )}></img><div style='display: none;' id='showDescription'><div class='triangle'></div><div class='showDescription'><text class='description'>${Translation(
                      'reviewWebForm.popup.description',
                    )}</text></div></div></div>`,
                };
              }
            }
          }
        }

        if (isPreview) {
          if (language === 'en') {
            data.result.template.surveyTemplate.title = `<text class='previewMsg'>${Translation(
              'reviewWebForm.preview.description',
            )}</text><br />${data.result.template.surveyTemplate.title.en}`;
          } else if (language === 'zh') {
            data.result.template.surveyTemplate.title = `<text class='previewMsg'>${Translation(
              'reviewWebForm.preview.description',
            )}</text><br />${data.result.template.surveyTemplate.title.zh}`;
          }
          delete data.result.template.surveyTemplate.pages[1].elements[0].elements[0]['minPanelCount'];
          data.result.template.surveyTemplate.pages[1].elements[0].elements[0].panelCount = 1;
        } else {
          data.result.template.surveyTemplate.pages[1].elements[0].elements[0].minPanelCount = 1;
          if (data.result.status != ProtectionReviewActivityEnum.FormOpened) {
            data.result.template.surveyTemplate.description =
              language === 'en'
                ? `${Translation('reviewWebForm.update.time')} ${moment(data.result.updatedAt)
                    .utcOffset('+0800')
                    .format('DD/MM/YYYY HH:mm')}`
                : language === 'zh'
                ? `${Translation('reviewWebForm.update.time')} ${moment(data.result.updatedAt)
                    .utcOffset('+0800')
                    .format('YYYY年M月D日 HH:mm')}`
                : '';
          }
          if (data.result.status === ProtectionReviewActivityEnum.FormSubmitted) {
            if (data.result.inputData && data.result.inputData.policies) {
              const policies = data.result.inputData.policies;
              setPolicyCount(policies.filter((item: any) => item.isPruPolicy).length);
              for (let i = 0; i < policies.length; i++) {
                const policyKeys = Object.keys(policies[i]);
                if (!policyKeys.includes('isTerminated')) {
                  policies[i]['isTerminated'] = false;
                }
              }
              data.result.inputData.policies = policies;
            }
          }

          if (data.result.inputData) {
            if (data.result.inputData.policies) {
              for (let index = 0; index < data.result.inputData.policies.length; index++) {
                for (const policyKey of Object.keys(data.result.inputData.policies[index])) {
                  if (
                    moment(data.result.inputData.policies[index][policyKey], 'YYYY-MM-DDTHH:mm:ss.sssZ', true).isValid()
                  ) {
                    data.result.inputData.policies[index][policyKey] = moment(
                      data.result.inputData.policies[index][policyKey],
                      'YYYY-MM-DDTHH:mm:ss.sssZ',
                      true,
                    )
                      .utcOffset('+0800')
                      .format('DD/MM/YYYY');
                  }
                }
                if (
                  data.result.inputData.policies[index]['lifeAssured'] &&
                  data.result.inputData.policies[index]['lifeAssured'] != 'Same as Policyowner' &&
                  data.result.inputData.policies[index]['lifeAssured'] != 'Others'
                ) {
                  data.result.inputData.policies[index]['lifeAssured_Comment'] =
                    data.result.inputData.policies[index]['lifeAssured'];
                  data.result.inputData.policies[index]['lifeAssured'] = 'Others';
                }
              }
            }

            const nameEn = data.result.inputData.clientNameEn;
            if (nameEn && nameEn.match('^[A-Za-z]*(\\s[A-Za-z]*)*$')) {
              const lowerStr = nameEn.toLowerCase();
              data.result.inputData.clientNameEn = lowerStr.replace(/\b\w|\s\w/g, (first: string) => {
                return first.toUpperCase();
              });
            }

            if (!data.result.inputData.clientNameLocal || data.result.inputData.clientNameLocal === null) {
              data.result.inputData.clientNameLocal = 'N/A';
            }
          }
        }
        setLoadingDisplayMode('none');
        setSurveyResult(data.result);
      })
      .catch((err: ApiErrorResp) => {
        setErrProps({ code: err.code, message: err.message });
        setLoadingDisplayMode('none');
        setHasError(true);
      });
  };
  useEffect(() => {
    GetCss();
    const splashScreen = document.getElementById('splash-screen');
    if (splashScreen) {
      splashScreen.style.display = 'none';
    }
    setLoadingDisplayMode('flex');
    loadData();
  }, [resultId]);

  return (
    <div style={{ height: '100%', margin: 'unset', padding: 'unset' }}>
      {loadingDisplayMode === 'flex' ? (
        <Loading displayMode={loadingDisplayMode} />
      ) : hasError ? (
        <ErrorPage code={errorProps.code} message={errorProps.message} />
      ) : (
        surveyResult && (
          <ReviewWebFormPage
            template={surveyResult.template}
            inputData={surveyResult.inputData}
            isPreview={isPreview}
            language={language}
            resultId={resultId}
            status={surveyResult.status}
            initialPolicyCount={policyCount}
          />
        )
      )}
    </div>
  );
};
