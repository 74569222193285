/* eslint-disable import/first */
import React, { useState, useMemo, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { toAbsoluteUrl } from 'src/app/common/utils';
import ProgressBarItem from './ProgressBarItem';
import { CampaignDetailPageStep, CampaignStructureItem, RsvpStatus } from '../../../../types/campaign-types';
import { AgencyCampaignTypeStatusEnum } from '../../../../constants';
import { useStyles } from './CampaignDetailPageLeftBar.style';
import { PruToast } from 'src/app/common/components/pru-toast';
import { Translation } from 'src/app/i18n';
import moment from 'moment';
import { ModalService } from 'src/app/common/components/pru-modal/modal-service';
import { takeUIClickEvent } from '../../../../../../common/ga/ga';
import { changeRsvpStatus } from '../../../../network/campaignCurd';
import { async } from 'q';
import { useDispatch } from 'react-redux';
import store from '../../../../../../../redux/store';
import ClickableRichTooltip from '../../../../../../common/components/RichTooltip/ClickableRichTooltip';
import { get } from 'lodash';
import { LockIcon } from 'src/app/common/components/ManagerDashboard';

interface DetailPageLeftBarProps {
  campaignTypeStructureData?: CampaignStructureItem;
  onSubmit: () => Promise<void>;
  onCancel: () => void;
  formState: any;
  currentStep: number;
  step1Warning: boolean;
  step2Warning: boolean;
  step3Warning: boolean;
  onStepChange: (step: number) => void;
  onBack: () => void;
  disabledEdit?: boolean;
  disabled?: boolean;
  campaignStatus: string;
  startDate: any;
  submitDateTips: string;
  rsvpStatus: string;
  campaignId: string;
  campaignOwner: string;
  applicantAgentCode: string;
  updateRSVPStatusAction: (rsvpStatus: string) => void;
}
const getTranslations = () => {
  return {
    campaign_has_been_approved_please_contanct_staff: Translation('campaign_has_been_approved_please_contanct_staff'),
    cannot_cancel_campaign_after_campaign_start_date: Translation('cannot_cancel_campaign_after_campaign_start_date'),
  };
};
const CampaignDetailPageLeftBar: React.FC<DetailPageLeftBarProps> = ({
  campaignTypeStructureData,
  onSubmit,
  onCancel,
  currentStep,
  step1Warning,
  step2Warning,
  step3Warning,
  onStepChange,
  onBack,
  submitDateTips,
  campaignStatus,
  startDate,
  formState,
  disabled,
  rsvpStatus,
  campaignId,
  campaignOwner,
  applicantAgentCode,
  updateRSVPStatusAction,
}) => {
  const dispatch = useDispatch();
  const agentCode = store.getState().auth.user?.sub;
  const [submitRequesting, setSubmitRequesting] = useState(false);
  const [cancelRequesting, setCancelRequesting] = useState(false);
  const [rsvpDropdownOpen, setRsvpDropdownOpen] = useState(false);

  const { stepPopulated } = useMemo(() => {
    const stepPopulated = formState.stepPopulated;
    return { accessedMaxStep: Math.max.apply(Math, stepPopulated.concat(currentStep)), stepPopulated };
  }, [currentStep, formState.stepPopulated]);
  const { classes } = useStyles();
  const intl = useIntl();
  const Translation = (id: string, variable?: Record<string, string>) => intl.formatMessage({ id }, variable);

  useEffect(() => {}, []);

  const onPressCancel = async () => {
    if (moment(new Date()).isAfter(moment(new Date(startDate)))) {
      PruToast({
        message: getTranslations().cannot_cancel_campaign_after_campaign_start_date,
      });
      return;
    }
    if (
      [
        AgencyCampaignTypeStatusEnum.APPROVED.toString(),
        AgencyCampaignTypeStatusEnum.ACTIVE.toString(),
        AgencyCampaignTypeStatusEnum.COMPLETED.toString(),
      ].includes(campaignStatus)
    ) {
      PruToast({
        message: getTranslations().campaign_has_been_approved_please_contanct_staff,
      });
      return;
    }

    setCancelRequesting(true);
    await ModalService.confirm({
      title: Translation('agencyCampaign.detail.leftBar.cancelTitle'),
      description: Translation('agencyCampaign.detail.leftBar.cancelText'),
      onConfirm: async () => {
        await onCancel();
      },
    }).finally(() => {
      setCancelRequesting(false);
    });
  };
  const onClickAcceptRsvpBtn = async () => {
    if (rsvpStatus === RsvpStatus.Accepted) {
      setRsvpDropdownOpen(false);
      return;
    }

    let data = {
      campaignId: campaignId,
      status: RsvpStatus.Accepted,
    };
    changeRsvpStatus(data, dispatch).then(() => {
      updateRSVPStatusAction(data.status);
      setRsvpDropdownOpen(false);
    });
  };
  const onClickRejectRsvpBtn = async () => {
    if (rsvpStatus === RsvpStatus.Rejected) {
      setRsvpDropdownOpen(false);
      return;
    }

    let data = {
      campaignId: campaignId,
      status: RsvpStatus.Rejected,
    };
    changeRsvpStatus(data, dispatch).then(() => {
      updateRSVPStatusAction(data.status);
      setRsvpDropdownOpen(false);
    });
  };

  const isOwnerOrCreateCase = !campaignOwner || agentCode === campaignOwner;
  const showRespondCampaignInvitation = !isOwnerOrCreateCase && rsvpStatus === RsvpStatus.Pending;
  const showUpdateRSVP = !isOwnerOrCreateCase && rsvpStatus !== RsvpStatus.Pending;
  const campaignStarted = startDate && moment().isAfter(moment(startDate));
  const isCampaignOwner = !campaignOwner || agentCode === applicantAgentCode;

  return (
    <div className={classes.container}>
      <div className={classes.backButtonContainer} onClick={onBack}>
        <img
          style={{
            height: '20px',
            width: '20px',
            marginRight: '10px',
            marginLeft: '5px',
          }}
          alt="arrow"
          src={toAbsoluteUrl('/media/icon/back.png')}
        />
        {Translation('agencyCampaign.detail.leftBar.backToList')}
      </div>
      <div className={classes.leftBarContainer}>
        <div className={classes.progressContainer}>
          <div className={classes.level1}>
            <div className={classes.level1Title}>
              <img
                style={{ height: '10px', width: '10px', margin: 7 }}
                alt="bulletpoint"
                src={toAbsoluteUrl('/media/icon/bulletpoint2.png')}
              />
              {Translation('agencyCampaign.detail.leftBar.application')}
            </div>
            <div className={classes.level2Container}>
              <div style={{ height: '100%', marginLeft: 40, marginTop: -2 }}>
                <ProgressBarItem
                  isFinished={!step1Warning}
                  inProgress={
                    currentStep === CampaignDetailPageStep.CAMPAIGN_INFO &&
                    step1Warning &&
                    !stepPopulated.includes(CampaignDetailPageStep.CAMPAIGN_INFO)
                  }
                  showWarning={stepPopulated.includes(CampaignDetailPageStep.CAMPAIGN_INFO) && step1Warning}
                  showTopLine={false}
                  showBottomLine={true}
                />
                <ProgressBarItem
                  isFinished={!step2Warning}
                  inProgress={
                    currentStep === CampaignDetailPageStep.PARTICIPANT &&
                    step2Warning &&
                    !stepPopulated.includes(CampaignDetailPageStep.PARTICIPANT)
                  }
                  showWarning={stepPopulated.includes(CampaignDetailPageStep.PARTICIPANT) && step2Warning}
                  showTopLine={true}
                  showBottomLine={true}
                />
                <ProgressBarItem
                  isFinished={!step3Warning}
                  inProgress={
                    currentStep === CampaignDetailPageStep.ATTACHMENT &&
                    step3Warning &&
                    !stepPopulated.includes(CampaignDetailPageStep.ATTACHMENT)
                  }
                  showWarning={stepPopulated.includes(CampaignDetailPageStep.ATTACHMENT) && step3Warning}
                  showTopLine={true}
                  showBottomLine={false}
                />
              </div>
              <div style={{ flex: 1 }}>
                <div
                  className={classes.level2}
                  onClick={() => {
                    onStepChange(CampaignDetailPageStep.CAMPAIGN_INFO);
                  }}
                >
                  <div className={classes.level2Title}>
                    {' '}
                    {Translation('agencyCampaign.detail.leftBar.campaignInfo')}
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img
                      style={{
                        height: '16px',
                        width: '16px',
                        marginRight: '10px',
                      }}
                      alt="arrow"
                      src={toAbsoluteUrl('/media/icon/arrow/right.png')}
                    />
                  </div>
                </div>
                <div
                  className={classes.level2}
                  onClick={() => {
                    onStepChange(CampaignDetailPageStep.PARTICIPANT);
                  }}
                >
                  <div className={classes.level2Title}>{Translation('agencyCampaign.detail.leftBar.participant')}</div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {isCampaignOwner ? (
                      <img
                        style={{
                          height: '16px',
                          width: '16px',
                          marginRight: '10px',
                        }}
                        alt="arrow"
                        src={toAbsoluteUrl('/media/icon/arrow/right.png')}
                      />
                    ) : (
                      <LockIcon customClasses={classes.lock} />
                    )}
                  </div>
                </div>
                <div
                  className={classes.level2}
                  onClick={() => {
                    onStepChange(CampaignDetailPageStep.ATTACHMENT);
                  }}
                >
                  <div className={classes.level2Title}>{Translation('agencyCampaign.detail.leftBar.attachment')}</div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {isCampaignOwner ? (
                      <img
                        style={{
                          height: '16px',
                          width: '16px',
                          marginRight: '10px',
                        }}
                        alt="arrow"
                        src={toAbsoluteUrl('/media/icon/arrow/right.png')}
                      />
                    ) : (
                      <LockIcon customClasses={classes.lock} />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className={classes.level1}
            style={{ display: 'flex', flexDirection: 'row' }}
            onClick={() => {
              onStepChange(CampaignDetailPageStep.APPROVAL);
            }}
          >
            <div className={classes.level1Title}>
              <img
                style={{ height: '10px', width: '10px', margin: 7 }}
                alt="buttelpoint"
                src={toAbsoluteUrl('/media/icon/bulletpoint2.png')}
              />
              {Translation('agencyCampaign.detail.leftBar.approval')}
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img
                style={{ height: '16px', width: '16px', marginRight: '10px' }}
                alt="arrow"
                src={toAbsoluteUrl('/media/icon/arrow/right.png')}
              />
            </div>
          </div>

          {campaignTypeStructureData?.sections.filter((item) => item.key === 'postDocuments')[0] && (
            <div
              className={classes.level1}
              style={{ display: 'flex', flexDirection: 'row' }}
              onClick={() => {
                onStepChange(CampaignDetailPageStep.EVNET_DOCUMENT);
              }}
            >
              <div className={classes.level1Title}>
                <img
                  style={{ height: '10px', width: '10px', margin: 7 }}
                  alt="buttelpoint"
                  src={toAbsoluteUrl('/media/icon/bulletpoint2.png')}
                />
                {Translation('agencyCampaign.detail.leftBar.postEventDocs')}
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {isCampaignOwner ? (
                  <img
                    style={{ height: '16px', width: '16px', marginRight: '10px' }}
                    alt="arrow"
                    src={toAbsoluteUrl('/media/icon/arrow/right.png')}
                  />
                ) : (
                  <LockIcon customClasses={classes.lock} />
                )}
              </div>
            </div>
          )}
          <div
            className={classes.level1}
            style={{ display: 'flex', flexDirection: 'row' }}
            onClick={() => {
              onStepChange(CampaignDetailPageStep.LEAD);
            }}
          >
            <div className={classes.level1Title}>
              <img
                style={{ height: '10px', width: '10px', margin: 7 }}
                alt="buttelpoint"
                src={toAbsoluteUrl('/media/icon/bulletpoint2.png')}
              />
              {Translation('agencyCampaign.detail.leftBar.lead')}
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img
                style={{ height: '16px', width: '16px', marginRight: '10px' }}
                alt="arrow"
                src={toAbsoluteUrl('/media/icon/arrow/right.png')}
              />
            </div>
          </div>
        </div>

        <div className={classes.footContainer}>
          {campaignStatus &&
            (campaignStatus === AgencyCampaignTypeStatusEnum.DRAFT ||
              campaignStatus === AgencyCampaignTypeStatusEnum.REJECTED ||
              campaignStatus === AgencyCampaignTypeStatusEnum.CANCELED) &&
            !disabled && (
              <>
                <Button
                  disabled={submitRequesting || disabled}
                  variant="contained"
                  color="secondary"
                  size="large"
                  onClick={async () => {
                    takeUIClickEvent({
                      journey: 'add_new_agency_campaign',
                      stage: 'submit',
                      object_name: 'Submit',
                      object_type: 'BUTTON',
                    });
                    setSubmitRequesting(true);
                    await onSubmit().finally(() => setSubmitRequesting(false));
                  }}
                >
                  {Translation('app.button.submit')}
                </Button>
                {!!submitDateTips && <div className={classes.tips}>* {submitDateTips}</div>}
              </>
            )}

          {currentStep === CampaignDetailPageStep.CAMPAIGN_INFO && !!showRespondCampaignInvitation && (
            <>
              <div style={{ fontWeight: 'bold' }}>{Translation('agencyCampaign.rsvp.invitation')}</div>
              <Button
                disabled={false}
                variant="contained"
                color="secondary"
                size="large"
                onClick={onClickAcceptRsvpBtn}
                style={{ marginTop: '10px' }}
              >
                {Translation('agencyCampaign.rsvp.accept')}
              </Button>

              <Button
                disabled={false}
                variant="outlined"
                color="inherit"
                size="large"
                onClick={onClickRejectRsvpBtn}
                style={{ marginTop: '10px' }}
              >
                {Translation('agencyCampaign.rsvp.reject')}
              </Button>
            </>
          )}
          {currentStep === CampaignDetailPageStep.CAMPAIGN_INFO && !!showUpdateRSVP && (
            <>
              <div style={{ fontWeight: 'bold' }}>{Translation('agencyCampaign.rsvp.yourRSVP')}</div>
              <ClickableRichTooltip
                placement="bottom-end"
                open={rsvpDropdownOpen}
                onClose={(event: Event) => {
                  const triggerId = get(event, 'target.parentElement.id');
                  if (triggerId !== 'btn-rsvp-dropdown') {
                    setRsvpDropdownOpen(false);
                  }
                }}
                content={
                  <div className="tw-flex tw-flex-col" style={{ width: 100 }}>
                    <div
                      className="tw-p-2 tw-mx-2 tw-py-2 tw-border-b tw-border-gray-300 tw-cursor-pointer tw-text-center"
                      onClick={onClickAcceptRsvpBtn}
                    >
                      {Translation('agencyCampaign.rsvp.accepted')}
                    </div>
                    <div className="tw-p-2 tw-mx-2 tw-cursor-pointer tw-text-center" onClick={onClickRejectRsvpBtn}>
                      {Translation('agencyCampaign.rsvp.rejected')}
                    </div>
                  </div>
                }
              >
                <div
                  id="btn-rsvp-dropdown"
                  className={`tw-flex tw-justify-between tw-py-2 tw-border-b tw-border-gray-400 ${
                    campaignStarted ? 'tw-cursor-default' : 'tw-cursor-pointer'
                  }`}
                  onClick={() => {
                    if (campaignStarted) return;
                    setRsvpDropdownOpen(true);
                  }}
                >
                  <div style={{ alignSelf: 'flex-start', fontWeight: 'bold' }}>
                    {rsvpStatus === RsvpStatus.Accepted
                      ? Translation('agencyCampaign.rsvp.accepted')
                      : Translation('agencyCampaign.rsvp.rejected')}
                  </div>
                  {!campaignStarted && (
                    <img
                      style={{ height: '15px', width: '15px' }}
                      alt="buttelpoint"
                      src={toAbsoluteUrl('/media/icon/rsvp_edit.png')}
                    />
                  )}
                </div>
              </ClickableRichTooltip>
            </>
          )}
          {/* {campaignStatus &&
            (campaignStatus == AgencyCampaignTypeStatusEnum.PENDING ||
              campaignStatus == AgencyCampaignTypeStatusEnum.ACTIVE ||
              campaignStatus == AgencyCampaignTypeStatusEnum.APPROVED) && (
              <Button
                disabled={cancelRequesting || disabled}
                variant="outlined"
                color="inherit"
                size="large"
                onClick={onPressCancel}
              >
                {Translation('app.button.cancel')}
              </Button>
            )} */}
        </div>
      </div>
    </div>
  );
};

export default CampaignDetailPageLeftBar;
