import { FC } from 'react';
import PruIcon from 'src/app/common/components/PruIcon';

type IconProps = {
  selected?: boolean;
  customClasses?: string;
  onClick?: () => void;
};

const styles = {
  date: {
    fontSize: 20,
    color: '#666666',
  },
  arrowRight: {
    fontSize: 24,
    color: '#666666',
  },
  arrowDown: {
    fontSize: 13,
    color: '#999999',
  },
  arrow: {
    fontSize: 24,
    color: '#000000',
  },
  close: {
    fontSize: 24,
    cursor: 'pointer',
  },
  info: {
    fontSize: 16,
    color: '#CCCCCC',
  },
  overviewIcon: {
    fontSize: 20,
    color: '#EB3B4C',
  },
  navIcon: {
    fontSize: 24,
    color: '#484f66',
  },
  iconSize: {
    fontSize: 14,
  },
  lock: {
    fontSize: 16,
    color: '#333333',
  },
};

export const DateIcon: FC<IconProps> = () => {
  return <PruIcon icon="date" style={styles.date} />;
};

export const ArrowRightIcon: FC<IconProps> = ({ onClick }) => {
  return <PruIcon icon="arrow_right_24" style={styles.arrowRight} onClick={onClick} />;
};

export const ArrowDownIcon: FC<IconProps> = () => {
  return <PruIcon icon="arrow_down" style={styles.arrowDown} />;
};

export const ArrowIcon: FC<IconProps> = ({ onClick }) => {
  return <PruIcon icon="arrow" style={styles.arrow} onClick={onClick} />;
};

export const CloseIcon: FC<IconProps> = ({ onClick, customClasses }) => {
  return <PruIcon icon="close" style={styles.close} onClick={onClick} customClasses={customClasses} />;
};

export const InfoIcon: FC<IconProps> = ({ onClick }) => {
  return <PruIcon icon="instruction_info" style={styles.info} onClick={onClick} />;
};

export const PointsIcon: FC<IconProps> = () => {
  return <PruIcon icon="Points" style={styles.overviewIcon} />;
};

export const LeadsIcon: FC<IconProps> = () => {
  return <PruIcon icon="leads_popup" style={styles.overviewIcon} />;
};

export const ProspectIcon: FC<IconProps> = () => {
  return <PruIcon icon="tabbar_me_normal" style={styles.overviewIcon} />;
};

export const EngagementIcon: FC<IconProps> = () => {
  return <PruIcon icon="comment" style={styles.overviewIcon} />;
};

export const PruLeadsIcon: FC<IconProps> = ({ selected, customClasses }) => {
  return <PruIcon icon={`${selected ? 'stuff_login' : 'leads_popup'}`} customClasses={customClasses} />;
};

export const NavIcon: FC<IconProps> = ({ onClick, customClasses }) => {
  return <PruIcon icon="nav" style={styles.navIcon} customClasses={customClasses} onClick={onClick} />;
};

export const UserIcon: FC<IconProps> = ({ onClick, customClasses }) => {
  return <PruIcon icon="tabbar_me_active" style={styles.navIcon} customClasses={customClasses} onClick={onClick} />;
};

export const HomeIcon: FC<IconProps> = ({ onClick, customClasses }) => {
  return <PruIcon icon="tabbar_home_normal" style={styles.iconSize} customClasses={customClasses} onClick={onClick} />;
};

export const LockIcon: FC<IconProps> = ({ customClasses, onClick }) => {
  return <PruIcon icon="Lock" style={styles.lock} customClasses={customClasses} onClick={onClick} />;
};
