import { IntlShape } from 'react-intl';
import { ModuleHierachy } from 'src/app/common/module/types';
import { AGENT_PROFILE_PATH, AGENT_PROFILE_TITLE, AGENT_PROFILE_NAMESPACE } from './constants';
import AgentProfileRoutes from './pages/AgentProfileRoutes';

export const agentProfileModuleConfig = (permissions: string[], intl: IntlShape): ModuleHierachy => {
  const Translation = (id: string) => intl.formatMessage({ id });
  return {
    title: Translation(AGENT_PROFILE_TITLE),
    path: AGENT_PROFILE_PATH,
    namespace: AGENT_PROFILE_NAMESPACE,
    disableMenu: true,
    disableRoleControl: true,
    component: AgentProfileRoutes,
    enableRead: true,
    enableCreate: true,
    enableUpdate: true,
  };
};
