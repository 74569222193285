import { Dispatch } from 'react';
import { apiClient, apiErrorHandler, AGENT_URL } from 'src/app/common/network';
import { CustomerSurveyResult, SurveyResult, CustomerSurveyPreview } from '../types/survey-types';

export type SurveyResultBody = {
  body: Record<string, unknown>;
};

export const fetchSurveyItem = async (id: string, dispatch?: Dispatch<any>): Promise<SurveyResult> => {
  console.log('isagentsurvey');

  return apiClient
    .get<SurveyResult>(`${AGENT_URL}/agents/me/survey/${id}/result`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const createSurveyResult = async (
  id: string,
  body: SurveyResultBody,
  dispatch?: Dispatch<any>,
): Promise<void> => {
  return apiClient
    .post<void>(`${AGENT_URL}/agents/me/survey/${id}/result`, body)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchCustomerSurveyItem = async (
  id: string,
  lang?: string,
  dispatch?: Dispatch<any>,
): Promise<CustomerSurveyPreview> => {
  let langCode = !!lang ? `&preferLanguage=${lang}` : '';
  return apiClient
    .get<CustomerSurveyPreview>(
      `${AGENT_URL}/agents/me/customer-surveys/${id}/preview?enableAgentNameCard=true${langCode}`,
    )

    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchCustomerSurveyResult = async (
  id: string,
  resultId: string,
  dispatch?: Dispatch<any>,
): Promise<CustomerSurveyResult> => {
  return apiClient
    .get<CustomerSurveyResult>(`${AGENT_URL}/agents/me/customer-surveys/${id}/results/${resultId}`)

    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};
