import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useStyles } from './styles';
import { useIntl } from 'react-intl';
import Form from '@common/components/Form';
import { Button, Divider, MenuItem, TextField } from '@mui/material';
import SelectBox from '../SelectBox';
import { FormInstance } from 'rc-field-form';
import { BgImagePaginateList } from '../../../../types';
import { defaultTextColorOptions, EVENT_TEMPLATE_CONFIG } from '../../../../constants';
import { get } from 'lodash';
import TextColorSelectBox from 'src/app/common/components/TextColorSelectBox';
import { arraysEqual } from '@/app/modules/ResourceLibrary/utils/common-utils';
import { fetchAllBgImageRequest } from '@/app/modules/ResourceLibrary/network/resourceCrud';

type IInformation = {
  form: FormInstance<any>;
  disabled: boolean;
};

const dropdownOptions = {
  rType: [
    { displayName: 'File', value: 'file' },
    { displayName: 'Link', value: 'link' },
    { displayName: 'Content', value: 'content' },
    { displayName: 'Material Set', value: 'materialSet' },
    { displayName: 'Text', value: 'text' },
  ],
};

const Information = ({ form, disabled }: IInformation) => {
  const { classes } = useStyles();
  const intl = useIntl();
  let selectRef: any = useRef(null);
  const Translation = (id: string, variable?: Record<string, string>) => intl.formatMessage({ id }, variable);
  const [bgImageList, setBgImageList] = useState<BgImagePaginateList>({
    items: [],
    page: 1,
    limit: 20,
    totalRecords: 0,
  });
  const [bgSearchKeyword, setBgSearchKeyword] = useState<string>('');
  const [bgSelected, setBgSelected] = useState<any>(form.getFieldValue('backgroundImage'));

  let textColorSelectRef: any = useRef(null);
  const [textColorOptions, setTextColorOptions] = useState<any[]>(defaultTextColorOptions);

  useEffect(() => {
    getBackgroundImageList(form.getFieldValue('eventInvitationTemplate'), bgSearchKeyword);
  }, [bgSearchKeyword]);

  const getBackgroundImageList = async (templateId?: string, name?: string, page: number = 1, limit: number = 20) => {
    const payload = {
      name,
      page,
      limit,
      type: 'GP',
      templateId: templateId && EVENT_TEMPLATE_CONFIG[templateId] ? EVENT_TEMPLATE_CONFIG[templateId]?.id : '',
    };

    if (templateId === '') {
      return setBgImageList({
        items: [],
        totalRecords: 0,
        page,
        limit,
      });
    }

    let resultList = await fetchAllBgImageRequest(payload);

    if (page === 1) {
      setBgImageList(resultList);
    } else {
      setBgImageList((prev) => ({
        ...resultList,
        items: [...prev.items, ...resultList.items],
      }));
    }
  };

  const onLoadMoreBgImg = () => {
    const { page, limit, totalRecords } = bgImageList;

    if (page * limit < totalRecords) {
      getBackgroundImageList(form.getFieldValue('eventInvitationTemplate'), bgSearchKeyword, page + 1, limit);
    }
  };

  useEffect(() => {
    if (bgSelected && bgSelected.textColorList) {
      if (!arraysEqual(textColorOptions, bgSelected.textColorList)) {
        setTextColorOptions(bgSelected.textColorList);
      }
    } else {
      if (!arraysEqual(textColorOptions, defaultTextColorOptions)) {
        setTextColorOptions(defaultTextColorOptions);
      }
    }
  }, [bgSelected]);

  const DEFAULT_REQUIRED_RULES = { required: true, message: Translation('component.form-required-text') };

  const BG_IMAGE_VIDATOR = [
    {
      validator(_: any, value: string) {
        if (value) {
          return Promise.resolve();
        }
        return Promise.reject(new Error(Translation('component.form-required-text')));
      },
    },
  ];

  const TEXT_COLOR_VIDATOR = [
    {
      validator(_: any, value: string) {
        if (value) {
          return Promise.resolve();
        }
        return Promise.reject(new Error(Translation('component.form-required-text')));
      },
    },
  ];

  const typeLabel = useMemo(() => {
    return dropdownOptions.rType.find((e: any) => e.value === form.getFieldValue('rType'))?.displayName || '';
  }, [form]);

  const unselectTextColor = () => {
    if (form.getFieldValue('textColor')) {
      textColorSelectRef.current?.unselect();
    }
  };

  const unselectBgImage = () => {
    setBgSelected(undefined);
    selectRef.current?.unselect();
    unselectTextColor();
  };

  return (
    <React.Fragment>
      <div className={classes.sectionWrap}>
        <div className={(classes.divideMargin, classes.sectionTitleWrap)}>
          <span className={classes.subHeader}>{Translation('component.formTitle.information')}</span>
        </div>
        <Form.Item childStyle={{ marginTop: 10 }} required={false} label={Translation('component.formLabel.type')}>
          <span>{typeLabel}</span>
        </Form.Item>
        <Form.Item
          childStyle={{ marginTop: 16, height: 32 }}
          name="category"
          label={Translation('component.formLabel.category')}
          required={false}
        >
          <span>{Translation('resource.library.gpInvitation')}</span>
        </Form.Item>
        <Form.Item
          childStyle={{ marginTop: 10 }}
          name="checkin-url"
          label={Translation('component.formLabel.checkin-url')}
          required={false}
        >
          <span>{form.getFieldValue('checkInUrl')}</span>
        </Form.Item>
        <Form.Item
          childStyle={{ marginTop: 16, height: 32 }}
          name="eventInvitationTemplate"
          label={Translation('component.formLabel.template')}
          rules={[DEFAULT_REQUIRED_RULES]}
        >
          {({ value, onChange }) => {
            return (
              <TextField
                value={value}
                disabled={disabled}
                select
                margin="dense"
                variant="outlined"
                fullWidth
                placeholder={''}
                onChange={(e) => {
                  if (e?.target?.value) {
                    form.setFieldValue('backgroundImage', undefined);
                    unselectBgImage();
                    onChange(e.target.value);
                    getBackgroundImageList(e.target.value);
                  }
                }}
              >
                {Object.keys(EVENT_TEMPLATE_CONFIG).map((key) => (
                  <MenuItem key={key} value={key || ''} onClick={() => {}}>
                    {get(EVENT_TEMPLATE_CONFIG, `${key}.label`)}
                  </MenuItem>
                ))}
              </TextField>
            );
          }}
        </Form.Item>
        <Form.Item
          childStyle={{ marginTop: 16, alignItems: 'flex-start' }}
          name="backgroundImage"
          label={'Background Image'}
          rules={BG_IMAGE_VIDATOR}
        >
          {({ value, onChange }) => {
            return (
              <div className={`${classes.d_flex_column} ${classes.w_50}`}>
                {bgSelected ? (
                  <div className={`${classes.d_flex_center}`}>
                    <span className={`${classes.mr_4}`}>{bgSelected.index || 0}</span>
                    <img src={bgSelected.imgUrl} className={`${classes.imgBg} ${classes.mr_4}`} alt="template img" />
                    <span className={`${classes.flex_fill}`}>{bgSelected.label}</span>
                    <Button
                      disabled={disabled}
                      onClick={() => {
                        unselectBgImage();
                        onChange(undefined);
                      }}
                      className={classes.downloadTxt}
                    >
                      {Translation('app.button.unselect')}
                    </Button>
                  </div>
                ) : (
                  <span>{Translation('resource.library.backgroundImagePlaceholder')}</span>
                )}
                <SelectBox
                  disabled={disabled}
                  ref={selectRef}
                  options={bgImageList.items}
                  onSelect={(item) => {
                    if (item && bgSelected && item.value !== bgSelected.value) {
                      unselectTextColor();
                    }
                    setBgSelected(item);
                    onChange(item);
                  }}
                  onLoadMore={() => onLoadMoreBgImg()}
                  initialValue={form.getFieldValue('backgroundImage')}
                  onChangeBgKeyword={setBgSearchKeyword}
                  clientFilterDisabled
                />
              </div>
            );
          }}
        </Form.Item>
        <Form.Item
          childStyle={{ marginTop: 16, alignItems: 'center' }}
          name="textColor"
          label={'Text Color'}
          rules={TEXT_COLOR_VIDATOR}
        >
          {({ value, onChange }) => {
            return (
              <div className={`${classes.d_flex_column} ${classes.w_50}`}>
                <TextColorSelectBox
                  ref={textColorSelectRef}
                  disabled={disabled}
                  options={textColorOptions}
                  onSelect={(item) => {
                    onChange(item);
                  }}
                  initialValue={form.getFieldValue('textColor')}
                />
              </div>
            );
          }}
        </Form.Item>
      </div>
      <Divider className={classes.sectionDivider} />
    </React.Fragment>
  );
};

export default React.memo(Information);
