import moment from "moment";

export const generalPattern = new RegExp(/^([a-zA-Z0-9\s][`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]{0,3})+$/);

export const currencyPattern = new RegExp(/^[€฿₫/$¥₩₱៛]{1}[0-9.,\s]+$/);

export const validationCompareChecking = (sectionItemKey: string, fieldItemKey: string, updatedFormState: any, validationObject: any) => {
    if (updatedFormState[sectionItemKey] && updatedFormState[sectionItemKey][fieldItemKey]) {
      const comparedField = validationObject.dependsOn ? validationObject.dependsOn : null;
      let currentField = updatedFormState[sectionItemKey][fieldItemKey];
      let diff = currentField;
      if (comparedField && updatedFormState[sectionItemKey][comparedField]) {
        if (validationObject.type == 'date') {
          let currentFieldDate = moment(updatedFormState[sectionItemKey][fieldItemKey]);
          let comparedFieldDate = moment(updatedFormState[sectionItemKey][comparedField]);
          diff = currentFieldDate.diff(comparedFieldDate, 'days');
        } else if (validationObject.type == 'number') {
          let currentFieldValue = updatedFormState[sectionItemKey][fieldItemKey];
          let comparedFieldValue = updatedFormState[sectionItemKey][comparedField];
          diff = currentFieldValue - comparedFieldValue;
        }
      }

      switch (validationObject.operator) {
        case 'lte':
          return diff > validationObject.value;
          break;
        case 'gte':
          return diff < validationObject.value;
          break;
        case 'lt':
          return diff <= validationObject.value;
          break;
        case 'gt':
          return diff >= validationObject.value;
          break;
        case 'eq':
          return diff != validationObject.value;
          break;
        case 'ne':
          return diff == validationObject.value;
          break;
        default:
          return false;
          break;
      }

    } else {
      return false;
    }
  }



  export  const validationLengthChecking = (sectionItemKey: string, fieldItemKey: string, updatedFormState: any, validationObject: any) => {
    // return true;
    if (updatedFormState[sectionItemKey] && updatedFormState[sectionItemKey][fieldItemKey]) {
      switch (validationObject.operator) {
        case 'lte':
          return updatedFormState[sectionItemKey][fieldItemKey].length > validationObject.value;
          break;
        case 'gte':
          return updatedFormState[sectionItemKey][fieldItemKey].length < validationObject.value;
          break;
        case 'le':
          return updatedFormState[sectionItemKey][fieldItemKey].length >= validationObject.value;
          break;
        case 'ge':
          return updatedFormState[sectionItemKey][fieldItemKey].length <= validationObject.value;
          break;
        case 'eq':
          return updatedFormState[sectionItemKey][fieldItemKey].length != validationObject.value;
          break;
        case 'ne':
          return updatedFormState[sectionItemKey][fieldItemKey].length == validationObject.value;
          break;
        default:
          return false;
          break;
      }
    } else {
      return false;
    }
  }

  export const validationMandatoryDependsChecking = (
    sectionItemKey: string,
    fieldItemKey: string,
    updatedFormState: any,
    validationObject: any,
    checkInput: boolean = true,
  ) => {
    const comparedField = validationObject.dependsOn ? validationObject.dependsOn : null;
    if (comparedField && updatedFormState[sectionItemKey] && updatedFormState[sectionItemKey][comparedField]) {
      let shouldMandatory = false;
      switch (validationObject.operator) {
        case 'lte':
          if (updatedFormState[sectionItemKey][comparedField] <= validationObject.value) {
            shouldMandatory = true;
          }
          break;
        case 'gte':
          if (updatedFormState[sectionItemKey][comparedField] >= validationObject.value) {
            shouldMandatory = true;
          }
          break;
        case 'lt':
          if (updatedFormState[sectionItemKey][comparedField] < validationObject.value) {
            shouldMandatory = true;
          }
          break;
        case 'gt':
          if (updatedFormState[sectionItemKey][comparedField] > validationObject.value) {
            shouldMandatory = true;
          }
          break;
        case 'eq':
          if (updatedFormState[sectionItemKey][comparedField] == validationObject.value) {
            shouldMandatory = true;
          }

          // if ([validationObject.value, 'Y'].includes(updatedFormState[sectionItemKey][comparedField])) {
          //   shouldMandatory = true;
          // }
          break;
        case 'ne':
          if (updatedFormState[sectionItemKey][comparedField] != validationObject.value) {
            shouldMandatory = true;
          }
          break;
        default:
          return false;
          break;
      }
      if (!checkInput) {
        // no need to check user input, just return
        return shouldMandatory;
      }
      if (shouldMandatory) {
        return (
          !updatedFormState[sectionItemKey] ||
          !updatedFormState[sectionItemKey][fieldItemKey] ||
          updatedFormState[sectionItemKey][fieldItemKey] == ''
        );
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  export const validationCurrencyChecking = (sectionItemKey: string, fieldItemKey: string, updatedFormState: any) => {
    if (updatedFormState[sectionItemKey] && updatedFormState[sectionItemKey][fieldItemKey]) {
      return !currencyPattern.test(updatedFormState[sectionItemKey][fieldItemKey]);
    } else {
      return false;
    }
  }
