import React, { FC, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { RouteComponentProps, useParams } from 'react-router-dom';
import { IconButton } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Edit } from '@mui/icons-material';
import { NOT_FOUND_ERROR_PAGE_PATH } from 'src/app/common/constants';
import { LayoutSplashScreen } from 'src/app/layout';
import { useLang } from 'src/app/i18n';
import { AGENT_PROFILE_PATH } from 'src/app/modules/AgentProfile/constants';
import { fetchAgentProfile, getAgentAwards } from '../../../network/agentProfileCrud';
import {
  AgentProfileItem,
  AwardItem,
  LocationState,
  FromPageEnum,
} from 'src/app/modules/AgentProfile/types/agent-profile-types';
import { moveSelectedAwardsToTop } from 'src/app/modules/AgentProfile/common/utils/list-utils';
import { localeMapToBackend } from 'src/app/modules/AgentProfile/common/utils/locale-utils';
import BasicInfoImage from 'src/app/modules/AgentProfile/common/components/BasicInfoImage';
import BasicInfo from 'src/app/modules/AgentProfile/common/components/BasicInfo';
import AwardList from 'src/app/modules/AgentProfile/common/components/AwardList';
import { EditIcon } from 'src/app/modules/AgentProfile/common/icons';
import { useGAScreenViewEvent } from '../../../../../common/ga/hook/useGAScreenViewEvent';
import { takeUIClickEvent } from '../../../../../common/ga/ga';

const SECTION_PADDING = 20;
const SECTION_MARGIN = 8;
const BASIC_INFO_SECTION_PADDING = '40px 30px 16px';

const useStyles = makeStyles()((theme) => ({
  header: {
    fontSize: 16,
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    '&:before': {
      content: "''",
      display: 'inline-block',
      height: 16,
      width: 3,
      backgroundColor: 'red',
      marginRight: 10,
      marginLeft: 10,
    },
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 15,
  },
  sectionContainer: {
    padding: SECTION_PADDING,
    paddingBottom: 8,
    marginBottom: SECTION_MARGIN,
    backgroundColor: theme.palette.common.white,
  },
  basicInfoSectionContainer: {
    padding: BASIC_INFO_SECTION_PADDING,
    marginBottom: SECTION_MARGIN,
    backgroundColor: theme.palette.common.white,
  },
  iconButton: {
    padding: 8,
    marginTop: -8,
    width: 43,
  },
}));

const AgentProfileAwardViewMorePage: FC<RouteComponentProps> = ({ history, location }) => {
  const { classes } = useStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();
  const lang = useLang();
  const mappedLocale = localeMapToBackend[lang] || lang;

  const receivedLocationState = location.state as LocationState;
  console.log('receivedLocationState',receivedLocationState)
  useGAScreenViewEvent(
    {
      module: 'AgentProfile',
      feature: 'AgentProfileSite',
      journey: receivedLocationState?.from === FromPageEnum.CREATE ? 'create_agent_profile' : 'edit_agent_profile',
      stage: 'view_full_awards',
      screen_name: 'AwardMorePageScreen',
    },
    true,
  );

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [agentProfile, setAgentProfile] = useState<AgentProfileItem>();
  const [awardList, setAwardList] = useState<AwardItem[]>([]);
  const [locationState, setLocationState] = useState<LocationState>();

  const reloadData = async () => {
    setIsLoading(true);
    try {
      const res = await fetchAgentProfile(mappedLocale, dispatch);
      setAgentProfile(res);
      setLocationState({ initAgentProfile: res, from: FromPageEnum.EDIT });
      const awardListRes = await getAgentAwards(mappedLocale, dispatch);
      setAwardList(moveSelectedAwardsToTop(res.awards, awardListRes.awards));
    } catch (err) {
      history.push(NOT_FOUND_ERROR_PAGE_PATH);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if ((window as any).ReactNativeWebView) {
      (window as any).ReactNativeWebView.postMessage(
        `{ "action": "loadheaderprops", "payload": { "title": "myAwardsAndAccomplishment", "backTarget": "web" } }`,
      );
    }
  }, []);

  useEffect(() => {
    reloadData();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {isLoading || !agentProfile ? (
        <LayoutSplashScreen />
      ) : (
        <div>
          <BasicInfoImage agentProfile={agentProfile} />
          <div className={classes.basicInfoSectionContainer}>
            <BasicInfo agentProfile={agentProfile} />
          </div>
          <div className={classes.sectionContainer}>
            <div className={classes.headerContainer}>
              <div className={classes.header}>{Translation(`agentProfile.ap_my_awards_header_title`)}</div>
              <IconButton
                className={classes.iconButton}
                onClick={() => {
                  takeUIClickEvent({
                    stage: 'view_full_awards',
                    object_name: 'Edit',
                    object_type: 'BUTTON',
                  });
                  history.push(`${AGENT_PROFILE_PATH}/award?noHeader`, locationState);
                }}
              >
                <EditIcon />
              </IconButton>
            </div>
            <AwardList mappedLocale={mappedLocale} badges={agentProfile.awardBadges} awards={awardList} />
          </div>
        </div>
      )}
    </>
  );
};

export default AgentProfileAwardViewMorePage;
