import React, { FC, useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';
import PruFilter, { PruFilterItemType } from '../../../../../common/components/PruTable/PruFilter';
import { useIntl } from 'react-intl';
import { map } from 'lodash';
import DashboardDetailPage from './components/DashboardDetailPage';
import { ParamsProps } from '../../../../../common/components/ParamsProvider';
import { ChartListParam, fetchChartList } from '../../../network/dashboardCrud';
import { DashboardItem, BDMdashboardName, DashboardType, BDMdashboardFilter } from '../../../types/dashboard-types';
import { useDataProvider } from '../../../../../common/utils/hook-utils';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import moment from 'moment';
import { takeUIClickEvent } from '../../../../../common/ga/ga';

const ExportJsonExcel = require("js-export-excel");
type DashboardListingPageProps = {} & ParamsProps;

const initialState: ChartListParam = {
  chartName: "Appointment to Lost Report",
  chartType: "Table",
  filter: "",
  value: ""
};

const paramsInitiator = (initialParams?: Record<string, string>): ChartListParam => {
  return initialParams ? {
    chartName: "Appointment to Lost Report",
    //chartID: "62625264-8055-4590-8c78-8bf995f6bcd1",
    chartType: "Table",
    filter: "",
    value: ""
  } : initialState;
}

export const dashboardFilterKeys = ["bundleIdentifier", "name", "role", "isPublic"];

const DashboardListingPage: FC<DashboardListingPageProps> = ({ initialParams, onChangeQueryParams }) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();

  const [filterState, setFilterState] = useState<ChartListParam>(paramsInitiator(initialParams));
  const [, setDashboardList] = useState<DashboardItem[]>();


  const [myFilter, setMyFilter] = useState({
    assignedDate: filterState.value,
    type: filterState.value,
  });

  const { isLoading, refreshData } = useDataProvider<DashboardItem[]>(async () => {
    try {
      if (onChangeQueryParams) {
        onChangeQueryParams(filterState);

      }
      return await fetchChartList(filterState, dispatch);
    } catch (err) { }
  }, setDashboardList, false);

  const [excelData, setExcelData] = useState<Promise<object>>();

  const exportExcel = async () => {

    let sheetHeader: any[] = [];

    takeUIClickEvent({
      journey:'download_report',
      stage:'download',
      object_name:'Download',
      object_type:'BUTTON'
    })

    excelData?.then((res) => {
      let obj = JSON.parse(JSON.stringify(res)).fields;

      for (let key in obj) {
        sheetHeader.push(obj[key]);
      }

      let sheetData: any = [];
      let documents = JSON.parse(JSON.stringify(res)).documents;


      //adjust the order of data : group -> group_series_(index) -> value
      if (filterState.chartType == "Table") {
        documents.forEach((element: any) => {
          let documentObj: any = {};

          if (element.length != sheetHeader.length) {
            documentObj["group"] = element.group ? element["group"] : "";

            let str = "group_series_";
            for (let i = 0; i < sheetHeader.length - 2; i++) {
              documentObj[str.concat(i.toString())] = element[str.concat(i.toString())] ? element[str.concat(i.toString())] : "";
            }

            documentObj["value"] = element["value"];
          }

          sheetData.push(element.length != sheetHeader.length ? documentObj : element);
        });
      }
      // over

      console.log("sheetData" + sheetData);
      console.log("sheetHeader1" + sheetHeader);
      var option = {
        fileName: filterState.chartName,
        datas: [
          {
            sheetData: sheetData,
            sheetName: "sheet",
            sheetHeader: sheetHeader,
          },
        ]
      };
      var toExcel = new ExportJsonExcel(option);

      toExcel.saveExcel();
    }
    );
  }
  console.log(filterState);

  const MyFilterTable = () => {

    const tmpFilter = JSON.parse(JSON.stringify(BDMdashboardFilter.get(filterState.chartName)));
    const tmpState = JSON.parse(JSON.stringify(myFilter));
    let map = new Map();

    for (const key in tmpFilter) {
      if (Object.prototype.hasOwnProperty.call(tmpFilter, key)) {
        map.set(tmpFilter[key], tmpState[key]);
      }
    }
    console.log(map);


    return (
      <Table aria-label="simple table" style={{ backgroundColor: 'white', marginBottom: 20, minHeight: 110 }} >
        <TableHead>
          <TableRow>
            {Array.from(map.keys()).length > 0 ? Array.from(map.keys()).map((key) => <TableCell align="left" >{key}</TableCell>) : <></>}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            {Array.from(map.values()).length > 0 ? Array.from(map.values()).map((value: any) => (<TableCell align="left">{value ? value instanceof Array ? moment(value[0]).format("DD/MM/YYYY") + " - " + moment(value[1]).format("DD/MM/YYYY") : value : " "}</TableCell>)) : <TableCell></TableCell>}
          </TableRow>
        </TableBody>
      </Table >
    );
  }

  useEffect(() => {
    MyFilterTable();
  }, [myFilter])


  return (
    <>
      <PruFilter
        title={Translation("dashboard.common.controlPanel")}
        itemDef={[
          {
            type: PruFilterItemType.DROPDOWN,
            field: "chartName",
            initialValue: filterState.chartName,
            defaultValue: initialState.chartName,
            displayName: Translation("dashboard.common.name"),
            choices: [
              ...map(BDMdashboardName, (option: string) => ({ displayName: option, value: option }))
            ]
          },
          {
            type: PruFilterItemType.DROPDOWN,
            field: "chartType",
            initialValue: filterState.chartType,
            defaultValue: initialState.chartType,
            displayName: Translation("dashboard.common.type"),
            choices: [
              ...map(DashboardType, (option: string) => ({ displayName: option, value: option }))
            ]
          },
          {
            type: PruFilterItemType.DROPDOWN,
            field: "filter",
            initialValue: filterState.filter,
            displayName: Translation("dashboard.common.filter"),
            choices: [
              { displayName: "", value: "" },
              ...map(BDMdashboardFilter.get(filterState.chartName), (option: string) => ({ displayName: option, value: option }))
            ]
          },
          {
            type: PruFilterItemType.FREE_TEXT,
            field: "value",
            initialValue: filterState.value,
            displayName: Translation("dashboard.common.value")
          }
        ]}
        onChangeFilter={data => setFilterState({
          ...filterState,
          chartName: data.chartName,
          chartType: data.chartType,
          filter: data.filter,
          value: data.value
        })}
        fetchData={refreshData}
        downloadExcel={exportExcel}
      />
      <MyFilterTable />
      <DashboardDetailPage
        filter={filterState}
        myFilter={myFilter}
        onRefresh={true}
        refreshdata={refreshData}
        setExcelData={(data) => setExcelData(data)}
        setMyFilter={setMyFilter}
      />

    </>
  )
};

export default DashboardListingPage;