import { ValueOf } from 'src/app/common/types/common-types';

export enum FilterDisplayFormatEnum {
  DEFAULT = 'default',
  SELECT_GROUP_OPTION = 'select_group_option',
  CHIP_TAB = 'chip_tab',
  BOX_TAB = 'box_tab',
}

export enum FilterTypeEnum {
  DEFAULT = 'default',
  OPTION_TO_DATE = 'option_to_date',
  CHIP = 'CHIP',
}

export enum OptionToDateFilterEnum {
  TODAY = 'today',
  THIS_WEEK = 'this_week',
  // LAST_WEEK = 'last_week',
  CUSTOM = 'custom',
}

export enum FilterContentEnum {
  MAIN = 'main',
  SELECT_WEEK = 'select_week',
}

type DashboardFilterOption<T> = {
  displayName: string;
  value: ValueOf<T>;
};

type CommonFilterDef<T> = {
  keyIndex: keyof T & string;
  displayName: string;
  options: DashboardFilterOption<T>[];
  disableCondition?: (filterState: T) => boolean;
};

type DefaultFilterDef<T> = {
  type: FilterTypeEnum.DEFAULT;
} & CommonFilterDef<T>;

type OptionToDateFilterDef<T> = {
  type: FilterTypeEnum.OPTION_TO_DATE;
  dateFromKey: keyof T & string;
  dateToKey: keyof T & string;
} & CommonFilterDef<T>;

type ChipFilterDef<T> = {
  type: FilterTypeEnum.CHIP;
} & CommonFilterDef<T>;

export type DashboardFilterDef<T> = DefaultFilterDef<T> | OptionToDateFilterDef<T> | ChipFilterDef<T>;

export type FilterProps<T> = {
  filterDef: DashboardFilterDef<T>[];
  filterState: T;
  setFilterState: (newFilterState: T) => void;
  onConfirm: () => void;
  onClose: () => void;
  onReset: () => void;
  handleSelectFilter: (filter: DashboardFilterDef<T>, value: ValueOf<T>, isConfirm?: boolean) => void;
};

type MainFilterContent = {
  content: FilterContentEnum.MAIN;
};

export type WeekFilterContent = {
  content: FilterContentEnum.SELECT_WEEK;
  dateFromKey: string;
  dateToKey: string;
  toContent?: FilterContent;
};

export type FilterContent = MainFilterContent | WeekFilterContent;

export type FilterContentProps<T> = {
  filterContent: FilterContent;
  setFilterContent: (newFilterContent: FilterContent) => void;
} & FilterProps<T>;

export type DialogState = {
  open: boolean;
  filterContent?: FilterContent;
};
