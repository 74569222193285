export type DesignationType =
  | "SRD"
  | "RD"
  | "SRM"
  | "RM"
  | "BM"
  | "SUM"
  | "UM"
  | "SBSM"
  | "BSM"
  | "SFC"
  | "FC"
  | "LA";

export const DesignationValueMap = {
  SRD: "G5",
  RD: "G4",
  SRM: "G3",
  RM: "G2",
  BM: "G1L3",
  SUM: "G1L2",
  UM: "G1L1",
  SBSM: "SIC2",
  BSM: "SIC1",
  SFC: "SLU",
  FC: "LU",
  LA: "LA",
};

export type AgentDesignation = Record<string, Record<string, string>>;

export enum LanguageType {
  EN = "enUs",
  ZH_HK = "zhHk",
}

export type PaginateList<T extends object> = {
  total: number;
  totalPages: number;
  limit: number;
  items: T[];
  data?: T[];
  docs: T[];
  hasNextPage: boolean;
  hasPrevPage: boolean;
  totalDocs: number;
};

export enum RedirectType {
  APPLICATION_DOWNLOAD = "download-page",
}

export const redirectMap: Record<RedirectType, string> = {
  [RedirectType.APPLICATION_DOWNLOAD]: "/application/download",
};

export type ValueOf<T> = T[keyof T & string];

export type SortKey = {
  key: string;
  value?: string;
}
