import React, { FC, useState, useEffect } from 'react';
import { RouteComponentProps, useParams, useLocation } from 'react-router-dom';
import { ApiErrorResponse } from '../../../../common/network/apiErrorHandler';
import { useDispatch } from 'react-redux';
import { LayoutSplashScreen } from 'src/app/layout';
import { fetchCustomerSurveyItem, fetchCustomerSurveyResult } from '../../network/surveyCrud';
import { CustomerSurveyResult, CustomerSurveyPreview } from '../../types/survey-types';
import { getBlobAsAgent } from 'src/app/common/network/crud/resourceCrud';
import { NOT_FOUND_ERROR_PAGE_PATH } from '../../../../common/constants';
import CustomerSurveyForm from './components/CustomerSurveyForm';

const CustomerSurveyPage: FC<RouteComponentProps> = ({ history, location }) => {
  const { surveyId, surveyResultId } = useParams<{ surveyId: string; surveyResultId: string }>();

  const dispatch = useDispatch();
  const queryParams = new URLSearchParams(window.location.search);
  const lang = queryParams.get('preferLanguage') ?? '';
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [surveyResult, setSurveyResult] = useState<CustomerSurveyResult>({
    surveyId: '',
    surveyBody: {},
    surveyResults: [],
  });
  const [preview, setPreview] = useState<CustomerSurveyPreview>({
    _id: '',
    body: {},
    bannerId: '',
    surveyImage: '',
    agentProfile: { displayName: '', designationName: '' },
  });
  const [banner, setBanner] = useState<string>('');
  const getBanner = async (result: CustomerSurveyPreview) => {
    const data = await getBlobAsAgent({ resourceIds: result.bannerId });
    const url = data[0].url;

    setBanner(url);
  };
  const reloadData = () => {
    if (surveyId && surveyResultId !== undefined) {
      setIsLoading(true);
      fetchCustomerSurveyResult(surveyId, surveyResultId, dispatch)
        .then((result) => {
          setSurveyResult(result);
        })
        .catch((err: ApiErrorResponse) => {
          history.push(NOT_FOUND_ERROR_PAGE_PATH);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else if (surveyId) {
      setIsLoading(true);
      fetchCustomerSurveyItem(surveyId, lang, dispatch)
        .then((prev) => {
          setPreview(prev);
          setBanner(prev?.surveyImage);
          // getBanner(prev);
        })
        .catch((err: ApiErrorResponse) => {
          history.push(NOT_FOUND_ERROR_PAGE_PATH);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    reloadData();

    // eslint-disable-next-line
  }, [surveyId]);
  console.log('preview-data', banner);

  return (
    <>
      {isLoading || (!surveyResult && !preview) ? (
        <LayoutSplashScreen />
      ) : (
        <>
          {!surveyResultId ? (
            <CustomerSurveyForm
              surveyId={preview._id}
              surveyBody={preview.body}
              surveyBanner={banner}
              agentProfile={preview.agentProfile}
            />
          ) : (
            <>
              <CustomerSurveyForm
                surveyId={surveyResult.surveyId}
                surveyBody={surveyResult.surveyBody}
                surveyResult={surveyResult.surveyResults.length > 0 ? surveyResult.surveyResults[0] : undefined}
              />
            </>
          )}
        </>
      )}
    </>
  );
};

export default CustomerSurveyPage;
