import { CSSProperties } from 'react';
import { makeStyles } from 'tss-react/mui';
import { CardProps, CardContentProps, CardTypeEnum } from '../types/dashboard-card-types';
import CardHeader from './components/CardHeader';
import MainCard from './components/MainCard';
import SplitCard from './components/SplitCard';

type DashboardCardProps<T, K> = {
  style?: CSSProperties;
  disableHeader?: boolean;
} & CardProps<T, K>;

const useStyles = makeStyles()((theme) => ({
  cardContainer: {
    backgroundColor: '#FFFFFF',
    borderRadius: 12,
    boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.08)',
  },
}));

const DashboardCard = <T extends Record<string, any>, K extends Record<string, any>>({
  style,
  disableHeader,
  title,
  headerIcon,
  tooltipContent,
  handleRedirect,
  ...props
}: DashboardCardProps<T, K>) => {
  const { classes } = useStyles();

  const renderCard = (contentProps: CardContentProps<T, K>) => {
    switch (contentProps.type) {
      case CardTypeEnum.MAIN:
        return <MainCard {...contentProps} />;
      case CardTypeEnum.SPLIT:
        return <SplitCard {...contentProps} />;
      default:
        return <></>;
    }
  };

  return (
    <div className={classes.cardContainer} style={style}>
      {!disableHeader && (
        <CardHeader
          title={title}
          headerIcon={headerIcon}
          tooltipContent={tooltipContent}
          handleRedirect={handleRedirect}
        />
      )}
      {renderCard(props)}
    </div>
  );
};

export default DashboardCard;
