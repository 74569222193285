import moment from 'moment';
import { DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import { Translation } from 'src/app/i18n';
import { getWeekStart, getWeekEnd } from 'src/app/common/utils/time-utils';
import { useCommonStyles } from '../index';
import { CloseIcon } from '../../../../Icons';
import { FilterContentProps, FilterContent, WeekFilterContent } from '../../../../types/dashboard-filter-types';
import WeekPicker from 'src/app/common/components/PruPicker/WeekPicker';

const WeekFilter = <T extends Record<string, unknown>>({
  filterContent,
  filterState,
  setFilterState,
  onClose,
  onConfirm,
  setFilterContent,
}: FilterContentProps<T>) => {
  const { classes: commonClasses } = useCommonStyles();
  const weekFilterContent = filterContent as WeekFilterContent;
  const { dateFromKey, dateToKey, toContent } = weekFilterContent;
  const initDateFrom = filterState[dateFromKey] as Date;
  const initDateTo = filterState[dateToKey] as Date;

  const handleChange = (week: string, year: string) => {
    const date = moment(`${week.split('-')[0]}/${year}`, 'DD/MM/YYYY').toDate();
    setFilterState({
      ...filterState,
      [dateFromKey]: getWeekStart(date, true),
      [dateToKey]: getWeekEnd(date, true),
    });
  };

  return (
    <>
      <DialogTitle className={commonClasses.titleContainer}>
        <div className={commonClasses.titleDiv}>
          <div className={commonClasses.title}>
            {toContent && (
              <ArrowBack
                className={`${commonClasses.icon} ${commonClasses.backIcon}`}
                onClick={() => setFilterContent(toContent)}
              />
            )}
            {Translation('managerDashboard.filter.select_week')}
          </div>
          <CloseIcon onClick={onClose} />
        </div>
      </DialogTitle>
      <DialogContent className={commonClasses.contentContainer} style={{ minHeight: 336 }}>
        <WeekPicker
          isIsoWeek
          initDateFrom={initDateFrom}
          initDateTo={initDateTo}
          startYear={moment(new Date()).year() - 1}
          weekFormat="DD/MM"
          onChange={handleChange}
        />
      </DialogContent>
      <DialogActions className={commonClasses.actionContainer}>
        <div className={commonClasses.actionDiv}>
          <Button variant="contained" color="secondary" className={commonClasses.button} onClick={() => onConfirm()}>
            {Translation('app.button.confirm')}
          </Button>
        </div>
      </DialogActions>
    </>
  );
};

export default WeekFilter;
