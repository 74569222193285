import moment from 'moment';
import { FC, Fragment, useState, useReducer, useEffect } from 'react';
import { map, isEmpty } from 'lodash';
import { useHistory } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import { LayoutSplashScreen } from 'src/app/layout';
import { NOT_FOUND_ERROR_PAGE_PATH } from 'src/app/common/constants';
import { Translation } from 'src/app/i18n';
import { takeUIClickEvent } from 'src/app/common/ga/ga';
import { getDayEnd, getWeekStart, getWeekEnd } from 'src/app/common/utils/time-utils';
import {
  FilterDisplayFormatEnum,
  FilterTypeEnum,
  OptionToDateFilterEnum,
  CardTypeEnum,
  DashboardSelectGroup,
  DashboardFilter,
  DashboardCard,
  PointsIcon,
  LeadsIcon,
  ProspectIcon,
  EngagementIcon,
} from 'src/app/common/components/ManagerDashboard';
import {
  PruLeadsDetailPageTabEnum,
  PruLeadsLocationState,
  DownlineItem,
  DimensionSection,
  CommonData,
  LeadsData,
  ProspectData,
  EngagementData,
  DimensionDataType,
  DimensionDataItem,
  DimensionDataState,
  DimensionFilterItem,
  DimensionFilterState,
} from 'src/app/modules/ManagerDashboard/types';
import {
  DimensionDataParam,
  fetchOverviewDimension,
  fetchDimensionDataItem,
} from 'src/app/modules/ManagerDashboard/network/pruLeadsCrud';
import { pruleadsPath, overviewGAData } from '../PruLeadsRoutes';
import { CARD_COLUMN_GAP, overviewStyles } from './styles/overview-styles';
import { pageReducer } from './reducers';

const useStyles = makeStyles()((theme) => ({
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: '#FFFFFF',
    padding: '16px 20px',
  },
  title: {
    fontSize: 18,
    fontWeight: 600,
  },
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  mainContainer: {
    display: 'flex',
    columnGap: CARD_COLUMN_GAP,
    rowGap: 12,
    padding: '12px 16px',
    overflowX: 'auto',
    flexWrap: 'wrap',
  },
}));

const PruLeadsOverviewPage: FC = () => {
  const { classes } = useStyles();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [pageState, pageDispatch] = useReducer(pageReducer, {});
  const { overviewDimension, dimensionDataState, dimensionFilterState, changedDimensionFilter } = pageState;

  const reloadOverviewDimension = async () => {
    setIsLoading(true);
    try {
      const res = await fetchOverviewDimension();
      pageDispatch({ type: 'SET_OVERVIEW_DIMENSION', payload: { value: res } });
    } catch (err) {
      history.push(NOT_FOUND_ERROR_PAGE_PATH);
    } finally {
      setIsLoading(false);
    }
  };

  const reloadDimensionDataState = async (dimensionKey?: string) => {
    if (overviewDimension) {
      if (dimensionFilterState && dimensionKey) {
        const filterItem: DimensionFilterItem = dimensionFilterState[dimensionKey];
        const now = new Date();
        const dateTo: Date = moment(filterItem.dateTo).isAfter(now) ? getDayEnd(now) : filterItem.dateTo;
        const selectedDownline = filterItem.downline;
        const param: DimensionDataParam = {
          dateFrom: filterItem.dateFrom,
          dateTo,
        };
        const newDataItem = await fetchDimensionDataItem(selectedDownline.groupType, selectedDownline.groupCode, param);
        pageDispatch({ type: 'SET_DIMENSION_DATA_ITEM_BY_KEY', payload: { dimensionKey, value: newDataItem } });
        pageDispatch({ type: 'SET_CHANGED_DIMENSION_FILTER', payload: { value: undefined } });
      } else {
        const newDimensionDataState: DimensionDataState = {};
        const newDimensionFilterState: DimensionFilterState = {};
        const now = new Date();
        const dateFilter = OptionToDateFilterEnum.THIS_WEEK;
        const dateFrom = getWeekStart(now, true);
        const dateTo = getWeekEnd(now, true);
        const param: DimensionDataParam = {
          dateFrom,
          dateTo: moment(dateTo).isAfter(now) ? getDayEnd(now) : dateTo,
        };
        for (let dimension of overviewDimension.dimensions) {
          const selectedDownline = dimension.groups[0];
          const dataItem = await fetchDimensionDataItem(selectedDownline.groupType, selectedDownline.groupCode, param);
          newDimensionDataState[dimension.name] = dataItem;
          newDimensionFilterState[dimension.name] = {
            downline: selectedDownline,
            downlineList: dimension.groups,
            dateFilter,
            dateFrom,
            dateTo,
          };
        }
        pageDispatch({ type: 'SET_DIMENSION_DATA_STATE', payload: { value: newDimensionDataState } });
        pageDispatch({ type: 'SET_DIMENSION_FILTER_STATE', payload: { value: newDimensionFilterState } });
      }
    }
  };

  useEffect(() => {
    reloadOverviewDimension();
  }, []);

  useEffect(() => {
    reloadDimensionDataState();
  }, [overviewDimension]);

  useEffect(() => {
    if (changedDimensionFilter) {
      reloadDimensionDataState(changedDimensionFilter);
    }
  }, [changedDimensionFilter]);

  const handleConfirmDownline = (dimensionKey: string, value: DownlineItem) => {
    pageDispatch({ type: 'SET_SELECTED_DOWNLINE', payload: { dimensionKey, value } });
  };

  const handleChangeFilter = (dimensionKey: string, value: DimensionFilterItem) => {
    pageDispatch({ type: 'SET_DIMENSION_FILTER_ITEM_BY_KEY', payload: { dimensionKey, value } });
  };

  const toDetailPage = (dimensionKey: string, dataKey: keyof DimensionDataItem) => {
    if (dimensionFilterState) {
      const { downlineList, ...rest } = dimensionFilterState[dimensionKey];
      const locationState: PruLeadsLocationState = {
        tab: dataKey,
        dimension: dimensionKey,
        ...rest,
      };
      history.push(`${pruleadsPath}/detail?noHeader`, locationState);
    }
  };

  const renderTooltip = (dataKey: keyof DimensionDataItem, numOfSection: number) => {
    const sections = [];
    for (let i = 0; i < numOfSection; i++) {
      sections.push({
        title: Translation(`managerDashboard.pruleads.overview.${dataKey}.tooltip.title_${i + 1}`),
        content: Translation(`managerDashboard.pruleads.overview.${dataKey}.tooltip.content_${i + 1}`),
      });
    }
    return (
      <div style={{ fontSize: 14, fontFamily: 'Arial', lineHeight: 1.5 }}>
        {sections.map((section, index) => (
          <div key={`tooltip-${dataKey}-${index}`} style={{ marginBottom: index !== numOfSection - 1 ? 20 : 0 }}>
            <div style={{ fontWeight: 600 }}>{section.title}</div>
            <div>{section.content}</div>
          </div>
        ))}
      </div>
    );
  };

  const renderData = (dimensionKey: string, section: DimensionSection) => {
    const dataKey = section.name;
    if (section.isShow && dimensionDataState && !isEmpty(dimensionDataState[dimensionKey][dataKey])) {
      const data: DimensionDataType = dimensionDataState[dimensionKey][dataKey];
      const key = `card-${dimensionKey}-${dataKey}`;
      const title = Translation(`managerDashboard.pruleads.title.${dataKey}`);

      // PCAAEB-26883: hide PruLeadsDetailPage
      // const handleRedirect = !section.canGoToDetail
      //   ? undefined
      //   : () => {
      //       toDetailPage(dimensionKey, dataKey);
      //     };
      const handleRedirect = undefined;
      const getCaption = (key: string) => Translation(`managerDashboard.pruleads.overview.${dataKey}.caption.${key}`);

      const splitCardCommonProps = {
        key,
        title,
        handleRedirect,
        leftLabel:
          dataKey === PruLeadsDetailPageTabEnum.POINTS
            ? ''
            : Translation(`managerDashboard.pruleads.overview.label.${dataKey}`),
        rightLabel: Translation('managerDashboard.pruleads.overview.label.agent'),
        rightValueStyle: (value: number) => (value > 0 ? overviewStyles.red : overviewStyles.green),
        getLeftCaption: getCaption,
        getRightCaption: getCaption,
      };

      switch (dataKey) {
        case PruLeadsDetailPageTabEnum.POINTS:
          const pointsData = data as CommonData;
          return (
            <DashboardCard
              key={key}
              style={overviewStyles.upperCard}
              title={title}
              headerIcon={<PointsIcon />}
              tooltipContent={renderTooltip(dataKey, 2)}
              handleRedirect={handleRedirect}
              type={CardTypeEnum.MAIN}
              data={pointsData}
              keys={['unqualifiedAgentsNum', 'qualifiedAgentsNum']}
              totalKey="totalAgentsNum"
              totalDisplayName={Translation('managerDashboard.common.total_agent')}
              caption={Translation('managerDashboard.pruleads.overview.point.caption')}
              remark={Translation('managerDashboard.common.number_of_agent_remark')}
              valueStyle={(value) => (value > 0 ? overviewStyles.red : overviewStyles.green)}
            />
          );
        case PruLeadsDetailPageTabEnum.LEADS:
          const leadsData = data as LeadsData;
          const { totalNewLead, totalLeadStatusUpdated, ...rightLeadsData } = leadsData;
          const leftLeadsData = { totalNewLead, totalLeadStatusUpdated };
          return (
            <DashboardCard
              {...splitCardCommonProps}
              style={overviewStyles.upperCard}
              headerIcon={<LeadsIcon />}
              tooltipContent={renderTooltip(dataKey, 6)}
              type={CardTypeEnum.SPLIT}
              leftData={leftLeadsData}
              leftDataKeys={['totalNewLead', 'totalLeadStatusUpdated']}
              rightData={rightLeadsData}
              rightDataKeys={['newLead', 'leadStatusUpdated']}
              rightDataValueKeys={['unqualifiedAgentsNum', 'qualifiedAgentsNum']}
              rightDataValueTotalKey="totalAgentsNum"
            />
          );
        case PruLeadsDetailPageTabEnum.PROSPECT:
          const prospectData = data as ProspectData;
          const { newProspectsNum, ...restProspectData } = prospectData;
          const leftProspectData = { newProspectsNum };
          const rightProspectData = { agent: restProspectData };
          return (
            <DashboardCard
              {...splitCardCommonProps}
              style={overviewStyles.lowerCard}
              headerIcon={<ProspectIcon />}
              tooltipContent={renderTooltip(dataKey, 2)}
              type={CardTypeEnum.SPLIT}
              leftData={leftProspectData}
              leftDataKeys={['newProspectsNum']}
              rightData={rightProspectData}
              rightDataKeys={['agent']}
              rightDataValueKeys={['unqualifiedAgentsNum', 'qualifiedAgentsNum']}
              rightDataValueTotalKey="totalAgentsNum"
            />
          );
        case PruLeadsDetailPageTabEnum.ENGAGEMENT:
          const engagementData = data as EngagementData;
          const { totalCompletedEngagement, ...restEngagementData } = engagementData;
          const leftEngagementData = { totalCompletedEngagement };
          const rightEngagementData = { agent: restEngagementData };
          return (
            <DashboardCard
              {...splitCardCommonProps}
              style={overviewStyles.lowerCard}
              headerIcon={<EngagementIcon />}
              tooltipContent={renderTooltip(dataKey, 3)}
              type={CardTypeEnum.SPLIT}
              leftData={leftEngagementData}
              leftDataKeys={['totalCompletedEngagement']}
              rightData={rightEngagementData}
              rightDataKeys={['agent']}
              rightDataValueKeys={['unqualifiedAgentsNum', 'qualifiedAgentsNum']}
              rightDataValueTotalKey="totalAgentsNum"
            />
          );
      }
    }
  };

  return (
    <>
      {isLoading || !overviewDimension || !dimensionDataState || !dimensionFilterState ? (
        <LayoutSplashScreen />
      ) : (
        <>
          {overviewDimension.dimensions.map((dimension) => {
            const dimensionKey = dimension.name;
            return (
              <Fragment key={`dimension-${dimensionKey}`}>
                <div className={classes.headerContainer}>
                  <div className={classes.title}>
                    {Translation(`managerDashboard.pruleads.overview.title.${dimensionKey.toLowerCase()}`)}
                  </div>
                  <div className={classes.rowContainer}>
                    <DashboardSelectGroup
                      title={Translation(`managerDashboard.filter.select_group.${dimensionKey.toLowerCase()}`)}
                      initialValue={dimensionFilterState[dimensionKey].downline}
                      groupList={dimensionFilterState[dimensionKey].downlineList}
                      codeKey="groupCode"
                      displayNameKey="groupName"
                      onConfirm={(value) => {
                        handleConfirmDownline(dimensionKey, value);
                        takeUIClickEvent({
                          ...overviewGAData,
                          object_name: 'Confirm',
                          object_type: 'BUTTON',
                        });
                      }}
                      onOpen={() => {
                        takeUIClickEvent({
                          ...overviewGAData,
                          object_name: 'Filter',
                          object_type: 'BUTTON',
                        });
                      }}
                    />
                    <DashboardFilter
                      style={{ marginLeft: 10 }}
                      displayFormat={FilterDisplayFormatEnum.SELECT_GROUP_OPTION}
                      filterDef={[
                        {
                          keyIndex: 'dateFilter',
                          type: FilterTypeEnum.OPTION_TO_DATE,
                          displayName: Translation('managerDashboard.filter.dateFilter'),
                          dateFromKey: 'dateFrom',
                          dateToKey: 'dateTo',
                          options: map(OptionToDateFilterEnum, (value: OptionToDateFilterEnum) => ({
                            displayName: Translation(`managerDashboard.filter.dateFilter.${value}`),
                            value,
                          })),
                        },
                      ]}
                      initialFilterState={dimensionFilterState[dimensionKey]}
                      onChangeFilter={(newFilterState) => handleChangeFilter(dimensionKey, newFilterState)}
                    />
                  </div>
                </div>
                <div className={classes.mainContainer}>
                  {dimension.sections.map((section) => renderData(dimensionKey, section))}
                </div>
              </Fragment>
            );
          })}
        </>
      )}
    </>
  );
};

export default PruLeadsOverviewPage;
