import { ApiErrorResp, ErrorEnum } from '../types/type';

type ApiResponse = {
  errorMessage: string;
  service: 'AGENT_PORTAL_FRONTEND';
  status: 'Error' | 'Success';
};

const errMap = new Map<ErrorEnum, string>([
  [ErrorEnum.netWorkError, 'Network Failed'],
  [ErrorEnum.timeoutError, 'Time out'],
  [ErrorEnum.notFoundError, 'Not Found'],
  [ErrorEnum.unknownError, 'Unknown Error'],
]);

export const ErrorHandler = (err: any): ApiErrorResp => {
  let error = {
    statusCode: 503,
    message: errMap.get(ErrorEnum.netWorkError),
    code: ErrorEnum.netWorkError,
  } as ApiErrorResp;
  if (err.message) {
    if (err.message.includes('timeout')) {
      error = {
        statusCode: 504,
        message: errMap.get(ErrorEnum.timeoutError),
        code: ErrorEnum.timeoutError,
      } as ApiErrorResp;
    }
  } else {
    const apiError = err as ApiResponse;
    const matchErrInfo = apiError.errorMessage.match(/\{(.*)\}/g);
    error = {
      statusCode: 500,
      message: matchErrInfo ? JSON.parse(matchErrInfo[0]).message : errMap.get(ErrorEnum.unknownError),
      code:
        matchErrInfo && JSON.parse(matchErrInfo[0]).message.includes('not found')
          ? ErrorEnum.notFoundError
          : ErrorEnum.unknownError,
    } as ApiErrorResp;
  }
  return error;
};
