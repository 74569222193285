import { AxiosRequestConfig } from 'axios';
import { apiClient, AGENT_URL } from 'src/app/common/network';
import { ProtectionReviewActivityEnum } from '../types/type';
import { ErrorHandler } from './ErrorHandel';

const PROTECTION_REVIEW_URL = `${AGENT_URL}/agents/me/insurance-summaries`;
const URL = 'http://172.21.67.76:3501/protection-reviews/me';
const azureURL = 'http://52.230.36.100:3501/protection-reviews/me';
// https://phkl-apim-nprd.prudential.com.hk/pruforce/agent/coedev/agents/me/insurance-summaries

export const getSurvetItem = async (isPreview: boolean, resultId?: string): Promise<any> => {
  if (isPreview) {
    return apiClient
      .get<any>(`${PROTECTION_REVIEW_URL}/default/preview`)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        throw ErrorHandler(err);
      });
  } else {
    return apiClient
      .get<any>(`${PROTECTION_REVIEW_URL}/results/${resultId}`)
      .then((response) => response.data)
      .catch((err) => {
        throw ErrorHandler(err);
      });
  }
};

export const submitSurveyData = async (resultId: string, body: any, actionType: ProtectionReviewActivityEnum) => {
  return apiClient
    .post<any>(`${PROTECTION_REVIEW_URL}/results/${resultId}`, {
      resultId: resultId,
      body: body,
      actionType: actionType,
    })
    .then((response) => response.data)
    .catch((err) => {
      throw ErrorHandler(err);
    });
};

export const syncNewDataApi = async (resultId: string, language?: string) => {
  const config: AxiosRequestConfig = {
    headers: {
      lang: language,
    },
  };
  return apiClient
    .get<any>(`${PROTECTION_REVIEW_URL}/results/${resultId}/sync-data`, config)
    .then((response) => response.data)
    .catch((err) => {
      throw ErrorHandler(err);
    });
};
