import * as React from 'react';
import { useIntl } from 'react-intl';
import { reverseNum } from '../../../constants';
import { barChartType, tabBarType } from '../../../types/chart-types';

type BarChartProps = {
  type: tabBarType | undefined;
  data: barChartType;
};

export const BarChart: React.FC<BarChartProps> = ({ type, data }) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  let leftWidth = Math.floor(((data.series.data.prudential ?? 0) / data.series.data.total) * 100);
  let rightWidth = Math.ceil(((data.series.data.others ?? 0) / data.series.data.total) * 100);

  if (leftWidth <= 1 && data.series.data.prudential) {
    leftWidth = 2;
  }
  if (rightWidth <= 1 && data.series.data.others) {
    leftWidth = 98;
  }

  rightWidth = 100 - leftWidth;

  return (
    <div
      className="barchart-progress-view"
      style={{ display: 'flex', flexDirection: 'column', paddingTop: 10, fontSize: 11, fontWeight: 500 }}
    >
      <div
        className="whole-part"
        style={{ height: 10, backgroundColor: data.series.backgroundColor, borderRadius: 10 }}
      >
        <div
          className="left-part"
          style={{ backgroundColor: data.series.itemColor, width: leftWidth + '%', height: '100%', borderRadius: 10 }}
        />
      </div>
      <div className="line-part" style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
        {data.series.data.prudential !== 0 && (
          <div className="left-part" style={{ width: leftWidth + '%', height: 12 }}>
            <div
              className="left-line"
              style={{ backgroundColor: data.splitLineColor[0], width: 1, height: '100%', margin: 'auto' }}
            />
          </div>
        )}
        {data.series.data.others !== 0 && (
          <div className="right-part" style={{ width: rightWidth + '%', height: 12 }}>
            <div
              className="right-line"
              style={{ backgroundColor: data.splitLineColor[1], width: 1, height: '100%', margin: 'auto' }}
            />
          </div>
        )}
      </div>
      <div
        className="text-part"
        style={{ display: 'flex', flexDirection: 'row', width: '100%', paddingBottom: 8, paddingTop: 3 }}
      >
        {data.series.data.total === 0 && <div style={{ height: 20 }}></div>}
        {data.series.data.prudential !== 0 && (
          <div className="left-text" style={leftWidth < 10 ? { height: 8 } : { width: leftWidth + '%', height: 8 }}>
            <div className="text" style={{ textAlign: 'center', fontSize: 11, minWidth: 'max-content' }}>
              {type ? '' : '$'}
              {reverseNum(data.series.data.prudential)} {Translation('summary.dashboard.bar.chart.type.prudential')}
            </div>
          </div>
        )}
        {data.series.data.others !== 0 && (
          <div
            className="right-text"
            style={leftWidth < 10 ? { width: rightWidth + '%', height: 8 } : { minWidth: rightWidth + '%', height: 8 }}
          >
            <div className="text" style={{ textAlign: 'center', fontSize: 11, minWidth: 'max-content' }}>
              {type ? '' : '$'}
              {reverseNum(data.series.data.others)} {Translation('summary.dashboard.bar.chart.type.others')}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
