import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  container: {
    marginBottom: 20,
    borderRadius: 5,
    backgroundColor: theme.palette.common.white,
  },
  accordContainer: {
    backgroundColor: theme.palette.common.white,
  },
  footerContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
  },
  divideMargin: {
    // marginBottom: 10
  },
  subHeader: {
    fontSize: '1.1rem',
    fontWeight: 'bold',
  },
  accordHeading: {
    fontWeight: 'bold',
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '15%',
    flexShrink: 0,
  },
  accordSecondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '0px 20px 8px 22px',
    paddingTop: 20,
  },
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  addNewMaterialSetBtn: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '10px',
  },
  modal: {
    display: 'flex',
    padding: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalButtons: {
    marginTop: '15px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  customMentionInput: {
    '& textarea': {
      borderRadius: '4px',
      padding: '5px 5px',
    },
    '& ul': {
      border: '1px solid #dedede',
    },
  },
  focusedMention: {
    backgroundColor: '#dedede',
  },
  btnBack: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
    color: '#FFFFFF',
    backgroundColor: '#BABABA',
  },
  alignCenter: {
    alignItems: 'center',
  },
  button: {
    margin: '10px',
    width: '100px',
    height: '30px',
    marginRight: 0,
    color: '#ffffff',
    fontWeight: 'bold',
    backgroungColor: '#BABABA',
  },
  highlightBtn: {
    backgroundColor: '#4386E5',
  },
  bg_gray: {
    backgroundColor: '#BABABA',
  },
  btnDownload: {
    color: 'blue',
    fontSize: 18,
  },
  sectionTitleWrap: { height: '32px', lineHeight: '32px' },
  sectionDivider: {
    width: '100%',
    height: 1,
  },
  sectionWrap: {
    padding: 20,
    paddingLeft: 30,
  },
  mt_0: {
    marginTop: 0,
  },
  mx_8: {
    marginLeft: 8,
    merginRight: 8,
  },
  downloadTxt: {
    color: 'blue',
    fontSize: 14,
  },
  mh_400: {
    maxHeight: 400,
  },
  imgBg: {
    width: 44,
    height: 44,
  },
  mr_10: {
    marginRight: 10,
  },
  cursorPointer: {
    cursor: 'pointer',
  },
  mt_10: {
    marginTop: 10,
  },
  mb_10: {
    marginBottom: 10,
  },
  w_50: {
    width: '50%',
  },
  mt_4: {
    marginTop: '1rem',
  },
  mt_3: {
    marginTop: '0.7rem',
  },
  mr_4: {
    marginRight: '1rem',
  },
  d_flex_center: {
    display: 'flex',
    alignItems: 'center',
  },
  d_flex_column: {
    display: 'flex',
    flexDirection: 'column',
  },
  flex_fill: {
    flex: '1 1 auto',
  },
}));
