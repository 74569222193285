// migrate from react-mobile-picker@0.2.1
import { FC, useRef, useState, useEffect, CSSProperties } from 'react';
import './style.css';

type PickerColumnState = {
  isMoving: boolean;
  startTouchY: number;
  startScrollerTranslate: number;
  scrollerTranslate: number;
  minTranslate: number;
  maxTranslate: number;
};

type PickerColumnProps = {
  value: string;
  options: string[];
  itemHeight: number;
  columnHeight: number;
  // wheel: 'off' | 'natural' | 'normal';
  onChange: (value: string) => void;
  onClick: (value: string) => void;
};

type PruPickerProps = {
  value: string;
  options: string[];
  itemHeight?: number;
  height?: number;
  pickerStyle?: CSSProperties;
  highlightStyle?: CSSProperties;
  // wheel?: 'off' | 'natural' | 'normal';
  onChange: (value: string) => void;
  onClick?: (value: string) => void;
};

const PickerColumn: FC<PickerColumnProps> = ({
  value,
  options,
  itemHeight,
  columnHeight,
  // wheel,
  onChange,
  onClick,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [state, setState] = useState<PickerColumnState>(() => ({
    isMoving: false,
    startTouchY: 0,
    startScrollerTranslate: 0,
    scrollerTranslate: 0,
    minTranslate: 0,
    maxTranslate: 0,
  }));

  const onValueSelected = (newValue: string) => {
    onChange(newValue);
  };

  const computeTranslate = () => {
    let selectedIndex = options.indexOf(value);
    if (selectedIndex < 0) {
      // throw new ReferenceError();
      // console.warn('Warning: picker doesn\'t contain an option of "' + value + '".');
      onValueSelected(options[0]);
      selectedIndex = 0;
    }
    return {
      scrollerTranslate: columnHeight / 2 - itemHeight / 2 - selectedIndex * itemHeight,
      minTranslate: columnHeight / 2 - itemHeight * options.length + itemHeight / 2,
      maxTranslate: columnHeight / 2 - itemHeight / 2,
    };
  };

  const onScrollerTranslateSettled = (scrollerTranslate: number, minTranslate: number, maxTranslate: number) => {
    let activeIndex = 0;
    if (scrollerTranslate >= maxTranslate) {
      activeIndex = 0;
    } else if (scrollerTranslate <= minTranslate) {
      activeIndex = options.length - 1;
    } else {
      activeIndex = -Math.round((scrollerTranslate - maxTranslate) / itemHeight);
    }
    onValueSelected(options[activeIndex]);
  };

  // const handleWheel = (event: WheelEvent) => {
  //   const { scrollerTranslate } = state;

  //   var delta = event.deltaY * 0.1;
  //   if (Math.abs(delta) < itemHeight) {
  //     delta = itemHeight * Math.sign(delta);
  //   }

  //   switch (wheel) {
  //     case 'natural':
  //       // ignore and continue
  //       break;
  //     case 'normal':
  //       delta = delta * -1;
  //       break;
  //     default:
  //       return;
  //   }

  //   onScrollerTranslateSettled(scrollerTranslate + delta);
  // };

  const handleTouchStart = (event: TouchEvent) => {
    const startTouchY = event.targetTouches[0].pageY;
    setState((prevState: PickerColumnState) => ({
      ...prevState,
      startTouchY,
      startScrollerTranslate: prevState.scrollerTranslate,
    }));
  };

  const handleTouchMove = (event: TouchEvent) => {
    event.preventDefault();
    const touchY = event.targetTouches[0].pageY;
    setState((prevState: PickerColumnState) => {
      const { isMoving, startTouchY, startScrollerTranslate, minTranslate, maxTranslate } = prevState;
      if (!isMoving) {
        return {
          ...prevState,
          isMoving: true,
        };
      }

      let nextScrollerTranslate = startScrollerTranslate + touchY - startTouchY;
      if (nextScrollerTranslate < minTranslate) {
        nextScrollerTranslate = minTranslate - Math.pow(minTranslate - nextScrollerTranslate, 0.8);
      } else if (nextScrollerTranslate > maxTranslate) {
        nextScrollerTranslate = maxTranslate + Math.pow(nextScrollerTranslate - maxTranslate, 0.8);
      }
      return {
        ...prevState,
        scrollerTranslate: nextScrollerTranslate,
      };
    });
  };

  const handleTouchEnd = (event: TouchEvent) => {
    setState((prevState: PickerColumnState) => {
      const { scrollerTranslate, minTranslate, maxTranslate } = prevState;
      setTimeout(() => {
        onScrollerTranslateSettled(scrollerTranslate, minTranslate, maxTranslate);
      }, 0);
      return {
        ...prevState,
        isMoving: false,
        startTouchY: 0,
        startScrollerTranslate: 0,
      };
    });
  };

  const handleTouchCancel = (event: TouchEvent) => {
    setState((prevState: PickerColumnState) => ({
      ...prevState,
      isMoving: false,
      startTouchY: 0,
      startScrollerTranslate: 0,
      scrollerTranslate: prevState.startScrollerTranslate,
    }));
  };

  const handleTranslate = () => {
    setState((prevState: PickerColumnState) => ({ ...prevState, ...computeTranslate() }));
  };

  // const handleItemClick = (option: string) => {
  //   if (option !== value) {
  //     onValueSelected(option);
  //   } else {
  //     onClick(value);
  //   }
  // };

  useEffect(() => {
    handleTranslate();
    if (ref.current) {
      // ref.current.addEventListener('wheel', handleWheel);
      ref.current.addEventListener('touchstart', handleTouchStart);
      ref.current.addEventListener('touchmove', handleTouchMove);
      ref.current.addEventListener('touchend', handleTouchEnd);
      ref.current.addEventListener('touchcancel', handleTouchCancel);
    }
    return () => {
      if (ref.current) {
        // ref.current.removeEventListener('wheel', handleWheel);
        ref.current.removeEventListener('touchstart', handleTouchStart);
        ref.current.removeEventListener('touchmove', handleTouchMove);
        ref.current.removeEventListener('touchend', handleTouchEnd);
        ref.current.removeEventListener('touchcancel', handleTouchCancel);
      }
    };
  }, [options]);

  useEffect(() => {
    if (!state.isMoving) {
      handleTranslate();
    }
  }, [state.isMoving, options, value, itemHeight, columnHeight]);

  const renderItems = () => {
    return options.map((option, index) => {
      const style = {
        height: itemHeight + 'px',
        lineHeight: itemHeight + 'px',
      };
      const className = `picker-item${option === value ? ' picker-item-selected' : ''}`;
      return (
        <div
          key={index}
          className={className}
          style={style}
          // onClick={() => handleItemClick(option)}
        >
          {option}
        </div>
      );
    });
  };

  return (
    <div className="picker-column" ref={ref}>
      <div
        className="picker-scroller"
        style={{
          transform: `translate3d(0, ${state.scrollerTranslate}px, 0)`,
          transitionDuration: state.isMoving ? '0ms' : undefined,
        }}
      >
        {renderItems()}
      </div>
    </div>
  );
};

export const PruPicker: FC<PruPickerProps> = ({
  value,
  options,
  itemHeight = 56,
  height = 336,
  pickerStyle,
  highlightStyle,
  // wheel = 'off',
  onChange,
  onClick = () => {},
}) => {
  return (
    <div className="picker-container" style={{ height, ...pickerStyle }}>
      <div className="picker-inner">
        <PickerColumn
          value={value}
          options={options}
          itemHeight={itemHeight}
          columnHeight={height}
          // wheel={wheel}
          onChange={onChange}
          onClick={onClick}
        />
        <div
          className="picker-highlight"
          style={{ height: itemHeight, marginTop: -(itemHeight / 2), ...highlightStyle }}
        />
      </div>
    </div>
  );
};

export default PruPicker;
