/* eslint-disable import/first */
import React, { useEffect } from 'react';
import Form from '@common/components/Form';
import { TextField } from '@mui/material';
import { useIntl } from 'react-intl';
import FileUploader from '@common/components/FileUploader';
import AddTags from '../Tag';
import { ResourceType, LinkType } from '../../../../constants';
import { createBlob, getBlob } from '../../../../../../network/resourceCrud';
import { fileUpload } from 'src/app/common/resource/resource-utils';
import { useDispatch } from 'react-redux';
import { makeStyles } from 'tss-react/mui';

import { isSaleModule as isSaleModuleFn } from '../../../../../../utils/common-utils';

const TranslationWithVariable = (key: string, count: number | string) =>
  useIntl().formatMessage({ id: key }, { count });

const useStyles = makeStyles()((theme) => ({
  textArea: {
    lineHeight: 1.5,
  },
  container: {
    '& .pru-form-item': {
      margin: '10px 0',
    },
    '& .MuiFormControl-root': {
      margin: 0,
    },
  },
}));

interface FormListProp {
  disabled: boolean;
  activeLang: string;
  form: any;
  resourceType: string;
  eventInvitation: boolean;
  moduleType: string;
  nameMaxSize?: any;
  descriptMaxSize?: any;
  addressMaxSize?: any;
  getFieldValue?: (name: any) => any;
  eventInvitationRegisterPageType?: string;
}

const SubFormList: React.FC<FormListProp> = (props) => {
  const { classes } = useStyles();
  const {
    activeLang,
    disabled,
    resourceType,
    moduleType,
    nameMaxSize,
    descriptMaxSize,
    getFieldValue,
    form,
    eventInvitation,
    eventInvitationRegisterPageType,
    addressMaxSize,
  } = props;
  const intl = useIntl();
  const dispatch = useDispatch();
  const Translation = (id: string) => intl.formatMessage({ id });
  const isSaleModule = isSaleModuleFn(moduleType);

  // validate rules
  const DEFAULT_REQUIRED_RULES = {
    required: true,
    message: Translation('component.form-required-text'),
  };

  const DEFAULT_NOT_REQUIRED_RULES = [{ required: false }];

  const LINK_VIDATOR = [
    DEFAULT_REQUIRED_RULES,
    {
      validator(_: any, value: string) {
        if (value && (value.startsWith('https://') || value.startsWith('http://'))) {
          return Promise.resolve();
        }
        return Promise.reject(new Error(Translation('component.form-link-text')));
      },
    },
  ];

  const CONTENT_VALIDATE = {
    validator(_: any, value: string) {
      const canBeShare = getFieldValue && getFieldValue([activeLang, 'canShared']);

      if (canBeShare === 'Y' && value && value.includes('<video preload="metadata"')) {
        return Promise.reject(new Error(Translation('component.conent.can-not-be-shared')));
      }

      return Promise.resolve();
    },
  };

  const TEXT_VALIDATE = {
    validator(_: any, value: string) {
      // const regex = /<\/?!?(source|video|img|(-- pagebreak --))[^>]*>/gi;
      const regex = new RegExp(/<\/?!?(source|video|img|(-- pagebreak --))[^>]*>/, 'gi');
      if (value && regex.test(value)) {
        return Promise.reject(new Error(Translation('component.text.file-can-not-be-shared-as-text')));
      }

      return Promise.resolve();
    },
  };
  const getInputMaxRules = (count: number) => {
    const errMessage = TranslationWithVariable('resource.form-max-characters-input-text', count);

    return {
      validator: async (_: any, inputString: string) => {
        // const re = /[\u4E00-\u9FA5]/g;
        const re = new RegExp(/[\u4E00-\u9FA5]/, 'g');
        let stringLength = inputString.length;
        const chineseCount = (inputString.match(re) || '').length;
        stringLength = stringLength + chineseCount;

        if (stringLength <= count) {
          return Promise.resolve();
        } else {
          throw new Error(errMessage);
        }
      },
    };
  };

  const handleUploadFile = async ({
    file,
    fileName,
    fileType,
    description = '',
  }: {
    file: any;
    fileName: string;
    fileType: string;
    description?: string;
  }) => {
    try {
      const createBlobRes = await createBlob(
        {
          mimeType: fileType,
          accessLevel: 'anonymous',
          module: `material_${moduleType.toLowerCase()}`,
        },
        dispatch,
      );
      await fileUpload(createBlobRes.url, file, () => {});
      const blobDetail = await getBlob({ resourceIds: createBlobRes.blobId }, dispatch);
      const result = blobDetail[0];
      if (result) {
        return {
          id: result.blobId,
          fileName: fileName,
          url: result.url,
          type: fileType,
        };
      }
    } catch (err) {
    } finally {
    }
  };

  const onChangeLinkType = (val: any) => {
    if (val.target.value === LinkType.UrlWithVideo) {
      form.setFieldsValue({
        [activeLang]: {
          link: '',
          title: '',
          information: '',
          body: '',
          videos: [
            {
              title: '',
              link: '',
              videoId: '',
              thumbnail: null,
            },
          ],
        },
      });
    } else {
      form.setFieldsValue({
        [activeLang]: {
          link: '',
          title: '',
          information: '',
          body: '',
          videos: null,
        },
      });
    }
  };

  const fileTypes = ['pdf', 'jpg', 'png', 'jpeg', 'mp4'];
  const imageTypes = ['jpg', 'jpeg', 'png'];
  const fileSize = 20;
  const placeEnter = Translation('app.input.placeholder.please-enter');
  const fileLabel = Translation('component.formSelectItem.file');
  const showAllowText = useIntl().formatMessage(
    { id: 'component.formSelectItem.fielType' },
    {
      formats: (resourceType === ResourceType.file ? fileTypes : imageTypes).join('/').toUpperCase(),
      size: `${fileSize}M`,
    },
  );

  useEffect(() => {
    form.setFieldsValue({
      [activeLang]: {
        linkType: LinkType.Url,
        link: '',
        title: '',
        information: '',
        body: '',
        videos: null,
      },
    });
  }, [eventInvitation]);

  return (
    <div className={classes.container}>
      <Form.Item
        childStyle={{ marginTop: 16, height: 32 }}
        name={[activeLang, 'name']}
        label={Translation('component.formLabel.name')}
        rules={[DEFAULT_REQUIRED_RULES, getInputMaxRules(nameMaxSize)]}
      >
        <TextField disabled={disabled} margin="dense" variant="outlined" fullWidth placeholder={placeEnter} />
      </Form.Item>
      {/* <Form.Item
        childStyle={{ marginTop: 16, height: 32 }}
        name={[activeLang, 'coverpage']}
        label={Translation('component.formLabel.coverpage')}
        rules={[DEFAULT_REQUIRED_RULES]}
      >
        <FileUploader
          disabled={true}
          upload={handleUploadFile}
          color="inherit"
          showAllowText={Translation('resouce.common.coverpage-description')}
          maxFileSize={20}
          allowedFileTypes={['jpg', 'png', 'jpeg']}
        />
      </Form.Item> */}
      <Form.Item
        childStyle={{ marginTop: 16 }}
        name={[activeLang, 'introduction']}
        label={Translation('component.formLabel.coverpage-introduction')}
        rules={[DEFAULT_REQUIRED_RULES, getInputMaxRules(descriptMaxSize)]}
      >
        <TextField
          disabled={disabled}
          margin="dense"
          variant="outlined"
          fullWidth
          multiline
          placeholder={placeEnter}
          InputProps={{
            classes: {
              input: classes.textArea,
            },
          }}
        />
      </Form.Item>
      <Form.Item
        childStyle={{ marginTop: 16, height: 32 }}
        required={false}
        name={[activeLang, 'tag']}
        label={Translation('component.formLabel.tags')}
        rules={DEFAULT_NOT_REQUIRED_RULES}
      >
        <AddTags disabled={disabled} btnTxt={Translation('component.tag-list.add-btn')} />
      </Form.Item>

      <Form.Item
        rules={[DEFAULT_REQUIRED_RULES, getInputMaxRules(addressMaxSize)]}
        childStyle={{ marginTop: 16, height: 32 }}
        name={[activeLang, 'eventLocation']}
        label={Translation('component.formLabel.location')}
      >
        <TextField
          disabled={disabled}
          margin="dense"
          variant="outlined"
          fullWidth
          placeholder={placeEnter}
          inputProps={{ maxLength: 100 }}
        />
      </Form.Item>

      <Form.Item
        rules={[DEFAULT_REQUIRED_RULES, getInputMaxRules(addressMaxSize)]}
        childStyle={{ marginTop: 16, height: 32 }}
        name={[activeLang, 'eventAddress']}
        label={Translation('component.formLabel.address')}
      >
        <TextField disabled={disabled} margin="dense" variant="outlined" fullWidth placeholder={placeEnter} />
      </Form.Item>
    </div>
  );
};

export default SubFormList;
