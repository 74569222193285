import React, { useState, useEffect, useRef, HTMLAttributes } from 'react';
import { ApiErrorResponse } from '../network/apiErrorHandler';
import { useDispatch } from 'react-redux';
import { appendAlertItem, AlertType } from '../../../redux/common/commonSlice';
import { CSVLink } from 'react-csv';

type AsyncCsvLinkProps<T> = {
  isDisabled: boolean;
  filename: string;
  dataParser: (data: T) => string[][] | string;
  asyncCall: () => Promise<T>;
} & HTMLAttributes<HTMLDivElement>;

function AsyncCsvLink<T = any>({
  isDisabled,
  filename,
  dataParser,
  asyncCall,
  children,
  ...rest
}: AsyncCsvLinkProps<T>) {
  const dispatch = useDispatch();
  const [csvData, setCsvData] = useState<T | undefined>();
  const csvInstance = useRef<any>(null);

  const onClickDownload = async () => {
    if (isDisabled) return;
    try {
      const result = await asyncCall();
      setCsvData(result);
    } catch (err) {
      const error = err as ApiErrorResponse;
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.ERROR,
            content: error.message,
          },
        ])
      );
    }
  };

  useEffect(() => {
    if (
      csvData &&
      csvInstance &&
      csvInstance.current &&
      csvInstance.current.link
    ) {
      setTimeout(() => {
        csvInstance.current.link.click();
        setCsvData(undefined);
      });
    }
  }, [csvData]);

  return (
    <>
      <div {...rest} onClick={onClickDownload}>
        {children}
      </div>
      {csvData && (
        <CSVLink
          ref={csvInstance}
          data={dataParser(csvData)}
          filename={filename}
          target="_blank"
        />
      )}
    </>
  );
}

export default AsyncCsvLink;
