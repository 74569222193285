export type GAEventName = 'ui_click' | 'ui_screen_visit' | 'login';

export type GAEventType = 'Click' | 'Screen' | 'Backend';

export type GAModule =
  | 'Auth'
  | 'Error'
  | 'Application'
  | 'Home'
  | 'PRULeads'
  | 'AgentProfile'
  | 'InsuranceSummaries'
  | 'Summary'
  | 'Survey'
  | 'ManagerDashboard';

export type GAFeature =
  | 'NA'
  | 'AgentProfileSite'
  | 'Error'
  | 'Login'
  | 'ACM'
  | 'ManagerDashboardPRULeads'
  | 'Dashboard'
  | 'Download'
  | 'InsuranceSummary'
  | 'AgentSurvey'
  | 'CustomerSurvey';

export type GAJourney =
  | 'NA'
  | 'agent_login'
  | 'create_agent_profile'
  | 'edit_agent_profile'
  | 'overview'
  | 'prospect_category'
  | 'prospect_source'
  | 'add_new_agency_campaign'
  | 'view_all_campaigns'
  | 'view_campaign_detail'
  | 'view_dashboard'
  | 'download_report'
  | 'download_application'
  | 'isr_form'
  | 'isr_dashboard'
  | 'fill_agt_survey'
  | 'preview_cus_survey'
  | 'cus_survey_result';

export type GAStage =
  | 'NA'
  | 'start'
  | 'sso_login'
  | 'password_login'
  | 'login_success'
  | 'login_fail'
  | 'view_agent_profile'
  | 'create_agent_profile'
  | 'edit_contact_info'
  | 'edit_self_introduction'
  | 'edit_awards'
  | 'add_awards'
  | 'view_full_awards'
  | 'add_promotions'
  | 'edit_promotions'
  | 'switch_language'
  | 'edit_contact_info'
  | 'edit_contact_info'
  | 'save_success'
  | 'create_success'
  | 'return_to_home'
  | 'view_overview'
  | 'view_prospect_category'
  | 'view_prospect_source'
  | 'create_campaign'
  | 'submit'
  | 'import_participant'
  | 'select_participant'
  | 'select_campaign_type'
  | 'download'
  | 'result'
  | 'preview'
  | 'fill_agt_survey'
  | 'view';

export type GAScreenName =
  | 'CallbackPage'
  | 'CreateAgentProfilePageScreen'
  | 'EditAgentProfilePageScreen'
  | 'GetInTouchWithMePageScreen'
  | 'SubmitSuccessPageScreen'
  | 'CreateSuccessPageScreen'
  | 'AboutMePageScreen'
  | 'AwardMorePageScreen'
  | 'AwardPageScreen'
  | 'PromotionPageScreen'
  | 'ErrorNotFoundPage'
  | 'ErrorForbiddenPage'
  | 'ErrorV2Page'
  | 'ErrorV4Page'
  | 'ErrorV5Page'
  | 'ErrorV6Page'
  | 'LoginPage'
  | 'CreateCampaignPage'
  | 'CampaignDetailPage'
  | 'CampaignListPage'
  | 'PRULeadsOverviewPage'
  | 'PRULeadsDetailPageProspectCategory'
  | 'PRULeadsDetailPageProspectSource'
  | 'DashboardPage'
  | 'DownloadApplicationPage'
  | 'InsuranceSummaryPreviewPage'
  | 'InsuranceSummaryFormPage'
  | 'SummaryPage'
  | 'AgentSurveyPage'
  | 'CustomerSurveyPreviewPage'
  | 'CustomerSurveyResultPage'
  | 'SingleSignOnPage';

export const objectTypeShortFormMap = {
  BUTTON: 'BTN',
};

export type GAObjectType = keyof typeof objectTypeShortFormMap;

export type GAEventSource = 'PRUForce-AgentPortal-IN-web' | 'PRUForce-AgentPortal-IN-webapp';
