import { FC, useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';
import { Tabs, Tab } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { NOT_FOUND_ERROR_PAGE_PATH, isPhone } from 'src/app/common/constants';
import { LayoutSplashScreen } from 'src/app/layout';
import { useLang } from 'src/app/i18n';
import { AGENT_PROFILE_PATH } from 'src/app/modules/AgentProfile/constants';
import {
  AgentProfileItem,
  AwardItem,
  LocationState,
  FromPageEnum,
} from 'src/app/modules/AgentProfile/types/agent-profile-types';
import { calculateItemWidth } from 'src/app/common/utils/common-utils';
import { itemListToIdList } from 'src/app/modules/AgentProfile/common/utils/list-utils';
import { localeMapToBackend } from 'src/app/modules/AgentProfile/common/utils/locale-utils';
import ItemCard from 'src/app/modules/AgentProfile/common/components/ItemCard';
import Footer, { FOOTER_CONTAINER_HEIGHT } from 'src/app/modules/AgentProfile/common/components/Footer';
import SortDialog, { SortState } from 'src/app/modules/AgentProfile/common/components/SortDialog';
import { getAgentAwards, updateAgentProfile, UpdateProfileBody } from '../../../network/agentProfileCrud';
import { takeUIClickEvent } from '../../../../../common/ga/ga';
import { useGAScreenViewEvent } from '../../../../../common/ga/hook/useGAScreenViewEvent';

// type AwardListParam = {
//   name: string;
// };

enum EditAwardTabEnum {
  BADGES = 'badges',
  AWARDS = 'awards',
}

const SCREEN_HEIGHT = 100;
const CONTAINER_PADDING = 16;

// const HEADER_CONTAINER_HEIGHT = 120;
const HEADER_CONTAINER_HEIGHT = 75;
const HEADER_CONTAINER_BOTTOM = 8;

const SECTION_CONTAINER_HEIGHT = `calc(
  100% -
  ${HEADER_CONTAINER_HEIGHT}px -
  ${HEADER_CONTAINER_BOTTOM}px -
  ${FOOTER_CONTAINER_HEIGHT}px
)`;

const BADGE_NUM_PER_ROW = isPhone ? 2 : 5;
const BADGE_COLUMN_GAP = 22;
const BADGE_PADDING_BOTTOM = 16;

const AWARD_NUM_PER_ROW = isPhone ? 1 : 2;
const AWARD_COLUMN_GAP = isPhone ? 0 : 18;

const MAX_SELECTED_AWARD = 3;
const MAX_SELECTED_BADGE = 5;

const useStyles = makeStyles()((theme) => ({
  container: {
    height: `${SCREEN_HEIGHT}vh`,
  },
  headerContainer: {
    backgroundColor: theme.palette.common.white,
    height: HEADER_CONTAINER_HEIGHT,
    marginBottom: HEADER_CONTAINER_BOTTOM,
    padding: `0 ${CONTAINER_PADDING}px`,
  },
  sectionContainer: {
    backgroundColor: theme.palette.common.white,
    maxHeight: SECTION_CONTAINER_HEIGHT,
    padding: CONTAINER_PADDING,
    paddingBottom: 0,
    overflow: 'scroll',
  },
  badgeListContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    columnGap: BADGE_COLUMN_GAP,
    rowGap: 20,
    paddingBottom: BADGE_PADDING_BOTTOM,
  },
  badgeContainer: {
    width: calculateItemWidth(BADGE_COLUMN_GAP, BADGE_NUM_PER_ROW),
  },
  badgeName: {
    fontSize: 12,
    textAlign: 'center',
  },
  awardListContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    columnGap: AWARD_COLUMN_GAP,
  },
  awardContainer: {
    width: calculateItemWidth(AWARD_COLUMN_GAP, AWARD_NUM_PER_ROW),
  },
  tabs: {
    marginBottom: 12,
    '& .MuiTab-root.Mui-selected': {
      color: 'black',
    },
  },
  tabFont: {
    fontSize: 16,
    fontWeight: 600,
    fontFamily: 'Arial',
  },
  caption: {
    color: '#999999',
    fontSize: 16,
    fontWeight: 600,
    marginBottom: 16,
  },
}));

const initialSortState: SortState = {
  sortBy: undefined,
  sortOrder: undefined,
};

const AgentProfileEditAwardPage: FC<RouteComponentProps> = ({ history, location }) => {
  const { classes } = useStyles();
  const intl = useIntl();
  const Translation = (id: string, payload?: any) => intl.formatMessage({ id }, payload);
  const lang = useLang();
  const mappedLocale = localeMapToBackend[lang] || lang;
  const dispatch = useDispatch();

  const receivedLocationState = location.state as LocationState;
  useGAScreenViewEvent(
    {
      module: 'AgentProfile',
      feature: 'AgentProfileSite',
      journey: receivedLocationState?.from === FromPageEnum.CREATE ? 'create_agent_profile' : 'edit_agent_profile',
      stage: 'edit_awards',
      screen_name: 'AwardPageScreen',
    },
    true,
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [agentProfile, setAgentProfile] = useState<AgentProfileItem>();
  const [selectedAwards, setSelectedAwards] = useState<AwardItem[]>([]);
  const [selectedBadges, setSelectedBadges] = useState<AwardItem[]>([]);
  const [awardList, setAwardList] = useState<AwardItem[]>([]);
  const [badgeList, setBadgeList] = useState<AwardItem[]>([]);

  // const [filterState, setFilterState] = useState<AwardListParam>({
  //   name: '',
  // });
  const [sortState, setSortState] = useState<SortState>({ ...initialSortState });

  const [tab, setTab] = useState<EditAwardTabEnum>(EditAwardTabEnum.BADGES);
  const [showSortDialog, setShowSortDialog] = useState<boolean>(false);
  const sectionRef = useRef<HTMLDivElement>(null);

  const reloadData = async () => {
    if (receivedLocationState?.initAgentProfile) {
      setIsLoading(true);
      setAgentProfile(receivedLocationState.initAgentProfile);
      setSelectedAwards(receivedLocationState.initAgentProfile.awards);
      setSelectedBadges(receivedLocationState.initAgentProfile.awardBadges);

      try {
        const awardListRes = await getAgentAwards(mappedLocale, dispatch);
        setAwardList(awardListRes.awards);
        setBadgeList(awardListRes.badges);
      } catch {}

      setIsLoading(false);
    } else {
      history.push(NOT_FOUND_ERROR_PAGE_PATH);
    }
  };

  useEffect(() => {
    if ((window as any).ReactNativeWebView) {
      (window as any).ReactNativeWebView.postMessage(
        `{ "action": "loadheaderprops", "payload": { "title": "myAwardsAndAccomplishment", "backTarget": "web" } }`,
      );
    }
  }, []);

  useEffect(() => {
    reloadData();
    // eslint-disable-next-line
  }, []);

  const renderTabTitle = (tab: EditAwardTabEnum) => (
    <span className={classes.tabFont}>{Translation(`agentProfile.ap_awards_${tab}_tab`)}</span>
  );

  const getYearUnit = (year: number) => {
    if (year > 1) {
      return Translation('agentProfile.ap_years_label');
    } else {
      return Translation('agentProfile.ap_year_label');
    }
  };

  const handleChangeTab = (_: any, newTab: EditAwardTabEnum) => {
    // getAwardList or getBadgeList api
    setTab(newTab);
    if (sectionRef.current) {
      sectionRef.current.scrollTop = 0;
    }
  };

  const handleSelectAward = (item: AwardItem, selectOrder: number) => {
    if (selectOrder !== -1) {
      let newSelectedAwards = [...selectedAwards];
      newSelectedAwards.splice(selectOrder, 1);
      setSelectedAwards(newSelectedAwards);
    } else if (selectedAwards.length < MAX_SELECTED_AWARD) {
      setSelectedAwards([...selectedAwards, item]);
    }
  };

  const handleSelectBadge = (item: AwardItem, selectOrder: number) => {
    if (selectOrder !== -1) {
      let newSelectedBadges = [...selectedBadges];
      newSelectedBadges.splice(selectOrder, 1);
      setSelectedBadges(newSelectedBadges);
    } else if (selectedBadges.length < MAX_SELECTED_BADGE) {
      setSelectedBadges([...selectedBadges, item]);
    }
  };

  const handleSort = (newSortState: SortState) => {
    setSortState(newSortState);
    setShowSortDialog(false);
  };

  const handleSave = async () => {
    const body: UpdateProfileBody = {};
    body.awards = itemListToIdList(selectedAwards, 'typeId');
    body.awardBadges = itemListToIdList(selectedBadges, 'typeId');
    let redirect = '';

    takeUIClickEvent({
      stage: 'edit_awards',
      object_name: 'Save',
      object_type: 'BUTTON',
    });
    if (receivedLocationState.from === FromPageEnum.EDIT) {
      await updateAgentProfile(body, dispatch);
      redirect = `${AGENT_PROFILE_PATH}/submit-success?noHeader`;
      history.push(redirect);
    } else if (agentProfile) {
      const initUpdateBody = receivedLocationState?.updateProfileBody ?? {};
      const passingLocationState: LocationState = {
        ...receivedLocationState,
        initAgentProfile: {
          ...agentProfile,
          awards: selectedAwards,
          awardBadges: selectedBadges,
        },
        updateProfileBody: {
          ...initUpdateBody,
          ...body,
        },
      };
      redirect = `${AGENT_PROFILE_PATH}/create?noHeader`;
      history.push(redirect, passingLocationState);
    }
  };

  return (
    <>
      {isLoading || !agentProfile ? (
        <LayoutSplashScreen />
      ) : (
        <>
          <div className={classes.container}>
            <div className={classes.headerContainer}>
            <Tabs indicatorColor="secondary" className={classes.tabs} value={tab} onChange={handleChangeTab}>
              <Tab label={renderTabTitle(EditAwardTabEnum.BADGES)} value={EditAwardTabEnum.BADGES} />
              <Tab label={renderTabTitle(EditAwardTabEnum.AWARDS)} value={EditAwardTabEnum.AWARDS} />
            </Tabs>
              {/* <SearchBar
                filterState={filterState}
                placeholder={Translation('agentProfile.ap_search_award_name_label')}
                setFilterState={setFilterState}
                setShowSortDialog={setShowSortDialog}
              /> */}
            </div>

            <div ref={sectionRef} className={classes.sectionContainer}>
              {tab === EditAwardTabEnum.BADGES && (
                <>
                  <div className={classes.caption}>
                    ({Translation('agentProfile.ap_choose_num_display_on_top_instruction', { num: MAX_SELECTED_BADGE })}
                    )
                  </div>
                  <div className={classes.badgeListContainer}>
                    {badgeList.map((badge) => {
                      if (badge) {
                        const selectOrder = selectedBadges.findIndex(
                          (selectedBadge) => selectedBadge?.typeId === badge.typeId,
                        );
                        return (
                          <div className={classes.badgeContainer}>
                            <ItemCard
                              disableText
                              item={badge}
                              image={badge.image}
                              name={badge.awardName[mappedLocale] || ''}
                              selected={selectOrder !== -1}
                              selectOrder={selectOrder}
                              handleSelect={handleSelectBadge}
                            />
                            <div className={classes.badgeName}>{badge.awardName[mappedLocale] || ''}</div>
                          </div>
                        );
                      } else {
                        return <></>;
                      }
                    })}
                  </div>
                </>
              )}
              {tab === EditAwardTabEnum.AWARDS && (
                <>
                  <div className={classes.caption}>
                    ({Translation('agentProfile.ap_choose_num_display_on_top_instruction', { num: MAX_SELECTED_AWARD })}
                    )
                  </div>
                  <div className={classes.awardListContainer}>
                    {awardList.map((award) => {
                      if (award) {
                        const selectOrder = selectedAwards.findIndex(
                          (selectedAward) => selectedAward?.typeId === award.typeId,
                        );
                        return (
                          <div className={classes.awardContainer}>
                            <ItemCard
                              disableImage
                              item={award}
                              image={award.image}
                              name={award.awardName[mappedLocale] || ''}
                              caption={
                                award.awardYear && award.awardYear !== ''
                                  ? `${award.awardYear} ${
                                      award.isGroupedYear ? getYearUnit(Number(award.awardYear)) : ''
                                    }`
                                  : undefined
                              }
                              selected={selectOrder !== -1}
                              selectOrder={selectOrder}
                              handleSelect={handleSelectAward}
                            />
                          </div>
                        );
                      } else {
                        return <></>;
                      }
                    })}
                  </div>
                </>
              )}
            </div>
            <Footer
              // disableClick={
              //   tab === EditAwardTabEnum.AWARDS &&
              //   ((awardList.length >= MAX_SELECTED_AWARD && selectedAwards.length !== MAX_SELECTED_AWARD) ||
              //     (awardList.length < MAX_SELECTED_AWARD && selectedAwards.length !== awardList.length))
              // }
              handleClick={handleSave}
            />
          </div>
          <SortDialog
            showDialog={showSortDialog}
            initialSortState={initialSortState}
            sortState={sortState}
            setShowDialog={setShowSortDialog}
            handleSort={handleSort}
          />
        </>
      )}
    </>
  );
};

export default AgentProfileEditAwardPage;
