import React, { FC, useEffect } from 'react';
import { RouteProps, Route, Redirect } from 'react-router-dom';
import { FORBIDDEN_ERROR_PAGE_PATH } from '../constants';
import { GAData, updateGAData } from '../ga/redux/gaSlice';
import { useDispatch } from 'react-redux';
import { takeUIScreenViewEvent } from '../ga/ga';

type GARouteData = GAData;

type PruRouteProps = {
  disabled?: boolean;
  title?: string;
  gaData?: GARouteData;
} & RouteProps;

const PruRoute: FC<PruRouteProps> = ({ disabled, title, gaData, ...rest }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!gaData) return;

    dispatch(updateGAData(gaData));
    //TODO: take screen view event
    takeUIScreenViewEvent(gaData);

    return () => {
      //TODO: reset
      dispatch(updateGAData(null));
    };
  }, [gaData, rest.path, dispatch]);

  useEffect(() => {
    let previousTitle = window.document.title;
    if (title) {
      window.document.title = title;
    }

    return () => {
      window.document.title = previousTitle;
    };
  }, [title]);

  return disabled ? <Redirect to={FORBIDDEN_ERROR_PAGE_PATH} /> : <Route {...rest} />;
};

export default PruRoute;
