import { Theme, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import React from 'react';
import { useProjectSelector } from 'src/redux/store';
import { ASIDE_WIDTH, COLLAPSE_ASIDE_WIDTH, HEADER_HEIGHT, MOBILE_HEADER_HEIGHT } from '../../const';
import HeaderBreadCrumbs from './components/header/header-breadcrumbs';
import HeaderRight from './components/header/header-right';

const ContentHeader = () => {
  const { classes, cx } = useStyles();
  const { isAsideMenuCollapse, showUserInfoHeader } = useProjectSelector((state) => state.layout);

  return (
    <div
      className={cx(classes.wrapper, { [classes.open]: showUserInfoHeader, [classes.close]: !showUserInfoHeader })}
      style={{ left: isAsideMenuCollapse ? COLLAPSE_ASIDE_WIDTH : ASIDE_WIDTH }}
    >
      <HeaderBreadCrumbs />
      <HeaderRight />
    </div>
  );
};

export default ContentHeader;

const useStyles = makeStyles()((theme: Theme) => ({
  wrapper: {
    height: HEADER_HEIGHT,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: '#ffffff',
    marginBottom: theme.spacing(1),
    paddingLeft: 20,
    paddingRight: 20,
    position: 'fixed',
    zIndex: 60,
    top: 0,
    right: 0,
    transition: theme.transitions.create('left', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.down('md')]: {
      top: MOBILE_HEADER_HEIGHT - HEADER_HEIGHT,
      left: '0 !important',
      boxShadow: '0px 0px 40px 0px rgba(82, 63, 105, 0.2)',
    },
  },
  open: {
    [theme.breakpoints.down('md')]: {
      top: MOBILE_HEADER_HEIGHT,
      transition: theme.transitions.create('top', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
  },
  close: {
    [theme.breakpoints.down('md')]: {
      top: MOBILE_HEADER_HEIGHT - HEADER_HEIGHT,
      transition: theme.transitions.create('top', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
  },
}));
