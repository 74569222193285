import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  dialogContent: {
    alignSelf: 'center',
    textAlign: 'center',
    flexDirection: 'column',
    display: 'flex',
    paddingLeft: 20,
    paddingRight: 20,
    justifyContent: 'center',
  },

  circle: {
    backgroundColor: '#E8192C',
    borderRadius: 12,
    width: 22,
    height: 22,
    marginRight: 8,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  loginWrapper: {
    width: '100%',
    maxWidth: 400,
    [theme.breakpoints.down('md')]: {
      maxWidth: 350,
    },
  },
  loginHeader: {
    marginBottom: '5rem',
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      marginBottom: '2.25rem',
    },
  },

  loginTitle: {
    fontSize: 26,
  },

  loginSubtitle: {
    color: '#B5B5C3',
    fontWeight: 500,
  },

  tips: {
    marginBottom: 32,
    backgroundColor: '#EFEFEF',
    display: 'flex',
    alignItems: 'stretch',
    padding: '20px 26px',
    borderRadius: 5.5,
    color: 'lightslategray',
    whiteSpace: 'pre-wrap',
  },

  formItem: {
    marginBottom: 23,
    position: 'relative',
  },

  input: {
    backgroundColor: '#F3F6F9',
    color: '#3F4254',
    padding: '16px 20px',
    width: '100%',
    borderRadius: 5.5,
    outline: 'none',
    '&:focus': {
      borderColor: '#EBEDF3',
      backgroundColor: '#EBEDF3',
    },
  },

  checkIcon: {
    position: 'absolute',
    right: 10,
    top: 18,
    fontSize: 16,
  },

  messageContainer: {
    marginTop: 3,
    color: '#F64E60',
    fontSize: 12,
    fontWeight: 400,
  },

  btnWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    boxSizing: 'border-box',
  },

  btn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#FFFFFF',
    backgroundColor: '#3699FF',
    padding: '13px 30px',
    margin: '13px 0',
    borderRadius: 5.5,
  },

  separator: {
    borderBottom: '1px dashed #EBEDF3',
    margin: '22px 0',
  },

  loading: {
    marginLeft: 8,
    position: 'relative',
    '&::before': {
      content: '""',
      width: 20,
      height: 20,
      marginTop: -10,
      boxSizing: 'border-box',
      position: 'absolute',
      top: '50%',
      left: 0,
      borderRadius: '50%',
      border: '2px solid #ffffff',
      borderRight: '2px solid transparent',
      animation: '$animation-spinner 0.5s linear infinite',
    },
  },

  '@keyframes animation-spinner': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
}));
