import { FC } from 'react';
import { Switch, Redirect, RouteComponentProps } from 'react-router-dom';
import PruRoute from 'src/app/common/components/PruRoute';
import { MANAGER_DASHBOARD_BASE_PATH, MANAGER_DASHBOARD_PRULEADS_PATH } from '../../constants';
import { NOT_FOUND_ERROR_PAGE_PATH } from 'src/app/common/constants';
import { GAData } from 'src/app/common/ga/redux/gaSlice';
import PruLeadsOverviewPage from './Overview/PruLeadsOverviewPage';
import PruLeadsDetailPage from './Detail/PruLeadsDetailPage';

export const pruleadsPath = `${MANAGER_DASHBOARD_BASE_PATH}${MANAGER_DASHBOARD_PRULEADS_PATH}`;

export const overviewGAData: GAData = {
  module: 'ManagerDashboard',
  feature: 'ManagerDashboardPRULeads',
  journey: 'overview',
  stage: 'view_overview',
  screen_name: 'PRULeadsOverviewPage',
};

const PruLeadsRoutes: FC<RouteComponentProps> = (props) => {
  return (
    <Switch>
      <Redirect exact from={pruleadsPath} to={`${pruleadsPath}/overview?noHeader`} />
      <PruRoute exact path={`${pruleadsPath}/overview`} component={PruLeadsOverviewPage} gaData={overviewGAData} />
      {/* PCAAEB-26883: hide PruLeadsDetailPage */}
      {/* <PruRoute exact path={`${pruleadsPath}/detail`} component={PruLeadsDetailPage} /> */}
      <Redirect to={NOT_FOUND_ERROR_PAGE_PATH} />
    </Switch>
  );
};

export default PruLeadsRoutes;
