import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
  rightContentContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    overflow: 'auto',
  },
  rightContent: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  bottomButtonContainer: {
    marginTop: 5,
    backgroundColor: 'white',
    height: 86,
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  bottomWhiteButton: {
    // backgroundColor: "white",
    // color: "#333333",
    width: '200px',
    // height: 46,
    // borderRadius: 12,
    // border: "2px solid #F0F0F0",
    margin: 10,
    fontFamily: 'Poppins, Helvetica, "sans-serif"',
  },
  bottomRedButton: {
    // backgroundColor: "#E8192C",
    // color: "white",
    width: '200px',
    // height: 46,
    borderRadius: 12,
    margin: 10,
    fontFamily: 'Poppins, Helvetica, "sans-serif"',
  },
  whiteButton: {
    backgroundColor: 'white',
    color: '#333333',
    width: '150px',
    height: 46,
    borderRadius: 12,
    border: '2px solid #F0F0F0',
    margin: 10,
    fontFamily: 'Poppins, Helvetica, "sans-serif"',
  },
  redButton: {
    backgroundColor: '#E8192C',
    color: 'white',
    width: '150px',
    height: 46,
    borderRadius: 12,
    margin: 10,
    fontFamily: 'Poppins, Helvetica, "sans-serif"',
  },
  dialogContainer: {
    padding: '20px',
  },
  btnContainer: {
    padding: '0 20px 20px 20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  btnRemoveWrap: {
    margin: 10,
    display: 'flex',
    justifyContent: 'space-between',
  },
  // remove success dialog
  sentSuccess: {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: 400,
    padding: 30,
  },
  sentSuccessText: {
    marginTop: 20,
    marginBottom: 50,
  },
  circle: {
    backgroundColor: '#D0F6DF',
    borderRadius: 40,
    width: 80,
    height: 80,
  },
  flex1: {
    flex: 1,
    width: '100%',
  },
  center: {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));
