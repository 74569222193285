import { TableCell } from '@mui/material';
import { withStyles } from 'tss-react/mui';

const DashboardTableCell = withStyles(TableCell, () => ({
  root: {
    borderBottom: '1px solid #F0F0F0',
  },
}));

export default DashboardTableCell;
