import { makeStyles } from 'tss-react/mui';
import { ValueOf } from 'src/app/common/types/common-types';
import { DashboardFilterDef, FilterTypeEnum } from '../../types/dashboard-filter-types';

type FilterChipProps<T> = {
  filterDef: DashboardFilterDef<T>[];
  filterState: T;
  handleSelectFilter: (filter: DashboardFilterDef<T>, value: ValueOf<T>, isConfirm?: boolean) => void;
};

const useStyles = makeStyles()((theme) => ({
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 14,
  },
  chipContainer: {
    display: 'flex',
    alignItems: 'center',
    columnGap: 16,
    padding: 16,
  },
  chip: {
    padding: '3px 12px',
    border: '1px solid',
    borderRadius: 14,
    cursor: 'pointer',
  },
  defaultChip: {
    color: '#999999',
    borderColor: '#F0F0F0',
  },
  selectedChip: {
    color: '#E8192C',
    backgroundColor: '#FFE4E6',
  },
}));

const FilterChip = <T extends Record<string, unknown>>({
  filterDef,
  filterState,
  handleSelectFilter,
}: FilterChipProps<T>) => {
  const { classes } = useStyles();
  return (
    <div className={classes.rowContainer}>
      {filterDef
        .filter((filter) => filter.type === FilterTypeEnum.CHIP)
        .map((filter) => (
          <div key={`filter-${filter.keyIndex}`} className={classes.chipContainer}>
            {filter.options.map((option, optionIndex) => (
              <div
                key={`filter-${filter.keyIndex}-option-${optionIndex}`}
                className={`
                  ${classes.chip}
                  ${filterState[filter.keyIndex] === option.value ? classes.selectedChip : classes.defaultChip}
                `}
                onClick={() => handleSelectFilter(filter, option.value, true)}
              >
                {option.displayName}
              </div>
            ))}
          </div>
        ))}
    </div>
  );
};

export default FilterChip;
