import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { MentionsInput, Mention, MentionItem } from 'react-mentions';

import { useStyles } from './styles';
import { FormInstance } from 'rc-field-form';
import { Divider } from '@mui/material';

const MentionList = [
  { id: 'name', display: 'Name' },
  { id: 'phone', display: 'Phone' },
];

type INotiMessageSettingProps = {
  initialValue: string;
  form: FormInstance<any>;
  disabled: boolean;
};
const NotificationMessageSetting = ({ initialValue, form, disabled }: INotiMessageSettingProps) => {
  const { classes } = useStyles();
  const intl = useIntl();
  const Translation = (id: string, variable?: Record<string, string>) => intl.formatMessage({ id }, variable);

  const [notiMessage, setNotiMessage] = useState<string>(initialValue);

  const onChangeMention = (
    event: { target: { value: string } },
    newValue: string,
    newPlainTextValue: string,
    mentions: MentionItem[],
  ) => {
    setNotiMessage(newValue);
    form.setFieldsValue({ notificationMessage: newValue });
  };

  return (
    <React.Fragment>
      <div className={`${classes.container} ${classes.sectionWrap}`}>
        <div className={classes.divideMargin} style={{ height: '32px', lineHeight: '32px' }}>
          <span className={classes.subHeader}>{Translation('component.formTitle.notification-message')}</span>
        </div>
        <div>
          <div className="mb-4">{Translation('component.noti-message-description')}</div>
          <MentionsInput
            disabled={disabled}
            onChange={onChangeMention}
            value={notiMessage}
            singleLine={false}
            style={{ minHeight: '100px', border: 'solid 2px #C4C4C4', borderRadius: '4px' }}
            className={classes.customMentionInput}
          >
            <Mention
              trigger="@"
              data={(search: string) =>
                MentionList.filter((el) => el.display.toLowerCase().includes(search.toLowerCase()))
              }
              displayTransform={(id: string, label: string) => `@${label}`}
              markup="@{{__id__}}"
              renderSuggestion={(entry: any, search: any, hightlightedDisplay: any, index: any, focused: any) => (
                <div style={{ padding: '3px 8px' }} className={focused ? classes.focusedMention : ''}>
                  {hightlightedDisplay}
                </div>
              )}
            />
          </MentionsInput>
        </div>
      </div>
      <Divider className={classes.sectionDivider} />
    </React.Fragment>
  );
};

export default React.memo(NotificationMessageSetting);
