import { makeStyles } from 'tss-react/mui';
import { roundPercentages } from 'src/app/common/utils/common-utils';
import { SingleStackedBarProps } from '../../types/dashboard-chart-types';

const BAR_HEIGHT = 8;
const BAR_BORDER_RADIUS = 4;

const useStyles = makeStyles()((theme) => ({
  rowContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  bar: {
    height: BAR_HEIGHT,
  },
  firstBar: {
    borderRadius: `${BAR_BORDER_RADIUS}px 0 0 ${BAR_BORDER_RADIUS}px`,
  },
  lastBar: {
    borderRadius: `0 ${BAR_BORDER_RADIUS}px ${BAR_BORDER_RADIUS}px 0`,
  },
  barSpacing: {
    marginRight: 5,
  },
  legendText: {
    fontSize: 12,
    marginRight: 24,
  },
}));

const SingleStackedBar = <T extends Record<string, number>>({
  data,
  keys,
  totalKey,
  colors,
  getDisplayName,
}: SingleStackedBarProps<T>) => {
  const { classes } = useStyles();
  const totalValue = data[totalKey];
  const values = keys.map((key) => (data[key] / totalValue) * 100);
  const percentageValues = roundPercentages(values);

  return (
    <>
      <div className={classes.rowContainer} style={{ marginBottom: 16 }}>
        {keys.map((key, index) =>
          percentageValues[index] > 0 ? (
            <div
              key={`single-stacked-bar-${key}`}
              className={`${classes.bar} ${
                index === keys.length - 1
                  ? classes.lastBar
                  : index === 0
                  ? `${classes.firstBar} ${classes.barSpacing}`
                  : classes.barSpacing
              }`}
              style={{ width: `${percentageValues[index]}%`, backgroundColor: colors[index] }}
            />
          ) : (
            <></>
          ),
        )}
      </div>
      <div className={classes.rowContainer}>
        {keys.map((key, index) => (
          <div key={`single-stacked-bar-legend-${key}`} className={classes.legendText} style={{ color: colors[index] }}>
            <span>
              {`
                ${getDisplayName(key)}
                ${percentageValues[index]}%
              `}
            </span>
          </div>
        ))}
      </div>
    </>
  );
};

export default SingleStackedBar;
