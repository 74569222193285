import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { Checkbox, FormControlLabel } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useLang, setLanguage } from 'src/app/i18n';
import { regionLocale } from 'src/app/i18n/utils/i18n-utils';
import { localeMapToBackend } from 'src/app/modules/AgentProfile/common/utils/locale-utils';
import { AgentProfileItem } from 'src/app/modules/AgentProfile/types/agent-profile-types';
import { takeUIClickEvent } from '../../../../common/ga/ga';

type BasicInfoProps = {
  agentProfile: AgentProfileItem;
};

const MAIN_WIDTH = 30;
const SWITCH_CONTAINER_WIDTH = 86;
const SWITCH_CONTAINER_HEIGHT = 36;
const SWITCH_CONTAINER_SIDE_PADDING = 4;
const SWITCH_CONTAINER_TOP_BOTTOM_PADDING = 3;

const SWITCH_WIDTH = (SWITCH_CONTAINER_WIDTH - SWITCH_CONTAINER_SIDE_PADDING * 2) / 2;
const SWITCH_HEIGHT = SWITCH_CONTAINER_HEIGHT - SWITCH_CONTAINER_TOP_BOTTOM_PADDING * 2;
const PROFILE_PIC_TOP = -90;

const useStyles = makeStyles()((theme) => ({
  card: {
    backgroundColor: 'white',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    minHeight: -PROFILE_PIC_TOP,
  },
  banner: {
    width: '100%',
  },
  imgCircleContainer: {
    width: 124,
    height: 124,
    borderWidth: 6,
    borderColor: theme.palette.common.white,
    borderRadius: '50%',
    backgroundColor: theme.palette.common.white,
    position: 'absolute',
    left: MAIN_WIDTH,
    top: PROFILE_PIC_TOP,
  },
  imgCircle: {
    borderRadius: '50%',
    width: '100%',
    height: '-webkit-fill-available',
    objectFit: 'cover',
  },
  leftRedCircle: {
    borderRadius: '0 90px 0 0',
    height: 90,
    width: 90,
    backgroundColor: theme.palette.common.white,
    opacity: 0.16,
    position: 'absolute',
    top: 110,
    left: 15,
  },
  rightTopRedCircle: {
    height: 90,
    width: 90,
    backgroundColor: theme.palette.common.white,
    opacity: 0.16,
    position: 'absolute',
    right: 15,
    borderRadius: '0 0 0 90px',
  },
  rightMiddleRedCircle: {
    position: 'absolute',
    top: 180,
    right: 15,
    height: 85,
    backgroundColor: '#E8192C26',
    borderRadius: '0 0 85px 85px',
    width: 140,
  },
  rightBottomRedCircle: {
    right: 15,
    width: 70,
    height: 150,
    top: 190,
    position: 'absolute',
    borderRadius: '70px 0 0 70px',
    backgroundColor: '#E8192C26',
  },
  mainContainer: {
    position: 'relative',
  },
  switchContainer: {
    backgroundColor: '#F5F5F5',
    position: 'absolute',
    top: 16,
    right: 30,
    width: SWITCH_CONTAINER_WIDTH,
    height: SWITCH_CONTAINER_HEIGHT,
    padding: `${SWITCH_CONTAINER_TOP_BOTTOM_PADDING}px ${SWITCH_CONTAINER_SIDE_PADDING}px`,
    borderRadius: 10,
    zIndex: 1,
  },
  switch: {
    width: SWITCH_WIDTH,
    height: SWITCH_HEIGHT,
    margin: 0,
  },
  switchChecked: {
    color: '#E8192C',
    backgroundColor: 'white',
    borderRadius: 8,
  },
  switchUnchecked: {
    color: '#999999',
  },
  switchLabel: {
    width: '100%',
    textAlign: 'center',
    fontSize: 14,
    fontWeight: 600,
    fontFamily: 'Arial',
  },
  langCheckBox: {
    display: 'none',
  },
}));

const BasicInfo: FC<BasicInfoProps> = ({ agentProfile }) => {
  const { classes } = useStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const currentLang = useLang();
  const mappedLocale = localeMapToBackend[currentLang] || currentLang;

  const { profilePictureUrl } = agentProfile.agentDetail;

  const defaultImg = process.env.PUBLIC_URL + '/media/svg/icons/Head/default-head.svg';

  return (
    <div>
      {regionLocale.length === 2 && (
        <div className={classes.switchContainer}>
          {regionLocale.map((locale) => {
            const checked = locale === currentLang;
            return (
              <FormControlLabel
                className={`${classes.switch} ${checked ? classes.switchChecked : classes.switchUnchecked}`}
                classes={{ label: classes.switchLabel }}
                control={
                  <Checkbox
                    className={classes.langCheckBox}
                    checked={checked}
                    onChange={(e) => {
                      if (e.target.checked) {
                        takeUIClickEvent({
                          stage: 'switch_language',
                          object_name: locale === 'zh-Hant' ? 'TC' : 'EN',
                          object_type: 'BUTTON',
                        });
                        setLanguage(locale);
                      }
                    }}
                  />
                }
                label={<span>{Translation(`agentProfile.ap_${locale === 'zh-Hant' ? 'zhhk' : locale}_switch`)}</span>}
              />
            );
          })}
        </div>
      )}
      <div className={classes.card}>
        <img src={agentProfile.banner[mappedLocale]} alt="" className={classes.banner} />
      </div>
      <div className={classes.mainContainer}>
        <div className={classes.imgCircleContainer}>
          <img
            src={profilePictureUrl || defaultImg}
            alt=""
            className={classes.imgCircle}
            onError={(e) => {
              const target = e.target as HTMLImageElement;
              if (target.src !== defaultImg) {
                target.src = defaultImg;
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default BasicInfo;
