import { IntlShape } from 'react-intl';
import { ModuleHierachy } from '../../common/module/types';
import {
  RESOURCE_TITLE,
  RESOURCE_BASE_PATH,
  SALES_MATERIAL_TITLE,
  SALES_MATERIAL_BASE_PATH,
  SALES_LIBRARY_TITLE,
  SALES_LIBRARY_BASE_PATH,
  RESOURCE_SALES_LIBRARY_NAMESPACE,
  RECRUIT_TITLE,
  RECRUIT_BASE_PATH,
  RECRUIT_LIBRARY_TITLE,
  RECRUIT_LIBRARY_BASE_PATH,
  RESOURCE_RECRUIT_LIBRARY_NAMESPACE,
} from './constants';

import SalesLibraryRoutes from './pages/SalesMaterial/SalesLibraryRoutes';
import RecruitLibraryRoutes from './pages/Recruit/RecruitLibraryRoutes';

export const resourceModuleConfig = (permissions: string[], intl: IntlShape): ModuleHierachy => {
  const Translation = (id: string) => intl.formatMessage({ id });
  return {
    title: Translation(RESOURCE_TITLE),
    path: RESOURCE_BASE_PATH,
    icon: '/media/svg/icons/Text/Bullet-list.svg',
    disableMenu: false,
    enableRead: true,
    child: [
      {
        title: Translation(SALES_MATERIAL_TITLE),
        path: `${RESOURCE_BASE_PATH}${SALES_MATERIAL_BASE_PATH}`,
        enableRead: true,
        child: [
          {
            title: Translation(SALES_LIBRARY_TITLE),
            path: `${RESOURCE_BASE_PATH}${SALES_MATERIAL_BASE_PATH}${SALES_LIBRARY_BASE_PATH}`,
            namespace: RESOURCE_SALES_LIBRARY_NAMESPACE,
            component: SalesLibraryRoutes,
            enableRead: true,
            enableCreate: true,
            enableUpdate: true,
          },
        ],
      },
      {
        title: Translation(RECRUIT_TITLE),
        path: `${RESOURCE_BASE_PATH}${RECRUIT_BASE_PATH}`,
        enableRead: true,
        child: [
          {
            title: Translation(RECRUIT_LIBRARY_TITLE),
            path: `${RESOURCE_BASE_PATH}${RECRUIT_BASE_PATH}${RECRUIT_LIBRARY_BASE_PATH}`,
            namespace: RESOURCE_RECRUIT_LIBRARY_NAMESPACE,
            component: RecruitLibraryRoutes,
            enableRead: true,
            enableCreate: true,
            enableUpdate: true,
          },
        ],
      },
    ],
  };
};
