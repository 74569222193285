import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { TokenInfo } from 'src/app/modules/Auth/types/auth-types';
import { RootState } from 'src/redux/store';
import { ApiErrorResponse } from '../network/apiErrorHandler';

export const useUpdateEffect = (callback: () => any, deps: any) => {
  const ref = useRef<boolean | null>(null);

  useEffect(() => {
    if (!ref.current) {
      ref.current = true;
    } else {
      callback();
    }
    // eslint-disable-next-line
  }, deps);
};

export const useDataProvider = <T = any>(
  callback: () => Promise<T | void | undefined>,
  setData: (data: T | undefined) => void,
  clear?: boolean,
  clearValue?: T,
  manualTrigger?: boolean,
) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<ApiErrorResponse | undefined>(undefined);

  const [refresh, setRefresh] = useState<boolean>(false);

  const [counter, setCounter] = useState<number>(0);

  const loadData = async () => {
    const timer = setInterval(() => {
      setCounter((seconds) => seconds + 1);
    }, 1000);
    setError(undefined);
    setIsLoading(true);
    if (clear) {
      setData(clearValue);
    }
    try {
      const response = await callback();
      if (response) {
        setData(response);
      }
    } catch (e: any) {
      setError(e);
    }
    setIsLoading(false);
    clearInterval(timer);
    setCounter(0);
  };

  useEffect(() => {
    if (!manualTrigger) {
      loadData();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (refresh) {
      loadData();
      setRefresh(false);
    }
    // eslint-disable-next-line
  }, [refresh]);

  return {
    isLoading,
    error,
    counter,
    refreshData: () => setRefresh(true),
  };
};

export const useUrlQueryReader = (excludeParams: string[]) => {
  const search = window.location.search;
  const param = new URLSearchParams(search);

  let result = '?';
  for (const [key, value] of param) {
    if (!excludeParams.includes(key)) {
      if (value !== '') {
        result += `${key}=${value}&`;
      } else {
        result += `${key}&`;
      }
    }
  }

  return result;
};

export const useParamReader = <T extends Record<string, unknown>>(names: string[]): Partial<T> => {
  let obj: Record<string, unknown> = {};
  const search = window.location.search;
  const params = new URLSearchParams(search);

  names.forEach((name) => {
    const val = params.get(name);
    if (!obj[name] && val !== undefined) {
      obj[name] = val;
    }
  });
  return obj as Partial<T>;
};

export const useJWT = (): TokenInfo => {
  return useSelector<RootState, TokenInfo>((state) => state.auth.user as TokenInfo);
};
