export const RESOURCE_TITLE = 'resource.title';
export const SALES_MATERIAL_TITLE = 'resource.sales_material_title';
export const SALES_LIBRARY_TITLE = 'resource.sales_library_title';
export const RECRUIT_TITLE = 'resource.recruit-title';
export const RECRUIT_LIBRARY_TITLE = 'resource.recruit-library-title';

export const RESOURCE_BASE_PATH = '/resource';
export const SALES_MATERIAL_BASE_PATH = '/sales-material';
export const SALES_LIBRARY_BASE_PATH = '/sales-library';
export const RECRUIT_BASE_PATH = '/recruit';
export const RECRUIT_LIBRARY_BASE_PATH = '/recruit-library';

export const RESOURCE_SALES_LIBRARY_NAMESPACE = 'RESOURCE-SALES-LIBRARY';
export const RESOURCE_RECRUIT_LIBRARY_NAMESPACE = 'RESOURCE-RECRUIT-LIBRARY';

// export enum AgentResourcePermitType {
//   RESOURCE_SALES_LIBRARY_READ = 'READ_ANY_RESOURCE-SALES-LIBRARY',
//   RESOURCE_SALES_LIBRARY_CREATE = 'CREATE_ANY_RESOURCE-SALES-LIBRARY',
//   RESOURCE_SALES_LIBRARY_UPDATE = 'UPDATE_ANY_RESOURCE-SALES-LIBRARY',
// }
