import React from 'react';
import Create from './Create';
import {
  RESOURCE_BASE_PATH,
  SALES_LIBRARY_BASE_PATH,
  SALES_MATERIAL_BASE_PATH,
} from '@/app/modules/ResourceLibrary/constants';

export const SystemConfig = {
  blockName: 'component.formTitle.sales-material',
  blockType: 'salesMaterial',
  libraryBasePath: `${RESOURCE_BASE_PATH}${SALES_MATERIAL_BASE_PATH}${SALES_LIBRARY_BASE_PATH}`,
  categoryType: 'salesMaterialCategory',
  columnType: 'salesMaterialColumn',
  addSuccessText: 'global.submit.success',
  columnModuleType: 'productionInfo',
  moduleType: 'Sales',
};

const SalesLibraryCreateContainer = (props: any) => {
  return <Create {...props} {...SystemConfig} />;
};

export default SalesLibraryCreateContainer;
