import { makeStyles } from 'tss-react/mui';

export const useCommonStyles = makeStyles()((theme) => ({
  header: {
    fontSize: '1.2rem',
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    '&:before': {
      content: "''",
      display: "inline-block",
      height: "1.5rem",
      width: 3,
      backgroundColor: "red",
      marginRight: 10,
      marginLeft: 10,
      borderTopLeftRadius: 2,
    },
  },
  noBorderHeader: {
    '&:before': {
      width: 0,
    },
  },
  popupCard: {
    borderRadius: 5,
    backgroundColor: theme.palette.common.white,
    boxShadow: "1px 2px 2px 1px rgba(0, 0, 0, 0.2)",
    padding: 10,
    outline: 'none',
  },
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  errorText: {
    marginTop: 4,
    marginLeft: "1rem",
    marginRight: "1rem",
    color: "#f018a6",
    fontSize: "0.75rem",
    fontWeight: 400,
    fontFamily: 'Poppins',
  },
  flexCenter: {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  link: {
    textDecoration:'underline'
  }
}));
