import { Dispatch } from 'react';
import { apiClient, apiErrorHandler, AGENT_URL } from 'src/app/common/network';
import {
  AboutMeTemplates,
  AgentAwardsType,
  AgentProfileItem,
  AboutMeDisplayStatusEnum,
} from 'src/app/modules/AgentProfile/types/agent-profile-types';
import { ReferralLinkOptions } from '../types/agent-profile-types';

const agentProfileEndpoint = 'agents/me/e-profiles';

export type UpdateProfileBody = {
  enableWhatsapp?: boolean;
  aboutMeTemplate?: string;
  awards?: string[];
  awardBadges?: string[];
  promotions?: string[];
  aboutMeDisplayStatus?: AboutMeDisplayStatusEnum;
  referralLinkOptions?: ReferralLinkOptions[];
};

export const fetchAgentProfile = async (lang: string, dispatch?: Dispatch<any>): Promise<AgentProfileItem> => {
  return apiClient
    .get<AgentProfileItem>(`${AGENT_URL}/${agentProfileEndpoint}?lang=${lang}`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const getAboutMeTemplates = async (dispatch?: Dispatch<any>): Promise<AboutMeTemplates> => {
  return apiClient
    .get<AboutMeTemplates>(`${AGENT_URL}/${agentProfileEndpoint}/configs/templates`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const getAgentAwards = async (lang: string, dispatch?: Dispatch<any>): Promise<AgentAwardsType> => {
  return apiClient
    .get<AgentAwardsType>(`${AGENT_URL}/${agentProfileEndpoint}/agent-awards?lang=${lang}`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const getAgentPromotions = async (lang: string, dispatch?: Dispatch<any>): Promise<any> => {
  return apiClient
    .get<any>(`${AGENT_URL}/${agentProfileEndpoint}/promotions?lang=${lang}`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const updateAgentProfile = async (body: UpdateProfileBody, dispatch?: Dispatch<any>): Promise<void> => {
  return apiClient
    .patch<any>(`${AGENT_URL}/${agentProfileEndpoint}`, body)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};