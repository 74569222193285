import React, { FC, useEffect, useState } from 'react';
import { Input } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { ToggleOn as ToggleOnIcon, ToggleOffRounded as ToggleOffRoundedIcon } from '@mui/icons-material';

type InputFormProps = {
  title: string;
  readOnly?: boolean;
  mandatory?: boolean;
  dropdownList?: string[];
  defaultValue?: string;
  placeholder?: string;
  showSwitch?: boolean;
  initIsSwitch?: boolean;
  onchange?: (data: string) => void;
  switchBoolean?: (flag: boolean) => void;
};

const useStyles = makeStyles()(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    fontWeight: 500,
    marginBlock: 14,
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: 14,
  },
  title: {
    color: '#333333',
  },
  mandatory: {
    color: '#EB3B4C',
  },
  contentContainer: {
    fontSize: 16,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyItems: 'center',
  },
  dropdownStyle: {},
}));

const InputForm: FC<InputFormProps> = ({
  title,
  readOnly,
  mandatory,
  dropdownList,
  defaultValue,
  placeholder,
  showSwitch,
  initIsSwitch,
  onchange,
  switchBoolean,
}) => {
  const { classes } = useStyles();
  const [isSwitch, setIsSwitch] = useState<boolean>(initIsSwitch === true ? true : false);
  const [dropdownValue, setDropdownValue] = useState<string>(dropdownList?.[0] ?? '');
  const [inputValue, setInputValue] = useState<string>(defaultValue ?? '');

  useEffect(() => {
    if (switchBoolean) {
      switchBoolean(isSwitch);
    }
  }, [isSwitch, switchBoolean]);

  return (
    <div className={classes.container}>
      <div className={classes.headerContainer}>
        <div className={classes.title}>{title}</div>
        {mandatory && <div className={classes.mandatory}>*</div>}
      </div>
      <div className={classes.contentContainer}>
        {dropdownList && (
          <select style={{ padding: 5 }} onChange={(event) => setDropdownValue(event.target.value)}>
            {dropdownList.map((value) => (
              <option value={value}>{value}</option>
            ))}
          </select>
        )}
        {readOnly ? (
          <div
            style={{
              fontSize: 16,
              width: '100%',
              fontFamily: 'Arial',
              color: '#858585',
              minHeight: 24,
              wordWrap: 'break-word',
            }}
          >
            {inputValue}
          </div>
        ) : (
          <Input
            key={title}
            style={
              dropdownList
                ? { fontSize: 16, width: '100%', fontFamily: 'Arial', color: '#333333', marginInline: 5 }
                : { fontSize: 16, width: '100%', fontFamily: 'Arial', color: '#333333' }
            }
            placeholder={placeholder ?? ''}
            disableUnderline={true}
            value={inputValue}
            onChange={(event) => setInputValue(event.target.value)}
          ></Input>
        )}

        {showSwitch && (
          <div onClick={() => setIsSwitch(!isSwitch)}>
            {isSwitch ? (
              <ToggleOnIcon style={{ color: '#E8192C', fontSize: 40 }} />
            ) : (
              <ToggleOffRoundedIcon style={{ fontSize: 40 }} />
            )}
          </div>
        )}
      </div>
      {dropdownList && <div style={{ borderTopWidth: 1, borderColor: '#F0F0F0', marginTop: -6, height: 12 }}></div>}
    </div>
  );
};

export default InputForm;
