import React, { FC, HTMLAttributes } from "react";
import { makeStyles } from 'tss-react/mui';
import { toAbsoluteUrl } from 'src/app/common/utils';
import { useIntl } from "react-intl";

type MoreLessProps = {
  isSeeMore: boolean;
};

const useStyles = makeStyles()(theme => ({
  showMoreBtn: {
    display: "inline-flex",
    alignItems: "center",
    fontWeight: 500,
    fontSize: 10,
    color: "#E8192C",
    cursor: "pointer",
  },
  iconContainer: {
    marginLeft: 4,
    display: "flex",
    alignItems: "center",
  },
  iconLeft: {
    transform: "rotate(180deg)",
  },
}));

const MoreLess: FC<MoreLessProps & HTMLAttributes<HTMLDivElement>> = ({ isSeeMore, children, ...rest }) => {
  const { classes } = useStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  return (
    <div className={classes.showMoreBtn} {...rest}>
      {isSeeMore
        ? Translation("common.showLess")
        : Translation("common.showMore")}
      <div className={classes.iconContainer}>
        <img
          className={isSeeMore ? classes.iconLeft : ""}
          src={toAbsoluteUrl("/media/download-page/arrow_right.png")}
          alt={"arrow"}
        />
      </div>
    </div>
  );
};

export default MoreLess;
