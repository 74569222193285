import { makeStyles } from 'tss-react/mui';
import { DashboardFilterDef, DialogState, FilterContentEnum } from '../../types/dashboard-filter-types';
import { ArrowDownIcon } from '../../Icons';

type FilterOptionProps<T> = {
  filterDef: DashboardFilterDef<T>[];
  initialFilterState: T;
  setDialogState: (newDialogState: DialogState) => void;
};

const useStyles = makeStyles()((theme) => ({
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  optionDiv: {
    display: 'flex',
    alignItems: 'center',
    padding: '3px 12px',
    border: '1px solid',
    borderRadius: 14,
    cursor: 'pointer',
    color: '#000000',
    borderColor: '#F0F0F0',
  },
  optionSpacing: {
    marginRight: 10,
  },
  optionValue: {
    fontSize: 14,
    marginRight: 8,
  },
}));

const FilterOption = <T extends Record<string, any>>({
  filterDef,
  initialFilterState,
  setDialogState,
}: FilterOptionProps<T>) => {
  const { classes } = useStyles();
  return (
    <div className={classes.rowContainer}>
      {filterDef
        .filter((filter) => {
          if (filter.disableCondition) {
            return !filter.disableCondition(initialFilterState);
          }
          return true;
        })
        .map((filter, index, filterList) => {
          const value =
            filter.options.find((option) => initialFilterState[filter.keyIndex] === option.value)?.displayName ||
            initialFilterState[filter.keyIndex];
          return (
            <div
              key={`filter-${filter.keyIndex}-result-${index}`}
              className={`
                      ${classes.optionDiv}
                      ${index !== filterList.length - 1 ? classes.optionSpacing : undefined}
                    `}
              onClick={() => setDialogState({ open: true, filterContent: { content: FilterContentEnum.MAIN } })}
            >
              <span className={classes.optionValue}>{value}</span>
              <ArrowDownIcon />
            </div>
          );
        })}
    </div>
  );
};

export default FilterOption;
