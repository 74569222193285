import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Icon } from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';
import { getLocalDateFormat } from '../../../../../../common/utils/time-utils';
import { useStyles } from './InvitePreview.style';

interface PrevieData {
  campaignType: string;
  campaignName: string;
  startDate: string;
  endDate: string;
}
interface InvitePreviewProps {
  data: PrevieData;
  open: boolean;
  sentInvite: boolean; // show success popup when done
  onClose: () => void;
  onOk: () => void;
}

export const InvitePreview: React.FC<InvitePreviewProps> = ({ data, open, sentInvite, onClose, onOk }) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const { classes } = useStyles();
  // const [isOpen, setIsOpen] = React.useState<boolean>(false);
  return (
    <Dialog
      open={open}
      onClose={(_, reason) => {
        if (reason !== 'backdropClick') {
          onClose();
        }
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{Translation('agencyCampaign.create.inviteTitle')}</DialogTitle>
      {sentInvite ? (
        <div className={classes.sentSuccess}>
          <div className={[classes.center, classes.circle].join(' ')}>
            <Icon className="fa fa-check" fontSize="large" color="primary" />
          </div>
          <span className={classes.sentSuccessText}>{Translation('agencyCampaign.create.inviteSent')}</span>
          <Button className={classes.flex1} onClick={onClose} variant="contained" color="secondary" autoFocus>
            {Translation('agencyCampaign.create.inviteBack')}
          </Button>
        </div>
      ) : (
        <>
          <DialogContent>
            <div className={classes.Content}>
              <div className={classes.acmType}>{data.campaignType}</div>

              <div className={classes.acmName}>{data?.campaignName}</div>
              <div className={classes.acmTime}>
                <Icon className="fa fa-clock" style={{ color: '#bbb', marginRight: 10 }} />
                <span>
                  {getLocalDateFormat(data.startDate)} - {getLocalDateFormat(data.endDate)}
                </span>
              </div>

              <div className={classes.btnWrap}>
                <Button className={classes.btnLeft} variant="outlined" color="inherit">
                  {Translation('agencyCampaign.create.inviteReject')}
                </Button>
                <Button className={classes.btnRight} variant="contained" color="secondary" autoFocus>
                  {Translation('agencyCampaign.create.inviteAccept')}
                </Button>
              </div>
            </div>
          </DialogContent>
          <DialogActions className={classes.btnInvitePreview}>
            <Button onClick={onClose} variant="outlined" color="inherit" className="btn">
              {Translation('app.button.cancel')}
            </Button>
            <Button
              onClick={onOk}
              variant="contained"
              autoFocus
              color="secondary"
              // className='btn btn-primary'
            >
              {Translation('agencyCampaign.create.inviteSend')}
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};
