export const REPORT_TITLE = 'report.title';
export const EVENT_TITLE = 'report.event.title';
export const GP_REPORT_TITLE = 'report.gp-report.title';
export const BOP_REPORT_TITLE = 'report.bop-report.title';

export const REPORT_BASE_PATH = '/report';
export const EVENT_BASE_PATH = '/sales-material';
export const GP_REPORT_BASE_PATH = '/gp-report';
export const BOP_REPORT_BASE_PATH = '/bop-report';

export const EVENT_GP_REPORT_NAMESPACE = 'EVENT_GP_REPORT';
export const EVENT_BOP_REPORT_NAMESPACE = 'EVENT_BOP_REPORT_NAMESPACE';
