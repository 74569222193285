import { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { RadioButtonChecked, RadioButtonUnchecked } from '@mui/icons-material';
import { Translation } from 'src/app/i18n';
import { isPhone } from 'src/app/common/constants';
import { ArrowDownIcon, CloseIcon } from '../Icons';

type DashboardSelectGroupProps<T> = {
  title: string;
  initialValue: T;
  groupList: T[];
  codeKey: keyof T;
  displayNameKey: keyof T;
  onConfirm: (value: T) => void;
  onOpen?: () => void;
};

const DIALOG_WIDTH = 512;
const PADDING = isPhone ? 30 : 36;

const useStyles = makeStyles()((theme) => ({
  paper: {
    width: DIALOG_WIDTH,
  },
  titleContainer: {
    padding: '16px 20px',
  },
  titleDiv: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  title: {
    fontFamily: 'Arial',
    fontSize: 18,
    fontWeight: 600,
  },
  contentContainer: {
    height: 368,
    padding: '0 16px',
    fontSize: 12,
  },
  option: {
    width: '100%',
    padding: 9,
    margin: 0,
  },
  selected: {
    backgroundColor: '#F4F4F4',
    borderRadius: 12,
  },
  radioIcon: {
    fontSize: 24,
  },
  optionText: {
    fontSize: 14,
    fontWeight: 500,
    fontFamily: 'Arial',
    marginLeft: 9,
  },
  actionContainer: {
    justifyContent: 'center',
    padding: PADDING,
    paddingTop: 24,
  },
  button: {
    width: '100%',
    height: 48,
    fontSize: 16,
    fontFamily: 'Arial',
  },
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  optionDiv: {
    display: 'flex',
    alignItems: 'center',
    padding: '3px 12px',
    border: '1px solid',
    borderRadius: 14,
    cursor: 'pointer',
    color: '#000000',
    borderColor: '#F0F0F0',
  },
  value: {
    fontSize: 14,
    marginRight: 8,
  },
}));

const DashboardSelectGroup = <T extends Record<string, string>>({
  title,
  initialValue,
  groupList,
  codeKey,
  displayNameKey,
  onConfirm,
  onOpen,
}: DashboardSelectGroupProps<T>) => {
  const { classes } = useStyles();
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [selected, setSelected] = useState<T>(initialValue);

  const handleOpen = () => {
    setShowDialog(true);
    if (onOpen) {
      onOpen();
    }
  };

  const handleClose = () => {
    setShowDialog(false);
    setSelected(initialValue);
  };

  const handleConfirm = () => {
    onConfirm(selected);
    setShowDialog(false);
  };

  return (
    <>
      {showDialog && (
        <Dialog open={showDialog} classes={{ paper: classes.paper }}>
          <DialogTitle className={classes.titleContainer}>
            <div className={classes.titleDiv}>
              <div className={classes.title}>{title}</div>
              <CloseIcon onClick={handleClose} />
            </div>
          </DialogTitle>
          <DialogContent className={classes.contentContainer}>
            {groupList.map((item, index) => {
              const isSelected = selected[codeKey] === item[codeKey];
              return (
                <FormControlLabel
                  key={`checkbox-${index}`}
                  className={`
                    ${classes.option}
                    ${isSelected ? classes.selected : undefined}
                  `}
                  control={
                    <Checkbox
                      color="secondary"
                      icon={<RadioButtonUnchecked className={classes.radioIcon} />}
                      checkedIcon={<RadioButtonChecked className={classes.radioIcon} />}
                      checked={isSelected}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelected(item);
                        }
                      }}
                    />
                  }
                  label={<div className={classes.optionText}>{item[displayNameKey]}</div>}
                  labelPlacement="end"
                />
              );
            })}
          </DialogContent>
          <DialogActions className={classes.actionContainer}>
            <Button variant="contained" color="secondary" className={classes.button} onClick={handleConfirm}>
              {Translation('app.button.confirm')}
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <div className={classes.rowContainer}>
        <div className={classes.optionDiv} onClick={handleOpen}>
          <span className={classes.value}>{initialValue[displayNameKey]}</span>
          <ArrowDownIcon />
        </div>
      </div>
    </>
  );
};

export default DashboardSelectGroup;
