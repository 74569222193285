import { makeStyles } from 'tss-react/mui';
import { isPhone } from 'src/app/common/constants';
import { DashboardChart } from '../../DashboardChart/DashboardChart';
import { SplitCardProps } from '../../types/dashboard-card-types';
import { ChartTypeEnum } from '../../types/dashboard-chart-types';

const CHART_SIZE = isPhone ? 50 : 60;
const DIVIDER_PADDING = isPhone ? 15 : 20;

const MAIN_FONT_SIZE = isPhone ? 25 : 30;
const SUB_FONT_SIZE = isPhone ? 15 : 20;
const CAPTION_FONT_SIZE = 12;

const useStyles = makeStyles()((theme) => ({
  mainContainer: {
    display: 'flex',
    padding: '16px 20px',
  },
  splitContainer: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 16,
  },
  left: {
    minWidth: '40%',
    paddingRight: DIVIDER_PADDING,
  },
  right: {
    flexGrow: 1,
    paddingLeft: DIVIDER_PADDING,
  },
  label: {
    fontSize: CAPTION_FONT_SIZE,
    fontWeight: 500,
    color: '#24B1C7',
    backgroundColor: '#E0FBFF',
    padding: '4px 8px',
    borderRadius: 4,
  },
  rowContainer: {
    width: '100%',
    minHeight: CHART_SIZE,
    display: 'flex',
    alignItems: 'center',
    columnGap: 10,
  },
  divider: {
    borderRight: '1px solid #F0F0F0',
  },
  valueContainer: {
    flexGrow: 1,
    marginTop: -12,
  },
  leftValue: {
    color: '#333333',
    fontSize: MAIN_FONT_SIZE,
    fontWeight: 700,
    fontFamily: 'Montserrat',
  },
  rightValue: {
    fontSize: MAIN_FONT_SIZE,
    fontWeight: 700,
    fontFamily: 'Montserrat',
    marginRight: 8,
  },
  percentage: {
    fontSize: SUB_FONT_SIZE,
    fontWeight: 700,
    fontFamily: 'Montserrat',
  },
  caption: {
    color: '#999999',
    fontSize: CAPTION_FONT_SIZE,
    fontWeight: 500,
  },
}));

const SplitCard = <T extends Record<string, any>, K extends Record<string, any>>({
  leftLabel,
  leftData,
  leftDataKeys,
  rightLabel,
  rightData,
  rightDataKeys,
  rightDataValueKeys,
  rightDataValueTotalKey,
  rightChartColors,
  rightValueStyle,
  getLeftCaption,
  getRightCaption,
}: SplitCardProps<T, K>) => {
  const { classes } = useStyles();

  const renderLabel = (label: string) => (
    <div>
      <span className={classes.label}>{label}</span>
    </div>
  );

  const renderLeftData = () =>
    leftDataKeys.map((key) => {
      const value = leftData[key];
      return (
        <div key={`left-column-${key}`} className={classes.rowContainer}>
          <div className={classes.valueContainer}>
            <div className={classes.leftValue}>{value}</div>
            {getLeftCaption && <div className={classes.caption}>{getLeftCaption(key)}</div>}
          </div>
        </div>
      );
    });

  const renderRightData = () =>
    rightDataKeys.map((key) => {
      const rightDataValue = rightData[key];
      const value = rightDataValue[rightDataValueKeys[0]];
      const total = rightDataValue[rightDataValueTotalKey];
      const percentage = total !== 0 ? Math.round((value / total) * 100) : 0;
      return (
        <div key={`right-column-${key}`} className={classes.rowContainer}>
          <DashboardChart
            disableTotal
            type={ChartTypeEnum.SINGLE_RADIAL_BAR}
            data={rightDataValue}
            keys={rightDataValueKeys}
            totalKey={rightDataValueTotalKey}
            colors={rightChartColors ? rightChartColors : ['#EB3B4C', 'rgba(153, 153, 153, 0.2)']}
            chartContainerStyle={{ width: CHART_SIZE, height: CHART_SIZE }}
          />
          <div
            className={classes.valueContainer}
            style={typeof rightValueStyle === 'function' ? rightValueStyle(value) : rightValueStyle}
          >
            <div>
              <span className={classes.rightValue}>{value}</span>
              <span className={classes.percentage}>{`(${percentage}%)`}</span>
            </div>
            {getRightCaption && <div className={classes.caption}>{getRightCaption(key)}</div>}
          </div>
        </div>
      );
    });

  return (
    <>
      <div className={classes.mainContainer}>
        <div className={`${classes.splitContainer} ${classes.left} ${classes.divider}`}>
          {renderLabel(leftLabel)}
          {renderLeftData()}
        </div>
        <div className={`${classes.splitContainer} ${classes.right}`}>
          {renderLabel(rightLabel)}
          {renderRightData()}
        </div>
      </div>
    </>
  );
};

export default SplitCard;
