import { DashboardChartProps, ChartProps, ChartTypeEnum } from '../types/dashboard-chart-types';
import SingleStackedBar from './components/SingleStackedBar';
import SingleRadialBar from './components/SingleRadialBar';

export const DashboardChart = <T extends Record<string, number>>({ style, ...props }: DashboardChartProps<T>) => {
  const renderChart = (chartProps: ChartProps<T>) => {
    switch (chartProps.type) {
      case ChartTypeEnum.SINGLE_STACKED_BAR:
        return <SingleStackedBar {...chartProps} />;
      case ChartTypeEnum.SINGLE_RADIAL_BAR:
        return <SingleRadialBar {...chartProps} />;
      default:
        return <></>;
    }
  };

  return <div style={style}>{renderChart(props)}</div>;
};

export default DashboardChart;
