import React, { useState } from 'react';
import { Button, Dialog } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useIntl } from 'react-intl';

interface BottonDialogProps {
  btnColor?: any;
  btnTxt: string;
  btnClasses?: string;
  dialogTxt: React.ReactNode;
  onCancel?: () => void;
  onOk: () => void;
}

const useStyles = makeStyles()((theme) => ({
  container: {
    padding: '20px',
  },
  btnContainer: {
    padding: '0 20px 20px 20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const BottonDialog = ({
  btnColor = 'inherit',
  btnTxt,
  dialogTxt,
  onCancel = () => {},
  onOk,
  btnClasses,
}: BottonDialogProps) => {
  const [open, openModal] = useState<boolean>(false);
  const { classes } = useStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  const onClose = () => {
    onCancel();
    openModal(false);
  };

  const onConfirm = () => {
    onOk();
  };

  return (
    <div>
      <Button variant="contained" color={btnColor} className={btnClasses} onClick={() => openModal(true)}>
        {btnTxt}
      </Button>
      <Dialog open={open} onClose={onClose}>
        <div className={classes.container}>{dialogTxt}</div>
        <div className={classes.btnContainer}>
          <Button style={{ marginRight: 20 }} variant="contained" color="inherit" onClick={onClose}>
            {Translation('global.text.no')}
          </Button>
          <Button variant="contained" color="secondary" onClick={onConfirm}>
            {Translation('global.text.yes')}
          </Button>
        </div>
      </Dialog>
    </div>
  );
};

export default BottonDialog;
