import { isPhone } from 'src/app/common/constants';
import { calculateItemWidth } from 'src/app/common/utils/common-utils';

const UPPER_CARD_HEIGHT = 260;
const LOWER_CARD_HEIGHT = 182;

export const CARD_COLUMN_GAP = isPhone ? 0 : 16;
const CARD_NUM_PER_ROW = isPhone ? 1 : 2;
const CARD_WIDTH = calculateItemWidth(CARD_COLUMN_GAP, CARD_NUM_PER_ROW);

export const overviewStyles = {
  upperCard: {
    width: CARD_WIDTH,
    minHeight: UPPER_CARD_HEIGHT,
  },
  lowerCard: {
    width: CARD_WIDTH,
    minHeight: LOWER_CARD_HEIGHT,
  },
  red: {
    color: '#EB3B4C',
  },
  green: {
    color: '#24C791',
  },
};
