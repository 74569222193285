export enum OperatorEnum {
  EQ = 'eq',
  GTE = 'gte',
  LTE = 'lte',
  LT = 'lt',
  GT = 'gt',
}

export type TimeSetting = {
  operator: OperatorEnum;

  dependsOn?: string;

  value?: number;
};

export enum LeadUploadErrorEnum {
  MAX_RECORDS,
  NO_RECORDS,
  INVALID_RECORDS,
  DUPLICATE_RECORDS,
  FILE_NOT_SUPPORTED,
}
