import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(() => ({
  btnBlue: {
    color: '#FFFFFF',
    fontWeight: 'bold',
    backgroundColor: '#4386E5',
    textTransform: 'uppercase',
  },
}));

export const rowTxtStyle = {
  color: '#666',
  fontSize: '1rem',
  fontFamily: 'SVN-Poppins',
};

export const operationTxt = {
  fontSize: '1rem',
  fontFamily: 'SVN-Poppins',
};
