import { Action, ThunkAction, createSlice } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { TokenInfo } from '../types/auth-types';

export type AuthenticationState = {
  isExpired?: boolean;
  token?: string;
  user?: TokenInfo;
  officeCodes?: string[];
};

export const authPersistConfig = {
  key: 'auth',
  storage,
};

const initialState: AuthenticationState = {
  isExpired: undefined,
  token: undefined,
  user: undefined,
};

type LoginAction = {
  payload: string;
};

type SetAgentAction = {
  payload: TokenInfo;
};

type SetTokenExpiredAction = {
  payload: boolean;
};

type SetOfficeCodeAction = {
  payload: string[];
};

export type AuthThunkAction = ThunkAction<void, AuthenticationState, unknown, Action<string>>;

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    authenticate: (state: AuthenticationState, action: LoginAction) => {
      if (typeof window !== undefined) {
        window.localStorage.setItem('jwt', action.payload);
      }
      return {
        ...state,
        token: action.payload,
      };
    },
    setTokenInfo: (state: AuthenticationState, action: SetAgentAction) => {
      return {
        ...state,
        user: action.payload,
      };
    },
    setExpired: (state: AuthenticationState, action: SetTokenExpiredAction) => {
      return {
        ...state,
        isExpired: action.payload,
      };
    },
    setOfficeCodes: (state: AuthenticationState, action: SetOfficeCodeAction) => {
      return {
        ...state,
        officeCodes: action.payload,
      };
    },
    logout: () => {
      window.localStorage.removeItem('jwt');
      window.localStorage.removeItem('refreshToken');
      window.localStorage.removeItem('expireDate');
      window.localStorage.removeItem('abondonSession');
      window.localStorage.removeItem('staffSelectedAgentCode');
      window.localStorage.removeItem('appConfig');
      return initialState;
    },
  },
});

export const { authenticate, setTokenInfo, logout, setExpired, setOfficeCodes } = authSlice.actions;
