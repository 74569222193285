import { FC, CSSProperties } from 'react';

type PruIconProps = {
  icon: string;
  customClasses?: string;
  style?: CSSProperties;
  onClick?: () => void;
};

const PruIcon: FC<PruIconProps> = ({ icon, customClasses, style, onClick }) => {
  return <i className={`iconfont ${icon} ${customClasses}`} style={style} onClick={onClick} />;
};

export default PruIcon;
