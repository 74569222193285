import React from 'react';
import { GlobalHelper } from '../../helpers/GlobalHelpers';
import { CloseModalProps, PruModalProps } from './modal-context';
import { ConfirmModalProps, ConfirmModalView } from './modals/confirm-modal-view';
import AlertModal from './modals/alert-modal';

class _ModalService {
  modalRef: any;
  promiseRef: any;
  // eslint-disable-next-line @typescript-eslint/lines-between-class-members
  constructor() {
    this.modalRef = GlobalHelper.getGlobalModalRef();
    this.promiseRef = new Promise<void>((resolve, reject) => resolve());
  }
  init() {
    this.modalRef = GlobalHelper.getGlobalModalRef();
    this.promiseRef = new Promise<void>((resolve, reject) => resolve());
  }
  /**
   *
   * @param {CloseModalProps} props
   *
   *
   * Description:
   *
   * close modal with a provided promise, if no promise provide, modal will dismiss directly
   *
   * but if a promise provided:
   *
   * 1. the modal will reopen when the promise resolved
   * 2. the modal will dismiss when the promise rejected
   *
   * Usage:
   * ```jsx
   * const reopenPromiseRef = React.useRef<{
   *   resolve: () => void;
   *   reject: () => void;
   * }>();
   * ModalService.close({
   *   reopenPromise: new Promise<void>((resolve, reject) => {
   *     reopenPromiseRef.current = { resolve, reject };
   *   }),
   * });
   * ```
   */
  close(props?: CloseModalProps) {
    this.modalRef?.close?.(props ?? {});
  }
  open({ title, children, ...rest }: PruModalProps | any) {
    return this.promiseRef.finally(() => {
      this.promiseRef = this.modalRef?.open?.({
        title,
        children,
        ...rest,
      });
      return this.promiseRef;
    });
  }
  confirm(option: ConfirmModalProps & { title: string }) {
    const result = this.promiseRef.finally(
      () => {
        this.promiseRef = this.modalRef?.open?.({
          title: option.title,
          children: <ConfirmModalView {...option} />,
        });
        return this.promiseRef;
      }
    );
    return result
  }
  openSubModal({ title, children }: { title?: string; children: JSX.Element }) {
    return this.modalRef?.openSubModal?.({
      title,
      children,
    });
  }
  showAlert(title: string, content: string) {
    return this.modalRef?.open?.({
      customHeader: true,
      children: <AlertModal title={title} content={content} onClose={() => this.close()} />,
    });
  }
}

export const ModalService = new _ModalService();
