import { Dispatch } from 'react';
import { apiClient, apiErrorHandler, AGENT_URL } from 'src/app/common/network';
import { getQueryPath } from 'src/app/common/utils';
import { GroupTypeEnum, OverviewDimension, DimensionDataItem } from '../../types';
import { managerDashboardEndpoint, leadOverviewEndpoint } from './constants';

export type DimensionDataParam = {
  dateFrom?: Date;
  dateTo?: Date;
};

export const fetchOverviewDimension = async (dispatch?: Dispatch<any>): Promise<OverviewDimension> => {
  let apiPath = `${AGENT_URL}/${managerDashboardEndpoint}/${leadOverviewEndpoint}/dimensions`;
  return apiClient
    .get<OverviewDimension>(apiPath)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchDimensionDataItem = async (
  groupType: GroupTypeEnum,
  groupCode: string,
  params: DimensionDataParam,
  dispatch?: Dispatch<any>,
): Promise<DimensionDataItem> => {
  let queryPath = getQueryPath(
    `${AGENT_URL}/${managerDashboardEndpoint}/${leadOverviewEndpoint}/${groupType}/${groupCode}?`,
    params,
  );
  return apiClient
    .get<DimensionDataItem>(queryPath)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};
