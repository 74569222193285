import React, { FC } from 'react';

type props = {
  displayMode: 'flex' | 'none';
};

export const Loading: FC<props> = ({ displayMode }) => {
  return (
    <div
      style={{
        display: displayMode,
        zIndex: 1300,
        width: '94px',
        height: '88px',
        position: 'absolute',
        top: '-30px',
        bottom: 0,
        right: 0,
        left: 0,
        margin: 'auto',
        background: 'rgba(0,0,0,0.8)',
        borderRadius: '12px',
      }}
    >
      <div style={{ padding: 'unset', margin: 'auto', justifyContent: 'center' }}>
        <div
          className="myLoadingContainer"
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            alignSelf: 'center',
          }}
        >
          <div
            className="myloadingCirLa"
            style={{
              width: '32px',
              height: '32px',
              border: '3px solid #fff',
              borderRadius: '50%',
              borderTopColor: '#E8192C',
              animation: 'roateloading .8s infinite linear',
            }}
          ></div>
        </div>
      </div>
    </div>
  );
};
