import { FC } from 'react';
import { Redirect, Switch } from 'react-router-dom';
import NotFoundErrorPage from './NotFoundErrorPage';
import ForbiddenErrorPage from './ForbiddenErrorPage';
import { NOT_FOUND_ERROR_PAGE_PATH, FORBIDDEN_ERROR_PAGE_PATH } from '../../common/constants';
import PruRoute from '../../common/components/PruRoute';
import { GAData } from '../../common/ga/redux/gaSlice';

const errorNotFoundPageAData: GAData = {
  module: 'Error',
  feature: 'Error',
  journey: 'NA',
  stage: 'NA',
  screen_name: 'ErrorNotFoundPage',
};

const errorForbiddenPageAData: GAData = {
  module: 'Error',
  feature: 'Error',
  journey: 'NA',
  stage: 'NA',
  screen_name: 'ErrorForbiddenPage',
};

const ErrorsPage: FC = () => {
  return (
    <Switch>
      <Redirect from="/error" exact={true} to={NOT_FOUND_ERROR_PAGE_PATH} />
      <PruRoute gaData={errorNotFoundPageAData} path={NOT_FOUND_ERROR_PAGE_PATH} component={NotFoundErrorPage} />
      <PruRoute gaData={errorForbiddenPageAData} path={FORBIDDEN_ERROR_PAGE_PATH} component={ForbiddenErrorPage} />
    </Switch>
  );
};

export default ErrorsPage;
