import React, { FC } from 'react';
import { Switch, Redirect, RouteComponentProps } from 'react-router-dom';
import PruRoute from 'src/app/common/components/PruRoute';
import { NOT_FOUND_ERROR_PAGE_PATH } from 'src/app/common/constants';
import { REVIEW_WEB_FORM_PATH } from '../constants';
import { ReviewWeb } from './ReviewWeb';
import { GAData } from '../../../common/ga/redux/gaSlice';

export const reviewWebFormPath = `${REVIEW_WEB_FORM_PATH}`;

const insuranceSummaryPreviewPageGDData: GAData = {
  module: 'PRULeads',
  feature: 'InsuranceSummary',
  journey: 'isr_form',
  stage: 'preview',
  screen_name: 'InsuranceSummaryPreviewPage',
};

const insuranceSummaryFormPageGDData: GAData = {
  module: 'PRULeads',
  feature: 'InsuranceSummary',
  journey: 'isr_form',
  stage: 'view',
  screen_name: 'InsuranceSummaryFormPage',
};

const ReviewWebFormRoutes: FC<RouteComponentProps> = () => {
  return (
    <Switch>
      <PruRoute
        exact
        path={`${reviewWebFormPath}/default/preview/:language`}
        component={ReviewWeb}
        gaData={insuranceSummaryPreviewPageGDData}
      />
      <PruRoute
        exact
        path={`${reviewWebFormPath}/results/:resultId/:language`}
        component={ReviewWeb}
        gaData={insuranceSummaryFormPageGDData}
      />
      <Redirect to={NOT_FOUND_ERROR_PAGE_PATH} />
    </Switch>
  );
};

export default ReviewWebFormRoutes;
