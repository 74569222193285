import { IntlShape } from 'react-intl';
import { get } from 'lodash';
import { ModuleHierachy } from '../../common/module/types';
import { LITMOS_TITLE } from './constants';
import { apiClient, apiErrorHandler, CORE_URL } from 'src/app/common/network';
import { Dispatch } from 'react';
import { useDispatch } from 'react-redux';
import { AlertType, appendAlertItem } from '../../../redux/common/commonSlice';
import store from 'src/redux/store';

const getLitmosLoginKey = (dispatch: Dispatch<any>): Promise<any> => {
  let queryPath = `${CORE_URL}/integration/token?issuer=pruexpert`;
  return apiClient
    .get<any>(queryPath)
    .then((response) => response)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const LitmosModuleConfig = (permissions: string[], intl: IntlShape): ModuleHierachy => {
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = store.dispatch;

  const title = `${Translation('title.litmos')}  ${Translation('common.login')}`;

  /**
   * get login url with LoginKey, open this url will do the sso
   */
  const ssoLogin = async () => {
    // display loading
    dispatch(
      appendAlertItem([
        {
          severity: AlertType.INFO,
          title: title,
          content: Translation('common.loading'),
        },
      ]),
    );
    // open sso url
    getLitmosLoginKey(dispatch)
      .then((response) => {
        const urlWithLoginKey = get(response, 'data.meta.LoginKey');
        if (response?.status === 200 && urlWithLoginKey) {
          const url = `${urlWithLoginKey}&skipFirstlogin=true`;
          window.open(url as string, '_blank');
        } else {
          dispatch(
            appendAlertItem([
              {
                severity: AlertType.ERROR,
                title: Translation('common.serverError'),
                content: `${Translation('common.serviceError')}`,
              },
            ]),
          );
        }
      })
      .catch();
  };

  return {
    title: Translation(LITMOS_TITLE),
    path: '',
    icon: '/media/svg/icons/Media/Equalizer.svg',
    disableMenu: false,
    disableRoleControl: true,
    enableRead: true,
    enableCreate: true,
    enableUpdate: true,
    externalUrl: ssoLogin,
    // externalUrl: REACT_APP_LITMOS_URL,
    // child: [
    //   {
    //     title: Translation(LITMOS_TITLE),
    //     path: "",
    //     // namespace: AGENCY_CAMPAIGN_NAMESPACE,
    //     // component: AgencyCampaignRoutes,
    //     enableRead:true,
    //     enableCreate:true,
    //     enableUpdate:true,
    //     externalUrl:REACT_APP_LITMOS_URL
    //   }
    // ]
  };
};
