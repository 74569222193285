import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import Select, { components } from 'react-select';
import { useStyle } from './styles';

type ISelectProps = {
  options: any[];
  onSelect: (item: any) => void;
  initialValue?: any;
  disabled?: boolean;
};

export const defaultTextColorOptions = [{ value: '#FFFFFF', label: '#FFFFFF' }];

const TextColorSelectBox = forwardRef(({ options, onSelect, initialValue, disabled }: ISelectProps, ref: any) => {
  const { classes } = useStyle();
  const [selectedItem, setSelectedItem] = useState(initialValue ? { value: initialValue, label: initialValue } : null);

  useImperativeHandle(
    ref,
    () => {
      return {
        unselect: () => {
          setSelectedItem(null);
          onSelect(null);
        },
      };
    },
    [],
  );

  return (
    <React.Fragment>
      <Select
        value={selectedItem}
        onChange={(newValue) => {
          setSelectedItem(newValue);
          if (newValue && newValue.value) {
            onSelect(newValue.value);
          }
        }}
        isSearchable={false}
        isDisabled={disabled}
        className={`w-50`}
        closeMenuOnSelect={true}
        components={{
          Option: ({ ...props }) => (
            <components.Option {...props}>
              <div className={`${classes.contentItem}`}>
                <div
                  style={{
                    backgroundColor: props.data.value,
                    width: 20,
                    height: 20,
                    marginRight: 16,
                    border: '1px solid rgb(210, 210, 210)',
                  }}
                ></div>
                <span className={classes.optionTxt}>{props.data.value}</span>
              </div>
            </components.Option>
          ),
          Control: ({ children, ...props }) => (
            <components.Control {...props}>
              <div className={`${classes.contentItem}`}>
                {selectedItem ? (
                  <div
                    style={{
                      backgroundColor: selectedItem.value,
                      width: 20,
                      height: 20,
                      marginLeft: 12,
                      marginRight: 5,
                      border: '1px solid rgb(210, 210, 210)',
                    }}
                  ></div>
                ) : (
                  ''
                )}
                {children}
              </div>
            </components.Control>
          ),
        }}
        options={options}
        placeholder={'Please select'}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary25: 'rgba(0, 0, 0, 0.04)',
            primary: 'rgba(0, 0, 0, 0.1)',
          },
        })}
      />
    </React.Fragment>
  );
});

export default React.memo(TextColorSelectBox);
