import { useState } from 'react';
import { Dialog } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { isPhone } from 'src/app/common/constants';
import {
  FilterProps,
  FilterContentProps,
  FilterContent,
  FilterContentEnum,
} from '../../../types/dashboard-filter-types';
import MainFilter from './components/MainFilter';
import WeekFilter from './components/WeekFilter';

type FilterDialogProps<T> = {
  open: boolean;
  initialFilterContent: FilterContent;
} & FilterProps<T>;

const DIALOG_WIDTH = 512;
const PADDING = isPhone ? 30 : 36;

export const useCommonStyles = makeStyles()((theme) => ({
  paper: {
    width: DIALOG_WIDTH,
  },
  titleContainer: {
    padding: PADDING,
    paddingBottom: 24,
  },
  titleDiv: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  title: {
    fontFamily: 'Arial',
    fontSize: 24,
    fontWeight: 600,
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    fontSize: 24,
    cursor: 'pointer',
  },
  backIcon: {
    marginRight: 12,
  },
  contentContainer: {
    padding: `0 ${PADDING}px`,
    fontSize: 12,
  },
  filterContainer: {
    marginBottom: 24,
  },
  filterTitle: {
    marginBottom: 12,
  },
  optionContainer: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    columnGap: 16,
    rowGap: 12,
  },
  option: {
    padding: '4px 20px',
    border: '1px solid',
    borderRadius: 14,
    cursor: 'pointer',
  },
  default: {
    color: '#999999',
    borderColor: '#F0F0F0',
  },
  selected: {
    color: '#E8192C',
  },
  customDateContainer: {
    display: 'flex',
    alignItems: 'center',
    height: 30,
    marginTop: 16,
    fontSize: 14,
  },
  customDate: {
    borderBottom: '1px solid #F0F0F0',
    paddingRight: 5,
    cursor: 'pointer',
  },
  dateIcon: {
    marginRight: 10,
  },
  actionContainer: {
    justifyContent: 'center',
    padding: PADDING,
    paddingTop: 24,
  },
  actionDiv: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    columnGap: 20,
  },
  reset: {
    backgroundColor: '#FFFFFF',
    border: '2px solid #CCCCCC',
    borderRadius: 12,
  },
  button: {
    width: '100%',
    height: 48,
    fontSize: 16,
    fontFamily: 'Arial',
    fontWeight: 600,
  },
}));

const FilterDialog = <T extends Record<string, unknown>>({
  open,
  initialFilterContent,
  ...props
}: FilterDialogProps<T>) => {
  const { classes } = useCommonStyles();
  const [filterContent, setFilterContent] = useState<FilterContent>(initialFilterContent);

  const renderFilterContent = () => {
    const filterContentProps: FilterContentProps<T> = {
      filterContent,
      setFilterContent,
      ...props,
    };
    switch (filterContent.content) {
      case FilterContentEnum.SELECT_WEEK:
        return <WeekFilter {...filterContentProps} />;
      default:
        return <MainFilter {...filterContentProps} />;
    }
  };

  return (
    <Dialog open={open} classes={{ paper: classes.paper }}>
      {renderFilterContent()}
    </Dialog>
  );
};

export default FilterDialog;
