import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(() =>
  ({
    menuItem: {
      display: 'flex',
      flexDirection: 'row',
      height: 40,
      alignItems: 'center',
      paddingLeft: 10,
      paddingRight: 10,
      '&:hover': {
        backgroundColor: '#E7E7E7'
      }
    },

    rowContainer: {
      display: 'flex',
      alignItems: 'center',
      marginTop: 10,
      flexDirection: 'row'
    },

    searchInput: {
      height: 46,
      padding: 0,
      fontFamily: 'Poppins, Helvetica, "sans-serif"'
    },

    btnGroupWrap: { flex: 1, padding: 20, display: 'flex', flexDirection: 'row', justifyContent:'center'},
    btnGroup: { margin: '0 10px'},
    dialogWrap: { paddingLeft: 20, paddingRight:20, minHeight: 200, minWidth: 500 },
    importFormat: { border: '1px solid #ccc', padding: 10, borderRadius: 8, marginBottom: 20 }
  }));
