import { makeStyles } from 'tss-react/mui';

const useFilterStyles = makeStyles()((theme) => ({
  root: {
    padding: 20,
    borderRadius: 5,
    backgroundColor: theme.palette.common.white,
    "& .PruFilter-header-container": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      "& .PruFilter-header": {
        fontSize: "1.2rem",
        fontWeight: "bold",
        display: "flex",
        alignItems: "center",
        "&:before": {
          content: "''",
          display: "inline-block",
          height: 16,
          width: 3,
          backgroundColor: "red",
          marginRight: 10,
          marginLeft: 10,
          borderTopLeftRadius: 2,
        },
      },
    },
    "& .PruFilter-row": {
      display: "flex",
      alignItems: "center",
    },
    "& .PruFilter-criteria": {
      fontSize: "1rem",
      marginRight: 10,
      whiteSpace: "nowrap",
    },
    "& .PruFilter-date-divider": {
      "&:before": {
        content: "'-'",
        marginRight: 8,
        marginLeft: 8,
      },
    },
  },
}));

export default useFilterStyles;
