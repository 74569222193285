import {
  Button,
  Checkbox,
  IconButton,
  lighten,
  Table,
  TableBody,
  TableCell,
  TableCellProps,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';
import KeyboardArrowUpOutlined from '@mui/icons-material/KeyboardArrowUpOutlined';
import RefreshIcon from '@mui/icons-material/Refresh';
import React, { CSSProperties, Fragment, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useCommonStyles } from '../../styles/common-styles';
import { usePaginateHandler } from '../../utils/paginate-utils';
import PruTableEmptyRow from '../Table/PruTableEmptyRow';
import { PruTableHeader } from '../Table/PruTableHeader';
import PruTableLoading from '../Table/PruTableLoading';
import PruTablePaginationActions from '../Table/PruTablePaginationActions';
import { PruTableRow } from '../Table/PruTableRow';
import { Translation, TranslationWithNum } from 'src/app/i18n';

export enum PruTableSortType {
  ASC = 'asc',
  DESC = 'desc',
}

export type PruTableButtonDef = {
  color: 'inherit' | 'primary' | 'secondary';
  title: string | JSX.Element;
  onClick: () => void;
  condition?: () => boolean;
  className?: string;
};

export type PruTableOperationDef<T> = {
  title: string | JSX.Element;
  tooltipText: string;
  condition?: (row: T, index: number) => boolean;
  onClick: (row: T, index: number) => void;
  style?: CSSProperties;
};

export type PruTableBulkSelectDef<T> = {
  title: string | JSX.Element;
  variant?: 'text' | 'outlined' | 'contained';
  color: 'inherit' | 'primary' | 'secondary';
  onClick: (rows: T[]) => void;
  condition?: (rows: T[]) => boolean;
};

export type PruTableColumnDef<T> = {
  isId?: boolean;
  hidden?: boolean;
  keyIndex: keyof T | string;
  displayName: string | JSX.Element;
  sortable?: boolean;
  style?: CSSProperties;
  renderData: (row: T) => string | JSX.Element;
  onSort?: (sort: SortState) => void;
} & TableCellProps;

type PruTableProps<T extends Record<string, unknown>> = {
  title?: string;
  subTitle?: string;
  isLoading: boolean;
  disableBulkSelect?: boolean;
  bulkSelectCheckboxDisable?: (rowData: any) => boolean;
  bulkSelectDef?: PruTableBulkSelectDef<T>[];
  currentSelectedRow?: (rowData: any[]) => void;
  disableRefresh?: boolean;
  disablePagination?: boolean;
  headerBtnDef?: PruTableButtonDef[];
  operationDef: PruTableOperationDef<T>[];
  columnDef: PruTableColumnDef<T>[];
  dataSource?: T[];
  totalRecords?: number;
  totalPages?: number;
  renderChildren?: boolean;
  onRefresh: () => void;
  onChangePage?: (page: number, rowsPerPage: number) => void;
  hideListTitleRow?: boolean;
  rowOnClicked?: (rowData: any) => void;
  hideBulkSelectHeader?: boolean;
  updateSelectedRow?: any[];
  type?: string;
  defaultRowsPerPage?: number;
  defaultPageNumber?: number;
  operationSticky?: boolean;
  headerStyle?: CSSProperties;
};

const useStyles = makeStyles<void, 'checked'>()((theme, _params, classes) => ({
  table: {
    minWidth: 700,
  },
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  tableHeader: {
    width: '100%',
    padding: '20px 0 20px 0',
    display: 'flex',
    justifyContent: 'space-between',
  },
  bulkActions: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    color: theme.palette.secondary.main,
    backgroundColor: lighten(theme.palette.secondary.light, 0.85),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  sortIcon: {
    color: `${theme.palette.common.white} !important`,
  },
  headerIcon: {
    // color: `${theme.palette.common.white} !important`,
    color: `#858585 !important`,
    opacity: 1,
  },
  selectedRow: {
    backgroundColor: lighten(theme.palette.secondary.light, 0.85),
  },
  footer: {
    width: '100%',
  },
  operationContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  operationBtn: {
    color: 'blue',
    cursor: 'pointer',
    textDecoration: 'underline',
    fontSize: '0.85rem',
    whiteSpace: 'nowrap',
  },
  disabled: {
    color: '#BBBBBB',
    cursor: 'default',
  },
  pagination: {
    fontFamily: 'Poppins, Helvetica, "sans-serif"',
  },
  checked: {},
  checkboxItem: {
    width: '100%',
    borderRadius: '12px',
    [`&.${classes.checked}`]: {
      color: '#E8192C',
    },
  },
}));

type SortState = {
  [id: string]: PruTableSortType | undefined;
};

const PruTable = <T extends Record<string, unknown>>({
  title,
  subTitle,
  isLoading,
  disableRefresh,
  disableBulkSelect,
  disablePagination,
  bulkSelectCheckboxDisable,
  currentSelectedRow,
  headerBtnDef,
  bulkSelectDef,
  operationDef,
  columnDef,
  dataSource,
  totalRecords,
  totalPages,
  renderChildren,
  onRefresh,
  onChangePage,
  hideListTitleRow,
  rowOnClicked,
  hideBulkSelectHeader,
  updateSelectedRow,
  type,
  defaultRowsPerPage,
  defaultPageNumber,
  operationSticky,
  headerStyle,
}: PruTableProps<T>) => {
  const { classes } = useStyles();
  const { classes: commonClasses } = useCommonStyles();
  const intl = useIntl();
  // const TranslationWithVariable = (key: string, variable: Record<string, string | number>) =>
  //   intl.formatMessage({ id: key }, variable);

  const colCount = useMemo(() => {
    return (
      columnDef.filter((column) => !column.hidden).length +
      Number(operationDef.length > 0) +
      Number(!!!disableBulkSelect)
    );
  }, [columnDef, operationDef, disableBulkSelect]);

  const idKeyIndex = useMemo(() => {
    const idCol = columnDef.find((column) => column.isId === true);
    return idCol ? idCol.keyIndex : '';
  }, [columnDef]);

  const onUpdateSelectedRow = useEffect(() => {
    if (updateSelectedRow != rowSelected) {
      setRowSelected(updateSelectedRow ?? []);
    }
  }, [updateSelectedRow]);

  // Column Sort Handling
  const [sortState, setSortState] = useState<SortState>({});

  const toggleDirection = (direction: PruTableSortType | undefined) => {
    if (direction === PruTableSortType.ASC) {
      return PruTableSortType.DESC;
    } else if (direction === PruTableSortType.DESC) {
      return undefined;
    } else {
      return PruTableSortType.ASC;
    }
  };

  const handleSort = (column: PruTableColumnDef<T>) => {
    if (column.sortable) {
      const newSortState: SortState = {
        ...sortState,
        [column.keyIndex]: toggleDirection(sortState[column.keyIndex as string]),
      };
      setSortState(newSortState);
      if (column.onSort) {
        column.onSort(newSortState);
      }
    }
  };
  // Bulk Select Handling
  // console.log("Prutable updateSelectedRow====",updateSelectedRow)
  const [rowSelected, setRowSelected] = useState<T[]>(updateSelectedRow || []);
  const [rowOpen, setRowOpen] = useState<string[]>([]);

  const onSelectAllRow = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    // console.log('checked===', checked);
    let arrAllSelected: any[] = []; // all selected rows (all pages)
    let arrRowSelected: any[] = []; // current page selected rows
    if (checked) {
      arrRowSelected = dataSource || [];
      if (typeof bulkSelectCheckboxDisable === 'function') {
        // reomve disabled items
        arrRowSelected = arrRowSelected.filter((item) => !bulkSelectCheckboxDisable(item));
      }
      arrAllSelected = [...rowSelected, ...arrRowSelected];
    } else {
      // un-select all: remove all item in current page
      // arrAllSelected = rowSelected.filter((selectedItem) => {
      //   const curSelectedItemId = selectedItem[idKeyIndex];
      //   const found = dataSource?.some((sorceItem) => sorceItem[idKeyIndex] === curSelectedItemId);
      //   return !found;
      // });
    }
    // setRowSelected(arrAllSelected);
    if (typeof currentSelectedRow === 'function') {
      currentSelectedRow(arrAllSelected);
    }
  };

  const onSelectRow = (row: T) => {
    const foundIndex = rowSelected.findIndex((selectedItem) => selectedItem[idKeyIndex] === row[idKeyIndex]);
    if (foundIndex === -1) {
      // setRowSelected([...rowSelected, row]);
      if (currentSelectedRow) {
        currentSelectedRow([...rowSelected, row]);
      }
    } else {
      let newArr = [...rowSelected];
      newArr.splice(foundIndex, 1);
      // setRowSelected(newArr);
      if (currentSelectedRow) {
        currentSelectedRow(newArr);
      }
    }
  };

  const onOpenRow = (row: T) => {
    const foundIndex = rowOpen.findIndex((selectedItem) => selectedItem === row.id);
    if (foundIndex === -1) {
      //@ts-ignore
      setRowOpen([...rowOpen, row.id]);
    } else {
      let newArr = [...rowOpen];
      newArr.splice(foundIndex, 1);
      setRowOpen(newArr);
    }
  };

  const _renderTableRow = (isRowSelected: any, row: any, index: any, level: number = 0) => {
    const isRowOpened = !!rowOpen.find((selectedItem) => {
      return selectedItem === row.id;
    });
    return (
      <Fragment>
        <PruTableRow
          className={isRowSelected ? classes.selectedRow : ''}
          onClick={(e) => {
            if (rowOnClicked) {
              rowOnClicked(row);
            }
          }}
        >
          {!disableBulkSelect && (
            <TableCell padding="checkbox">
              <Checkbox
                onClick={() => onSelectRow(row)}
                checked={isRowSelected}
                disabled={bulkSelectCheckboxDisable ? bulkSelectCheckboxDisable(row) : false}
                classes={{
                  root: classes.checkboxItem,
                  checked: classes.checked,
                }}
              />
            </TableCell>
          )}

          {columnDef.map((column, columnIndex) => {
            return (
              !column.hidden && (
                <TableCell
                  style={column.style}
                  key={`table-row-field-${column.keyIndex as string}`}
                  align={column.align}
                >
                  {renderChildren && (
                    <Fragment>
                      {columnIndex === 0 && row.children && row.children.length > 0 && (
                        <span style={{ marginLeft: 18 * (level - 1) }}>
                          <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => {
                              onOpenRow(row);
                            }}
                          >
                            {!isRowOpened ? <AddBoxOutlinedIcon /> : <IndeterminateCheckBoxOutlinedIcon />}
                          </IconButton>
                        </span>
                      )}
                      {columnIndex === 0 && (!row.children || row.children.length === 0) && (
                        <span style={{ marginLeft: 18 * level }}></span>
                      )}
                    </Fragment>
                  )}
                  <span style={{ fontFamily: 'Poppins, Helvetica, "sans-serif"' }}>{column.renderData(row)}</span>
                </TableCell>
              )
            );
          })}
          {operationDef.length > 0 && (
            <TableCell align="center">
              <div className={classes.operationContainer}>
                {operationDef.map((operation, index) => (
                  <Fragment key={`operation-${index}`}>
                    {((operation.condition !== undefined && operation.condition(row, index)) ||
                      !operation.condition) && (
                      <Tooltip title={operation.tooltipText}>
                        <div
                          style={{
                            marginRight: operationDef[index + 1] ? 10 : 0,
                            ...operation.style,
                          }}
                          className={`${classes.operationBtn}`}
                          onClick={() => operation.onClick(row, index)}
                        >
                          {operation.title}
                        </div>
                      </Tooltip>
                    )}
                  </Fragment>
                ))}
              </div>
            </TableCell>
          )}
        </PruTableRow>
        {renderChildren &&
          isRowOpened &&
          row.children &&
          row.children.map((item: any, childrenIndex: any) =>
            _renderTableRow(isRowSelected, item, childrenIndex, level + 1),
          )}
      </Fragment>
    );
  };

  // Paginate Handling
  const { page, rowsPerPage, handleChangePage, handleChangeRowsPerPage } = usePaginateHandler(
    onChangePage,
    totalPages,
    defaultPageNumber ? defaultPageNumber : 0,
    defaultRowsPerPage ? defaultRowsPerPage : undefined,
  );

  // if current page has selected items?
  const isChecked = rowSelected.some((selectedItem) => {
    const curSelectedItemId = selectedItem[idKeyIndex];

    return dataSource?.some((sorceItem) => sorceItem[idKeyIndex] === curSelectedItemId);
  });
  return (
    <TableContainer>
      <Table className={classes.table}>
        <TableHead>
          {!hideListTitleRow && (
            <>
              <TableRow style={{ backgroundColor: 'white' }}>
                <TableCell colSpan={Number.isInteger(colCount / 2) ? colCount / 2 : colCount / 2 + 0.5}>
                  {title && (
                    <div className={commonClasses.header}>
                      {title}
                      <span
                        style={{
                          color: '#888888',
                          fontSize: '12px',
                          marginLeft: '5px',
                        }}
                      >
                        {subTitle}
                      </span>
                    </div>
                  )}
                </TableCell>
                <TableCell colSpan={Number.isInteger(colCount / 2) ? colCount / 2 : colCount / 2 - 0.5} align="right">
                  <div style={{ justifyContent: 'flex-end' }} className={classes.rowContainer}>
                    {!disableRefresh && (
                      <Tooltip title="Refresh">
                        <IconButton onClick={onRefresh}>
                          <RefreshIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                    {headerBtnDef &&
                      headerBtnDef.map(
                        (btn) =>
                          (!btn.condition || (btn.condition !== undefined && btn.condition())) && (
                            <Button
                              key={`header-button-${btn.title}`}
                              style={{ marginLeft: 15 }}
                              variant="contained"
                              color={btn.color || 'secondary'}
                              onClick={btn.onClick}
                              className={btn.className}
                            >
                              {btn.title}
                            </Button>
                          ),
                      )}
                  </div>
                </TableCell>
              </TableRow>
            </>
          )}

          {!hideBulkSelectHeader && !disableBulkSelect && rowSelected.length > 0 && (
            <TableRow>
              <TableCell padding="none" colSpan={colCount}>
                <Toolbar className={classes.bulkActions}>
                  <Typography
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                    style={{ fontFamily: 'Poppins, Helvetica, "sans-serif"' }}
                  >
                    {TranslationWithNum('prutable.rowSelected', rowSelected.length)}
                  </Typography>
                  <div>
                    {bulkSelectDef &&
                      bulkSelectDef.length > 0 &&
                      bulkSelectDef.map((action) => (
                        <Button
                          variant={action.variant}
                          disabled={
                            !((action.condition !== undefined && action.condition(rowSelected)) || !action.condition)
                          }
                          color={action.color}
                          onClick={() => {
                            action.onClick(rowSelected);
                            // setRowSelected([]);
                          }}
                        >
                          {action.title}
                        </Button>
                      ))}
                  </div>
                </Toolbar>
              </TableCell>
            </TableRow>
          )}
          <TableRow>
            {!disableBulkSelect && (
              <PruTableHeader style={headerStyle} padding="checkbox">
                <Checkbox
                  disabled={Number(dataSource?.length) <= 0}
                  icon={<CheckBoxOutlineBlankOutlinedIcon />}
                  checkedIcon={<IndeterminateCheckBoxIcon />}
                  // checked={rowSelected.length > 0}
                  checked={isChecked}
                  onChange={onSelectAllRow}
                  inputProps={{ 'aria-label': 'select all rows', style: { backgroundColor: '#FFF' } }}
                  classes={{
                    root: classes.checkboxItem,
                    checked: classes.checked,
                  }}
                  style={{ color: headerStyle?.color }}
                />
              </PruTableHeader>
            )}
            {columnDef.map(
              (column) =>
                !column.hidden && (
                  <Fragment key={`table-column-${column.keyIndex as string}`}>
                    <PruTableHeader
                      align={column.align}
                      style={
                        column.sortable
                          ? {
                              cursor: 'pointer',
                              whiteSpace: 'nowrap',
                              ...headerStyle,
                            }
                          : headerStyle
                      }
                      onClick={() => handleSort(column)}
                    >
                      {column.displayName}
                      {column.sortable && sortState[column.keyIndex as string] !== undefined && (
                        <TableSortLabel
                          classes={{
                            icon: classes.headerIcon,
                          }}
                          className={classes.sortIcon}
                          active={true}
                          direction={sortState[column.keyIndex as string]}
                          IconComponent={KeyboardArrowUpOutlined}
                        />
                      )}
                    </PruTableHeader>
                  </Fragment>
                ),
            )}
            {operationDef.length > 0 && (
              <PruTableHeader style={headerStyle} align="center">
                {Translation('section.common.operation')}
              </PruTableHeader>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          <PruTableLoading isLoading={isLoading} />
          <PruTableEmptyRow isEmpty={!!(!dataSource || (dataSource && dataSource.length <= 0))} type={type} />
          {dataSource &&
            dataSource.map((row, index) => {
              const isRowSelected = !!rowSelected.find((selectedItem) => selectedItem[idKeyIndex] === row[idKeyIndex]);
              return <Fragment key={index}>{_renderTableRow(isRowSelected, row, index)}</Fragment>;
            })}
        </TableBody>
        {!disablePagination && (
          <TableFooter>
            <TableRow>
              <TablePagination
                align="right"
                rowsPerPageOptions={[5, 10, 20, 50]}
                colSpan={colCount}
                count={totalRecords ?? 0}
                rowsPerPage={rowsPerPage}
                page={page}
                slotProps={{
                  select: {
                    inputProps: { 'aria-label': 'rows per page' },
                    native: true,
                  },
                }}
                classes={{ root: classes.pagination, selectRoot: classes.pagination }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={PruTablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        )}
      </Table>
    </TableContainer>
  );
};

export default PruTable;
