import React, { FC } from 'react';
import { Switch, Redirect, RouteComponentProps } from 'react-router-dom';
import PruRoute from '../../../common/components/PruRoute';
import { SURVEY_BASE_PATH, SURVEY_CUSTOMER_PATH } from '../constants';
import { NOT_FOUND_ERROR_PAGE_PATH } from '../../../common/constants';
import SurveySubmitPage from './Submit/SurveySubmitPage';
import CustomerSurveyPage from './Preview/CustomerSurveyPage';
import { GAData } from '../../../common/ga/redux/gaSlice';

const agentSurveyGAData: GAData = {
  module: 'Survey',
  feature: 'AgentSurvey',
  journey: 'fill_agt_survey',
  stage: 'fill_agt_survey',
  screen_name: 'AgentSurveyPage',
};

const customerSurveyPreviewGAData: GAData = {
  module: 'Survey',
  feature: 'CustomerSurvey',
  journey: 'preview_cus_survey',
  stage: 'preview',
  screen_name: 'CustomerSurveyPreviewPage',
};

const customerSurveyResultGAData: GAData = {
  module: 'Survey',
  feature: 'CustomerSurvey',
  journey: 'cus_survey_result',
  stage: 'result',
  screen_name: 'CustomerSurveyResultPage',
};

const SurveyRoutes: FC<RouteComponentProps> = () => {
  return (
    <Switch>
      <PruRoute exact path={`${SURVEY_BASE_PATH}/:surveyId`} component={SurveySubmitPage} gaData={agentSurveyGAData} />
      <PruRoute
        exact
        path={`${SURVEY_BASE_PATH}${SURVEY_CUSTOMER_PATH}/:surveyId`}
        component={CustomerSurveyPage}
        gaData={customerSurveyPreviewGAData}
      />
      <PruRoute
        exact
        path={`${SURVEY_BASE_PATH}${SURVEY_CUSTOMER_PATH}/:surveyId/result/:surveyResultId`}
        component={CustomerSurveyPage}
        gaData={customerSurveyResultGAData}
      />

      <Redirect to={NOT_FOUND_ERROR_PAGE_PATH} />
    </Switch>
  );
};

export default SurveyRoutes;
