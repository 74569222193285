import React, { FC, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import { CheckCircle } from '@mui/icons-material';
import { AGENT_PROFILE_PATH } from 'src/app/modules/AgentProfile/constants';
import Footer from 'src/app/modules/AgentProfile/common/components/Footer';
import { takeUIClickEvent } from '../../../../common/ga/ga';

const SCREEN_HEIGHT = 100;

const useStyles = makeStyles()((theme) => ({
  container: {
    backgroundColor: theme.palette.common.white,
    height: `${SCREEN_HEIGHT}vh`,
  },
  sectionContainer: {
    textAlign: 'center',
    padding: 24,
    paddingTop: 56,
  },
  checkCircle: {
    width: 72,
    height: 72,
    marginBottom: 24,
    color: '#24C791',
  },
  message: {
    fontSize: 16,
    fontWeight: 600,
    marginBottom: 8,
  },
  caption: {
    fontSize: 14,
    color: '#858585',
  },
}));

const SubmitSuccessPage: FC<RouteComponentProps> = ({ history }) => {
  const { classes } = useStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  useEffect(() => {
    if ((window as any).ReactNativeWebView) {
      (window as any).ReactNativeWebView.postMessage(
        `{ "action": "loadheaderprops", "payload": { "title": "edit_profile", "backTarget": "web" } }`,
      );
    }
  }, []);

  const handleBack = () => {
    takeUIClickEvent({
      stage: 'return_to_home',
      object_name: 'Back',
      object_type: 'BUTTON',
    });
    history.push(`${AGENT_PROFILE_PATH}/edit?noHeader`);
  };

  return (
    <div className={classes.container}>
      <div className={classes.sectionContainer}>
        <CheckCircle className={classes.checkCircle} />
        <div className={classes.message}>{Translation('agentProfile.ap_submitted_successfully_label')}</div>
        <div className={classes.caption}>{Translation('agentProfile.ap_submitted_successfully_message')}</div>
      </div>
      <Footer isBack handleClick={handleBack} />
    </div>
  );
};

export default SubmitSuccessPage;
