import React, { useContext, useMemo } from 'react';
import { Breadcrumbs, Theme, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { ModuleHierachy } from 'src/app/common/module/types';
import { ModuleContext } from 'src/app/common/module/ModuleProvider';
import { NavLink, useLocation } from 'react-router-dom';
import { HomeIcon } from 'src/app/common/components/ManagerDashboard';
import HeaderBreadcrumbsWrapper from './header-breadcrumbs-wrapper';

export default function HeaderBreadCrumbs() {
  const { classes } = useStyles();
  const { pathname } = useLocation();
  const { moduleMap } = useContext(ModuleContext);
  const menuList = useMemo(() => moduleMap.filter((module) => !module.disableMenu && module.path), [moduleMap]);

  const breadcrumbItems = useMemo(() => {
    const findBreadcrumbItems = (
      menus: ModuleHierachy[],
      curPath: string,
      breadcrumbs: ModuleHierachy[] = [],
    ): ModuleHierachy[] => {
      const menuItem = menus.find((item) => curPath.startsWith(item.path));
      if (menuItem && !menuItem.child) {
        const breadcrumb = { ...menuItem };
        return [...breadcrumbs, breadcrumb];
      }
      for (let i = 0; i < menus.length; i++) {
        const child = menus[i].child;
        if (child && child.length > 0) {
          const breadcrumbItems = findBreadcrumbItems(child, curPath, [...breadcrumbs, { ...menus[i] }]);
          if (breadcrumbItems.length > 0) {
            return breadcrumbItems;
          }
        }
      }
      return [];
    };

    return findBreadcrumbItems(menuList, pathname);
  }, [menuList, pathname]);

  return (
    <div>
      {breadcrumbItems?.length > 0 && (
        <div className={classes.wrapper}>
          <Typography color="textPrimary" className={classes.text}>
            {breadcrumbItems?.[breadcrumbItems?.length - 1].title}
          </Typography>
          <Breadcrumbs aria-label="breadcrumb" separator="·">
            <NavLink color="inherit" to="/">
              <HomeIcon />
            </NavLink>
            {breadcrumbItems?.map((item: any, index: number) => (
              <NavLink key={index} color="inherit" to={item.path}>
                {item.title}
              </NavLink>
            ))}
          </Breadcrumbs>
        </div>
      )}
    </div>
  );
}

const useStyles = makeStyles()((theme: Theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    '& a:hover': {
      color: '#3699FF !important',
    },
    [theme.breakpoints.down('md')]: {
      // padding: '10px 0',
      display: 'none',
    },
  },
  text: {
    marginRight: 20,
    fontWeight: 500,
  },
}));
