import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles<void, 'checked'>()((theme, _params, classes) => ({
  valueBox: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
  },
  value: {},
  sectionTitle: {
    fontSize: 16,
    fontWeight: 600,
    width: '20%',
    minWidth: '200px',
    paddingRight: 15,
  },
  fieldContainer: {
    width: '40%',
    minWidth: 200,
    boxSizing: 'border-box',
    marginRight: 15,
    marginBottom: 10,
  },
  field: {
    fontSize: '1rem',
    marginRight: 10,
  },
  mandatory: {
    color: 'red',
  },
  errorText: {
    fontSize: 10,
    color: '#f018a6',
    paddingLeft: 14,
  },
  checked: {},
  checkboxItem: {
    borderRadius: '12px',
    [`&.${classes.checked}`]: {
      color: '#E8192C',
    },
  },
  addBtnWrap: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    paddingLeft: 20,
    paddingRight: 20,
    marginBottom: 12,
    alignItems: 'center',
  },
  numberLimit: {
    paddingLeft: 30,
    paddingTop: 10,
    paddingBottom: 10,
    backgroundColor: '#FCE8BE',
    marginBottom: 10,
    color: '#666',
  },
  btnRemoveWrap: {
    margin: 10,
    display: 'flex',
    justifyContent: 'space-between',
  },
  btnBulkSelect: {
    margin: '0 10px 10px 10px',
    alignSelf: 'flex-start',
  },
  numberLimitList: {
    width: '50%',
    minWidth: 200,
    padding: 10,
    marginLeft: 10,
    marginBottom: 15,
    background: '#FCE8BE',
    borderRadius: 10,
    listStyle: 'inside',
    listStyleType: 'disc',
    color: '#666666',
    fontSize: 12,
  },
}));
