export const AGENCY_CAMPAIGN_TITLE = "title.agencyCampaign";
export const AGENCY_CAMPAIGN_LIST_TITLE = "title.agencyCampaign.list";
export const AGENCY_CAMPAIGN_DASHBOARD_TITLE = "title.agencyCampaign.dashboard";

export const AGENCY_CAMPAIGN_BASE_PATH = '/agencyCampaign';
export const AGENCY_CAMPAIGN_CAMPAIGN_PATH = '/campaign';
export const AGENCY_CAMPAIGN_DASHBOARD_PATH = '/dashboard';

export const AGENCY_CAMPAIGN_NAMESPACE = "AGENCY_CAMPAIGN";
export const AGENCY_CAMPAIGN_DASHBOARD_NAMESPACE = "AGENCY-CAMPAIGN-DASHBOARD";

export enum CampaignPermissionType {
  AGENCY_CAMPAIGN = "CREATE_AGENT-PORTAL_SURVEY-RESULT",
  // AGENCY_CAMPAIGN_LIST_READ="READ_ANY_CAMPAIGN_LIST",
  // AGENCY_CAMPAIGN_LIST_CREATE="CREATE_ANY_CAMPAIGN_LIST",
  // AGENCY_CAMPAIGN_LIST_UPDATE="UPDATE_ANY_CAMPAIGN_LIST",
  AGENCY_CAMPAIGN_LIST_READ = "CREATE_AGENT-PORTAL_SURVEY-RESULT",
  AGENCY_CAMPAIGN_LIST_CREATE = "CREATE_AGENT-PORTAL_SURVEY-RESULT",
  AGENCY_CAMPAIGN_LIST_UPDATE = "CREATE_AGENT-PORTAL_SURVEY-RESULT",
}

export enum AgencyCampaignTypeStatusEnum {
  PENDING = 'pendingApproval',
  APPROVED = 'approved',
  REJECTED = 'rejected',
  ACTIVE = 'active',
  COMPLETED = 'completed',
  PAUSED = "paused",
  CANCELED = 'canceled',
  DRAFT = 'draft'
}

export enum WorkflowApprovalStatusEnum {
  PENDING = 'pending',
  APPROVED = 'approved',
  REJECTED = 'rejected'
}


export const ELIGIBILITY_CHECK_BATCH_SIZE = 200;