import { OptionToDateFilterEnum } from 'src/app/common/components/ManagerDashboard';
import { DownlineItem } from './common-types';

export enum PruLeadsDetailPageTabEnum {
  PROSPECT = 'prospect',
  LEADS = 'leads',
  ENGAGEMENT = 'engagement',
  POINTS = 'points',
}

export type PruLeadsLoadingState = {
  [PruLeadsDetailPageTabEnum.PROSPECT]: boolean;
  [PruLeadsDetailPageTabEnum.LEADS]: boolean;
  [PruLeadsDetailPageTabEnum.ENGAGEMENT]: boolean;
  [PruLeadsDetailPageTabEnum.POINTS]: boolean;
};

export type PruLeadsFilterState = {
  downline?: DownlineItem;
  dateFilter: OptionToDateFilterEnum;
  dateFrom: Date;
  dateTo: Date;
};
