
/**
 *
 * @description
 * Get All parameter with {} format from a string
 *
 * @returns
 * Input: "Hello everyone, my name is {firstname}, You can also call me {mobileno}"
 * Output: ["{firstname}", "{mobileno}"]
 *
 */
export const getAllParameters = (text: String): Array<String> => text.toString().match(/{.*?}/g) || []


/**
 *
 * @description
 * Resolve parameters with {} format
 *
 * @returns
 * Input: resolveParameters("{greeting}, Welcome {firstname}"), { greeting: "Hello", firstname: "Mr. Chan Tai Man" })
 * Output: "Hello, Welcome Mr. Chan Tai Man"
 *
 */
export const resolveParameters = (text: String, translatedObject: any): string => {
  var translatedText = text.toString();

  for (const translationKey in translatedObject) {
    const translationValue = translatedObject[translationKey] || "";
    translatedText = translatedText.replace(`{${translationKey}}`, translationValue)
  }

  return translatedText;
};


/**
 *
 * @description
 * Manually mapped parameter list with data list and resolve parameters in a string
 *
 */
export const mapAllParameters = (mappedDataList: any, allParamterList: Array<String>): object => {
  var getObjectKeys = Object.entries(mappedDataList).map((item) => item[0]);
  var getObjectValues = Object.entries(mappedDataList).map((item) => item[1])

  const result = allParamterList.map((item) => {
    const key = item.replace(/[^a-zA-Z ]/g, "");
    return ({ [key]: getObjectKeys.indexOf(key) >= 0 ? getObjectValues[getObjectKeys.indexOf(key)] : item })
  }).reduce((obj, item) => {
    const key = Object.entries(item)[0][0] || ""
    const value = Object.entries(item)[0][1] || ""
    return ( obj[key] = value, obj )
  }, {});

  return result || {}
}

/**
 *
 * @description
 * Translation All Parameter with API Data in a string (Without override)
 *
 */
export const translateAllParameters = (mappedDataList: any, data: string) => {
  var translationString: string = data || ""
  if (mappedDataList.length == 0) return translationString

  for (let i = 0; i < 5; i++) {
    const getAllParameterList = getAllParameters(translationString) || [];
    if (getAllParameterList.length == 0) break;
    if (getAllParameterList.length > 0) {
      translationString = resolveParameters(translationString, mapAllParameters(mappedDataList, getAllParameterList));
    }
  }

  return translationString
}