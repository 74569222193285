import React, { FC } from 'react';
import { makeStyles } from 'tss-react/mui';
import { CheckCircle, RadioButtonUnchecked, CancelOutlined } from '@mui/icons-material';
import { toAbsoluteUrl } from 'src/app/common/utils';

type ProgressBarItemProps = {
  disableLine: boolean;
  isFinished?: boolean;
  isCanceled?: boolean;
  isDefault?: boolean;
  isHead?: boolean;
}

const useStyles = makeStyles()((theme) => ({
  progressBarContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    marginRight: 15,
  },
  line: {
    width: 4,
    height: '100%',
    position: 'absolute',
    top: 9,
  },
  red: {
    backgroundColor: '#E8192C',
  },
  grey: {
    backgroundColor: '#EDEDEE',
  },
  iconContainer: {
    position: 'relative',
    zIndex: 1,
  },
  iconBackground: {
    position: 'absolute',
    width: 14,
    height: 14,
    top: 3,
    left: 2,
    borderRadius: 14,
    backgroundColor: theme.palette.common.white,
    zIndex: 2,
  },
  icon: {
    color: '#E8192C',
    position: 'relative',
    zIndex: 3,
  },
}));

const ProgressBarItem: FC<ProgressBarItemProps> = ({ isFinished, disableLine, isCanceled, isDefault, isHead}) => {
  const { classes } = useStyles();
  const currentImg = toAbsoluteUrl("/media/icon/progressBar/progress_current.png");
  const comingImg = toAbsoluteUrl("/media/icon/progressBar/progress_future.png");
  return (
    <div className={classes.progressBarContainer}>
      <div className={classes.iconContainer}>
        {isFinished ? (
          <CheckCircle className={classes.icon} />
        ) : isCanceled ? (
          <CancelOutlined className={classes.icon} />
        ) : isDefault ?
          <div className={classes.icon}>
            <img style={{ height: '14px', width: '14px' }} alt="bulletpoint" src={isHead ? currentImg : comingImg} />
          </div>
        :(
          <RadioButtonUnchecked className={classes.icon} />
        )}
        <div className={classes.iconBackground} />
      </div>
      {!disableLine && (
        <div
          className={`
            ${classes.line}
            ${isFinished || isCanceled ? classes.red : classes.grey}
          `}
        />
      )}
    </div>
  );
}

export default ProgressBarItem;