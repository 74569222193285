import { Button, InputBase, TextField } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { isNumber } from 'lodash';
import * as React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { reverseNum } from '../../../constants';
import { saveSummaryCommentsData } from '../../../network/crud';
import {
  chartOptions,
  commentsObject,
  dataType,
  defaultCommentaryType,
  pageType,
  policySourceType,
} from '../../../types/chart-types';
import { DashboardListingPage } from '../../Dashboard/DashboardListingPage';
import { DashboardPreViewPage } from '../../Dashboard/DashboardPreviewPage';

type SummaryDetailPageProps = {
  type: pageType;
  options: chartOptions;
  data: dataType;
};

type categoryName = 'life' | 'medical' | 'criticalillness' | 'savings' | 'others';

export const SummaryDetailPage: React.FC<SummaryDetailPageProps> = ({ type, options, data }) => {
  const { classes } = styles();
  const intl = useIntl();
  const dispatch = useDispatch();
  const Translation = (id: string) => intl.formatMessage({ id });
  const [saveButton, setSaveButton] = React.useState<boolean>(true);

  const [defaultCommentaryNums, setCommentaryNums] = React.useState<defaultCommentaryType>({
    Cat: [
      Translation('summary.dashboard.commentary.title.life'),
      Translation('summary.dashboard.commentary.title.medical'),
      Translation('summary.dashboard.commentary.title.criticalillness'),
      Translation('summary.dashboard.commentary.title.savings'),
      Translation('summary.dashboard.commentary.title.others'),
    ],
    totalSumAssuredCat: [
      options.tabData[1].life.total,
      options.tabData[1].medical.total,
      options.tabData[1].criticalIllness.total,
      options.tabData[1].savings.total,
      options.tabData[1].others.total,
    ],
    totalAnnualPremiumCat: [
      options.tabData[0].life.total,
      options.tabData[0].medical.total,
      options.tabData[0].criticalIllness.total,
      options.tabData[0].savings.total,
      options.tabData[0].others.total,
    ],
    totalAccidentalDeathBenefitCat: [
      options.accidentalDeathBenefit.life.totalAccidentalDeathBenefit,
      options.accidentalDeathBenefit.medical.totalAccidentalDeathBenefit,
      options.accidentalDeathBenefit.criticalIllness.totalAccidentalDeathBenefit,
      options.accidentalDeathBenefit.savings.totalAccidentalDeathBenefit,
      options.accidentalDeathBenefit.others.totalAccidentalDeathBenefit,
    ],
    minBenefitTermCat: [
      options.benefitTerm.life.minBenefitTerm,
      options.benefitTerm.medical.minBenefitTerm,
      options.benefitTerm.criticalIllness.minBenefitTerm,
      options.benefitTerm.savings.minBenefitTerm,
      options.benefitTerm.others.minBenefitTerm,
    ],
    maxBenefitTermCat: [
      options.benefitTerm.life.maxBenefitTerm,
      options.benefitTerm.medical.maxBenefitTerm,
      options.benefitTerm.criticalIllness.maxBenefitTerm,
      options.benefitTerm.savings.maxBenefitTerm,
      options.benefitTerm.others.maxBenefitTerm,
    ],
    policyNosCat: [
      options.tabData[2].life.total,
      options.tabData[2].medical.total,
      options.tabData[2].criticalIllness.total,
      options.tabData[2].savings.total,
      options.tabData[2].others.total,
    ],
  });

  const names: Array<categoryName> = ['life', 'medical', 'criticalillness', 'savings', 'others'];

  let initComments = {
    life: '',
    medical: '',
    criticalillness: '',
    savings: '',
    others: '',
  };

  let defaultInitCommentary = {
    life: '',
    medical: '',
    criticalillness: '',
    savings: '',
    others: '',
  };

  let initRecommendation = '';

  if (options?.dashboardComments) {
    type commentaryNumsType = keyof typeof defaultCommentaryNums;
    options.dashboardComments.forEach((comment: commentsObject) => {
      if (comment.commentCategory === 'recommendations') {
        initRecommendation = comment.commentContent;
      } else {
        if (comment.commentCategory === 'criticalIllness') {
          comment.commentCategory = 'criticalillness';
        }
        if (comment.commentary) {
          //match 符合{xxxx0}类型的字符串
          var tmpArr = comment.commentary.match(/{[A-Za-z]+[0-9]}/g);
          if (tmpArr) {
            for (var i = 0; i < tmpArr.length; i++) {
              var index = Number(tmpArr[i].substring(tmpArr[i].length - 2, tmpArr[i].length - 1)) - 1;
              if (!isNaN(index)) {
                const cat = defaultCommentaryNums[tmpArr[i].substring(1, tmpArr[i].length - 2) as commentaryNumsType];
                if (cat) {
                  const targetNum = isNumber(cat?.[index])
                    ? reverseNum(cat?.[index] as number)
                    : cat?.[index]?.toString();
                  comment.commentary = comment.commentary?.replace(tmpArr[i], targetNum);
                }
              }
            }
          }
        }
        initComments[comment.commentCategory as categoryName] = comment.commentContent ?? '';
        defaultInitCommentary[comment.commentCategory as categoryName] = comment.commentary ? comment.commentary : '';
      }
    });
  }

  const [getDashboardComments, setDashboardComments] = React.useState<commentsObject[]>(() => {
    let commentsArray: commentsObject[] = [];
    for (const key in initComments) {
      commentsArray.push({
        commentCategory: key === 'criticalillness' ? 'criticalIllness' : key,
        commentContent: initComments[key as categoryName],
      });
    }
    commentsArray.push({ commentCategory: 'recommendations', commentContent: initRecommendation });
    console.log(commentsArray);
    return commentsArray;
  });

  const [recommendation, setRecommendation] = React.useState<string>(initRecommendation);
  const [comments, setComments] = React.useState(initComments);
  const [defaultCommentary, setDefaultCommentary] = React.useState(defaultInitCommentary);

  const saveApi = async (tmpComments: commentsObject[]) => {
    await saveSummaryCommentsData(data.resultId, { comments: tmpComments }, dispatch)
      .then((result: any) => {
        setDashboardComments(tmpComments);
        console.log(result);
      })
      .catch((error) => console.log(error));
  };

  const saveCommentsData = (saveType: boolean) => {
    let tmpComments: commentsObject[] = getDashboardComments;

    tmpComments.forEach((comment) => {
      if (comment.commentCategory === 'recommendations') {
        comment.commentContent = recommendation;
      } else {
        comment.commentContent =
          comments[
            comment.commentCategory === 'criticalIllness'
              ? 'criticalillness'
              : (comment.commentCategory as categoryName)
          ];
      }
    });

    saveApi(tmpComments);
    console.log(tmpComments);
  };

  const PolicySource = () => {
    const policies: policySourceType[] | undefined = options.policySource;

    return (
      <div>
        {policies?.map((item: policySourceType, index: number) => (
          <div className={classes.policyView} style={data.isExport ? { paddingInline: 30 } : {}}>
            {index !== 0 && index % 6 === 0 && <div className="pageBreak"></div>}
            <div className={classes.table}>
              <div className={classes.policyEachItem}>
                <div className={classes.policyTitlePoint}></div>
                <div className={classes.policyTitle}>{item.name}</div>
                <div className={classes.policyBlue}>{item.type}</div>
              </div>
              <div className={classes.policyEachItem}>
                <div className={classes.itemName}>{Translation('summary.dashboard.policySource.policyNumer')}</div>
                <div style={{ textAlign: 'right' }}>{item.policyNumber}</div>
              </div>
              <div className={classes.policyEachItem}>
                <div className={classes.itemName}>{Translation('summary.dashboard.policySource.insuredCompany')}</div>
                <div style={{ textAlign: 'right' }}>{item.insuredCompany}</div>
              </div>
              <div className={classes.policyEachItem}>
                <div className={classes.itemName}>{Translation('summary.dashboard.policySource.policyCategory')}</div>
                <div style={{ textAlign: 'right' }}>{item.policyCategory}</div>
              </div>
              <div className={classes.policyEachItem}>
                <div className={classes.itemName}>{Translation('summary.dashboard.policySource.policyType')}</div>
                <div style={{ textAlign: 'right' }}>{item.policyType}</div>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  let text: any = undefined;

  React.useEffect(() => {
    let flag = false;
    for (const comment in comments) {
      if (comments[comment as categoryName].length > 200) {
        flag = true;
      }
    }
    setSaveButton(flag);
  }, [comments]);

  return (
    <div
      className="kt_body"
      style={{
        fontFamily: 'sans-serif',
        fontSize: 14,
        fontWeight: 500,
        color: '#333333',
        backgroundColor: '#FFFFFF',
      }}
    >
      {type === 'default' && <DashboardListingPage options={options} />}
      {type === 'preview' && <DashboardPreViewPage options={options} isExport={data.isExport ?? false} />}
      <div className="pageBreak"></div>
      {data.isCommentary && (
        <div>
          <div
            className={classes.pageTitle}
            style={
              data.isExport
                ? { paddingInline: 35, display: 'flex', flexDirection: 'row' }
                : { display: 'flex', flexDirection: 'row' }
            }
          >
            <div style={{ flex: 'auto' }}>{Translation('summary.dashboard.page.tilte.commentary')}</div>
            {type === 'default' && (
              <Button
                className="save-commentary-button"
                color="inherit"
                style={{
                  backgroundColor: '#FFFFFF',
                  border: '2px solid #F0F0F0',
                  paddingBlock: 3,
                  paddingInline: 28,
                  fontSize: 14,
                  fontFamily: 'sans-serif',
                  alignSelf: 'center',
                  borderRadius: 8,
                }}
                disabled={saveButton}
                onClick={() => saveCommentsData(true)}
              >
                {Translation('summary.dashboard.commentary.save')}
              </Button>
            )}
          </div>
          <div>
            {names.map((item, index) => (
              <div className="commentary-table" style={data.isExport ? { paddingInline: 40 } : {}}>
                <div className="bar-chart-title">
                  <div className={'bar-chart-' + item}> </div>
                  <div className="bar-chart-span">{Translation('summary.dashboard.commentary.title.' + item)}</div>
                </div>
                {defaultCommentary[item] && (
                  <div className="comment-content">
                    {defaultCommentary[item]
                      .split('<br/>')
                      .map((string) => string && <div className={classes.contentView}>{string}</div>)}
                  </div>
                )}
                {((type === 'preview' && comments[item]) || type === 'default') && (
                  <div className="comment-input" style={{ marginTop: 12 }}>
                    <div>
                      <div style={{ fontSize: 12, fontWeight: 500 }}>
                        {Translation('summary.dashboard.page.tilte.others.comments')}
                      </div>

                      <InputBase
                        style={
                          type === 'preview'
                            ? {
                                marginBlock: 8,
                                fontFamily: 'sans-serif',
                                backgroundColor: '#FFFFFF',
                                borderRadius: 10,
                                paddingInline: 14,
                              }
                            : {
                                marginBlock: 8,
                                fontFamily: 'sans-serif',
                                backgroundColor: '#F0F0F0',
                                borderRadius: 10,
                                paddingInline: 14,
                              }
                        }
                        readOnly={type === 'preview'}
                        fullWidth
                        multiline={true}
                        rows={4}
                        defaultValue={comments[item] ?? ''}
                        onChange={(e) => {
                          let tmpJson = JSON.parse(JSON.stringify(comments));
                          tmpJson[item] = e.target.value;
                          setComments(tmpJson);
                        }}
                      />
                    </div>

                    {type === 'default' && (
                      <span
                        className="comment-description"
                        style={
                          200 - comments[item].length >= 0
                            ? { fontSize: 12, color: '#858585', marginTop: 4 }
                            : { fontSize: 12, color: 'red', marginTop: 4 }
                        }
                      >
                        {intl.locale !== 'en' ? Translation('summary.dashboard.commentary.limit.content.start') : ''}
                        {200 - comments[item].length} {Translation('summary.dashboard.commentary.limit.content.end')}
                      </span>
                    )}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      )}
      {data.isRecommendations && (
        <div>
          <div
            className={classes.pageTitle}
            style={
              data.isExport
                ? { paddingInline: 30, display: 'flex', flexDirection: 'row' }
                : { display: 'flex', flexDirection: 'row' }
            }
          >
            <div style={{ flex: 'auto' }}>{Translation('summary.dashboard.page.tilte.recommendations')}</div>
            {type === 'default' && (
              <Button
                className="save-commentary-button"
                color="inherit"
                style={{
                  backgroundColor: '#FFFFFF',
                  border: '2px solid #F0F0F0',
                  paddingBlock: 3,
                  paddingInline: 28,
                  fontSize: 14,
                  fontFamily: 'sans-serif',
                  alignSelf: 'center',
                  borderRadius: 8,
                }}
                disabled={recommendation.length > 1000}
                onClick={() => saveCommentsData(false)}
              >
                {Translation('summary.dashboard.commentary.save')}
              </Button>
            )}
          </div>
          <div className={classes.recommendationView} style={data.isExport ? { paddingInline: 35 } : {}}>
            {type === 'preview' && recommendation ? (
              <div
                style={{
                  marginTop: 8,
                  fontFamily: 'sans-serif',
                  borderRadius: 10,
                  width: '100%',
                }}
              >
                <div className="MuiInputBase-input MuiInputBase-inputMultiline" style={{ wordBreak: 'break-all' }}>
                  {recommendation}
                </div>
              </div>
            ) : (
              <div>
                <InputBase
                  style={{
                    fontFamily: 'sans-serif',
                    backgroundColor: '#F0F0F0',
                    borderRadius: 10,
                    marginTop: 16,
                    paddingInline: 14,
                    marginBottom: 4,
                  }}
                  fullWidth
                  multiline={true}
                  rows={6}
                  readOnly={type === 'preview'}
                  defaultValue={recommendation ?? text}
                  onChange={(e) => {
                    text = e.target.value;
                    setRecommendation(e.target.value);
                  }}
                />
                {type === 'default' && (
                  <span
                    className="comment-description"
                    style={
                      1000 - recommendation.length >= 0
                        ? { fontSize: 12, color: '#858585' }
                        : { fontSize: 12, color: 'red' }
                    }
                  >
                    {intl.locale !== 'en' ? Translation('summary.dashboard.commentary.limit.content.start') : ''}
                    {1000 - recommendation.length} {Translation('summary.dashboard.commentary.limit.content.end')}
                  </span>
                )}
              </div>
            )}
          </div>
        </div>
      )}
      {type === 'preview' && (
        <div>
          <div className="pageBreak"></div>
          <div className={classes.pageTitle} style={data.isExport ? { paddingInline: 35 } : {}}>
            {Translation('summary.dashboard.page.tilte.policySource')}
          </div>
          <PolicySource />
          <div className="pageBreak"></div>
          <div className={classes.pageTitle} style={data.isExport ? { paddingInline: 35 } : {}}>
            {Translation('summary.dashboard.page.tilte.disclaimer')}
          </div>
          <div style={{ paddingInline: 30, paddingBlock: 16, fontSize: 12 }}>
            <div style={data.isExport ? { paddingInline: 30 } : {}}>
              {options.disclaimer.split('\n').map((item) => (
                <div style={{ display: 'list-item' }}>{item}</div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const styles = makeStyles()(() => ({
  pageTitle: {
    height: 56,
    padding: 16,
    fontSize: 16,
    fontWeight: 600,
    backgroundColor: '#F4F4F4',
  },
  contentView: {
    paddingInline: 5,
    fontSize: 12,
    marginLeft: 15,
    fontWeight: 400,
    display: 'list-item',
  },
  recommendationView: {
    paddingInline: 20,
    paddingTop: 12,
    paddingBottom: 20,
    backgroundColor: '#FFFFFF',
  },
  policyView: {
    backgroundColor: '#FFFFFF',
    padding: 10,
    paddingBottom: 0,
  },
  policyTitlePoint: {
    width: 4,
    height: 18,
    backgroundColor: '#E1001F',
    alignSelf: 'center',
  },
  policyEachItem: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    paddingBlock: 10,
  },
  policyTitle: {
    fontSize: 16,
    fontWeight: 600,
    flex: 'auto',
    marginLeft: 14,
  },
  policyBlue: {
    backgroundColor: '#E0FBFF',
    color: '#24B1C7',
    fontSize: 12,
    alignSelf: 'center',
    paddingBlock: 4,
    paddingInline: 8,
    minWidth: 'max-content',
  },
  itemName: {
    color: '#858585',
    flex: 'auto',
    marginLeft: 18,
  },
  table: {
    marginInline: 5,
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 10,
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: '#F0F0F0',
  },
}));
