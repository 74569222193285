import React, { FC } from 'react';
import { Button } from '@mui/material';
import { useIntl } from 'react-intl';
import { ProtectionReviewActivityEnum } from '../../types/type';

type RemiderProps = {
  isShowReminder: boolean;
  handleError: () => void;
  submitSurvey: () => Promise<void>;
  cancelSubmit: () => void;
  status: ProtectionReviewActivityEnum;
};

export const Reminder: FC<RemiderProps> = ({ isShowReminder, handleError, submitSurvey, cancelSubmit, status }) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const display = isShowReminder ? 'flex' : 'none';

  return (
    <>
      <div style={{ display: display }}>
        <div
          style={{
            position: 'fixed',
            zIndex: 1300,
            inset: 0,
            width: '100%',
            alignItems: 'center',
            background: 'rgba(51, 51, 51, 0.4)',
          }}
        >
          <div
            style={{
              opacity: 1,
              transition: 'opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
              height: '100%',
              outline: 0,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <div
              style={{
                margin: '32px',
                position: 'relative',
                overflowY: 'auto',
                display: 'flex',
                maxHeight: 'calc(100%-64px)',
                flexDirection: 'column',
                boxShadow:
                  '0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%)',
                borderRadius: '16px',
              }}
            >
              <div
                style={{
                  padding: 20,
                  paddingTop: 36,
                  alignItems: 'center',
                  fontFamily: 'Arial',
                  background: 'white',
                  flexDirection: 'column',
                }}
              >
                <div style={{ width: '100%', padding: 'unset', fontWeight: 600, fontSize: 24, textAlign: 'center' }}>
                  {Translation('reviewWebForm.dialog.title')}
                </div>
                <div style={{ marginBottom: 10, marginTop: 10, fontSize: 14, fontWeight: 500 }}>
                  {Translation('reviewWebForm.dialog.contentText')}
                </div>
                <Button
                  id="custom-complete"
                  className="sd-navigation__complete-btn"
                  color="inherit"
                  style={{
                    marginTop: 12,
                    width: '100%',
                    height: 48,
                    fontSize: 16,
                    fontWeight: 600,
                    borderRadius: 12,
                    display: 'initial',
                    backgroundColor: '#E8192C',
                    borderWidth: 0,
                  }}
                  onClick={handleError}
                >
                  {Translation('reviewWebForm.dialog.ok')}
                </Button>
                {status !== ProtectionReviewActivityEnum.FormSubmitted ? (
                  <Button
                    color="inherit"
                    style={{
                      marginTop: 12,
                      width: '100%',
                      height: 48,
                      fontSize: 16,
                      fontWeight: 600,
                      borderRadius: 12,
                      backgroundColor: '#FFFFFF',
                      color: '#333333',
                      borderWidth: 2,
                      borderColor: '#CCCCCC',
                    }}
                    onClick={submitSurvey}
                  >
                    {Translation('reviewWebForm.dialog.submit')}
                  </Button>
                ) : (
                  <Button
                    color="inherit"
                    style={{
                      marginTop: 12,
                      width: '100%',
                      height: 48,
                      fontSize: 16,
                      fontWeight: 600,
                      borderRadius: 12,
                      backgroundColor: '#FFFFFF',
                      color: '#333333',
                      borderWidth: 2,
                      borderColor: '#CCCCCC',
                    }}
                    onClick={cancelSubmit}
                  >
                    {Translation('reviewWebForm.dialog.cancel')}
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
