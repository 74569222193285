import React, { FC, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { NOT_FOUND_ERROR_PAGE_PATH } from 'src/app/common/constants';
import { useLang } from 'src/app/i18n';
import { LayoutSplashScreen } from 'src/app/layout';
import { AgentProfileItem, AwardItem } from 'src/app/modules/AgentProfile/types/agent-profile-types';
import { moveSelectedAwardsToTop } from 'src/app/modules/AgentProfile/common/utils/list-utils';
import {
  localeMapToBackend,
  localeMapToPromotionBackend,
} from 'src/app/modules/AgentProfile/common/utils/locale-utils';
import { fetchAgentProfile, getAgentAwards } from 'src/app/modules/AgentProfile/network/agentProfileCrud';
import AgentProfileEditMainForm from 'src/app/modules/AgentProfile/pages/Edit/components/AgentProfileEditMainForm';

const AgentProfileEditMainPage: FC<RouteComponentProps> = ({ history }) => {
  const dispatch = useDispatch();
  const lang = useLang();
  const mappedLocale = localeMapToBackend[lang] || lang;
  const promotionMappedLocale = localeMapToPromotionBackend[lang] || lang;

  const [agentProfile, setAgentProfile] = useState<AgentProfileItem>();
  const [awardList, setAwardList] = useState<AwardItem[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const reloadData = async () => {
    setIsLoading(true);
    try {
      const res = await fetchAgentProfile(mappedLocale, dispatch);
      setAgentProfile(res);
      const awardListRes = await getAgentAwards(mappedLocale, dispatch);
      setAwardList(moveSelectedAwardsToTop(res.awards, awardListRes.awards));
    } catch (err) {
      history.push(NOT_FOUND_ERROR_PAGE_PATH);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if ((window as any).ReactNativeWebView) {
      (window as any).ReactNativeWebView.postMessage(
        `{ "action": "loadheaderprops", "payload": { "title": "profilePage", "backTarget": "app" } }`,
      );
    }
  }, []);

  useEffect(() => {
    reloadData();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {isLoading || !agentProfile ? (
        <LayoutSplashScreen />
      ) : (
        <AgentProfileEditMainForm
          agentProfile={agentProfile}
          awardList={awardList}
          mappedLocale={mappedLocale}
          promotionMappedLocale={promotionMappedLocale}
        />
      )}
    </>
  );
};

export default AgentProfileEditMainPage;
