import { ResponsiveRadialBar } from '@nivo/radial-bar';
import { makeStyles } from 'tss-react/mui';
import { Translation } from 'src/app/i18n';
import { SingleRadialBarProps } from '../../types/dashboard-chart-types';

const useStyles = makeStyles()((theme) => ({
  chartContainer: {
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    width: 120,
    height: 120,
    fontFamily: 'Arial',
  },
  totalContainer: {
    position: 'absolute',
    top: '28%',
    left: '25%',
    textAlign: 'center',
  },
  totalLabel: {
    color: '#737373',
    fontSize: 12,
  },
  totalValue: {
    color: '#000000',
    fontSize: 30,
    fontWeight: 700,
    fontFamily: 'Montserrat',
  },
}));

const SingleRadialBar = <T extends Record<string, number>>({
  data,
  keys,
  totalKey,
  colors,
  disableTotal,
  totalDisplayName,
  chartContainerStyle,
  totalContainerStyle,
}: SingleRadialBarProps<T>) => {
  const { classes } = useStyles();
  const values = [
    {
      id: 'data',
      data: keys.map((key) => ({ x: key, y: data[key] })),
    },
  ];
  const totalValue = data[totalKey];

  return (
    <div className={classes.chartContainer} style={chartContainerStyle}>
      <ResponsiveRadialBar
        data={values}
        colors={colors}
        startAngle={0}
        endAngle={360}
        innerRadius={0.8}
        cornerRadius={10}
        tracksColor={colors[colors.length - 1]}
        animate={false}
        isInteractive={false}
        enableRadialGrid={false}
        enableCircularGrid={false}
        radialAxisStart={null}
        circularAxisOuter={null}
      />
      {!disableTotal && (
        <div className={classes.totalContainer} style={totalContainerStyle}>
          <div className={classes.totalLabel}>{totalDisplayName || Translation('managerDashboard.common.total')}</div>
          <div className={classes.totalValue}>{totalValue}</div>
        </div>
      )}
    </div>
  );
};

export default SingleRadialBar;
