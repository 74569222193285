export enum LangType {
  enUs = 'enUs',
  zhHk = 'zhHk',
}

export enum PromotionLangType {
  en = "en",
  zh = "zh-Hant",
}

export const localeMapToBackend: any = {
  en: LangType.enUs,
  'zh-Hant': LangType.zhHk,
};

export const localeMapToPromotionBackend: any = {
  en: PromotionLangType.en,
  'zh-Hant': PromotionLangType.zh,
};
