import { FC } from 'react';
import { useIntl } from 'react-intl';
import { Button } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { isPhone } from 'src/app/common/constants';

type FooterProps = {
  isBack?: boolean;
  disableClick?: boolean;
  handleClick: () => void;
};

const BUTTON_HEIGHT = 48;
const BODY_FOOTER_SPACING = 16;
const FOOTER_BOTTOM_SPACING = 16;
const FOOTER_SIDE_PADDING = 24;
export const FOOTER_CONTAINER_HEIGHT = BUTTON_HEIGHT + BODY_FOOTER_SPACING + FOOTER_BOTTOM_SPACING;

const useStyles = makeStyles()((theme) => ({
  footerContainer: {
    height: FOOTER_CONTAINER_HEIGHT,
    width: '100%',
    textAlign: 'center',
    position: 'absolute',
    left: 0,
    bottom: 0,
    padding: `${BODY_FOOTER_SPACING}px ${FOOTER_SIDE_PADDING}px 0`,
  },
  button: {
    height: BUTTON_HEIGHT,
    width: isPhone ? '100%' : 382,
    fontSize: 16,
    fontFamily: 'Arial',
  },
  backButton: {
    backgroundColor: 'white',
  },
}));

const Footer: FC<FooterProps> = ({ isBack, disableClick, handleClick }) => {
  const { classes } = useStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  return (
    <div className={classes.footerContainer}>
      {!isBack ? (
        <Button
          variant="contained"
          color="secondary"
          className={classes.button}
          disabled={disableClick}
          onClick={handleClick}
        >
          {Translation('app.button.save')}
        </Button>
      ) : (
        <Button variant="contained" color="inherit" className={`${classes.button} ${classes.backButton}`} onClick={handleClick}>
          {Translation('app.button.back')}
        </Button>
      )}
    </div>
  );
};

export default Footer;
