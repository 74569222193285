import moment from 'moment';
import { EModuleTypes, LibraryPublishedMode } from '../pages/SalesMaterial/constants';
import { LibraryItem } from '../pages/SalesMaterial/types';

export const isSaleModule = (moduleName: string) => {
  return moduleName === EModuleTypes.Sale;
};

export const renderDisplayStatusField = (rowData: LibraryItem): string => {
  if (rowData.publishedAt) {
    if (rowData.publishToSetOnly) {
      return 'component.status.publishedToSetOnly';
    } else {
      return 'component.status.published';
    }
  } else {
    if (rowData.lastPublishTime) {
      return 'component.status.unpublished';
    } else {
      return 'component.status.draft';
    }
  }
};

export const getType = (type: string) => {
  const ref = {
    file: 'File',
    materialSet: 'Material Set',
    content: 'Content',
    link: 'Link',
    text: 'Text',
    picture: 'Picture',
  };
  return ref[type as keyof typeof ref] || '';
};

export const getDefaultDisplayDate = (date: Date, formatKey: string = 'date') => {
  const defaultFormat = 'DD/MM/YYYY HH:mm';

  return moment(date).format(defaultFormat);
};

export const filterQueryForPublish = (isActiveParam = '', queryPath: string): string => {
  let extendQuery = '';

  if (isActiveParam !== '' && isActiveParam !== LibraryPublishedMode.ALL) {
    // extendQuery = `status_eq=${isActiveParam}&`;
    extendQuery = `filters[status][$eq]=${isActiveParam}&`;
  }

  return extendQuery;
};

const objectsEqualOneLevel = (object1: Record<string, any>, object2: Record<string, any>) =>
  Object.keys(object1).length === Object.keys(object2).length &&
  Object.keys(object1).every((key: string) => object1[key] === object2[key]);

export const arraysEqual = (array1: any[], array2: any[]) =>
  array1.length === array2.length && array1.every((item, index) => objectsEqualOneLevel(item, array2[index]));
