import { FC, PropsWithChildren } from 'react';
import { IntlProvider } from 'react-intl';
import { useLang } from './I18nConfig';
import { vi, en, zhHans, zhHant, id } from './translations';

const allMessages: Record<string, Record<string, string>> = {
  en,
  'zh-Hans': zhHans,
  'zh-Hant': zhHant,
  id,
  vi,
};

export const I18nProvider: FC<PropsWithChildren> = ({ children }) => {
  const locale = useLang();
  const messages = allMessages[locale];

  return (
    <IntlProvider locale={locale} messages={messages}>
      {children}
    </IntlProvider>
  );
};
