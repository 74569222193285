import { useState, Fragment } from 'react';
import { TableContainer, Table, TableHead, TableBody, TableRow, TableFooter } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Translation } from 'src/app/i18n';
import { DashboardTableProps, DashboardTableColumnDef } from '../types/dashboard-table-types';
import { flattenColumnDef } from '../utils/dashboard-common-utils';
import DashboardTableHead from './components/DashboardTableHead';
import DashboardTableCell from './components/DashboardTableCell';

type RenderRowProps<T> = {
  row: T;
  rowIndex?: number;
  isTotal?: boolean;
};

type RenderCellProps<T> = {
  column: DashboardTableColumnDef<T>;
  columnIndex: number;
  className: string;
} & RenderRowProps<T>;

const useStyles = makeStyles()((theme) => ({
  container: {
    zIndex: 0,
  },
  cell: {
    fontSize: 16,
    fontWeight: 500,
    fontFamily: 'Arial',
    color: '#000000',
    backgroundColor: '#FFFFFF',
  },
  clickableCell: {
    cursor: 'pointer',
    borderBottom: '1px solid #999999',
    paddingBottom: 4,
  },
  totalCell: {
    left: 'auto',
  },
}));

const DashboardTable = <T extends Record<string, unknown>>({
  columnDef,
  multiRowHeader,
  dataSource,
  totalData,
  totalCellTop,
  style,
  ...props
}: DashboardTableProps<T>) => {
  const { classes } = useStyles();
  const [columnDefForData, _] = useState<DashboardTableColumnDef<T>[]>(() =>
    flattenColumnDef(columnDef, multiRowHeader),
  );

  const renderCell = ({ row, rowIndex, isTotal, column, columnIndex, className }: RenderCellProps<T>) => {
    if (column.renderData) {
      let cellStyle = typeof column.cellStyle === 'function' ? column.cellStyle(row) : column.cellStyle;
      if (isTotal) {
        cellStyle = { ...cellStyle, top: `${totalCellTop || 0}px` };
      }
      return (
        <DashboardTableCell
          key={`table-row-${rowIndex}-cell-${column.keyIndex}`}
          className={className}
          align={column.align}
          style={cellStyle}
        >
          <span
            className={!isTotal && column.onClickCell ? classes.clickableCell : undefined}
            onClick={() => !isTotal && column.onClickCell && column.onClickCell(row)}
          >
            {columnIndex === 0 && isTotal ? Translation('managerDashboard.common.total') : column.renderData(row)}
          </span>
        </DashboardTableCell>
      );
    }
  };

  const renderRow = ({ row, rowIndex, isTotal }: RenderRowProps<T>) => {
    return (
      <Fragment key={`table-row-container-${rowIndex}`}>
        <TableRow key={`table-row-${rowIndex}`}>
          {columnDefForData
            .filter((column) => !column.hidden)
            .map((column, columnIndex) => {
              const props = {
                row,
                rowIndex,
                isTotal,
                column,
                columnIndex,
                className: `${classes.cell} ${isTotal ? classes.totalCell : undefined}`,
              };
              return renderCell(props);
            })}
        </TableRow>
      </Fragment>
    );
  };

  return (
    <TableContainer className={classes.container} style={style}>
      <Table stickyHeader>
        <TableHead>
          <DashboardTableHead {...props} columnDef={columnDef} />
          {totalData && renderRow({ row: totalData, isTotal: true })}
        </TableHead>
        <TableBody>{dataSource.map((row, rowIndex) => renderRow({ row, rowIndex }))}</TableBody>
      </Table>
    </TableContainer>
  );
};

export default DashboardTable;
