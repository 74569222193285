import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(() =>
  ({
    Content: {
      width: 400,
      padding: 16,
      flexDirection: 'row',
      borderRadius: 12,
      marginBottom: 30,
      boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.12)',
    },

    acmName: {
      fontSize: 18,
      // fontWeight: 600,
      lineHeight: 2,
      color: '#333',
      paddingVertical: 4,
    },

    acmType: {
      fontSize: 14,
      // fontWeight: 400,
      lineHeight: 1.5,
      color: '#858585',
    },

    acmTime: {
      fontSize: 16,
      fontWeight: 400,
      lineHeight: 1.5,
      color: '#858585',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },

    btnWrap: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginTop: 20,
    },

    btnLeft: { marginRight: 6, flex: 1 },
    btnRight: { marginLeft: 6, flex: 1 },

    btnContainer: {
      padding: '0 20px 20px 20px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },

    btnInvitePreview: {
      // marginBottom: 10,
      padding: 20,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },

    sentSuccess: {
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: 400,
      padding: 30,
    },

    sentSuccessText: {
      marginTop: 20,
      marginBottom: 50,
    },

    circle: {
      backgroundColor: '#D0F6DF',
      borderRadius: 40,
      width: 80,
      height: 80,
    },

    flex1: {
      flex: 1,
      width: '100%',
    },

    center: {
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    }
  }));
