import React, { FC } from 'react';
import { RouteComponentProps, Switch, Redirect } from 'react-router-dom';
import PruRoute from '../../../common/components/PruRoute';
import { APPLICATION_BASE_PATH, APPLICATION_DOWNLOAD_PATH } from '../constants';
import DownloadPage from './Download/DownloadPage';
import { GAData } from '../../../common/ga/redux/gaSlice';

const downloadPageGAData: GAData = {
  module: 'Application',
  feature: 'Download',
  journey: 'download_application',
  stage: 'download',
  screen_name: 'DownloadApplicationPage',
};

const ApplicationRoutes: FC<RouteComponentProps> = ({ history, location, match }) => {
  return (
    <Switch>
      <Redirect exact from={`${APPLICATION_BASE_PATH}`} to={`${APPLICATION_BASE_PATH}${APPLICATION_DOWNLOAD_PATH}`} />
      <PruRoute
        title="Prudential | Download"
        path={`${APPLICATION_BASE_PATH}${APPLICATION_DOWNLOAD_PATH}`}
        component={DownloadPage}
        gaData={downloadPageGAData}
      />
      <Redirect to="/error" />
    </Switch>
  );
};

export default ApplicationRoutes;
