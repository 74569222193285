import { makeStyles } from 'tss-react/mui';

export const useStyle = makeStyles()((theme) => ({
  itemStyle: {
    padding: '8px 16px',
    borderBottomWidth: 0.5,
    borderColor: '#DFDFDF',
  },
  viewTxt: {
    color: '#4386E5',
    fontFamily: 'SVN-Poppins',
    fontSize: '1rem',
  },
  indexTxt: {
    width: 30,
  },
  imgTemplate: {
    marginRight: 10,
    width: 44,
    height: 44,
  },
  optionTxt: {
    color: '#333',
    fontFamily: 'SVN-Poppins',
    fontSize: 14,
    fontWeight: 400,
  },
  previewImage: {
    width: 900,
    height: 586,
  },
  selectedTxt: {
    color: '#ED1B2E',
    fontWeight: 'bold',
  },
  w_50: {
    width: '50%',
  },
  mt_4: {
    marginTop: '1rem',
  },
  d_flex_center: {
    display: 'flex',
    alignItems: 'center',
  },
  flex_fill: {
    flex: '1 1 auto',
  },
}));
