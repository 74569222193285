import { IntlShape } from 'react-intl';
import { ModuleHierachy } from '../../common/module/types';
import { SURVEY_TITLE, SURVEY_BASE_PATH, SurveyPermissionType } from './constants';
import SurveyRoutes from './pages/SurveyRoutes';
import { isPermitted } from '../../common/permissions/permission-utils';

export const surveySubmitModuleConfig = (
  permissions: string[],
  intl: IntlShape
): ModuleHierachy => {
  const Translation = (id: string) => intl.formatMessage({ id });
  return {
    title: Translation(SURVEY_TITLE),
    path: SURVEY_BASE_PATH,
    namespace: 'submit-survey',
    disableMenu: true,
    disableRoleControl: true,
    component: SurveyRoutes,
    enableRead: isPermitted(
      [SurveyPermissionType.TEMP_CREATE, SurveyPermissionType.SECRETARY_READ_OWN_SURVEY],
      permissions,
    ),
    enableCreate: isPermitted([SurveyPermissionType.TEMP_CREATE], permissions),
    enableUpdate: isPermitted([SurveyPermissionType.TEMP_CREATE], permissions),
  };
};
