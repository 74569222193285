import { makeStyles } from 'tss-react/mui';
import React, { FC, useEffect, useState } from 'react';
import { Button } from '@mui/material';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { NOT_FOUND_ERROR_PAGE_PATH } from 'src/app/common/constants';
import { LayoutSplashScreen } from 'src/app/layout';
import { getAboutMeTemplates, updateAgentProfile, UpdateProfileBody } from '../../network/agentProfileCrud';
import {
  AboutMeTemplateItem,
  AgentProfileItem,
  LocationState,
  FromPageEnum,
  AboutMeDisplayStatusEnum,
} from '../../types/agent-profile-types';
import SelectItem from '../../common/components/SelectItem';
import { AGENT_PROFILE_PATH } from '../../constants';
import { translateAllParameters } from '../../common/utils/i18n-utils';
import { getAboutDataTemplateMapping } from '../../common/utils/data-utils';
import GAClickFragment from '../../../../common/ga/components/GAClickFragment';
import { useGAScreenViewEvent } from '../../../../common/ga/hook/useGAScreenViewEvent';

const AgentProfileEditAboutMePage: FC<RouteComponentProps> = ({ history, location }) => {
  const { classes } = useStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();

  const receivedLocationState = location.state as LocationState;
  useGAScreenViewEvent(
    {
      module: 'AgentProfile',
      feature: 'AgentProfileSite',
      journey: receivedLocationState?.from === FromPageEnum.CREATE ? 'create_agent_profile' : 'edit_agent_profile',
      stage: 'edit_self_introduction',
      screen_name: 'AboutMePageScreen',
    },
    true,
  );

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [agentProfile, setAgentProfile] = useState<AgentProfileItem>();
  const [templates, setTemplates] = useState<AboutMeTemplateItem[]>([]);
  const [selectedIndex, setSelectedIndex] = useState<string | undefined>(undefined);
  const [aboutMeDisplayStatus, setAboutMeDisplayStatus] = useState<AboutMeDisplayStatusEnum | undefined>(undefined);

  const onResolveParameter = (profile: AgentProfileItem, template: AboutMeTemplateItem[]) => {
    const dataTemplateMappingList = getAboutDataTemplateMapping(profile);

    template.map((item) => {
      var contentEn = item.content.enUs != undefined ? item.content.enUs : '';
      var contentZhHk = item.content.zhHk != undefined ? item.content.zhHk : '';

      // Handle with English Content
      item.content.enUs = translateAllParameters(dataTemplateMappingList, contentEn) || '';

      // Handle with Chinese Content
      item.content.zhHk = translateAllParameters(dataTemplateMappingList, contentZhHk) || '';

      return item;
    });

    return template;
  };

  const reloadData = async () => {
    if (receivedLocationState?.initAgentProfile) {
      setIsLoading(true);
      try {
        setAgentProfile(receivedLocationState.initAgentProfile);
        // Only get standard templates if it is not custom about me
        if (!receivedLocationState.initAgentProfile.enableMyAboutMe) {
          const res = await getAboutMeTemplates(dispatch);
          setTemplates(onResolveParameter(receivedLocationState.initAgentProfile, res.docs));
        }
        setAboutMeDisplayStatus(receivedLocationState.initAgentProfile.aboutMeDisplayStatus);
      } catch (err) {
        history.push(NOT_FOUND_ERROR_PAGE_PATH);
      } finally {
        setIsLoading(false);
      }
    } else {
      history.push(NOT_FOUND_ERROR_PAGE_PATH);
    }
  };

  useEffect(() => {
    if ((window as any).ReactNativeWebView) {
      (window as any).ReactNativeWebView.postMessage(
        `{ "action": "loadheaderprops", "payload": { "title": "aboutMe", "backTarget": "web" } }`,
      );
    }
  }, []);

  useEffect(() => {
    reloadData();
    // eslint-disable-next-line
  }, []);

  const handleSave = async () => {
    const newAboutMeDisplayStatus =
      aboutMeDisplayStatus === AboutMeDisplayStatusEnum.DEFAULT ? AboutMeDisplayStatusEnum.SHOW : aboutMeDisplayStatus;
    const body: UpdateProfileBody = selectedIndex
      ? {
          aboutMeTemplate: selectedIndex,
          aboutMeDisplayStatus: newAboutMeDisplayStatus,
        }
      : {
          aboutMeDisplayStatus: newAboutMeDisplayStatus,
        };
    let redirect = '';

    if (receivedLocationState.from === FromPageEnum.EDIT) {
      await updateAgentProfile(body, dispatch);
      redirect = `${AGENT_PROFILE_PATH}/submit-success?noHeader`;
      history.push(redirect);
    } else if (agentProfile) {
      const initUpdateBody = receivedLocationState?.updateProfileBody ?? {};
      const passingLocationState: LocationState = {
        ...receivedLocationState,
        initAgentProfile: {
          ...agentProfile,
          aboutMeTemplate: selectedIndex,
          aboutMeDisplayStatus: newAboutMeDisplayStatus,
          myAboutMe: agentProfile.enableMyAboutMe
            ? agentProfile.myAboutMe
            : templates.find((template) => template.id === selectedIndex)?.content,
        },
        updateProfileBody: {
          ...initUpdateBody,
          ...body,
        },
      };
      redirect = `${AGENT_PROFILE_PATH}/create?noHeader`;
      history.push(redirect, passingLocationState);
    }
  };

  return (
    <>
      {isLoading ? (
        <LayoutSplashScreen />
      ) : (
        <div className={classes.mainContainer}>
          <div style={{ flex: 1 }}>
            <div className={classes.sectionContainer}>
              <div>
                <SelectItem
                  selectedTemplate={agentProfile?.aboutMeTemplate}
                  enableMyAboutMe={agentProfile?.enableMyAboutMe}
                  myAboutMe={agentProfile?.myAboutMe}
                  templates={templates}
                  selectedIndex={selectedIndex}
                  aboutMeDisplayStatus={aboutMeDisplayStatus}
                  setSelectedIndex={setSelectedIndex}
                  setAboutMeDisplayStatus={setAboutMeDisplayStatus}
                />
              </div>
            </div>
          </div>
          <div className={classes.footerContainer}>
            <GAClickFragment stage="edit_self_introduction" object_name="Save" object_type="BUTTON">
              <Button variant="contained" color="secondary" className={classes.saveButton} onClick={() => handleSave()}>
                {Translation('app.button.save')}
              </Button>
            </GAClickFragment>
          </div>
        </div>
      )}
    </>
  );
};

export default AgentProfileEditAboutMePage;

const useStyles = makeStyles()((theme) => ({
  header: {
    fontSize: 16,
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    '&:before': {
      content: "''",
      display: 'inline-block',
      height: 16,
      width: 3,
      backgroundColor: 'red',
      marginRight: 10,
      marginLeft: 10,
    },
  },
  mainContainer: {
    marginTop: 10,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  sectionContainer: {
    padding: 20,
    paddingBottom: 0,
    backgroundColor: theme.palette.common.white,
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 15,
  },
  iconButton: {
    padding: 8,
    marginTop: -8,
  },
  footerContainer: {
    textAlign: 'center',
    marginBlock: 10,
  },
  saveButton: {
    color: theme.palette.common.white,
    backgroundColor: '#E8192C',
    height: 48,
    width: '100%',
    fontSize: 16,
  },
}));
