import { Dialog, DialogProps, DialogTitle, ModalProps } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { CloseOutlined } from '@mui/icons-material';
import * as React from 'react';

export interface ModalContainerProps extends Omit<DialogProps, 'onClose' | 'open'> {
  title?: string;
  children: any;
  visible: boolean;
  transparent: boolean;
  onClose?: ModalProps['onClose'];
  customHeader?: JSX.Element;
  contentContainerStyle?: React.CSSProperties;
}

/**
 * Describe your component here
 */
export function ModalContainer(props: ModalContainerProps) {
  const { onClose = () => null, title, customHeader, contentContainerStyle = {}, ...rest } = props;

  const [visible, setVisible] = React.useState(false);
  const [isTransparent, setTransparent] = React.useState(false);
  const { classes } = useStyles();

  React.useEffect(() => {
    setVisible(false);
    setTransparent(false);
    return () => {
      onClose?.({ type: 'reject' }, 'determined' as any);
      setVisible(false);
      setTransparent(false);
    };
  }, []);
  React.useEffect(() => {
    setVisible(props.visible);
  }, [props.visible]);
  React.useEffect(() => {
    setTransparent(props.transparent);
  }, [props.transparent]);

  return (
    <Dialog
      open={visible}
      onClose={() => onClose?.({ type: 'reject' }, 'selfClose' as any)}
      fullWidth
      maxWidth="sm"
      {...rest}
    >
      {customHeader ?? (
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <DialogTitle>{title}</DialogTitle>
          <div
            style={{ padding: '16px 20px' }}
            onClick={() =>
              onClose(
                {
                  type: 'reject',
                },
                'selfClose' as any,
              )
            }
          >
            <CloseOutlined />
          </div>
        </div>
      )}

      <div className={classes.dialog} style={isTransparent ? { height: 0, width: 0, marginLeft: '-1000px' } : {}}>
        <div style={contentContainerStyle}>{props.children}</div>
      </div>
    </Dialog>
  );
}

export const useStyles = makeStyles()((theme) => ({
  dialog: {
    width: '100%',
    alignItems: 'center',
  },
}));
