import { IntlShape } from "react-intl";
import { ModuleHierachy } from "src/app/common/module/types";
import { SUMMARY_DASHBOARD_PATH, SUMMARY_DASHBOARD_TITLE } from "./constants";
import SummaryDashboardRoutes from "./pages/SummaryDashboardRoutes";

export const SummaryDashboardModuleConfig = (permissions: string[], intl: IntlShape): ModuleHierachy => {
  const Translation = (id: string) => intl.formatMessage({ id });
  return {
    title: Translation(SUMMARY_DASHBOARD_TITLE),
    path: SUMMARY_DASHBOARD_PATH,
    namespace: 'summary-dashboard',
    disableMenu: true,
    disableRoleControl: true,
    component: SummaryDashboardRoutes,
    enableRead: true,
    enableCreate: true,
    enableUpdate: true,
  }
}