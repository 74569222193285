import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  btn: {
    color: '#FFFFFF',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    backgroundColor: '#BABABA',
  },
  secondaryBg: {
    backgroundColor: '#4386E5',
  },
}));
