import React, { FC, PropsWithChildren, createContext } from 'react';
import { useMemo } from 'react';
import { useContext } from 'react';
import { regionLocale } from './utils/i18n-utils';

const I18N_CONFIG_KEY = process.env.REACT_APP_I18N_CONFIG_KEY || 'i18nConfig';

const initialState = {
  selectedLang: window.envConfig['PRUFORCE_DEFAULT_LANGUAGE'] || regionLocale[0],
};

const appLocaleMapToPortal: any = {
  en: 'en',
  zhhk: 'zh-Hant',
  vi: 'vi',
};

const getConfig = () => {
  const url_string = window.location.href;
  var url = new URL(url_string);
  var lang = url.searchParams.get('lang');
  if (lang) {
    const mappedLocale = appLocaleMapToPortal[lang.toLowerCase()] || lang;
    localStorage.setItem(I18N_CONFIG_KEY, JSON.stringify({ selectedLang: mappedLocale }));
    return { selectedLang: mappedLocale };
  }
  const ls = localStorage.getItem(I18N_CONFIG_KEY);
  if (ls) {
    try {
      return JSON.parse(ls);
    } catch (er) {
      console.error(er);
    }
  }

  // PVA: Fix extra character error
  if (initialState?.selectedLang && initialState.selectedLang.includes('\r')) {
    initialState.selectedLang = initialState.selectedLang.replace('\r', '');
  }
  return initialState;
};

// Side effect
export const setLanguage = (lang: any) => {
  const queryParams = new URLSearchParams(window.location.search);
  // Set new or modify existing parameter value.
  queryParams.set('lang', lang);
  localStorage.setItem(I18N_CONFIG_KEY, JSON.stringify({ selectedLang: lang }));
  window.location.replace(window.location.href.split('?')[0] + '?' + queryParams.toString());
};

//@ts-ignore
const I18nContext = createContext();

export const useLang = () => {
  //@ts-ignore
  return useContext(I18nContext).selectedLang;
};

export const withI18n = (Component: any) => {
  class WithI18n extends React.Component {
    static displayName = `WithI18n(${Component.displayName || Component.name})`;

    static contextType = I18nContext;

    render() {
      return <Component {...this.props} menu={this.context} />;
    }
  }

  return WithI18n;
};

export const I18nConsumer = I18nContext.Consumer;

export const I18nConfigProvider: FC<PropsWithChildren> = ({ children }: any) => {
  const lang = useMemo(getConfig, []);

  return <I18nContext.Provider value={lang}>{children}</I18nContext.Provider>;
};
