import React, { FC, useCallback, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { ToggleOn, ToggleOffRounded } from '@mui/icons-material';
import { useIntl } from 'react-intl';

export type Options = {
  name: string;
  isOn: '0' | '1'; // ARL options switch on/off
  status?: '1' | '0'; // from admin-portal: ARL is active or not
};
type InputProps = {
  title: string;
  onChange: (arr: Options[]) => void;
  options?: Options[];
  mandatory?: boolean;
  multipleOn?: boolean; // if can set multiple option On
};

const useStyles = makeStyles()(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    fontWeight: 500,
    marginBlock: 14,
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: 14,
  },
  title: {
    color: '#333333',
  },
  toggleName: {
    fontSize: 16,
    width: '100%',
    fontFamily: 'Arial',
    color: '#858585',
    minHeight: 24,
    wordWrap: 'break-word',
  },
  toggleOn: { color: '#E8192C', fontSize: 40 },
  toggleOff: { fontSize: 40 },
  mandatory: { color: '#EB3B4C' },
  contentContainer: {
    fontSize: 16,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyItems: 'center',
  },
  dropdownStyle: {},
}));

export const ToggleOptions: FC<InputProps> = ({ title, mandatory, options = [], onChange, multipleOn = false }) => {
  const { classes } = useStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  const [switchOptions, setSwitchOptions] = useState<Options[]>(options);
  const onClickSwitch = (name: string) => {
    const arr = switchOptions.map((option) => {
      if (option.name === name) {
        option.isOn = option.isOn === '0' ? '1' : '0';
      } else {
        if (!multipleOn) {
          option.isOn = '0';
        }
      }
      return option;
    });
    setSwitchOptions(arr);
    onChange(arr);
  };

  const toggleName = useCallback(
    (name: any) => {
      const key = `agentProfile.${(name || '').toUpperCase()}`;
      const value = Translation(key);
      // in case no translation, direct use key as name
      return value === key ? key : value;
    },
    [Translation],
  );
  // if have any active referral-link
  const displayOption = switchOptions.some((item) => item.status === '1');
  if (!displayOption) {
    return null;
  }

  return (
    <div className={classes.container}>
      <div className={classes.headerContainer}>
        <div className={classes.title}>{title}</div>
        {mandatory && <div className={classes.mandatory}>*</div>}
      </div>
      {switchOptions.map((item) => {
        if (!item.status || item.status !== '1') {
          return null;
        }
        return (
          <div className={classes.contentContainer} key={item.name}>
            <div className={classes.toggleName}>{toggleName(item.name)}</div>
            <div onClick={() => onClickSwitch(item.name)}>
              {item.isOn === '1' ? (
                <ToggleOn className={classes.toggleOn} />
              ) : (
                <ToggleOffRounded className={classes.toggleOff} />
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};
