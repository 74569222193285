import { IntlShape } from "react-intl";
import { ModuleHierachy } from "../../common/module/types";
import { AGENCY_CAMPAIGN_TITLE, AGENCY_CAMPAIGN_LIST_TITLE, AGENCY_CAMPAIGN_DASHBOARD_TITLE, AGENCY_CAMPAIGN_NAMESPACE, AGENCY_CAMPAIGN_DASHBOARD_NAMESPACE, AGENCY_CAMPAIGN_BASE_PATH, AGENCY_CAMPAIGN_CAMPAIGN_PATH, AGENCY_CAMPAIGN_DASHBOARD_PATH } from "./constants";
import AgencyCampaignRoutes from "./pages/Campaign/CampaignRoutes";
import DashboardRoutes from "./pages/Dashboard/DashboardRoutes";
import { isPermitted } from "../../common/permissions/permission-utils";

export const agencyCampaignModuleConfig = (permissions: string[], intl: IntlShape): ModuleHierachy => {
  const Translation = (id: string) => intl.formatMessage({ id });
  return {
    title: Translation(AGENCY_CAMPAIGN_TITLE),
    path: AGENCY_CAMPAIGN_BASE_PATH,
    disableMenu: false,
    disableRoleControl: true,
    enableRead: true,
    enableCreate: true,
    enableUpdate: true,
    child: [
      {
        title: Translation(AGENCY_CAMPAIGN_LIST_TITLE),
        path: `${AGENCY_CAMPAIGN_BASE_PATH}${AGENCY_CAMPAIGN_CAMPAIGN_PATH}`,
        namespace: AGENCY_CAMPAIGN_NAMESPACE,
        component: AgencyCampaignRoutes,
        enableRead: true,
        enableCreate: true,
        enableUpdate: true,
      },
      {
        title: Translation(AGENCY_CAMPAIGN_DASHBOARD_TITLE),
        path: `${AGENCY_CAMPAIGN_BASE_PATH}${AGENCY_CAMPAIGN_DASHBOARD_PATH}`,
        namespace: AGENCY_CAMPAIGN_DASHBOARD_NAMESPACE,
        component: DashboardRoutes,
        enableRead: true,
        enableCreate: true,
        enableUpdate: true,
      }
    ]
  }
}