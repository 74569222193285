import axios from 'axios';
import { ModalService } from 'src/app/common/components/pru-modal/modal-service';
import { Translation } from 'src/app/i18n';
import { ErrorMsg } from './apiErrorHandler';
import { decodeJWT } from 'src/app/modules/Auth/network/authCrud';

const OEM_APIM_SUBSCRIPTION_KEY =
  window.envConfig["REACT_APP_OEM_APIM_SUBSCRIPTION_KEY"] || "";
const APPLICATION = window.envConfig["REACT_APP_APPLICATION"] || "";

const apiClient = axios.create({
  timeout: 120000,
  withCredentials: false,
})

//  Pre-Config before request
apiClient.interceptors.request.use((config) => {
  const { url } = config;
  config.url = url && url?.indexOf('?') > 0 ? `${url}&subscription-key=${OEM_APIM_SUBSCRIPTION_KEY}` : `${url}?subscription-key=${OEM_APIM_SUBSCRIPTION_KEY}`
  if (!config.headers.Authorization) {
    // set default Authorization only when it's null
    config.headers.Authorization = `Bearer ${window.localStorage.getItem('jwt')}`;
  }

  config.headers['Ocp-Apim-Subscription-Key'] = `${OEM_APIM_SUBSCRIPTION_KEY}`;
  return config;
}, (error) => {
  return Promise.reject(error);
});

//  Pre-Config before request
apiClient.interceptors.request.use(
  async (config) => {
    // PCAAEB-22458: security fix, remove key from url
    // const { url } = config;
    // config.url = url && url?.indexOf('?') > 0 ? `${url}&subscription-key=${OEM_APIM_SUBSCRIPTION_KEY}` : `${url}?subscription-key=${OEM_APIM_SUBSCRIPTION_KEY}`
    const jwt = window.localStorage.getItem('jwt');
    if (!config.headers.Authorization) {
      // set default Authorization only when it's null
      config.headers.Authorization = `Bearer ${jwt}`;
    }
    // handle api routing for staff
    if (jwt) {
      const token = await decodeJWT(jwt);
      if (token.role === 'STAFF' || token.role === 'SECRETARY') {
        if (config.method !== 'get') {
          ModalService.showAlert(
            Translation('common.staff_block_alert_title'),
            Translation('common.staff_block_alert_label'),
          );
          throw new axios.Cancel(ErrorMsg.STAFF_BLOCK);
        } else if (config.url) {
          const staffSelectedAgentCode = window.localStorage.getItem('staffSelectedAgentCode');
          config.url = config.url.replace('/me/', `/${staffSelectedAgentCode || 'me'}/`);
        }
      }
    }
    config.headers['Ocp-Apim-Subscription-Key'] = OEM_APIM_SUBSCRIPTION_KEY;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

//  Handle response from every api call
apiClient.interceptors.response.use(
  (data) => {
    return data;
  },
  (error) => {
    if (!error.response) {
      throw error;
    }
    if (error.response.data) {
      throw error.response.data;
    } else {
      throw error.response;
    }
  }
);

export default apiClient;
