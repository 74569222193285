import { makeStyles } from 'tss-react/mui';
import { isPhone } from 'src/app/common/constants';
import { DashboardChart } from '../../DashboardChart/DashboardChart';
import { MainCardProps } from '../../types/dashboard-card-types';
import { ChartTypeEnum } from '../../types/dashboard-chart-types';

const PADDING_LEFT = isPhone ? 20 : 48;

const useStyles = makeStyles()((theme) => ({
  mainContainer: {
    display: 'flex',
    alignItems: 'center',
    columnGap: 20,
    padding: `28px 20px 0 ${PADDING_LEFT}px`,
    marginBottom: 28,
  },
  valueContainer: {
    flexGrow: 1,
  },
  remarkContainer: {
    color: '#999999',
    fontSize: 11,
    paddingLeft: 20,
  },
  value: {
    fontSize: 30,
    fontWeight: 700,
    fontFamily: 'Montserrat',
    marginRight: 8,
  },
  percentage: {
    fontSize: 20,
    fontWeight: 700,
    fontFamily: 'Montserrat',
  },
  caption: {
    color: '#999999',
    fontSize: 12,
    fontWeight: 500,
  },
}));

const MainCard = <T extends Record<string, number>>({
  data,
  keys,
  totalKey,
  totalDisplayName,
  caption,
  remark,
  chartColors,
  valueStyle,
}: MainCardProps<T>) => {
  const { classes } = useStyles();
  const value = data[keys[0]];
  const total = data[totalKey];
  const percentage = total !== 0 ? Math.round((value / total) * 100) : 0;

  return (
    <>
      <div className={classes.mainContainer}>
        <DashboardChart
          type={ChartTypeEnum.SINGLE_RADIAL_BAR}
          data={data}
          keys={keys}
          totalKey={totalKey}
          colors={chartColors ? chartColors : ['#EB3B4C', '#CCCCCC']}
          totalDisplayName={totalDisplayName}
        />
        <div
          className={classes.valueContainer}
          style={typeof valueStyle === 'function' ? valueStyle(value) : valueStyle}
        >
          <div>
            <span className={classes.value}>{value}</span>
            <span className={classes.percentage}>{`(${percentage}%)`}</span>
          </div>
          <div className={classes.caption}>{caption}</div>
        </div>
      </div>
      <div className={classes.remarkContainer}>{remark}</div>
    </>
  );
};

export default MainCard;
