import { Dispatch } from 'react';
import { BopReportItem, GpReportPayload, PaginateListMore } from '../pages/Event/types';
import { apiClient, apiErrorHandler } from 'src/app/common/network';
import moment from 'moment';

const AGENT_URL = window.envConfig['REACT_APP_APIM_BASE_URL'];
const APIM_AGENT = window.envConfig['REACT_APP_APIM_AGENT'];

const getReportDateRange = (data: GpReportPayload) => {
  const { eventEndDate, eventStartDate } = data;
  if (eventEndDate && eventStartDate) {
    return {
      eventEndDate: moment(eventEndDate).endOf('D').toISOString(),
      eventStartDate: moment(eventStartDate).startOf('D').toISOString(),
    };
  }

  if (eventEndDate) {
    return {
      eventEndDate: moment(eventEndDate).endOf('D').toISOString(),
      eventStartDate: moment(eventEndDate).startOf('D').subtract(6, 'months').toISOString(),
    };
  }

  if (eventStartDate) {
    return {
      eventStartDate: moment(eventStartDate).startOf('D').toISOString(),
      eventEndDate: moment(eventStartDate).endOf('D').add(6, 'months').toISOString(),
    };
  }

  return {
    eventEndDate: moment().endOf('D').toISOString(),
    eventStartDate: moment().startOf('D').subtract(6, 'months').toISOString(),
  };
};

const convertReportPayload = (params: GpReportPayload) => {
  const { page, limit, eventName } = params;
  const { eventStartDate, eventEndDate } = getReportDateRange(params);
  const dataFilters = Object.keys(params).reduce((data: any, key) => {
    if (key === 'eventName' && eventName) {
      return [
        ...data,
        {
          name: 'materialName',
          value: eventName,
          type: 'String',
          operator: 'search',
        },
      ];
    }

    if (key === 'eventStartDate' && eventStartDate) {
      return [
        ...data,
        {
          name: 'materialEventStartDate',
          value: eventStartDate,
          type: 'Date',
          operator: 'gt',
        },
      ];
    }

    if (key === 'eventEndDate' && eventEndDate) {
      return [
        ...data,
        {
          name: 'materialEventEndDate',
          value: eventEndDate,
          type: 'Date',
          operator: 'lte',
        },
      ];
    }

    return data;
  }, []);

  return {
    dataFilters,
    page,
    limit,
  };
};

export const fetchListBopReportRequest = async (
  params: GpReportPayload,
  dispatch?: Dispatch<any>,
): Promise<PaginateListMore<BopReportItem>> => {
  const uri = `${AGENT_URL}${APIM_AGENT}/ad/report/event-bop`;

  const payload = convertReportPayload(params);

  return apiClient
    .post<PaginateListMore<BopReportItem>>(uri, payload)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchBopReportDownloadRequest = (params: GpReportPayload, dispatch?: Dispatch<any>): void => {
  const uri = `${AGENT_URL}${APIM_AGENT}/ad/report/event-bop/export`;
  const payload = convertReportPayload(params);

  apiClient
    .post<Blob>(uri, payload, { responseType: 'text' })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob(['\uFEFF' + response.data], { type: 'text/csv;charset=utf-8;' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'bop-report-' + new Date().toISOString() + '.csv');
      document.body.appendChild(link);
      link.click();
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};
