import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Icon,
  TextField,
} from '@mui/material';
import { useFormik } from 'formik';
import moment from 'moment';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
//@ts-ignore
import * as Yup from 'yup';
import { AlertType, appendAlertItem } from '../../../../redux/common/commonSlice';
import { useLang } from '../../../i18n';
import { preFillZero } from '../../../common/utils/common-utils';
import { agentLogin, decodeJWT, fetchCurrentAgentInfo, getOTP, verifyOTP } from '../network/authCrud';
import { authenticate, setTokenInfo } from '../network/authSlice';
import { TokenInfo } from '../types/auth-types';
import { useStyles } from './Login.style';
import { AppConfigData, fetchAppConfigs } from '../../../common/network/crud/configsCurd';
import { ROLLOUT_VERSION, takeLoginEvent, takeUIClickEvent, updateUserProperties } from '../../../common/ga/ga';
import { getUserLBU } from '../../../common/ga/utils';

const initialValues = {
  agentCode: '',
  password: '',
};

const REACT_APP_LOGIN_WITH_OTP: boolean = (window.envConfig['REACT_APP_LOGIN_WITH_OTP'] as string) == 'true';
const REACT_APP_ENABLE_VERIFICATION_TRAILS: boolean =
  (window.envConfig['REACT_APP_ENABLE_VERIFICATION_TRAILS'] as string) == 'true';

const OTP_MAX_COUNT = 60; // 1 min

const Login: FC<RouteComponentProps> = ({ history, location, match }) => {
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const [displayOtp, setDisplayOtp] = useState(false);
  const [otpCode, setOtpCode] = useState('');
  const [loginRes, setLoginRes] = useState<any>();
  const [agentCode, setAgentCode] = useState<string>();
  const [appConfigs, setAppConfigs] = useState<AppConfigData[] | null>(null);
  const [agentNamePhone, setAgentNamePhone] = useState<{
    name?: string;
    phone?: string;
  }>({});
  const intervalRef = useRef<any>(null);
  const { classes } = useStyles();

  const [count, changeCount] = useState<number>(0);
  const [otpError, setOTPError] = useState<boolean>(false);

  const onOtpChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // setName(event.target.value);
    const val = event.target.value;
    if (!val) {
      return setOtpCode('');
    }
    // const regex = /^\d+$/; // number only
    const regex = new RegExp(/^\d+$/); // number only
    if (!val.match(regex)) {
      return;
    }
    const trimedVal = Math.max(0, parseInt(val)).toString().slice(0, 6); // 6 chars
    setOtpCode(trimedVal);
  };

  // clear otp timer on unmount
  useEffect(() => {
    return () => {
      clearInterval(intervalRef.current);
    };
  }, []);

  useEffect(() => {
    if (count === OTP_MAX_COUNT) {
      intervalRef.current = setInterval(() => {
        changeCount((preCount) => preCount - 1);
      }, 1000);
    } else if (count === 0) {
      clearInterval(intervalRef.current);
    }
  }, [count]);

  const onResendOtp = useCallback(() => {
    changeCount(OTP_MAX_COUNT);
    sendOTP(agentCode, agentNamePhone.phone);
  }, [agentCode, agentNamePhone]);
  const dispatch = useDispatch();
  const lang = useLang();

  const LoginSchema = Yup.object().shape({
    agentCode: Yup.string().required(
      intl.formatMessage({
        id: 'AUTH.VALIDATION.REQUIRED_FIELD',
      }),
    ),
    password: Yup.string()
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD',
        }),
      ),
  });

  const getInputClasses = (fieldname: string) => {
    //@ts-ignore
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return 'is-invalid';
    }

    //@ts-ignore
    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return 'is-valid';
    }

    return '';
  };
  // const onResendOtp = () => {};
  const onSubmitOtp = async () => {
    // if (!otpCode || otpCode.length !== 6) {
    //   return setOTPError(true);
    // }
    setOTPError(false);
    const optCodeData = {
      optCode: otpCode,
      agentCode: agentCode,
      lang: lang,
      enableVerificationTrials: REACT_APP_ENABLE_VERIFICATION_TRAILS,
    };
    const headers = { Authorization: `Bearer ${loginRes.accessToken}` };
    const tokenInfo = await decodeJWT(loginRes.accessToken);
    verifyOTP(optCodeData, dispatch, { headers })
      .then(() => {
        if (loginRes) {
          //TODO: login success
          const res: any = loginRes;
          dispatch(authenticate(res.accessToken));
          dispatch(setTokenInfo(tokenInfo));
          const expireDate = moment(new Date()).add(res.expiresIn, 's').toDate();
          const abondonSession = moment(new Date()).add(1, 'd').toDate();
          window.localStorage.setItem('expireDate', expireDate.toISOString());
          window.localStorage.setItem('abondonSession', abondonSession.toISOString());
          window.localStorage.setItem('refreshToken', res.refreshToken);
          window.localStorage.setItem('appConfig', JSON.stringify(appConfigs));

          takeLoginEvent(preFillZero(tokenInfo.sub), {
            module: 'Auth',
            feature: 'Login',
            journey: 'agent_login',
            stage: 'login_success',
            screen_name: 'LoginPage',
            method: 'password',
            status: 'Success',
          });

          updateUserProperties({
            user_id: preFillZero(tokenInfo.sub),
            user_lbu: getUserLBU(appConfigs),
            country: tokenInfo.region,
            language: intl.locale,
            Agent: preFillZero(tokenInfo.sub),
            rollout: ROLLOUT_VERSION,
            user_role: tokenInfo.role,
          });

          const redirectLink = window.localStorage.getItem('redirect');
          if (redirectLink) {
            window.localStorage.removeItem('redirect');
            history.push(redirectLink);
          } else {
            history.push('/');
          }
        }
      })
      .catch((err) => {
        //TODO: login fail
        takeLoginEvent(preFillZero(tokenInfo.sub), {
          module: 'Auth',
          feature: 'Login',
          journey: 'agent_login',
          stage: 'login_fail',
          screen_name: 'LoginPage',
          method: 'password',
          status: 'Fail',
        });
        setOTPError(true);
        // dispatch(
        //   appendAlertItem([
        //     {
        //       severity: AlertType.ERROR,
        //       title: 'OTP Verify Fail',
        //       content: `OTP Verify Fail`,
        //     },
        //   ])
        // );
        // debugger;
        // window.localStorage.removeItem('jwt');
        // setLoginRes(null);
      });
  };
  const sendOTP = (agentCode: string | undefined, mobile: string | undefined, token?: string) => {
    const getOtpData = {
      agentCode: agentCode,
      lang: lang?.includes('zh') ? 'zh' : lang, // [en,zh-Hant] api only support [en,zh]
      enableVerificationTrials: REACT_APP_ENABLE_VERIFICATION_TRAILS || false,
      phoneNum: mobile,
    };
    if (!agentCode || !mobile) {
      return dispatch(
        appendAlertItem([
          {
            severity: AlertType.ERROR,
            title: 'SEND OTP FAIL',
            content: `Fail to send OTP, please try again later`,
          },
        ]),
      );
    }
    const headers = { Authorization: `Bearer ${token || loginRes.accessToken}` };
    getOTP(getOtpData, dispatch, { headers })
      .then((res) => {
        setDisplayOtp(true);
        changeCount(OTP_MAX_COUNT);
      })
      .catch((err) => {
        dispatch(
          appendAlertItem([
            {
              severity: AlertType.ERROR,
              title: 'SEND OTP FAIL',
              content: `Fail to send OTP, please try again later`,
            },
          ]),
        );
        window.localStorage.removeItem('jwt');
        setLoginRes(null);
      });
  };
  const getOtpCode = async (agentCode: string, token: string) => {
    const headers = { Authorization: `Bearer ${token}` };
    const currentAgentInfo = await fetchCurrentAgentInfo(lang, dispatch, { headers });

    const mobile = currentAgentInfo?.phone?.mobile;
    const agentName = currentAgentInfo.agencyName;
    setAgentNamePhone({ name: agentName, phone: mobile });
    if (mobile) {
      sendOTP(agentCode, mobile, token);
    } else {
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.ERROR,
            title: 'No Phone Record',
            content: `Cannot find the phone belongs to the agent`,
          },
        ]),
      );
      window.localStorage.removeItem('jwt');
      setLoginRes(null);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values, { setSubmitting }) => {
      setLoading(true);
      takeUIClickEvent({
        module: 'Auth',
        feature: 'Login',
        journey: 'agent_login',
        stage: 'password_login',
        screen_name: 'LoginPage',
        object_name: 'Login',
        object_type: 'BUTTON',
      });

      if (values.password) {
        agentLogin(preFillZero(values.agentCode), values.password as unknown as string, dispatch)
          .then(async (res) => {
            // fix PCAAEB-14413: can not save token before OTP-verify is done
            // if (typeof window !== undefined) {
            //   window.localStorage.setItem('jwt', res.accessToken);
            // }

            const appConfigs = await fetchAppConfigs(res.accessToken, dispatch);
            if (!appConfigs) throw Error();
            setAppConfigs(appConfigs);

            if (REACT_APP_LOGIN_WITH_OTP) {
              setLoginRes(res);
              setSubmitting(false);
              setLoading(false);
              setAgentCode(values.agentCode);
              getOtpCode(values.agentCode, res.accessToken);
            } else {
              //TODO: login success
              const tokenInfo = await decodeJWT(res.accessToken);
              dispatch(authenticate(res.accessToken));
              dispatch(setTokenInfo(tokenInfo));
              const expireDate = moment(new Date()).add(res.expiresIn, 's').toDate();
              const abondonSession = moment(new Date()).add(1, 'd').toDate();
              window.localStorage.setItem('expireDate', expireDate.toISOString());
              window.localStorage.setItem('abondonSession', abondonSession.toISOString());
              window.localStorage.setItem('refreshToken', res.refreshToken);
              window.localStorage.setItem('appConfig', JSON.stringify(appConfigs));

              takeLoginEvent(preFillZero(values.agentCode), {
                module: 'Auth',
                feature: 'Login',
                journey: 'agent_login',
                stage: 'login_success',
                screen_name: 'LoginPage',
                method: 'password',
                status: 'Success',
              });

              updateUserProperties({
                user_id: preFillZero(tokenInfo.sub),
                user_lbu: getUserLBU(appConfigs),
                country: tokenInfo.region,
                language: intl.locale,
                Agent: preFillZero(tokenInfo.sub),
                rollout: ROLLOUT_VERSION,
                user_role: tokenInfo.role,
              });

              history.push('/');
            }
          })
          .catch(() => {
            //TODO: login fail
            takeLoginEvent(preFillZero(values.agentCode), {
              module: 'Auth',
              feature: 'Login',
              journey: 'agent_login',
              stage: 'login_fail',
              screen_name: 'LoginPage',
              method: 'password',
              status: 'Fail',
            });

            setLoading(false);
            setSubmitting(false);
          });
      }
    },
  });

  const resendText = count > 0 ? `Resend (${count}s)` : 'Resend';
  const btnDisabled = !otpCode || otpCode.length !== 6;

  return (
    <div className={classes.loginWrapper}>
      <div className={classes.loginHeader}>
        <h3 className={classes.loginTitle}>
          <FormattedMessage id="AUTH.LOGIN.TITLE" />
        </h3>
        <p className={classes.loginSubtitle}>Enter your agent code and password</p>
      </div>

      <form onSubmit={formik.handleSubmit}>
        {formik.status ? (
          <div className={classes.tips} style={{ backgroundColor: '#ffe2e5' }}>
            {formik.status}
          </div>
        ) : (
          <div className={classes.tips}>
            <div>
              Use your <strong>Agent Code</strong> and <strong>Password</strong> to continue.
            </div>
          </div>
        )}

        <div className={classes.formItem}>
          <input
            placeholder="Agent Code"
            type="text"
            className={classes.input}
            {...formik.getFieldProps('agentCode')}
          />
          {formik.touched.agentCode ? (
            <Icon
              className={`${formik.errors.agentCode ? 'fa fa-exclamation-circle' : 'fa fa-check'} ${classes.checkIcon}`}
              style={{ color: formik.errors.agentCode ? '#F64E60' : '#1BC5BD' }}
            />
          ) : null}
          {formik.touched.agentCode && formik.errors.agentCode ? (
            <div className={classes.messageContainer}>{formik.errors.agentCode}</div>
          ) : null}
        </div>
        <div className={classes.formItem}>
          <input
            placeholder="Password"
            type="password"
            className={classes.input}
            //@ts-ignore
            name="password"
            autoComplete="on"
            {...formik.getFieldProps('password')}
          />
          {formik.touched.password ? (
            <Icon
              className={`${formik.errors.password ? 'fa fa-exclamation-circle' : 'fa fa-check'} ${classes.checkIcon}`}
              style={{ color: formik.errors.password ? '#F64E60' : '#1BC5BD' }}
            />
          ) : null}
          {formik.touched.password && formik.errors.password ? (
            <div className={classes.messageContainer}>{formik.errors.password}</div>
          ) : null}
        </div>

        <div className={classes.btnWrapper}>
          <button type="submit" disabled={formik.isSubmitting} className={classes.btn}>
            <span>Sign In</span>
            {loading && <span className={classes.loading}></span>}
          </button>
        </div>
      </form>
      <div className={classes.separator}></div>
      <Dialog
        open={displayOtp}
        onClose={(_, reason) => {
          if (reason !== 'backdropClick') {
            setDisplayOtp(false);
            window.localStorage.removeItem('jwt');
          }
        }}
        disableEscapeKeyDown
        maxWidth="sm"
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <DialogTitle>{`OTP Verification`}</DialogTitle>
          {/* <div style={{ padding: "16px 20px" }} onClick={() => { setDisplayOtp(false); window.localStorage.removeItem('jwt'); }}>
            <CloseOutlined />
          </div> */}
        </div>
        <DialogContent className={classes.dialogContent}>
          <DialogContentText style={otpContent1}>
            We sent a verification code to {agentNamePhone?.name}’s mobile number
          </DialogContentText>
          <DialogContentText color="secondary" style={otpContent2}>
            <span className={classes.circle}>
              <Icon className="fa fa-phone" fontSize="small" style={{ color: '#fff' }} />
            </span>
            <span>{agentNamePhone?.phone}</span>
          </DialogContentText>
          <DialogContentText style={{ ...otpContent1, ...otpContent2 }}>
            Please enter the verification code
          </DialogContentText>
          <div>
            <TextField
              id="outlined-basic"
              variant="outlined"
              color="secondary"
              type="tel"
              error={otpError}
              helperText={otpError ? 'Incorrect OTP' : ''}
              value={otpCode}
              onChange={onOtpChange}
            />
            <Button variant="text" color="inherit" style={btnResend} onClick={onResendOtp} disabled={count > 0}>
              <span style={{ color: '#828282' }}>{resendText}</span>
            </Button>
          </div>
        </DialogContent>
        <DialogActions style={actionWrap}>
          <Button
            variant="contained"
            size="large"
            color="secondary"
            style={btnSubmit}
            disabled={btnDisabled}
            onClick={onSubmitOtp}
          >
            {`Continue`}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
const otpInput = {
  height: 46,
  fontSize: 24,
};
const actionWrap = { flex: 1, alignSelf: 'center', padding: 20, width: '100%' };
const btnSubmit = {
  height: 46,
  flex: 1,
};
const btnResend = {
  height: 46,
  flex: 1,
  marginLeft: 10,
};

const otpContent1 = {
  fontWeight: 600,
  color: '#333333',
  fontSize: '1.5rem',
  marginTop: 20,
};
const otpContent2 = {
  fontSize: '1.1rem',
  display: 'flex',
  justifyContent: 'center',
  // alignSelf: 'end',
};

export default Login;
