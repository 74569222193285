import React, { FC } from 'react';
import { Switch, Redirect, RouteComponentProps } from 'react-router-dom';
import PruRoute from '../../../../common/components/PruRoute';
import { AGENCY_CAMPAIGN_BASE_PATH, AGENCY_CAMPAIGN_CAMPAIGN_PATH } from '../../constants';
import { NOT_FOUND_ERROR_PAGE_PATH } from '../../../../common/constants';
import CampaignListPage from './List/CampaignListingPage';
import CampaignDetailPage from './Details/CampaignDetailPage';
import { GAData } from '../../../../common/ga/redux/gaSlice';

const AgencyCampaignListPath = `${AGENCY_CAMPAIGN_BASE_PATH}${AGENCY_CAMPAIGN_CAMPAIGN_PATH}`;

const createCampaignPageGAData: GAData = {
  module: 'PRULeads',
  feature: 'ACM',
  journey: 'add_new_agency_campaign',
  stage: 'create_campaign',
  screen_name: 'CreateCampaignPage',
};

const viewCampaignGPageGAData: GAData = {
  module: 'PRULeads',
  feature: 'ACM',
  journey: 'view_campaign_detail',
  stage: 'view',
  screen_name: 'CampaignDetailPage',
};

const campaignListPageGAData: GAData = {
  module: 'PRULeads',
  feature: 'ACM',
  journey: 'view_all_campaigns',
  stage: 'view',
  screen_name: 'CampaignListPage',
};

const AgencyCampaignRoutes: FC<RouteComponentProps> = (props) => {
  return (
    <Switch>
      <PruRoute
        gaData={createCampaignPageGAData}
        path={`${AgencyCampaignListPath}/create/:typeId`}
        component={CampaignDetailPage}
      />
      <PruRoute
        gaData={viewCampaignGPageGAData}
        path={`${AgencyCampaignListPath}/detail/:id`}
        component={CampaignDetailPage}
      />
      <PruRoute gaData={campaignListPageGAData} path={`${AgencyCampaignListPath}`} component={CampaignListPage} />
      <Redirect to={NOT_FOUND_ERROR_PAGE_PATH} />
    </Switch>
  );
};

export default AgencyCampaignRoutes;
