import { Dispatch } from "react";
import { logout } from "../../modules/Auth/network/authSlice";
import { authenticateFailed, appendAlertItem, AlertType } from "../../../redux/common/commonSlice";

export type Error = {
  code: string;
  message: string;
};

export type ApiErrorResponse = {
  statusCode: number;
  message: ErrorMsg | string;
  code: ErrorType | string;
  errors: Error[];
};

export enum ErrorType {
  //  Error enum from auth
  AUTH_UNSUPPORTED_GRANT_TYPE = "AuthenticationProcessApi-Auth-0",
  AUTH_UNPROCESSABLE_ENTITY = "AuthenticationProcessApi-Auth-1",
  AUTH_TOKEN_NOT_FOUND = "AuthenticationProcessApi-Auth-2",
  AUTH_ROLE_NOT_FOUND = "AuthenticationProcessApi-Auth-3",
}

export enum ErrorMsg {
  STAFF_BLOCK = 'staff-block',
}

export const apiErrorHandler = (err?: ApiErrorResponse, dispatch?: Dispatch<any>) => {
  const error = err || {
    statusCode: 503,
    message: "Network failed",
    code: "Network failed",
    errors: []
  } as ApiErrorResponse;

  const forceLogout = error.code === ErrorType.AUTH_TOKEN_NOT_FOUND;

  if (dispatch && error.message !== ErrorMsg.STAFF_BLOCK) {
    if (forceLogout) {
      dispatch(authenticateFailed());
      dispatch(logout());
    } else {
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.ERROR,
            content: error.message,
          },
        ])
      );
    }
  }

  return error;
};
