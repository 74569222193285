import React, { useEffect, useReducer, useState } from 'react';
import { useIntl } from "react-intl";
import { makeStyles } from 'tss-react/mui';
import { getBlobAsAgent } from '../../../../../../../common/network/crud/resourceCrud';



const useStyles = makeStyles()(() =>
  ({
    RemarksItem: {}
  }));

interface RemarksItemProps {
  RemarksItem: any,
  index: number
}



const RemarksItem: React.FC<RemarksItemProps> = ({ RemarksItem, index }) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const { classes } = useStyles();
  const [url, setUrl] = useState<string>();
  const [name, setName] = useState<string>();

  const getUrlFromItem = (item: any) => {
    getBlobAsAgent({ resourceIds: item}).then((value) => {
      const url = value[0].url;
      const name = value[0].originalFilename;
      setUrl(url);
      setName(name);
    }).catch(() => {
      return null
    })
  }

  useEffect(() => {
    getUrlFromItem(RemarksItem);
  }, [RemarksItem]);


  return (
    <>
      {index > 0 ? <div>{`,`}&nbsp;</div>:null}
      {
        url ?
          <>
            <div
              style={{textDecoration:'underline', cursor:'pointer'}}
              onClick={() => {
                if ((window as any).ReactNativeWebView) {
                  (window as any).ReactNativeWebView.postMessage(`{ "action" : "loadurlwithtarget", "payload" : { "target" : "_blank", "url" : "${encodeURIComponent(url)}" } }`);
                } else {
                  window.open(url, '_blank');
                }
              }}>{`${name}`}</div>
          </>
          : null
      }
    </>
  )
}

export default RemarksItem;