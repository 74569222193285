import { AwardItem } from '../../types/agent-profile-types';

export const moveSelectedAwardsToTop = (selectedAwards: AwardItem[], awardList: AwardItem[]): AwardItem[] => {
  let newAwardList = [...awardList];
  let selectedAwardIdList = itemListToIdList(selectedAwards, 'typeId');
  newAwardList = newAwardList.filter((award) => !selectedAwardIdList.includes(award.typeId));
  return [...selectedAwards, ...newAwardList];
};

export const itemListToIdList = (itemList: any[], idName: string): string[] =>
  itemList.reduce((obj: string[], item) => [...obj, item[idName]], []);
