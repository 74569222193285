import { makeStyles } from 'tss-react/mui';
import React, { FC } from 'react';
import * as Survey from 'survey-react';
import { SurveyResultItem } from '../../../types/survey-types';
import 'survey-react/survey.min.css';
import { createSurveyResult } from '../../../network/surveyCrud';

type SurveySubmitFormProps = {
  surveyId: string;
  surveyBody: Record<string, unknown>;
  surveyResult?: SurveyResultItem;
};

const useStyles = makeStyles()((theme) => ({
  container: {
    borderRadius: 5,
    overflow: 'hidden',
  },
  headerContainer: {
    backgroundColor: 'white',
    [theme.breakpoints.up('xs')]: {
      padding: 10,
    },
    [theme.breakpoints.up('sm')]: {
      padding: 20,
    },
  },
  sectionMargin: {
    marginBottom: 10,
  },
  surveyBtn: {
    fontSize: '1rem',
    fontWeight: 500,
    lineHeight: 1.5,
    borderRadius: 5,
    padding: '5px 20px',
    border: 0,
    color: 'white',
    backgroundColor: 'rgb(60, 79, 109)',
  },
  body: {
    borderTop: '1px solid rgb(60, 79, 109) !important',
    padding: '14px !important',
  },
  row: {},
  surveyPrevBtn: {
    fontSize: '1rem',
    fontWeight: 500,
    lineHeight: 1.5,
    borderRadius: 5,
    padding: '5px 20px',
    border: 0,
    color: 'white',
    backgroundColor: '#DDDDDD',
  },
  headerText: {
    textAlign: 'center',
    '& h3': {
      fontSize: '2rem !important',
    },
    '& h5': {
      fontSize: '1rem',
      fontWeight: 500,
      lineHeight: 1.5,
    },
  },
  questionTitle: {
    fontSize: '1.1rem',
  },
  questionContainer: {
    padding: '0 10px !important',
    marginBottom: 10,
  },
  checkboxLabel: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
  },
  footerContainer: {
    padding: '0 0 1rem 0 !important',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  completedPage: {
    padding: '40px 30px !important',
    textAlign: 'center',
  },
  saveErrorContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 30,
    color: '#a94442',
    backgroundColor: '#f2dede',
    borderColor: '#ebccd1',
    textAlign: 'center',
  },
  savingContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 30,
    color: '#31708f',
    backgroundColor: '#d9edf7',
    borderColor: '#bce8f1',
    textAlign: 'center',
  },
  successContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 30,
    color: '#3c763d',
    backgroundColor: '#dff0d8',
    borderColor: '#d6e9c6',
    textAlign: 'center',
  },
  saveAgainBtn: {
    display: 'none',
  },
}));

Survey.StylesManager.applyTheme('darkblue');

const SurveySubmitForm: FC<SurveySubmitFormProps> = ({ surveyId, surveyBody, surveyResult }) => {
  const { classes } = useStyles();

  const model = new Survey.Model(surveyBody);

  model.css = {
    ...model.css,
    header: classes.headerContainer,
    headerText: classes.headerText,
    row: classes.row,
    body: `${model.css.body} ${classes.body}`,
    navigation: {
      ...model.css.navigation,
      complete: `${model.css.navigation.complete} ${classes.surveyBtn}`,
      next: `${model.css.navigation.next} ${classes.surveyBtn}`,
      prev: `${model.css.navigation.complete} ${classes.surveyPrevBtn}`,
    },
    page: {
      ...model.css.page,
      root: `${model.css.page.root} ${classes.sectionMargin}`,
    },
    footer: `${model.css.footer} ${classes.footerContainer}`,
    completedPage: `${model.css.completedPage} ${classes.completedPage}`,
    saveData: {
      ...model.css.saveData,
      error: classes.saveErrorContainer,
      saveAgainButton: classes.saveAgainBtn,
      saving: classes.savingContainer,
      success: classes.successContainer,
    },
  };

  model.onUpdateQuestionCssClasses.add((survey, options) => {
    let surveyClasses = options.cssClasses;
    surveyClasses.title += ` ${classes.questionTitle}`;
    surveyClasses.mainRoot += ` ${classes.questionContainer}`;
    surveyClasses.label += ` ${classes.checkboxLabel}`;
  });

  model.onComplete.add(async (survey, options) => {
    options.showDataSaving();
    try {
      await createSurveyResult(surveyId, { body: survey.data });
      options.showDataSavingSuccess('The results were saved successfully! Please close this browser to proceed...');
    } catch (err: any) {
      console.error(err);
      options.showDataSavingError(err.message);
    }
  });

  if (surveyResult) {
    model.mode = 'display';
    model.data = surveyResult.body;
  }

  return <Survey.Survey model={model} />;
};

export default SurveySubmitForm;
