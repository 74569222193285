import { FC, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { IconButton } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { LocalPhoneOutlined, WhatsApp, MailOutline, AddCircle } from '@mui/icons-material';
import { isPhone } from 'src/app/common/constants';
import { AGENT_PROFILE_PATH } from 'src/app/modules/AgentProfile/constants';
import {
  AgentProfileItem,
  AwardItem,
  LocationState,
  FromPageEnum,
  AboutMeDisplayStatusEnum,
} from 'src/app/modules/AgentProfile/types/agent-profile-types';
import BasicInfoImage from 'src/app/modules/AgentProfile/common/components/BasicInfoImage';
import BasicInfo from 'src/app/modules/AgentProfile/common/components/BasicInfo';
import AwardList from 'src/app/modules/AgentProfile/common/components/AwardList';
import ItemCard from 'src/app/modules/AgentProfile/common/components/ItemCard';
import { EditIcon, ArrowRightIcon } from 'src/app/modules/AgentProfile/common/icons';
import WhitePlayer from '../../../common/components/WhitePlayer';
import { getAboutDataTemplateMapping } from '../../../common/utils/data-utils';
import { translateAllParameters } from '../../../common/utils/i18n-utils';
import { get } from 'lodash';
import { Options } from '../../../common/components/toggle-options/toggle-options';
import GAClickFragment from '../../../../../common/ga/components/GAClickFragment';
import { takeUIClickEvent } from '../../../../../common/ga/ga';

type AgentProfileEditMainFormProps = {
  agentProfile: AgentProfileItem;
  awardList: AwardItem[];
  mappedLocale: string;
  promotionMappedLocale: string;
};

const INNER_WIDTH = window.innerWidth;
const SECTION_PADDING = 20;
const SECTION_MARGIN = 8;
const BASIC_INFO_SECTION_PADDING = '40px 30px 16px';
const PROMOTION_CARD_COLUMN_GAP = 29;

const iPadViewContainerStyle = {
  display: 'flex',
  columnGap: 8,
};

const useStyles = makeStyles()((theme) => ({
  container: {
    backgroundColor: '#F4F4F4',
    ...(() => (isPhone ? {} : iPadViewContainerStyle))(),
  },
  subContainer: {
    width: isPhone ? '100%' : 'calc(50% - 4px)',
  },
  header: {
    fontSize: 16,
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    '&:before': {
      content: "''",
      display: 'inline-block',
      height: 16,
      width: 3,
      backgroundColor: 'red',
      marginRight: 10,
      marginLeft: 10,
    },
  },
  sectionContainer: {
    padding: SECTION_PADDING,
    marginBottom: SECTION_MARGIN,
    backgroundColor: theme.palette.common.white,
  },
  basicInfoSectionContainer: {
    padding: BASIC_INFO_SECTION_PADDING,
    marginBottom: SECTION_MARGIN,
    backgroundColor: theme.palette.common.white,
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 15,
  },
  iconButton: {
    padding: 8,
    marginTop: -8,
    width: 43,
  },
  aboutMeContainer: {
    fontSize: 14,
    padding: 10,
  },
  emptyAddContainer: {
    padding: 10,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  intouchMeContainer: {
    paddingLeft: 10,
    display: 'flex',
    flexDirection: 'row',
  },
  intouchMeIcon: {
    borderRadius: 50,
    borderWidth: 2,
    borderColor: '#F0F0F0',
    padding: 10,
    marginRight: 30,
  },
  viewMore: {
    fontSize: 14,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 12,
  },
  disabled: {
    color: 'rgba(0, 0, 0, 0.26)',
  },
  iPadViewPromotionContainer: {
    display: 'flex',
    alignItems: 'center',
    columnGap: PROMOTION_CARD_COLUMN_GAP,
  },
  promotionCard: {
    width: '100%',
  },
  iPadViewNoPromotion: {
    justifyContent: 'center',
  },
  addIcon: {
    color: '#E8192C',
    height: 24,
    width: 24,
    marginBottom: 10,
  },
  addMsgContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontSize: 14,
    fontWeight: 500,
    color: '#E8192C',
  },
  playerContainer: {
    borderRadius: 10,
    paddingInline: 10,
    paddingBlock: 5,
  },
  iPadViewPlayerContainer: {
    borderRadius: 10,
    maxWidth: INNER_WIDTH * 0.8,
    paddingBlock: 5,
  },
}));

const AgentProfileEditMainForm: FC<AgentProfileEditMainFormProps> = ({
  agentProfile,
  awardList,
  mappedLocale,
  promotionMappedLocale,
}) => {
  const history = useHistory();
  const { classes } = useStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const { PUBLIC_URL } = process.env;
  const hasAwards = agentProfile.awards?.length !== 0 || agentProfile.awardBadges?.length !== 0;
  const hasPromotions =
    agentProfile.promotions[promotionMappedLocale] && agentProfile.promotions[promotionMappedLocale].length !== 0;

  const [locationState, setLocationState] = useState<LocationState>({
    initAgentProfile: agentProfile,
    from: FromPageEnum.EDIT,
  });
  const [isIPadAwardViewMore, setIsIPadAwardViewMore] = useState<boolean>(false);

  const resolveAboutMeParameter = (text: string) =>
    translateAllParameters(getAboutDataTemplateMapping(agentProfile), text);

  // if display entry: Leave Us Your Contact
  const displayReferralLink = useMemo(() => {
    const referralLinkOptions = get(agentProfile, 'referralLinkOptions', []);
    if (!referralLinkOptions || referralLinkOptions.length < 1) {
      return false;
    }
    return referralLinkOptions.some((item: Options) => item.isOn === '1' && item.status === '1');
  }, [agentProfile]);
  return (
    <div>
      <BasicInfoImage agentProfile={agentProfile} />
      <div className={classes.container}>
        <div className={classes.subContainer}>
          <div className={classes.basicInfoSectionContainer}>
            <BasicInfo agentProfile={agentProfile} />
          </div>
          {isPhone && (
            <div className={classes.sectionContainer}>
              <div className={classes.headerContainer}>
                <div className={classes.header}>{Translation(`agentProfile.ap_get_in_touch_with_me_header_title`)}</div>
                <GAClickFragment
                  journey="edit_agent_profile"
                  stage="edit_contact_info"
                  object_name="Edit"
                  object_type="BUTTON"
                >
                  <IconButton
                    className={classes.iconButton}
                    onClick={() => history.push(`${AGENT_PROFILE_PATH}/getInTouchWithMe?noHeader`, locationState)}
                  >
                    <EditIcon />
                  </IconButton>
                </GAClickFragment>
              </div>
              <div className={classes.intouchMeContainer}>
                <div className={classes.intouchMeIcon}>
                  <LocalPhoneOutlined />
                </div>
                {agentProfile.enableWhatsapp && (
                  <div className={classes.intouchMeIcon}>
                    <WhatsApp />
                  </div>
                )}
                <div className={classes.intouchMeIcon}>
                  <MailOutline />
                </div>
                <div className={classes.intouchMeIcon}>
                  <img width={18} height={18} src={PUBLIC_URL + '/media/svg/icons/General/Person-add.svg'} alt="" />
                </div>
              </div>
              {displayReferralLink && (
                <div className={`${classes.viewMore} ${classes.disabled}`}>
                  {Translation('agentProfile.leave-us-your-contact')} <ArrowRightIcon disabled />
                </div>
              )}
            </div>
          )}
          <div className={classes.sectionContainer}>
            <div className={classes.headerContainer}>
              <div className={classes.header}>{Translation(`agentProfile.ap_about_me_header_title`)}</div>
              {agentProfile.myAboutMe && agentProfile.aboutMeDisplayStatus === AboutMeDisplayStatusEnum.SHOW && (
                <GAClickFragment
                  journey="edit_agent_profile"
                  stage="edit_self_introduction"
                  object_name="Edit"
                  object_type="BUTTON"
                >
                  <IconButton
                    className={classes.iconButton}
                    onClick={() => history.push(`${AGENT_PROFILE_PATH}/aboutMe?noHeader`, locationState)}
                  >
                    <EditIcon />
                  </IconButton>
                </GAClickFragment>
              )}
            </div>
            {agentProfile.myAboutMe && agentProfile.aboutMeDisplayStatus === AboutMeDisplayStatusEnum.SHOW ? (
              <div className={classes.aboutMeContainer}>
                {resolveAboutMeParameter(agentProfile.myAboutMe?.[mappedLocale])}
              </div>
            ) : (
              <GAClickFragment
                journey="edit_agent_profile"
                stage="edit_self_introduction"
                object_name="SelectTemplate"
                object_type="BUTTON"
              >
                <div className={classes.emptyAddContainer}>
                  <button
                    type="button"
                    className={classes.addMsgContainer}
                    onClick={() => history.push(`${AGENT_PROFILE_PATH}/aboutMe?noHeader`, locationState)}
                  >
                    <AddCircle className={classes.addIcon} />
                    {agentProfile.aboutMeDisplayStatus === AboutMeDisplayStatusEnum.HIDE
                      ? Translation('agentProfile.ap_no_template_icon')
                      : Translation('agentProfile.ap_add_template_icon')}
                  </button>
                </div>
              </GAClickFragment>
            )}
          </div>
          {agentProfile.myHighlight?.[mappedLocale] && (
            <div className={classes.sectionContainer}>
              <div className={classes.headerContainer}>
                <div className={classes.header}>{Translation(`agentProfile.ap_my_highlight_header_title`)}</div>
              </div>
              <WhitePlayer
                className={isPhone ? classes.playerContainer : classes.iPadViewPlayerContainer}
                url={agentProfile.myHighlight?.[mappedLocale]}
              />
            </div>
          )}
        </div>
        <div className={classes.subContainer}>
          {!isPhone && (
            <div className={classes.sectionContainer}>
              <div className={classes.headerContainer}>
                <div className={classes.header}>{Translation(`agentProfile.ap_get_in_touch_with_me_header_title`)}</div>
                <IconButton
                  className={classes.iconButton}
                  onClick={() => {
                    takeUIClickEvent({
                      journey: 'edit_agent_profile',
                      stage: 'edit_contact_info',
                      object_name: 'Edit',
                      object_type: 'BUTTON',
                    });
                    history.push(`${AGENT_PROFILE_PATH}/getInTouchWithMe?noHeader`, locationState);
                  }}
                >
                  <EditIcon />
                </IconButton>
              </div>
              <div className={classes.intouchMeContainer}>
                <div className={classes.intouchMeIcon}>
                  <LocalPhoneOutlined />
                </div>
                {agentProfile.enableWhatsapp && (
                  <div className={classes.intouchMeIcon}>
                    <WhatsApp />
                  </div>
                )}
                <div className={classes.intouchMeIcon}>
                  <MailOutline />
                </div>
                <div className={classes.intouchMeIcon}>
                  <img width={18} height={18} src={PUBLIC_URL + '/media/svg/icons/General/Person-add.svg'} alt="" />
                </div>
              </div>
              {displayReferralLink && (
                <div className={`${classes.viewMore} ${classes.disabled}`}>
                  {Translation('agentProfile.leave-us-your-contact')} <ArrowRightIcon disabled />
                </div>
              )}
            </div>
          )}
          <div className={classes.sectionContainer}>
            <div className={classes.headerContainer}>
              <div className={classes.header}>{Translation(`agentProfile.ap_my_awards_header_title`)}</div>
              {hasAwards && (
                <GAClickFragment
                  journey="edit_agent_profile"
                  stage="edit_awards"
                  object_name="Edit"
                  object_type="BUTTON"
                >
                  <IconButton
                    className={classes.iconButton}
                    onClick={() => history.push(`${AGENT_PROFILE_PATH}/award?noHeader`, locationState)}
                  >
                    <EditIcon />
                  </IconButton>
                </GAClickFragment>
              )}
            </div>
            {hasAwards ? (
              <>
                <AwardList
                  mappedLocale={mappedLocale}
                  badges={agentProfile.awardBadges}
                  awards={isIPadAwardViewMore ? awardList : agentProfile.awards}
                />
                <GAClickFragment
                  stage="view_full_awards"
                  object_name="ViewMore"
                  object_type="BUTTON"
                >
                  <div
                    className={classes.viewMore}
                    onClick={() => {
                      if (isPhone) {
                        history.push(`${AGENT_PROFILE_PATH}/award-view-more?noHeader`);
                      } else {
                        setIsIPadAwardViewMore(!isIPadAwardViewMore);
                      }
                    }}
                  >
                    {!isIPadAwardViewMore
                      ? Translation('agentProfile.ap_view_more_label')
                      : Translation('agentProfile.ap_view_less_label')}
                    <ArrowRightIcon />
                  </div>
                </GAClickFragment>
              </>
            ) : (
              <GAClickFragment
                journey="edit_agent_profile"
                stage="add_awards"
                object_name="AddAwards"
                object_type="BUTTON"
              >
                <div className={classes.emptyAddContainer}>
                  <button
                    className={classes.addMsgContainer}
                    type="button"
                    onClick={() => history.push(`${AGENT_PROFILE_PATH}/award?noHeader`, locationState)}
                  >
                    <AddCircle className={classes.addIcon} />
                    {Translation('agentProfile.ap_add_award_icon')}
                  </button>
                </div>
              </GAClickFragment>
            )}
          </div>
          <div className={classes.sectionContainer}>
            <div className={classes.headerContainer}>
              <div className={classes.header}>{Translation(`agentProfile.ap_promotions_header_title`)}</div>
              {hasPromotions && (
                <GAClickFragment
                  journey="edit_agent_profile"
                  stage="edit_promotions"
                  object_name="Edit"
                  object_type="BUTTON"
                >
                  <IconButton
                    className={classes.iconButton}
                    onClick={() => history.push(`${AGENT_PROFILE_PATH}/promotion?noHeader`, locationState)}
                  >
                    <EditIcon />
                  </IconButton>
                </GAClickFragment>
              )}
            </div>
            <div className={`${!hasPromotions ? classes.iPadViewNoPromotion : ''}`}>
              {hasPromotions ? (
                agentProfile.promotions[promotionMappedLocale].map((promotion) => (
                  <div className={classes.promotionCard}>
                    <ItemCard
                      disableSelect
                      item={promotion}
                      image={promotion.coverpage?.url}
                      name={promotion.name || '-'}
                      labels={promotion.tag}
                    />
                  </div>
                ))
              ) : (
                <GAClickFragment
                  journey="edit_agent_profile"
                  stage="add_promotions"
                  object_name="SelectPromotions"
                  object_type="BUTTON"
                >
                  <div className={classes.emptyAddContainer}>
                    <button
                      type="button"
                      className={classes.addMsgContainer}
                      onClick={() => history.push(`${AGENT_PROFILE_PATH}/promotion?noHeader`, locationState)}
                    >
                      <AddCircle className={classes.addIcon} />
                      {Translation('agentProfile.ap_add_promotion_icon')}
                    </button>
                  </div>
                </GAClickFragment>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AgentProfileEditMainForm;
