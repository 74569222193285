import { FC } from 'react';
import { Switch, Redirect, RouteComponentProps } from 'react-router-dom';
import PruRoute from 'src/app/common/components/PruRoute';
import { ModulePermissionProps } from 'src/app/common/module/types';
import { RECRUIT_BASE_PATH, RECRUIT_LIBRARY_BASE_PATH, RESOURCE_BASE_PATH } from '../../constants';
import Empty from '../../Empty';
import { NOT_FOUND_ERROR_PAGE_PATH } from 'src/app/common/constants';
import Create from './RecruitLibrary/Create.Page';
import List from './RecruitLibrary/List.Page';

const RecruitLibraryRoutes: FC<RouteComponentProps & ModulePermissionProps> = ({
  enableRead,
  enableCreate,
  enableUpdate,
}) => {
  return (
    <Switch>
      <Redirect
        exact
        from={`${RESOURCE_BASE_PATH}${RECRUIT_BASE_PATH}${RECRUIT_LIBRARY_BASE_PATH}`}
        to={`${RESOURCE_BASE_PATH}${RECRUIT_BASE_PATH}${RECRUIT_LIBRARY_BASE_PATH}/list`}
      />
      <PruRoute
        exact
        path={`${RESOURCE_BASE_PATH}${RECRUIT_BASE_PATH}${RECRUIT_LIBRARY_BASE_PATH}/list`}
        render={(props) => (
          <List {...props} enableRead={enableRead} enableCreate={enableCreate} enableUpdate={enableUpdate} />
        )}
      />
      <PruRoute
        exact
        path={`${RESOURCE_BASE_PATH}${RECRUIT_BASE_PATH}${RECRUIT_LIBRARY_BASE_PATH}/create`}
        component={Create}
      />
      <PruRoute
        exact
        path={`${RESOURCE_BASE_PATH}${RECRUIT_BASE_PATH}${RECRUIT_LIBRARY_BASE_PATH}/edit/:id`}
        component={Create}
      />
      <PruRoute
        exact
        path={`${RESOURCE_BASE_PATH}${RECRUIT_BASE_PATH}${RECRUIT_LIBRARY_BASE_PATH}/view/:id`}
        component={Create}
      />
      <PruRoute
        exact
        path={`${RESOURCE_BASE_PATH}${RECRUIT_BASE_PATH}${RECRUIT_LIBRARY_BASE_PATH}/performance/:id`}
        component={Empty}
      />
      <Redirect to={NOT_FOUND_ERROR_PAGE_PATH} />
    </Switch>
  );
};

export default RecruitLibraryRoutes;
