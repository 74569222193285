import { CSSProperties } from 'react';
import { ValueOf } from 'src/app/common/types/common-types';

export enum CardTypeEnum {
  MAIN = 'main',
  SPLIT = 'split',
}

export type CardHeaderProps = {
  title?: string;
  headerIcon?: JSX.Element;
  tooltipContent?: string | JSX.Element;
  handleRedirect?: () => void;
};

export type MainCardProps<T> = {
  type: CardTypeEnum.MAIN;
  data: T;
  keys: (keyof T & string)[];
  totalKey: keyof T & string;
  totalDisplayName?: string;
  caption?: string;
  remark?: string;
  chartColors?: [string, string];
  valueStyle?: CSSProperties | ((value: number) => CSSProperties);
};

export type SplitCardProps<T, K> = {
  type: CardTypeEnum.SPLIT;
  leftLabel: string;
  leftData: T;
  leftDataKeys: (keyof T & string)[];
  rightLabel: string;
  rightData: K;
  rightDataKeys: (keyof K & string)[];
  rightDataValueKeys: (keyof ValueOf<K> & string)[];
  rightDataValueTotalKey: keyof ValueOf<K> & string;
  rightChartColors?: [string, string];
  rightValueStyle?: CSSProperties | ((value: number) => CSSProperties);
  getLeftCaption?: (key: string) => string;
  getRightCaption?: (key: string) => string;
};

export type CardContentProps<T, K> = MainCardProps<T> | SplitCardProps<T, K>;

export type CardProps<T, K> = CardHeaderProps & CardContentProps<T, K>;
