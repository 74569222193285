import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { makeStyles } from 'tss-react/mui';
import { useLang } from 'src/app/i18n';
import { localeMapToBackend } from 'src/app/modules/AgentProfile/common/utils/locale-utils';
import { AgentProfileItem } from 'src/app/modules/AgentProfile/types/agent-profile-types';

type BasicInfoProps = {
  agentProfile: AgentProfileItem;
};

const useStyles = makeStyles()((theme) => ({
  agentNameStyle: {
    fontSize: 20,
    paddingTop: 4,
    fontWeight: 700,
  },
  informationContainer: {
    fontSize: 14,
  },
  informationContentStyle: {
    paddingTop: 4,
  },
}));

const BasicInfo: FC<BasicInfoProps> = ({ agentProfile }) => {
  const { classes } = useStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const currentLang = useLang();
  const mappedLocale = localeMapToBackend[currentLang] || currentLang;

  const { lbuName, designation, license } = agentProfile.agentDetail;

  return (
    <>
      <div className={classes.agentNameStyle}>{agentProfile.name[mappedLocale]}</div>
      <div className={classes.informationContainer}>
        {agentProfile.achievements && agentProfile.achievements.length !== 0 && (
          <div className={classes.informationContentStyle}>{agentProfile.achievements.join(', ')}</div>
        )}
        {designation[mappedLocale] && (
          <div className={classes.informationContentStyle}>{designation[mappedLocale]}</div>
        )}
        {lbuName && lbuName[mappedLocale] && (
          <div className={classes.informationContentStyle}>{lbuName[mappedLocale]}</div>
        )}
        {license.gi === 'Y' && (
          <div className={classes.informationContentStyle}>{Translation('agentProfile.ap_pgihk_label')}</div>
        )}
        {license.hkfi && (
          <div className={classes.informationContentStyle}>
            {Translation('agentProfile.ap_ia_license_label')}: {license.hkfi}
          </div>
        )}
        {license.mpf && (
          <div className={classes.informationContentStyle}>
            {Translation('agentProfile.ap_mpf_license_label')}: {license.mpf}
          </div>
        )}
        {license.sfc && (
          <div className={classes.informationContentStyle}>
            {Translation('agentProfile.ap_sfc_license_label')}: {license.sfc}
            <br />
            {Translation('agentProfile.ap_sfc_license_statement')}
          </div>
        )}
      </div>
    </>
  );
};

export default BasicInfo;
