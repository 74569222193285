import React, { FC, useState } from 'react';
import 'survey-react/survey.min.css';
import 'survey-core/defaultV2.css';
import { Model, StylesManager, Serializer, RendererFactory } from 'survey-core';
import { CompletedPage } from './component/CompletedPage';
import { ReactQuestionFactory, Survey } from 'survey-react-ui';
import showdown from 'showdown';
import { useIntl } from 'react-intl';
import moment from 'moment';
import { toAbsoluteUrl } from 'src/app/common/utils';
import { errorListProps, ProtectionReviewActivityEnum, WebFormPageProps } from '../types/type';
import { submitSurveyData, syncNewDataApi } from '../network/crud';
import * as SurveyCore from 'survey-core';
import { Loading } from './component/Loading';
import { Reminder } from './component/Reminder';
import { DropdownSelect } from './component/dropdownSelect';
import { ErrorPage, ErrorProps } from './component/ErrorPage';

const handelPolicies = (policyList: any[]) => {
  for (let i = 0; i < policyList.length; i++) {
    const policyKeys = Object.keys(policyList[i]);
    for (const key of policyKeys) {
      if (moment(policyList[i][key], 'YYYY-MM-DDTHH:mm:ss.sssZ', true).isValid()) {
        policyList[i][key] = moment(policyList[i][key]).utcOffset('+0800').format('DD/MM/YYYY');
      }
    }
  }
  return policyList;
};

const handelEnableData = (
  model: Model,
  inputData: any,
  isPreview: boolean,
  status: ProtectionReviewActivityEnum,
  needQuestions: any,
) => {
  if (isPreview) {
    const questionList = model.getAllQuestions();
    for (const question of questionList) {
      if (question instanceof SurveyCore.QuestionPanelDynamicModel) {
        for (const panelQuestion of question.propertyHash.panels[0].questionsValue) {
          model
            .getQuestionByName(question.propertyHash.name)
            .propertyHash.panels[0].getQuestionByName(panelQuestion.propertyHash.name).readOnly = true;
        }
      } else {
        model.getQuestionByName(question.propertyHash.name).readOnly = true;
      }
    }
  } else {
    if (inputData && (inputData.existingClient || status === ProtectionReviewActivityEnum.FormSubmitted)) {
      const keyList = Object.keys(inputData);
      if (status === ProtectionReviewActivityEnum.FormSubmitted) {
        if (keyList.includes('policies') && inputData.policies.length > 0) {
          const panelLen = Math.min(
            inputData.policies.length,
            model.getQuestionByName('policies').propertyHash.panels.length,
          );
          for (let i = 0; i < panelLen; i++) {
            const policy = inputData.policies[i];
            const policyKeys = Object.keys(policy);
            if (policy && policyKeys.includes('isTerminated')) {
              const needHandelPolicyQuestion = model.getQuestionByName('policies').propertyHash.panels[i];
              if (needHandelPolicyQuestion) {
                if (!(policyKeys.includes('isPruPolicy') && policy['isPruPolicy']) && policy['isTerminated']) {
                  for (const panelQuestion of needHandelPolicyQuestion.questionsValue) {
                    if (panelQuestion.propertyHash.name != 'isTerminated') {
                      model
                        .getQuestionByName('policies')
                        .propertyHash.panels[i].getQuestionByName(panelQuestion.propertyHash.name).readOnly = true;
                    }
                  }
                } else {
                  for (const panelQuestion of needHandelPolicyQuestion.questionsValue) {
                    if (policyKeys.includes('isPruPolicy') && policy['isPruPolicy']) {
                      const answerKeys = Object.keys(policy);
                      if (
                        ['policyCategory', 'policyType', 'accidentalDeathBenefit', 'roomType'].includes(
                          panelQuestion.propertyHash.name,
                        )
                      ) {
                        model
                          .getQuestionByName('policies')
                          .propertyHash.panels[i].getQuestionByName(panelQuestion.propertyHash.name).readOnly = false;
                      } else {
                        model
                          .getQuestionByName('policies')
                          .propertyHash.panels[i].getQuestionByName(panelQuestion.propertyHash.name).readOnly = true;
                      }
                    } else {
                      model
                        .getQuestionByName('policies')
                        .propertyHash.panels[i].getQuestionByName(panelQuestion.propertyHash.name).readOnly = false;
                    }
                  }
                }
              }
            }
          }
        }
      } else if (keyList.includes('existingClient') && inputData.existingClient) {
        const policyInformation = needQuestions ? needQuestions.policyInformation : undefined;
        if (policyInformation && policyInformation.length > 0) {
          for (let i = 0; i < policyInformation.length; i++) {
            for (const questionName of policyInformation[i]) {
              if (['policyCategory', 'policyType', 'accidentalDeathBenefit', 'roomType'].includes(questionName)) {
                model
                  .getQuestionByName('policies')
                  .propertyHash.panels[i].getQuestionByName(questionName).readOnly = false;
              } else if (model.getQuestionByName('policies').propertyHash.panels[i]?.getQuestionByName(questionName)) {
                model
                  .getQuestionByName('policies')
                  .propertyHash.panels[i].getQuestionByName(questionName).readOnly = true;
              }
            }
          }
        }
      }

      if (keyList.includes('existingClient') && inputData.existingClient) {
        const personalInformation = needQuestions ? needQuestions.personalInformation : undefined;
        if (personalInformation && personalInformation.length > 0) {
          for (const key of personalInformation) {
            const question = model.getQuestionByName(key);
            if (question && inputData[key]) {
              question.propertyHash.readOnly = true;
            }
          }
        }
      }
    }
  }
};

const handelModelData = (model: Model) => {
  const modifyData = model.data;
  const surveyKeys = Object.keys(modifyData);
  for (const surveyKey of surveyKeys) {
    if (surveyKey === 'policies') {
      for (let i = 0; i < modifyData.policies.length; i++) {
        const policyFields = Object.keys(modifyData.policies[i]);
        if (!policyFields.includes('isPruPolicy')) {
          modifyData.policies[i]['isPruPolicy'] = modifyData.policies[i]['company']
            ? modifyData.policies[i]['company'] === 'Prudential'
            : false;
        }
        if (policyFields.includes('lifeAssured') && modifyData.policies[i]['lifeAssured'] === 'Others') {
          modifyData.policies[i]['lifeAssured'] = modifyData.policies[i]['lifeAssured_Comment'];
          delete modifyData.policies[i]['lifeAssured_Comment'];
        }
        for (const policyKey of policyFields) {
          const value = modifyData.policies[i][policyKey];
          if (moment(value, 'DD/MM/YYYY', true).isValid()) {
            modifyData.policies[i][policyKey] = moment(
              modifyData.policies[i][policyKey],
              'DD/MM/YYYY',
              true,
            ).toISOString();
          }
        }
      }
      modifyData.policies = modifyData.policies.filter((item: any) => item != undefined);
      if (modifyData.policies.length === 0) {
        delete modifyData.policies;
      }
    } else if (surveyKey === 'hasDependents') {
      if (modifyData.hasDependents === 'No') {
        if (surveyKeys.includes('dependentsNumber')) {
          delete modifyData.dependentsNumber;
        }
        if (surveyKeys.includes('dependentsInformation')) {
          delete modifyData.dependentsInformation;
        }
      } else {
        if (!surveyKeys.includes('dependentsNumber') && modifyData.dependentsInformation) {
          delete modifyData.dependentsInformation;
        }
      }
    } else if (surveyKey === 'dependentsInformation' && modifyData.dependentsInformation) {
      modifyData.dependentsInformation = modifyData.dependentsInformation
        .map((item: any) => {
          if (Object.keys(item).length > 0) {
            return item;
          }
        })
        .filter((item: any) => item != undefined);
      if (modifyData.dependentsInformation.length === 0) {
        delete modifyData.dependentsInformation;
      }
    }
  }
  return modifyData;
};

StylesManager.applyTheme('defaultV2');

export const ReviewWebFormPage: FC<WebFormPageProps> = ({
  template,
  inputData,
  isPreview,
  language,
  resultId,
  status,
  initialPolicyCount,
}) => {
  const [needQuestions, setNeedQuestions] = useState(
    inputData && Object.keys(inputData).includes('needQuestions') ? inputData.needQuestions : undefined,
  );
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const isUpdate = status != ProtectionReviewActivityEnum.FormOpened;
  const [isCompleted, setIsCompleted] = useState<boolean>(false);
  const [isShowReminder, setIsShowReminder] = useState<boolean>(false);
  const [loadingDisplayMode, setLoadingDisplayMode] = useState<'flex' | 'none'>('none');
  const [userData, setUserData] = useState<any>(inputData);
  const [inputSyncData, setInputSyncData] = useState<any>(inputData);
  const [pageNo, setPageNo] = useState<number>(0);
  const [completeMessage, setCompleteMessage] = useState<{
    title: { enUs: string; local: string };
    content: { enUs: string; local: string };
  }>();
  const [errQuestionList, SetErrQuestionList] = useState<errorListProps>({
    personalInformation: {},
    policyInformation: [],
  });
  const [needInitial, setNeedInitial] = useState<boolean>(true);
  const [isShowError, setIsShowError] = useState<boolean>(false);
  const [errorProps, setErrProps] = useState<ErrorProps>({ code: '', message: '' });
  const [hasError, setHasError] = useState<boolean>(false);
  const [policyCount, setPolicyCount] = useState<number>(initialPolicyCount);

  Serializer.addProperty('page', 'syncNewData:text');
  ReactQuestionFactory.Instance.registerQuestion('sv-dropdown-react', (props) => {
    return React.createElement(DropdownSelect, { props, language: language });
  });
  RendererFactory.Instance.registerRenderer('dropdown', 'dropdown-react', 'sv-dropdown-react');

  const submitSurvey = async (actionType: ProtectionReviewActivityEnum): Promise<void> => {
    setIsShowReminder(false);
    setLoadingDisplayMode('flex');
    const modifyData = handelModelData(model);
    setUserData(modifyData);
    setInputSyncData(modifyData);
    try {
      modifyData.needQuestions = needQuestions;
      console.log('submit data', modifyData);
      const submitData = await submitSurveyData(resultId, modifyData, actionType);
      setCompleteMessage(submitData.result.completeMessage);
      setLoadingDisplayMode('none');
      setIsCompleted(true);
    } catch (error: any) {
      setErrProps({ code: error.code, message: error.message });
      setIsShowError(true);
    }
  };
  const handleError = (): void => {
    setIsShowReminder(false);
    let hasErr = hasError;
    let firstErrorIndex = -1;
    const pages = model.visiblePages;
    for (let i = 0; i < pages.length; i++) {
      if (pages[i].hasErrors()) {
        hasErr = true;
        firstErrorIndex = i;
        break;
      } else if (status === ProtectionReviewActivityEnum.FormSubmitted) {
        const panels = model.getQuestionByName('policies').propertyHash.panels;
        for (let i = 0; i < panels.length; i++) {
          for (const panelQuestion of panels[i].questionsValue) {
            const propertyHash = panelQuestion.propertyHash;
            if (!Object.keys(propertyHash).includes('visible') || propertyHash.visible) {
              if (propertyHash.isRequired && !propertyHash.isAnswered) {
                hasErr = true;
                break;
              }
            }
          }
          if (hasErr) {
            break;
          }
        }
      }
    }
    if (hasErr) {
      model.completeLastPage();
      model.currentPageNo = firstErrorIndex;
      handelErrorQuestions();
    }
    setHasError(hasErr);
  };
  const handelErrorQuestions = () => {
    const errList = errQuestionList;
    const questionList = model.getAllQuestions();
    const personalInformation: Record<any, boolean> = {};
    let panelErrorList = [];
    for (const question of questionList) {
      const propertyHash = question.propertyHash;
      if (!(question instanceof SurveyCore.QuestionPanelDynamicModel)) {
        if (Object.keys(propertyHash).includes('isReadOnly') && propertyHash.isReadOnly) {
          personalInformation[propertyHash.name] = false;
        } else {
          personalInformation[propertyHash.name] = propertyHash.isRequired && !propertyHash.isAnswered;
        }
      } else if (question instanceof SurveyCore.QuestionPanelDynamicModel && propertyHash.name === 'policies') {
        const panels = propertyHash.panels;
        for (let i = 0; i < panels.length; i++) {
          let policyErrorList: Record<any, boolean> = {};
          for (const panelQuestion of panels[i].questionsValue) {
            const propertyHash = panelQuestion.propertyHash;
            policyErrorList[propertyHash.name] = propertyHash.isRequired && !propertyHash.isAnswered;
          }
          panelErrorList.push(policyErrorList);
        }
      }
    }
    errList.personalInformation = personalInformation;
    errList.policyInformation = panelErrorList;
    SetErrQuestionList(errList);
  };
  const syncNewData = async (model: Model) => {
    setLoadingDisplayMode('flex');
    setNeedInitial(true);
    try {
      const modifyData = handelModelData(model);
      modifyData.currentPageNo = model.currentPageNo;
      await submitSurveyData(resultId, modifyData, status);
      const syncNewDataResult = await syncNewDataApi(
        resultId,
        language === 'en' ? 'en' : language === 'zh' ? 'zhHk' : undefined,
      );
      const modifySyncData = syncNewDataResult.result;
      console.log('sync new Data', modifySyncData);
      template.surveyTemplate.description =
        language === 'en'
          ? `${Translation('reviewWebForm.update.time')} ${moment(modifySyncData.updatedAt ?? new Date())
              .utcOffset('+0800')
              .format('DD/MM/YYYY HH:mm')}`
          : language === 'zh'
          ? `${Translation('reviewWebForm.update.time')} ${moment(modifySyncData.updatedAt ?? new Date())
              .utcOffset('+0800')
              .format('YYYY年M月D日 HH:mm')}`
          : '';
      const keys = Object.keys(modifyData);
      if (modifySyncData?.policies) {
        const policyList = modifySyncData.policies;
        if (status === ProtectionReviewActivityEnum.FormSubmitted) {
          const policyNum = policyList.filter((item: any) => item.isPruPolicy).length;
          setPolicyCount(policyNum);
        }
        for (let i = 0; i < policyList.length; i++) {
          const panelKeys = Object.keys(policyList[i]);
          if (
            panelKeys.includes('lifeAssured') &&
            policyList[i]['lifeAssured'] != 'Same as Policyowner' &&
            policyList[i]['lifeAssured'] != 'Others'
          ) {
            modifySyncData.policies[i]['lifeAssured_Comment'] = modifySyncData.policies[i]['lifeAssured'];
            modifySyncData.policies[i]['lifeAssured'] = 'Others';
          }
        }
      }
      if (modifySyncData) {
        setNeedQuestions(modifySyncData.needQuestions);
        if (keys.includes('policies') && modifySyncData.policies && modifySyncData.policies.length > 0) {
          const policyList = handelPolicies(modifySyncData.policies);
          modifySyncData.policies = policyList;
        }

        const nameEn = modifySyncData.clientNameEn;
        if (nameEn && nameEn.match('^[A-Za-z]*(\\s[A-Za-z]*)*$')) {
          const lowerStr = nameEn.toLowerCase();
          modifySyncData.clientNameEn = lowerStr.replace(/\b\w|\s\w/g, (first: string) => {
            return first.toUpperCase();
          });
        }

        if (!modifySyncData.clientNameLocal || modifySyncData.clientNameLocal === null) {
          modifySyncData.clientNameLocal = 'N/A';
        }
      }
      setUserData(modifySyncData);
      setInputSyncData(modifySyncData);
    } catch (error: any) {
      setErrProps({ code: error.code, message: error.message });
      setIsShowError(true);
    }
    setLoadingDisplayMode('none');
  };
  const loadData = (model: Model) => {
    model.data = inputSyncData;
    model.currentPageNo = pageNo;
    const keys = Object.keys(userData ?? {});
    if (
      status === ProtectionReviewActivityEnum.FormOpened &&
      !needQuestions &&
      userData &&
      keys.includes('existingClient') &&
      userData.existingClient
    ) {
      let arr: { personalInformation: string[] | undefined; policyInformation: any[] | undefined } = {
        personalInformation: undefined,
        policyInformation: undefined,
      };
      if (keys.includes('policies') && userData.policies.length > 0) {
        const keysList = [];
        for (const policy of userData.policies) {
          const policykeys = Object.entries(policy)
            .map((item) => {
              if (item[1] !== null) {
                return item[0];
              }
            })
            .filter((value) => value !== null);
          if (policykeys.includes('isPruPolicy') && policy.isPruPolicy) {
            keysList.push(policykeys);
          } else {
            keysList.push([]);
          }
        }
        arr.policyInformation = keysList;
      }
      const list = keys.filter((item) => item !== 'policies' && item !== 'existingClient');
      arr.personalInformation = list.length > 0 ? list : undefined;
      setNeedQuestions(arr);
    }
    if (needInitial && !isPreview && userData) {
      initialPolicyQuestion(model);
      setNeedInitial(false);
    }
    handelEnableData(model, userData, isPreview, status, needQuestions);
  };

  const initialPolicyQuestion = (model: Model) => {
    const policyErrorList = [];
    if (Object.keys(userData).includes('policies')) {
      const panels = model.getQuestionByName('policies').propertyHash.panels;
      const panelLen = Math.min(
        userData.policies.length,
        model.getQuestionByName('policies').propertyHash.panels.length,
      );
      for (let i = 0; i < panelLen; i++) {
        let policyError: Record<any, boolean> = {};
        const policy = userData.policies[i];
        const policyKey = Object.keys(policy);
        if (policyKey.includes('isPruPolicy') && policy.isPruPolicy) {
          for (const policyQuestion of panels[i].questionsValue) {
            const propertyHash = policyQuestion.propertyHash;
            if (!Object.keys(propertyHash).includes('visible') || propertyHash.visible) {
              if (
                Object.keys(propertyHash).includes('isRequired') &&
                propertyHash.isRequired &&
                !propertyHash.isAnswered
              ) {
                policyError[propertyHash.name] = true;
                setHasError(true);
              }
            } else {
              policyError[propertyHash.name] = false;
            }
          }
        }
        policyErrorList.push(policyError);
      }
    } else {
      policyErrorList.push({});
    }
    const err = errQuestionList;
    err.policyInformation = policyErrorList;
    SetErrQuestionList(err);
  };

  if (
    status === ProtectionReviewActivityEnum.FormSubmitted &&
    (template.surveyTemplate.pages[
      template.surveyTemplate.pages.length - 1
    ]?.elements[0]?.elements[0]?.templateElements?.at(0)?.name ?? 'isTerminated') != 'isTerminated'
  ) {
    template.surveyTemplate.pages[
      template.surveyTemplate.pages.length - 1
    ].elements[0].elements[0].templateElements.unshift({
      type: 'radiogroup',
      name: 'isTerminated',
      title: {
        en: 'Policy Status',
        zh: '保單状态',
      },
      choices: [
        {
          value: false,
          text: {
            en: 'Inforce',
            zh: '生效',
          },
        },
        {
          value: true,
          text: {
            en: 'Lapsed',
            zh: '失效',
          },
        },
      ],
      isRequired: true,
      requiredErrorText: {
        en: 'Please select.',
        zh: '請選擇',
      },
      defaultValue: false,
    });
  }

  const model = new Model(template.surveyTemplate);
  const completeBtn = document.getElementById('sv-nav-complete');
  if (completeBtn) {
    completeBtn.style.display = 'none';
  }
  if (!isCompleted) {
    loadData(model);
  }

  if (isUpdate && !isPreview) {
    model.onGetPageTitleActions.add((survey: any, options: any) => {
      options.titleActions = [
        {
          title: language === 'en' ? 'Fetch Latest Data' : language === 'zh' ? '同步最新資料' : '',
          innerCss: 'syncNewData',
          action: () => {
            syncNewData(model);
          },
        },
      ];
    });
  }

  if (!isPreview) {
    model.addNavigationItem({
      id: 'survey-my-complete',
      title: language === 'en' ? 'Update' : language === 'zh' ? '更新' : '',
      innerCss: 'sd-navigation__complete-btn',
      action: async () => {
        const data = inputSyncData;
        if (data) {
          let valid = true;
          const questionList = model.getAllQuestions();
          for (const question of questionList) {
            if (!(question instanceof SurveyCore.QuestionPanelDynamicModel)) {
              const propertyHash = question.propertyHash;
              if (!Object.keys(propertyHash).includes('visible') || propertyHash.visible) {
                if (propertyHash.isRequired && !propertyHash.isAnswered) {
                  valid = false;
                  break;
                }
              }
            } else if (question instanceof SurveyCore.QuestionPanelDynamicModel && data.policies && data.policies[0]) {
              const panels = model.getQuestionByName('policies').propertyHash.panels;
              for (let i = 0; i < panels.length; i++) {
                for (const panelQuestion of panels[i].questionsValue) {
                  const propertyHash = panelQuestion.propertyHash;
                  if (!Object.keys(propertyHash).includes('visible') || propertyHash.visible) {
                    if (propertyHash.isRequired && !propertyHash.isAnswered) {
                      valid = false;
                      break;
                    }
                  }
                }
                if (!valid) {
                  break;
                }
              }
            }
          }
          if (valid && data['hasDependents'] === 'Yes' && !data['dependentsNumber']) {
            valid = false;
          } else if (valid && !data.policies[0]) {
            valid = false;
          }
          if (valid) {
            await submitSurvey(ProtectionReviewActivityEnum.FormSubmitted);
          } else {
            setIsShowReminder(true);
          }
        } else {
          setIsShowReminder(true);
        }
      },
    });
  }

  model.onAfterRenderPage.add(() => {
    if (!isPreview) {
      const myCompleteBtn = document.getElementById('survey-my-complete');
      if (myCompleteBtn) {
        myCompleteBtn.style.display = model.isLastPage ? 'initial' : 'none';
      }
    }
  });

  model.onAfterRenderPanel.add((sender: any, options: any) => {
    if (options.panel.propertyHash.name === 'policyInformation') {
      const trashList = document.getElementsByClassName(
        'sd-action sd-paneldynamic__btn sd-action--negative sd-paneldynamic__remove-btn',
      );
      if (isPreview) {
        const addPolicy = document.getElementsByClassName(
          'sd-action sd-paneldynamic__btn sd-paneldynamic__add-btn sd-paneldynamic__add-btn--list-mode',
        );
        if (trashList.length > 0) {
          trashList[0].setAttribute('disabled', 'true');
        }
        if (addPolicy.length > 0) {
          addPolicy[0].setAttribute('disabled', 'true');
        }
      } else {
        if (policyCount > 0 && trashList.length > 0 && userData && userData.policies) {
          for (let i = 0; i < policyCount; i++) {
            if (trashList[i]) {
              trashList[i].setAttribute('style', 'display: none');
            }
          }
        }
      }
    }
  });

  model.onAfterRenderQuestion.add((sender: any, options: any) => {
    if (options.question.propertyHash.name === 'dependentYear') {
      const handelMouse = document.getElementById('mousehandel');
      if (handelMouse) {
        const des = document.getElementById('showDescription');
        if (des) {
          handelMouse.onmouseenter = () => {
            des.style.display = 'inherit';
          };
          handelMouse.onmouseleave = () => {
            des.style.display = 'none';
          };
        }
      }
    }
  });

  model.onUpdateQuestionCssClasses.add((survey: any, options: any) => {
    if (options.question.propertyHash.name === 'policies') {
      options.cssClasses.title += ' my-panel-title';
      options.cssClasses.root += ' my-panel-root';
      options.cssClasses.footer += ' my-panel-footer';
    } else if (options.question.propertyHash.name === 'lifeAssured_Comment') {
      options.cssClasses.root += ' lifeAssuredFliter';
      if (language === 'zh') {
        options.question.propertyHash.renderedPlaceHolder = options.question.localizableStrings.placeHolder.values.zh;
      }
    }
    if (options.question instanceof SurveyCore.QuestionTextModel) {
      options.question.propertyHash.renderedPlaceHolder = Translation('reviewWebForm.dynamicPanel.input.placeholder');
    }
  });

  model.onTextMarkdown.add((survey: any, options: any) => {
    if (
      options.text.match(/\[(.*)\]\((.*)\)/g) ||
      options.text.match(/<svg.*?\/svg>/g) ||
      options.text.match(/<text.*/g)
    ) {
      const converter = new showdown.Converter({ openLinksInNewWindow: true });
      const str = converter.makeHtml(options.text);
      options.html = str.substring(3).substring(0, str.length - 4);
    }
  });

  model.onCurrentPageChanged.add(() => {
    setPageNo(model.currentPageNo);
  });

  model.onValueChanged.add((sender: any, options: any) => {
    const errList = errQuestionList;
    if (options.name === 'policies' && model.data.policies) {
      const modifyData = model.data;
      const oldValue = userData;
      const policiesPanel = model.getQuestionByName('policies');
      for (let i = 0; i < modifyData.policies.length; i++) {
        const modifyKeys = Object.keys(modifyData.policies[i] ?? {});
        if (
          modifyKeys.includes('policyNumber') &&
          typeof modifyData.policies[i].policyNumber === 'string' &&
          !modifyData.policies[i].policyNumber.match('^[0]*[1-9]{1,}([0-9]*)$')
        ) {
          delete modifyData.policies[i].policyNumber;
        }
        if (modifyData.policies[i]?.annualPremium) {
          modifyData.policies[i].annualPremium = Math.floor(modifyData.policies[i].annualPremium * 100) / 100;
        }
        if (modifyData.policies[i]?.benefitTerm) {
          modifyData.policies[i].benefitTerm = Math.floor(modifyData.policies[i].benefitTerm * 100) / 100;
        }
        if (modifyData.policies[i]?.accidentalDeathBenefit) {
          modifyData.policies[i].accidentalDeathBenefit =
            Math.floor(modifyData.policies[i].accidentalDeathBenefit * 100) / 100;
        }
        if (modifyData.policies[i]?.sumAssured) {
          modifyData.policies[i].sumAssured = Math.floor(modifyData.policies[i].sumAssured * 100) / 100;
        }
        if (status === ProtectionReviewActivityEnum.FormSubmitted) {
          const oldKeys = Object.keys(oldValue.policies[i] ?? {});
          if (
            !(
              (modifyKeys.includes('isTerminated') &&
                oldKeys.includes('isTerminated') &&
                modifyData.policies[i]['isTerminated'] === oldValue.policies[i]['isTerminated']) ||
              (!modifyKeys.includes('isTerminated') && !oldKeys.includes('isTerminated'))
            )
          ) {
            setUserData(modifyData);
          }
        }
        const policyPanel = policiesPanel.propertyHash.panels[i];
        for (const key of modifyKeys) {
          const props = policyPanel.getQuestionByName(key);
          if (props) {
            if (i >= errList.policyInformation.length) {
              errList.policyInformation.push({});
            }
            if (Object.keys(props.propertyHash).includes('isReadOnly') && props.propertyHash.isReadOnly) {
              errList.policyInformation[i][key] = false;
            } else {
              errList.policyInformation[i][key] = props.propertyHash.isRequired && !props.propertyHash.isAnswered;
            }
          }
        }
      }
      model.data = modifyData;
    }
    if (options.name !== 'policies' && options.name !== 'dependentsInformation') {
      const questionProps = options.question.propertyHash;
      const propKeys = Object.keys(questionProps);
      if (propKeys.includes('isRequired') && questionProps.isRequired) {
        errList.personalInformation[options.question.propertyHash.name] = options.value ? false : true;
      }
    }
    setInputSyncData(model.data);
  });

  let index = -1;
  model.onAfterRenderQuestion.add((sender: any, options: any) => {
    if (
      errQuestionList &&
      (Object.keys(errQuestionList.personalInformation ?? {}).length > 0 ||
        (errQuestionList.policyInformation &&
          errQuestionList.policyInformation.filter((item: any) => Object.keys(item ?? {}).length > 0).length > 0))
    ) {
      if (
        status === ProtectionReviewActivityEnum.FormSubmitted
          ? options.question.propertyHash.name === 'isTerminated'
          : options.question.propertyHash.name === 'policyName'
      ) {
        index++;
      } else if (options.question.propertyHash.name === 'policies') {
        index = -1;
      }
      let showError = false;
      if (
        !options.question.parentQuestionValue &&
        errQuestionList.personalInformation &&
        errQuestionList.personalInformation[options.question.propertyHash.name]
      ) {
        showError = true;
      } else if (
        (options.question.parentQuestionValue || options.question.propertyHash.name === 'isTerminated') &&
        index < errQuestionList.policyInformation.length &&
        Object.keys(errQuestionList.policyInformation[index] ?? {}).length !== 0 &&
        errQuestionList.policyInformation[index] &&
        errQuestionList.policyInformation[index][options.question.propertyHash.name]
      ) {
        showError = true;
      }
      if (showError) {
        const errDiv = document.createElement('div');
        const errText = document.createElement('span');
        errText.style.color = '#e8192c';
        errText.style.fontWeight = '500';
        errText.innerText =
          language === 'en'
            ? options.question.localizableStrings.requiredErrorText.values.en
            : language === 'zh'
            ? options.question.localizableStrings.requiredErrorText.values.zh
            : '';
        errDiv.appendChild(errText);
        options.htmlElement.lastChild.appendChild(errDiv);
      } else {
        if (options.htmlElement.lastChild.childElementCount > 2) {
          options.htmlElement.lastChild.removeChild(options.htmlElement.lastChild);
        }
      }
    }
  });

  model.onDynamicPanelRemoved.add((sender: any, options: any) => {
    if (options.question.propertyHash.name === 'policies') {
      if (status === ProtectionReviewActivityEnum.FormSubmitted) {
        setUserData(model.data);
      }
      const errlist = errQuestionList;
      errlist.policyInformation.splice(options.panelIndex, 1);
      SetErrQuestionList(errlist);
    }
  });

  model.onDynamicPanelAdded.add((sender: any, options: any) => {
    if (options.question.propertyHash.name === 'policies') {
      const errList = errQuestionList;
      errList.policyInformation.push({});
      SetErrQuestionList(errList);
    }
  });

  model.onCompleting.add((sender: any, options: any) => {
    options.allowComplete = !model.hasErrors(true, true);
  });

  return (
    <>
      {isCompleted ? (
        <CompletedPage title={completeMessage?.title} content={completeMessage?.content} isUpdate={isUpdate} />
      ) : isShowError ? (
        <ErrorPage code={errorProps.code} message={errorProps.message} />
      ) : (
        <>
          <Reminder
            isShowReminder={isShowReminder}
            handleError={handleError}
            submitSurvey={async () => {
              await submitSurvey(ProtectionReviewActivityEnum.FormSaved);
            }}
            cancelSubmit={() => {
              setIsShowReminder(false);
            }}
            status={status}
          />
          <Loading displayMode={loadingDisplayMode} />

          <div
            id="webForm"
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              height: '100%',
            }}
          >
            <div
              style={{
                position: isShowReminder || loadingDisplayMode === 'flex' ? 'fixed' : 'initial',
                width: '100%',
                margin: '16px 0 30px 0',
                boxShadow: '0 4px 10px #00000019',
              }}
            >
              <div style={{ height: '180px' }}>
                <img
                  src={
                    template.bannerData
                      ? `data:image/gif;base64,${template.bannerData}`
                      : toAbsoluteUrl('/media/insurance-summary/reviewWebForm_default_banner.png')
                  }
                  style={{ height: '100%', width: '100%', objectFit: 'cover' }}
                />
              </div>
              <div style={{ minHeight: 'max-content', overflowX: 'hidden' }}>
                <Survey model={model} />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
