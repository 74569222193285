import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  container: {
    width: 224,
    borderRight: '1px solid #F0F0F0',
    display: 'flex',
    flexDirection: 'column',
    marginRight: 5,
  },
  backButtonContainer: {
    backgroundColor: 'white',
    height: 52,
    marginBottom: 5,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontWeight: 600,
  },
  leftBarContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
  },
  progressContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  level1: {
    borderBottom: '1px solid #F0F0F0',
  },
  level1Title: {
    paddingTop: 15,
    paddingBottom: 15,
    fontWeight: 'bold',
    flex: 1,
    display: 'flex',
    alignItems: 'center',
  },
  level2: {
    borderTop: '1px solid #F0F0F0',
    marginLeft: 15,
    height: 55,
    display: 'flex',
    flexDirection: 'row',
  },
  level2Title: {
    padding: 15,
    fontWeight: 'bold',
    display: 'flex',
    flex: 1,
  },
  level2Container: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
  },
  footContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: 15,
    marginTop: 30,
  },
  btnCancelWrap: {
    margin: 10,
    display: 'flex',
    justifyContent: 'space-between',
  },
  tips: {
    color: '#858585',
    marginTop: 15,
  },
  lock: {
    marginRight: 10,
  },
}));
