import { CSSProperties } from 'react';

export enum ChartTypeEnum {
  SINGLE_STACKED_BAR = 'single_stacked_bar',
  SINGLE_RADIAL_BAR = 'single_radial_bar',
}

export type SingleStackedBarProps<T> = {
  type: ChartTypeEnum.SINGLE_STACKED_BAR;
  data: T;
  keys: (keyof T & string)[];
  totalKey: keyof T & string;
  colors: string[];
  getDisplayName: (key: string) => string;
};

export type SingleRadialBarProps<T> = {
  type: ChartTypeEnum.SINGLE_RADIAL_BAR;
  data: T;
  keys: (keyof T & string)[];
  totalKey: keyof T & string;
  colors: string[];
  disableTotal?: boolean;
  totalDisplayName?: string;
  chartContainerStyle?: CSSProperties;
  totalContainerStyle?: CSSProperties;
};

export type ChartProps<T> = SingleStackedBarProps<T> | SingleRadialBarProps<T>;

export type DashboardChartProps<T> = {
  style?: CSSProperties;
} & ChartProps<T>;
