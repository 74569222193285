import React, { FC, useEffect, useState } from 'react';
import { barProgressMap, pieOption, reverseNum } from '../../../constants';
import Chart from './Chart';
import '../../../index.css';
import { barChartType, barData, pieChartType, tabBarData } from '../../../types/chart-types';
import { BarChart } from './BarChart';
import { useIntl } from 'react-intl';

type DashboardDetailPageProps = {
  pieChart?: pieChartType;
  barCharts: tabBarData;
  percentage?: Record<string, string>;
  type: boolean;
  isExport?: boolean;
};

type barChartProp = {
  name: string;
  data: barData;
};

export const DashboardDetailPage: FC<DashboardDetailPageProps> = ({
  pieChart,
  barCharts,
  percentage,
  type,
  isExport,
}) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const [getPercentage, updatePercentage] = useState<Record<string, string>>();
  console.log(pieChart);

  useEffect(() => {
    updatePercentage(percentage);
  }, [percentage]);

  const BarChartView = (prop: barChartProp) => {
    const lowerName = prop.name.toLowerCase().split(' ').join('');
    let name: barChartType = barProgressMap.get(prop.name);
    name.series.data = prop.data;

    return (
      <div
        className="bar-container"
        style={window.innerWidth > 800 || isExport ? { display: 'table', margin: 'auto', width: '80%' } : {}}
      >
        <div className="bar-chart">
          <div className="bar-chart-title">
            <div className={'bar-chart-' + lowerName}> </div>
            <div className="bar-chart-span">
              {Translation('summary.dashboard.page.category.' + lowerName)} (
              {getPercentage?.[Translation('summary.dashboard.page.category.' + lowerName)] ?? '0.0'}%)
            </div>
          </div>
          <div className="bar-chart-money">
            <div className="bar-chart-number">
              {type && '$'}
              {reverseNum(name?.series?.data?.total)}
            </div>
            <div className="bar-chart-measurement">
              {type
                ? Translation('summary.dashboard.bar.chart.default')
                : Translation(
                    name?.series?.data?.total > 1
                      ? 'summary.dashboard.bar.chart.policies'
                      : 'summary.dashboard.bar.chart.policy',
                  )}
            </div>
          </div>
          <BarChart type={barCharts?.type ?? undefined} data={name}></BarChart>
        </div>
      </div>
    );
  };

  return (
    <div className="charts-view">
      {pieChart && <Chart options={pieChart} style={{ height: 300 }} isExport={false}></Chart>}
      <BarChartView name="Life" data={barCharts.life} />
      <BarChartView name="Medical" data={barCharts.medical} />
      <BarChartView name="Critical Illness" data={barCharts.criticalIllness} />
      <BarChartView name="Savings" data={barCharts.savings} />
      <BarChartView name="Others" data={barCharts.others} />
    </div>
  );
};
