import { forEach } from 'lodash';
import { Dispatch } from 'react';
import apiClient from '../../../common/network/axios';
import { convertServerLocale, RegionLocale } from 'src/app/i18n';
import { apiErrorHandler, AGENT_URL } from 'src/app/common/network';
import {
  AgentItem,
  AgentListParam,
  AgentPaginateList,
  CampaignDetailItem,
  CampaignItem,
  CampaignListParam,
  CampaignPaginateList,
  CampaignStructureItem,
  CampaignTypeItem,
  DownlineVerifyList,
  ImportVerify,
  LeadUploadItem,
  NonDownlineVerifyList,
} from '../types/campaign-types';

export const fetchCurrentAgentInfo = async (lang: string, dispatch?: Dispatch<any>): Promise<any> => {
  let queryPath = `${AGENT_URL}/agents/me?lang=${convertServerLocale(lang as RegionLocale)}`;

  return apiClient
    .get<any>(queryPath)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      // debugger;
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchCampaignList = async (
  params: CampaignListParam,
  sortKeys: { key: string; value?: string }[],
  dispatch?: Dispatch<any>,
): Promise<CampaignPaginateList> => {
  let queryPath = `${AGENT_URL}/agents/me/agency-campaign?`;
  forEach(params, (param, key) => {
    queryPath += param ? `${key}=${param}&` : '';
  });

  //Query for sorting
  let sortingCount = 0;
  queryPath += `sort=`;
  forEach(sortKeys, (sortParam, key) => {
    if (sortParam.value) {
      queryPath += `${sortingCount === 0 ? `` : `,`}${sortParam.key}:${sortParam.value}`;
      sortingCount++;
    }
  });
  if (sortingCount === 0) {
    queryPath += `updatedAt:desc`;
  }

  return apiClient
    .get<CampaignPaginateList>(queryPath)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchCampaignTypeList = async (dispatch?: Dispatch<any>): Promise<CampaignTypeItem[]> => {
  return apiClient
    .get<CampaignTypeItem[]>(`${AGENT_URL}/agents/me/campaign-type/list`)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchCampaignTypeStructure = async (
  campaignTypeId: string,
  dispatch?: Dispatch<any>,
): Promise<CampaignStructureItem> => {
  return apiClient
    .get<CampaignStructureItem>(`${AGENT_URL}/agents/me/campaign-type/${campaignTypeId}`)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const downloadParticipantUploadCsv = async (dispatch?: Dispatch<any>): Promise<string> => {
  return apiClient
    .get<string>(`${AGENT_URL}/agents/me/agency-campaign/participant/template/download`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchDownlineList = async (
  params: AgentListParam,
  sortKeys: { key: string; value?: string }[],
  dispatch?: Dispatch<any>,
): Promise<AgentPaginateList> => {
  let queryPath = `${AGENT_URL}/agents/me/agency-campaign/downline/list?`;
  forEach(params, (param, key) => {
    queryPath += param ? `${key}=${param}&` : '';
  });
  //Query for sorting
  let sortingCount = 0;
  queryPath += `sort=`;
  forEach(sortKeys, (sortParam, key) => {
    if (sortParam.value) {
      queryPath += `${sortingCount === 0 ? `` : `,`}${sortParam.key}:${sortParam.value}`;
      sortingCount++;
    }
  });
  if (sortingCount === 0) {
    queryPath += `updatedAt:desc`;
  }
  return apiClient
    .get<AgentPaginateList>(queryPath)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

/**
 * select from list
 * @returns
 */
export const verifyDownlineList = async (
  param: ImportVerify,
  dispatch?: Dispatch<any>,
): Promise<NonDownlineVerifyList> => {
  let path = `${AGENT_URL}/agents/me/agency-campaign/downline/verification`;
  return apiClient
    .post<NonDownlineVerifyList>(path, param, { headers: { 'Content-Type': 'application/json' } })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};
/**
 * eligibility-check for import participant from file
 * @param dispatch
 * @returns
 */
export const verifyNonDownlineList = async (
  param: ImportVerify,
  dispatch?: Dispatch<any>,
): Promise<NonDownlineVerifyList> => {
  let path = `${AGENT_URL}/agents/me/agency-campaign/non-downline/verification`;
  return apiClient
    .post<NonDownlineVerifyList>(path, param, {
      headers: { 'Content-Type': 'application/json' },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const verifyEligibilityList = async (
  agentList: string[],
  rules: string[],
  dispatch?: Dispatch<any>,
): Promise<DownlineVerifyList> => {
  let path = `${AGENT_URL}/agents/me/agency-campaign/eligibility-check`;
  return apiClient
    .post<DownlineVerifyList>(
      path,
      {
        agents: agentList,
        rules: rules,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const createNewCampaign = async (
  data: CampaignDetailItem,
  dispatch?: Dispatch<any>,
): Promise<CampaignDetailItem> => {
  return apiClient
    .post<CampaignDetailItem>(`${AGENT_URL}/agents/me/agency-campaign`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const updateCampaign = async (
  data: CampaignDetailItem,
  campaignId: string,
  dispatch?: Dispatch<any>,
): Promise<CampaignDetailItem> => {
  return apiClient
    .patch<CampaignDetailItem>(`${AGENT_URL}/agents/me/agency-campaign/${campaignId}`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const submitCampaign = async (
  data: CampaignDetailItem,
  campaignId: string,
  dispatch?: Dispatch<any>,
): Promise<CampaignDetailItem> => {
  return apiClient
    .patch<CampaignDetailItem>(`${AGENT_URL}/agents/me/agency-campaign/submit/${campaignId}`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const cancelCampaignSubmission = async (
  data: CampaignDetailItem,
  dispatch?: Dispatch<any>,
): Promise<CampaignDetailItem> => {
  return apiClient
    .post<CampaignDetailItem>(`${AGENT_URL}/agents/me/agency-campaign/discard-status`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchCampaignItem = async (id: string, dispatch?: Dispatch<any>): Promise<CampaignDetailItem> => {
  return apiClient
    .get<CampaignDetailItem>(`${AGENT_URL}/agents/me/agency-campaign/${id}`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const downloadLeadsTemplateCsv = async (dispatch?: Dispatch<any>): Promise<string> => {
  return apiClient
    .get<string>(`${AGENT_URL}/agents/me/agency-campaign/lead/template/download`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const uploadLead = async (data: LeadUploadItem, dispatch?: Dispatch<any>): Promise<LeadUploadItem> => {
  return apiClient
    .post<CampaignDetailItem>(`${AGENT_URL}/agents/me/agency-campaign/lead/create`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchParticipantList = async (
  params: AgentListParam,
  sortKeys: { key: string; value?: string }[],
  dispatch?: Dispatch<any>,
): Promise<AgentPaginateList> => {
  let queryPath = `${AGENT_URL}/agents/me/agency-campaign/participant/list?`;
  forEach(params, (param, key) => {
    queryPath += param ? `${key}=${param}&` : '';
  });
  //Query for sorting
  let sortingCount = 0;
  queryPath += `sortBy=`;
  forEach(sortKeys, (sortParam, key) => {
    if (sortParam.value) {
      queryPath += `${sortingCount === 0 ? `` : `,`}${sortParam.key}:${sortParam.value}`;
      sortingCount++;
    }
  });
  if (sortingCount === 0) {
    queryPath += `updatedAt:desc`;
  }
  return apiClient
    .get<AgentPaginateList>(queryPath)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const addParticipant = async (data: any[], dispatch?: Dispatch<any>): Promise<LeadUploadItem[]> => {
  return apiClient
    .post<CampaignDetailItem>(`${AGENT_URL}/agents/me/agency-campaign/participant`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const removeParticipant = async (data: any, dispatch?: Dispatch<any>): Promise<LeadUploadItem> => {
  return apiClient
    .post<CampaignDetailItem>(`${AGENT_URL}/agents/me/agency-campaign/participant/remove`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const sendInvitation = async (data: any, dispatch?: Dispatch<any>): Promise<LeadUploadItem> => {
  return apiClient
    .post<CampaignDetailItem>(`${AGENT_URL}/agents/me/agency-campaign/invitation`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const checkAllRsvpResponded = async (campaignObjId: string, dispatch?: Dispatch<any>): Promise<any> => {
  return apiClient
    .get<any>(`${AGENT_URL}/agents/me/agency-campaign/participant/all-responded?id=${campaignObjId}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const changeRsvpStatus = async (data: any, dispatch?: Dispatch<any>): Promise<any> => {
  return apiClient
    .patch<CampaignDetailItem>(`${AGENT_URL}/agents/me/agency-campaign/app/update-rsvp`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};
