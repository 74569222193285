import { FC } from 'react';
import { useIntl } from 'react-intl';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

type AlertModalProps = {
  title: string;
  content: string;
  onClose: () => void;
};

const useStyles = makeStyles()((theme) => ({
  titleContainer: {
    textAlign: 'center',
  },
  title: {
    fontFamily: 'Arial',
    fontSize: 24,
    fontWeight: 600,
  },
  contentContainer: {
    fontFamily: 'Arial',
    fontSize: 14,
    textAlign: 'center',
  },
  actionContainer: {
    justifyContent: 'center',
    padding: 16,
  },
  button: {
    width: '100%',
    height: 48,
    fontFamily: 'Arial',
    fontSize: 16,
  },
}));

const AlertModal: FC<AlertModalProps> = ({ title, content, onClose }) => {
  const { classes } = useStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  return (
    <>
      <DialogTitle className={classes.titleContainer}>
        <span className={classes.title}>{title}</span>
      </DialogTitle>
      <DialogContent className={classes.contentContainer}>{content}</DialogContent>
      <DialogActions className={classes.actionContainer}>
        <Button variant="contained" color="secondary" className={classes.button} onClick={onClose}>
          {Translation('app.button.confirm')}
        </Button>
      </DialogActions>
    </>
  );
};

export default AlertModal;
