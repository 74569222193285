import React, { useEffect, useReducer, useState } from 'react';
import { useIntl } from "react-intl";
import { makeStyles } from 'tss-react/mui';
import { ApprovalSettingItem, CampaignStructureItem } from '../../../../types/campaign-types';
import { useDispatch } from 'react-redux';
import { getDisplayDate } from '../../../../../../common/utils/time-utils';
import RemarksItem from './components/RemarksItem';
import ProgressBarItem from './components/ProgressBarItem';
import { WorkflowApprovalStatusEnum } from '../../../../constants';
import { LouTemplate } from './components/LouTemplate'


const useStyles = makeStyles()((theme) => ({

  backButtonContainer: {
    backgroundColor: 'white',
    height: 52,
    marginBottom: 5,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontWeight: 600
  },
  leftBarContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: 'white'
  },
  progressContainer: {
    display: 'flex',
    flexDirection: 'column',

  },
  level1: {
    borderBottom: '1px solid #F0F0F0'
  },
  level1Title: {
    paddingTop: 15,
    paddingBottom: 15,
    fontWeight: 'bold',
    flex: 1
  },
  level2: {
    // borderTop: '1px solid #F0F0F0',
    marginTop: 15,
    marginLeft: 15,
    height: 70,
    display: 'flex',
    flexDirection: 'column'
  },
  level2Title: {
    padding: 15,
    fontWeight: 'bold',
    display: 'flex',
    flex: 1,
    flexDirection: 'column'
  },
  level2Container: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1
  },
  footContainer: {
    display: 'flex'
  },
  container: {
    padding: 20,
    marginBottom: 20,
    borderRadius: 5,
    backgroundColor: theme.palette.common.white
  },
  rowContainer: {
    display: 'flex',
    marginBottom: 25,
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 15
  },
  basicInfoContainer: {
    padding: 16,
    backgroundColor: theme.palette.common.white,
  },
  footerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  bottomSpacing: {
    marginBottom: 5,
  },
  largerBottomSpacing: {
    marginBottom: 20,
  },
  // sectionTitle: {
  //   fontSize: '1.4rem',
  //   fontWeight: 'bold',
  // },
  sectionContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
  },
  fieldContainer: {
    width: '50%',
    boxSizing: 'border-box',
    marginBottom: 16
  },
  participantRowContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  participantCellContainer: {
    paddingLeft: 0
  },
  disabled: {
    color: 'rgba(0, 0, 0, 0.5)'
  },
  olderRecord: {
    color: '#C1C1C1',
  },
  iconButton: {
    padding: 8,
    marginTop: -8,
  },
  sectionTitle: { display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', paddingLeft: 20, paddingRight: 20, height: 50, marginBottom: 8, fontWeight: 600, alignItems: 'center' }


}));

interface ApprovalProps {
  campaignTypeStructureData: CampaignStructureItem;
  formDispatch: (data: any) => void;
  approvalHistoryList: any;
  sectionKey: string;
  campaignObjId: string | undefined;
  remarkList: any;
  submitData?: { submitDate: Date; submitBy: string; cancelDate: Date; canceledBy: string, cancelReason?: string };
  approvalData?: any;
  previousApprovalData?: any
}




const Approval: React.FC<ApprovalProps> = ({ campaignObjId, approvalHistoryList, remarkList, submitData, approvalData, previousApprovalData = [] }) => {
  const { classes } = useStyles();
  const intl = useIntl();
  const dispatch = useDispatch();
  const Translation = (id: string) => intl.formatMessage({ id });
  const [uploadApprovalProgress, setUploadApprovalProgress] = useState<number>();
  const [approvalHistory, setApprovalHistory] = useState<any>();
  const [ramarks, setRemarks] = useState<any>();


  useEffect(() => {
    setApprovalHistory(approvalHistoryList);
    // eslint-disable-next-line
  }, [approvalHistoryList]);

  useEffect(() => {
    setRemarks(remarkList);
    // eslint-disable-next-line
  }, [remarkList]);

  const isCanceled = !!submitData?.canceledBy;

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
        <div className={classes.sectionTitle}>{Translation('agencyCampaign.detail.leftBar.approval')}</div>

        <div style={{ flex: 1, display: 'flex', flexDirection: 'row', flexWrap: 'wrap', background: '#FFFFFF' }}>
          <div className={classes.container}>
            {
              previousApprovalData?.length > 0 && previousApprovalData.map((approval:any, index: number) => (
                <>
                <div style={{ display: 'flex' }}>
                    <ProgressBarItem
                      isCanceled={approval.status !== WorkflowApprovalStatusEnum.APPROVED || approval.type === 'cancel' }
                      isFinished={approval.status === WorkflowApprovalStatusEnum.APPROVED && approval.type !== 'cancel' }
                      disableLine={ index === previousApprovalData.length - 1}
                    />
                    <div
                      className={`
                      ${classes.largerBottomSpacing}
                      ${
                        index !== previousApprovalData.length - 1 &&
                        approval.status === WorkflowApprovalStatusEnum.APPROVED
                          ? classes.olderRecord
                          : ''
                      }
                    ` }
                    >
                      <div>
                        {approval.role.join(', ')}
                        {
                          approval.type === 'submit' ? Translation('agencyCampaign.approval.submit') :
                          approval.type === 'cancel' ? Translation('agencyCampaign.common.status.canceled') :
                          ` ${Translation('agencyCampaign.approval')}`
                        }
                      </div>
                      <div>
                        { approval.type === 'cancel' ?
                          Translation('agencyCampaign.common.status.canceled'):
                          approval.type === 'submit' ?
                          Translation('agencyCampaign.approval.submittedBy'):
                          Translation(`${approval.status}`) }
                        {approval.status !== WorkflowApprovalStatusEnum.PENDING &&
                          ` ${Translation('common.by')} ${approval.assigneeName} ${getDisplayDate(
                            approval.submitDate,
                            'DD/MM/YYYY HH:mm',
                          )}`}

                        <div>{approval.reason}</div>
                      </div>
                      {approval.comment && (
                        <div>
                          {Translation('agencyCampaign.approval.comment')}: {approval.comment}
                        </div>
                      )}
                    </div>
                  </div>
                </>
              ))
            }
            {submitData ? (
              <>
                <div style={{ display: 'flex' }}>
                  <ProgressBarItem
                    isFinished={true}
                    isCanceled={isCanceled}
                    disableLine={!isCanceled && approvalHistoryList.length === 0}
                  />
                  <div
                    className={`
                  ${classes.largerBottomSpacing}
                  ${approvalHistoryList.length > 0 ? classes.olderRecord : ''}
                `}
                  >
                    <div>{Translation('agencyCampaign.approval.submit')}</div>
                    <div>
                      {`${Translation('agencyCampaign.approval.submittedBy')} ${submitData.submitBy} ${getDisplayDate(submitData.submitDate, 'DD/MM/YYYY HH:mm')}`}
                    </div>
                  </div>
                </div>
                {approvalHistoryList.map((approvalHistory: any, index: number) => (
                  <div style={{ display: 'flex' }}>
                    <ProgressBarItem
                      isCanceled={isCanceled}
                      isFinished={approvalHistory.status === WorkflowApprovalStatusEnum.APPROVED}
                      disableLine={!isCanceled && index === approvalHistoryList.length - 1}
                    />
                    <div
                      className={`
                    ${classes.largerBottomSpacing}
                    ${
                      index !== approvalHistoryList.length - 1 &&
                      approvalHistory.status === WorkflowApprovalStatusEnum.APPROVED
                        ? classes.olderRecord
                        : ''
                    }
                  `}
                    >
                      <div>
                        {approvalHistory.role.join(', ')} {Translation('agencyCampaign.approval')}
                      </div>
                      <div>
                        {Translation(`agencyCampaign.common.status.${approvalHistory.status}`)}
                        {approvalHistory.status !== WorkflowApprovalStatusEnum.PENDING &&
                          ` ${Translation('common.by')} ${approvalHistory.assigneeName} ${getDisplayDate(
                            approvalHistory.submitDate,
                            'DD/MM/YYYY HH:mm',
                          )}`}
                      </div>
                      {approvalHistory.comment && (
                        <div>
                          {Translation('agencyCampaign.approval.comment')}: {approvalHistory.comment}
                        </div>
                      )}
                    </div>
                  </div>
                ))}
                {!!submitData.canceledBy && (
                  <div style={{ display: 'flex' }}>
                    <ProgressBarItem isCanceled={isCanceled} disableLine={true} />
                    <div className={classes.largerBottomSpacing}>
                      <div>{Translation('agencyCampaign.common.status.canceled')}</div>
                      <div>
                        {`${Translation('agencyCampaign.common.status.canceled')} ${Translation('common.by')} ${
                          submitData.canceledBy
                        } ${getDisplayDate(submitData.cancelDate, 'DD/MM/YYYY HH:mm')}`}
                      </div>
                      <div>{submitData.cancelReason}</div>
                    </div>
                  </div>
                )}
              </>
            ) : (
              // approval not submit yet

              <div>
                <div style={{ display: "flex", height: "60px" }}>
                    <ProgressBarItem
                        isCanceled={false}
                        isFinished={false}
                        disableLine={false}
                        isDefault={true}
                        isHead={true}
                        />
                    <div
                        className={`
                        ${classes.largerBottomSpacing}
                        ${approvalData.length > 0 ? classes.olderRecord : ''}
                        `}
                        >
                        <div>{Translation('agencyCampaign.approval.submit')}</div>
                        <div>
                          {Translation('agencyCampaign.common.status.pending')}
                        </div>
                    </div>
                </div>
                {
                  approvalData?.length > 0 && approvalData.map((data:any, idx:number) => (
                    <div style={{ display: "flex", height: "60px" }}>
                      <ProgressBarItem
                        isCanceled={false}
                        isFinished={false}
                        isDefault={true}
                        disableLine={idx === approvalData?.length -1 ? true : false}
                      />
                      <div
                        className={`
                        ${classes.largerBottomSpacing}
                        ${approvalData.length > 0 ? classes.olderRecord : ''}
                        `}
                        >
                        <div>{String(data.labelName)}</div>
                        </div>
                    </div>
                  ))
                }
                </div>

            )}
          </div>
        </div>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div className={classes.sectionTitle}>{Translation('agencyCampaign.common.remarks')}</div>

        <div style={{ flex: 1, display: 'flex', flexDirection: 'row', flexWrap: 'wrap', background: '#FFFFFF' }}>
          <div style={{ flex: 1 }}>
            {ramarks &&
              ramarks.map((item: any, index: Number) => {
                return (
                  <>
                    {item.sendTypes.includes('applicant') || item.sendTypes.includes('participant') ? (
                      <div className={classes.level2}>
                        {`${item.updatedBy} ${getDisplayDate(item.updatedAt, 'DD/MM/YYYY HH:mm')}`}
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
                          {item.resourceIds &&
                            (item.resourceIds as string[]).map((item, index) => {
                               return <RemarksItem RemarksItem={item} index={index} key={index} />;
                            })}
                          {item.type === 'variableContent' && (
                            <LouTemplate campaignObjId={campaignObjId} remarksItem={item} />
                          )}
                        </div>
                      </div>
                    ) : null}
                  </>
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
}

export default Approval;