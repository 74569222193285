/**
 * Create React App entry point. This and `public/index.html` files can not be
 * changed or moved.
 */
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import { createRoot } from 'react-dom/client';
import store, { persistor } from './redux/store';
import App from './app/App';
import './index.scss'; // Standard version
import './index.css';
import 'socicon/css/socicon.css';
import '@fortawesome/fontawesome-free/css/all.min.css';

// Icon
import './icon-font/fonts/iconfont.css';

import { SplashScreenProvider } from './app/layout';
import { I18nConfigProvider } from './app/i18n';
import { initializeApp } from 'firebase/app';
import { firebaseConfig } from './firebase.config';

// console.log("------- Environment Variables ------");
// console.log(window.envConfig);

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { PUBLIC_URL } = process.env;

/**
 * Inject metronic interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */

initializeApp(firebaseConfig);

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <I18nConfigProvider>
    <SplashScreenProvider>
      <App store={store} persistor={persistor} basename={PUBLIC_URL} />
    </SplashScreenProvider>
  </I18nConfigProvider>,
);
