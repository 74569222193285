import { Button } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import React, { FC, useState, useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { useLang } from 'src/app/i18n';
import { localeMapToBackend } from 'src/app/modules/AgentProfile/common/utils/locale-utils';
import { agentDetailType, AgentProfileItem, LocationState, FromPageEnum } from '../../types/agent-profile-types';
import { updateAgentProfile, UpdateProfileBody } from '../../network/agentProfileCrud';
import InputForm from '../../common/components/InputForm';
import InfoIcon from '@mui/icons-material/Info';
import RichTooltip from 'src/app/common/components/RichTooltip/RichTooltip';
import { AGENT_PROFILE_PATH } from '../../constants';
import { ToggleOptions, Options } from '../../common/components/toggle-options/toggle-options';
import GAClickFragment from '../../../../common/ga/components/GAClickFragment';
import { useGAScreenViewEvent } from '../../../../common/ga/hook/useGAScreenViewEvent';

const AgentProfileEditGetInTouchWithMePage: FC<RouteComponentProps> = ({ history, location }) => {
  const { classes } = useStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();
  const lang = useLang();
  const mappedLocale = localeMapToBackend[lang] || lang;

  const receivedLocationState = location.state as LocationState;
  useGAScreenViewEvent(
    {
      module: 'AgentProfile',
      feature: 'AgentProfileSite',
      journey: receivedLocationState?.from === FromPageEnum.CREATE ? 'create_agent_profile' : 'edit_agent_profile',
      stage: 'edit_contact_info',
      screen_name: 'GetInTouchWithMePageScreen',
    },
    true,
  );
  const [agentProfile, setAgentProfile] = useState<AgentProfileItem>(receivedLocationState.initAgentProfile);
  const [showWhatsApp, setShowWhatsApp] = useState<boolean>(
    receivedLocationState.initAgentProfile.enableWhatsapp ? true : false,
  );
  const [showToolTips, setShowToolTips] = useState<boolean>(false);
  const { designation, license, phone, email } = agentProfile.agentDetail;

  const refReferralLinkOptions = useRef<Options[] | undefined>(undefined);

  useEffect(() => {
    if ((window as any).ReactNativeWebView) {
      (window as any).ReactNativeWebView.postMessage(
        `{ "action": "loadheaderprops", "payload": { "title": "getInTouchWithMe", "backTarget": "web" } }`,
      );
    }
  }, []);

  const handleSave = async () => {
    const body: UpdateProfileBody = {
      enableWhatsapp: showWhatsApp,
      referralLinkOptions: refReferralLinkOptions.current,
    };
    let redirect = '';

    if (receivedLocationState.from === FromPageEnum.EDIT) {
      await updateAgentProfile(body, dispatch);
      redirect = `${AGENT_PROFILE_PATH}/submit-success?noHeader`;
      history.push(redirect);
    } else if (agentProfile) {
      const initUpdateBody = receivedLocationState?.updateProfileBody ?? {};
      const passingLocationState: LocationState = {
        ...receivedLocationState,
        initAgentProfile: {
          ...agentProfile,
          enableWhatsapp: showWhatsApp,
        },
        updateProfileBody: {
          ...initUpdateBody,
          ...body,
        },
      };
      redirect = `${AGENT_PROFILE_PATH}/create?noHeader`;
      history.push(redirect, passingLocationState);
    }
  };

  return (
    <div style={{ marginTop: 10, display: 'flex', flexDirection: 'column', height: '100%' }}>
      <div style={{ flex: 1 }}>
        <div className={classes.sectionContainer} style={{ paddingBottom: 10 }}>
          <div className={classes.headerContainer}>
            <div className={classes.header}>
              {Translation(`agentProfile.ap_basic_information_header_title`)}
              <RichTooltip
                open={showToolTips}
                fontColor="white"
                backgroundColor="#333333"
                placement="bottom"
                content={
                  <div
                    style={{
                      maxWidth: window.innerWidth - 32,
                      paddingInline: 16,
                      paddingBlock: 8,
                    }}
                  >
                    {Translation(`agentProfile.ap_contact_staff_message`)}
                  </div>
                }
                onClose={() => setShowToolTips(!showToolTips)}
              >
                <div onClick={() => setShowToolTips(!showToolTips)}>
                  <InfoIcon style={{ color: '#7D8699', marginLeft: 4, width: 16, height: 16 }} />
                </div>
              </RichTooltip>
            </div>
          </div>
          <div className={classes.inputContainer}>
            <InputForm
              title={Translation(`agentProfile.ap_name_label`)}
              mandatory={true}
              readOnly={true}
              defaultValue={agentProfile.name[mappedLocale] ?? ''}
            ></InputForm>
            <InputForm
              title={Translation(`agentProfile.ap_achievements_label`)}
              mandatory={true}
              readOnly={true}
              defaultValue={agentProfile.achievements.join(', ')}
            ></InputForm>
            <InputForm
              title={Translation(`agentProfile.ap_position_label`)}
              mandatory={true}
              readOnly={true}
              defaultValue={designation[mappedLocale] ?? ''}
            ></InputForm>
            <InputForm
              title={Translation(`agentProfile.ap_gi_license_label`)}
              mandatory={true}
              readOnly={true}
              defaultValue={
                license.gi
                  ? license.gi === 'Y'
                    ? Translation('agentProfile.getInTouchWithMe.yes')
                    : Translation('agentProfile.getInTouchWithMe.no')
                  : ''
              }
            ></InputForm>
            <InputForm
              title={Translation(`agentProfile.ap_ia_license_label`)}
              mandatory={true}
              readOnly={true}
              defaultValue={license.hkfi ?? ''}
            ></InputForm>
            <InputForm
              title={Translation(`agentProfile.ap_mpf_license_label`)}
              mandatory={true}
              readOnly={true}
              defaultValue={license.mpf ?? ''}
            ></InputForm>
            <InputForm
              title={Translation(`agentProfile.ap_sfc_license_label`)}
              mandatory={true}
              readOnly={true}
              defaultValue={license.sfc ?? ''}
            ></InputForm>
          </div>
        </div>
        <div className={classes.sectionContainer} style={{ marginTop: 10, paddingBottom: 10 }}>
          <div className={classes.headerContainer}>
            <div className={classes.header}>{Translation(`agentProfile.ap_get_in_touch_with_me_header_title`)}</div>
          </div>
          <div className={classes.inputContainer}>
            <InputForm
              title={Translation(`agentProfile.ap_contact_method_phone_label`)}
              readOnly={true}
              mandatory={true}
              placeholder={'+852 91234567'}
              defaultValue={phone.mobile ?? ''}
            ></InputForm>
            <InputForm
              title={Translation(`agentProfile.ap_contact_method_email_label`)}
              readOnly={true}
              mandatory={true}
              placeholder={'alextam@prudential.com.hk'}
              defaultValue={email ?? ''}
            ></InputForm>
            <InputForm
              title={Translation(`agentProfile.ap_contact_method_whatsapp_label`)}
              readOnly={true}
              defaultValue={phone.mobile ?? ''}
              showSwitch={true}
              initIsSwitch={showWhatsApp}
              switchBoolean={setShowWhatsApp}
            ></InputForm>
            <ToggleOptions
              title={Translation(`agentProfile.referral-link`)}
              options={agentProfile.referralLinkOptions}
              onChange={(arr: Options[]) => {
                refReferralLinkOptions.current = arr;
              }}
            ></ToggleOptions>
          </div>
        </div>
      </div>
      <div className={classes.footerContainer}>
        <GAClickFragment stage="edit_contact_info" object_name="Save" object_type="BUTTON">
          <Button variant="contained" color="secondary" className={classes.saveButton} onClick={() => handleSave()}>
            {Translation('app.button.save')}
          </Button>
        </GAClickFragment>
      </div>
    </div>
  );
};

export default AgentProfileEditGetInTouchWithMePage;

const useStyles = makeStyles()((theme) => ({
  header: {
    fontSize: 16,
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    '&:before': {
      content: "''",
      display: 'inline-block',
      height: 16,
      width: 3,
      backgroundColor: 'red',
      marginRight: 10,
      marginLeft: 10,
    },
  },
  sectionContainer: {
    padding: 20,
    paddingBottom: 0,
    backgroundColor: theme.palette.common.white,
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 15,
  },
  inputContainer: {
    marginLeft: 10,
  },
  footerContainer: {
    textAlign: 'center',
    marginBlock: 10,
  },
  saveButton: {
    color: theme.palette.common.white,
    backgroundColor: '#E8192C',
    height: 48,
    width: '100%',
    fontSize: 16,
  },
}));
