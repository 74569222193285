import { Snackbar } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { createContext, useState, useCallback, useContext } from 'react';

interface ToastContextType {
  hide?: () => void;
  show?: (args: ToastType) => void;
  toast?: ToastType;
}

export interface ToastType {
  visible?: boolean;
  message?: string;
  duration?: number;
}
const initialToast: ToastType = {
  visible: false,
};

export const ToastContext = createContext<ToastContextType>({
  hide: undefined,
  show: undefined,
  toast: undefined,
});
let toastSnapshot: ToastType = initialToast;
export const ToastProvider = ({ children }: any) => {
  const [toast, setToast] = useState<ToastType>(toastSnapshot);

  const show = (args: any) => {
    toastSnapshot = { ...toastSnapshot, visible: true, ...args };
    setToast(toastSnapshot);
  };

  const hide = useCallback(() => {
    toastSnapshot = initialToast;
    setToast(initialToast);
  }, [toast]);
  return (
    <>
      <ToastContext.Provider
        value={{
          hide,
          show,
          toast,
        }}
      >
        {children}
        <ToastContextView />
      </ToastContext.Provider>
    </>
  );
};
export const ToastContextView = () => {
  const { toast, hide } = useContext(ToastContext);
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      style={{
        top: '10%',
      }}
      open={toast?.visible}
      autoHideDuration={toast?.duration ?? 5000}
      onClose={hide}
      message={toast?.message}
    />
  );
};

export const useStyles = makeStyles()((theme) => ({
  container: {
    position: 'absolute',
  },
}));
