export const textClamp = (text: string, language: string): string => {
  // Match any character includes \n \r \t with count in sepcified length with lookahead
  // let reEn = /^((.|\n|\r|\t){0,200}(?!\w))/;
  let reEn = new RegExp(/^((.|\n|\r|\t){0,200}(?!\w))/);
  // let reChi = /^((.|\n|\r|\t){0,100}(?!\w))/;
  let reChi = new RegExp(/^((.|\n|\r|\t){0,100}(?!\w))/);
  let result = language === "en" ? reEn.exec(text) : reChi.exec(text);
  if (
    (text.length > 200 && language === "en") ||
    (text.length > 100 && language !== "en")
  ) {
    return !!result ? result[0] + "..." : text;
  } else {
    return !!result ? result[0] : text;
  }
};

export const needTextClamp = (text: string, language: string): boolean => {
  if (
    (text.length > 200 && language === "en") ||
    (text.length > 100 && language !== "en")
  ) {
    return true;
  } else {
    return false;
  }
};

export const nl2br = (text: string) => {
  if (!text) {
    return <></>;
  }
  let groups = text.split("\n");
  return groups.map((item, key) => {
    return (
      <span key={key}>
        {item}
        {key !== groups.length - 1 && <br />}
      </span>
    );
  });
};
