import { chartOptions } from './types/chart-types';

export const SUMMARY_DASHBOARD_PATH = '/summary';
export const SUMMARY_DASHBOARD_TITLE = 'title.summary.dashboard';

export const reverseNum = (num: number) => {
  if (num.toString().includes('.')) {
    let numStr = num.toString().split('.');
    numStr[0] = (numStr[0] || 0).toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,');
    return numStr.join('.');
  } else return (num || 0).toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,');
};

export const barProgressOption = {
  life: {
    splitLineColor: ['#E8192C', 'rgba(232,25,44,0.4)'],
    series: {
      data: { total: 50000, prudential: 20000, others: 30000 },
      backgroundColor: 'rgba(232, 25, 44, 0.4)',
      itemColor: '#E8192C',
    },
  },
  medical: {
    splitLineColor: ['rgba(56,82,126,1)', 'rgba(56,82,126,0.4)'],
    series: {
      data: { total: 5000, prudential: 3000, others: 2000 },
      backgroundColor: '#38527E66',
      itemColor: 'rgba(56,82,126,1),rgba(232,25,44,1)',
    },
  },
  criticalIllness: {
    splitLineColor: ['rgba(0,156,189,1)', 'rgba(0,156,189,0.4)'],
    series: {
      data: { total: 1500, prudential: 500, others: 1000 },
      backgroundColor: 'rgba(0,156,189,0.4)',
      itemColor: 'rgba(0,156,189,1),rgba(232,25,44,1)',
    },
  },
  savings: {
    splitLineColor: ['rgba(255, 201, 89, 1)', 'rgba(255, 201, 89, 0.4)'],
    series: {
      data: { total: 21000, prudential: 15000, others: 6000 },
      backgroundColor: 'rgba(255, 201, 89, 0.4)',
      itemColor: 'rgba(255, 201, 89, 1),rgba(232, 25, 44, 1)',
    },
  },
  others: {
    splitLineColor: ['rgba(0, 138, 55, 1)', 'rgba(0, 138, 55, 0.4)'],
    series: {
      data: { total: 300, prudential: 100, others: 200 },
      backgroundColor: 'rgba(0, 138, 55, 0.4)',
      itemColor: 'rgba(0, 138, 55, 1),rgba(232, 25, 44, 1)',
    },
  },
};

export const barProgressMap = new Map<string, any>([
  ['Life', barProgressOption.life],
  ['Medical', barProgressOption.medical],
  ['Critical Illness', barProgressOption.criticalIllness],
  ['Savings', barProgressOption.savings],
  ['Others', barProgressOption.others],
]);

export const pieOption = {
  title: [
    {
      text: 'Total per year',
      left: 'center',
      top: '40%',
      textStyle: {
        fontSize: 12,
        align: 'center',
        fontWeight: 500,
        lineHeight: 12,
        color: '#333333',
        fontFamily: 'sans-serif',
      },
    },
    {
      text: '$37,800',
      left: 'center',
      top: '45%',
      textStyle: {
        fontSize: 18,
        color: '#333333',
        fontWeight: 600,
        align: 'center',
        lineHeight: 25,
        fontFamily: 'sans-serif',
      },
    },
    {
      text: 'HKD',
      left: 'center',
      top: '52%',
      textStyle: {
        fontSize: 14,
        color: '#333333',
        fontWeight: 600,
        align: 'center',
        lineHeight: 25,
        fontFamily: 'sans-serif',
      },
    },
  ],
  series: [
    {
      name: 'Access From',
      type: 'pie',
      radius: ['45%', '65%'],
      labelLine: {
        length: 15,
        smooth: true,
        length2: 0,
      },
      label: {
        formatter: function (data: any) {
          return data.percent.toFixed(1) + '%\n' + data.name;
        },
        borderWidth: 1,
        borderRadius: 4,
        fontFamily: 'sans-serif',
        fontWeight: 500,
        fontSize: 11,
        lineHeight: 15,
      },
      color: ['#E8192C', '#38527E', '#009CBD', '#008A37', '#FFC959'],
      data: [
        { value: 26, name: 'Life' },
        { value: 13, name: 'Medical' },
        { value: 4, name: 'Critical Illness' },
        { value: 1, name: 'Others' },
        { value: 56, name: 'Savings' },
      ],
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: 'rgba(0, 0, 0, 0.5)',
        },
      },
    },
  ],
  grid: {
    left: '0%',
    right: '0%',
    containLabel: true,
  },
};
