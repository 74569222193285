import { TableCell } from '@mui/material';
import { withStyles } from 'tss-react/mui';

export const PruTableHeader = withStyles(TableCell, (theme) => ({
  head: {
    // backgroundColor: theme.palette.common.black,
    // color: theme.palette.common.white,
    backgroundColor: theme.palette.common.white,
    color: "#858585",
    fontFamily: 'Poppins, Helvetica, "sans-serif"'
  }
}));
