import moment from 'moment';
import { DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import { Translation } from 'src/app/i18n';
import { useCommonStyles } from '../index';
import { CloseIcon, DateIcon } from '../../../../Icons';
import {
  FilterContentProps,
  FilterTypeEnum,
  OptionToDateFilterEnum,
  FilterContentEnum,
} from '../../../../types/dashboard-filter-types';

const MainFilter = <T extends Record<string, unknown>>({
  filterDef,
  filterState,
  handleSelectFilter,
  onConfirm,
  onClose,
  onReset,
  setFilterContent,
}: FilterContentProps<T>) => {
  const { classes: commonClasses } = useCommonStyles();

  const renderCustomDateFilter = (dateFromKey: string, dateToKey: string) => {
    const dateFrom = moment(filterState[dateFromKey] as Date);
    const dateTo = moment(filterState[dateToKey] as Date);
    const sameYear = dateFrom.year() === dateTo.year();
    const dateStr = `${dateFrom.format(sameYear ? 'DD/MM' : 'DD/MM/YYYY')}-${dateTo.format('DD/MM/YYYY')}`;
    return (
      <div className={commonClasses.customDateContainer}>
        <div
          className={commonClasses.customDate}
          onClick={() =>
            setFilterContent({
              content: FilterContentEnum.SELECT_WEEK,
              dateFromKey,
              dateToKey,
              toContent: { content: FilterContentEnum.MAIN },
            })
          }
        >
          <span className={commonClasses.dateIcon}>
            <DateIcon />
          </span>
          {dateStr}
        </div>
      </div>
    );
  };

  return (
    <>
      <DialogTitle className={commonClasses.titleContainer}>
        <div className={commonClasses.titleDiv}>
          <div className={commonClasses.title}>{Translation('managerDashboard.filter')}</div>
          <CloseIcon onClick={onClose} />
        </div>
      </DialogTitle>
      <DialogContent className={commonClasses.contentContainer}>
        {filterDef
          .filter((filter) => {
            if (filter.disableCondition) {
              return !filter.disableCondition(filterState);
            }
            return true;
          })
          .map((filter) => (
            <div key={`filter-${filter.keyIndex}`} className={commonClasses.filterContainer}>
              <div className={`${commonClasses.filterTitle} ${commonClasses.default}`}>{filter.displayName}</div>
              <div className={commonClasses.optionContainer}>
                {filter.options.map((option, optionIndex) => (
                  <div
                    key={`filter-${filter.keyIndex}-option-${optionIndex}`}
                    className={`
                      ${commonClasses.option}
                      ${filterState[filter.keyIndex] === option.value ? commonClasses.selected : commonClasses.default}
                    `}
                    onClick={() => handleSelectFilter(filter, option.value)}
                  >
                    {option.displayName}
                  </div>
                ))}
              </div>
              {filter.type === FilterTypeEnum.OPTION_TO_DATE &&
                filterState[filter.keyIndex] === OptionToDateFilterEnum.CUSTOM &&
                renderCustomDateFilter(filter.dateFromKey, filter.dateToKey)}
            </div>
          ))}
      </DialogContent>
      <DialogActions className={commonClasses.actionContainer}>
        <div className={commonClasses.actionDiv}>
          <Button color="inherit" className={`${commonClasses.button} ${commonClasses.reset}`} onClick={() => onReset()}>
            {Translation('app.button.reset')}
          </Button>
          <Button variant="contained" color="secondary" className={commonClasses.button} onClick={() => onConfirm()}>
            {Translation('app.button.confirm')}
          </Button>
        </div>
      </DialogActions>
    </>
  );
};

export default MainFilter;
