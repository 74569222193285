import { makeStyles } from 'tss-react/mui';

export const useStyle = makeStyles()((theme) => ({
  itemStyle: {
    padding: '8px 16px',
    borderBottomWidth: 0.5,
    borderColor: '#DFDFDF',
    cursor: 'pointer',
  },
  optionTxt: {
    color: '#333',
    fontFamily: 'SVN-Poppins',
    fontSize: 14,
    fontWeight: 400,
  },
  cursorPoint: {
    cursor: 'pointer',
  },
  contentItem: {
    display: 'flex',
    alignItems: 'center',
    flex: '1 1 auto',
    cursor: 'pointer',
  },
  d_flex_center: {
    display: 'flex',
    alignItems: 'center',
  },
  flex_fill: {
    flex: '1 1 auto',
  },
  bgSelected: {
    backgroundColor: 'rgba(0, 0, 0, 0.03)',
  },
}));
