import { makeStyles } from 'tss-react/mui';
import React, { FC } from 'react';
import { toAbsoluteUrl } from 'src/app/common/utils';
import { ErrorEnum } from '../../types/type';

export type ErrorProps = {
  code: string;
  message: string;
};

export const ErrorPage: FC<ErrorProps> = ({ code, message }) => {
  const { classes } = useStyles();
  return (
    <div className={classes.errContain}>
      <img
        style={{ width: '180px' }}
        src={toAbsoluteUrl(
          `/media/insurance-summary/${code === ErrorEnum.unknownError ? ErrorEnum.netWorkError : code}.png`,
        )}
      />
      <text className={classes.messageText}>{message}</text>
    </div>
  );
};

const useStyles = makeStyles()(() => ({
  errContain: {
    height: '90%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#f5f5f5',
  },
  messageText: {
    fontSize: '18px',
    fontWeight: 700,
    lineHeight: '18px',
    letterSpacing: 0,
    color: '#283542',
    textAlign: 'center',
  },
}));
