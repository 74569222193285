import { makeStyles } from 'tss-react/mui';
import {
  DashboardFilterDef,
  DialogState,
  FilterContentEnum,
} from '../../types/dashboard-filter-types';
import { ArrowDownIcon } from '../../Icons';

type FilterTextProps<T> = {
  filterDef: DashboardFilterDef<T>[];
  initialFilterState: T;
  setDialogState: (newDialogState: DialogState) => void;
};

const useStyles = makeStyles()((theme) => ({
  filterContainer: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    padding: 16,
  },
  filterValue: {
    fontSize: 16,
    fontWeight: 600,
    marginRight: 8,
  },
}));

const FilterText = <T extends Record<string, unknown>>({
  filterDef,
  initialFilterState,
  setDialogState,
}: FilterTextProps<T>) => {
  const { classes } = useStyles();
  return (
    <div
      className={classes.filterContainer}
      onClick={() => setDialogState({ open: true, filterContent: { content: FilterContentEnum.MAIN } })}
    >
      <span className={classes.filterValue}>
        {filterDef
          .filter((filter) => {
            if (filter.disableCondition) {
              return !filter.disableCondition(initialFilterState);
            }
            return true;
          })
          .map(
            (filter) =>
              filter.options.find((option) => initialFilterState[filter.keyIndex] === option.value)?.displayName ||
              initialFilterState[filter.keyIndex],
          )
          .join(', ')}
      </span>
      <ArrowDownIcon />
    </div>
  );
};

export default FilterText;
