import { CSSProperties } from 'react';
import { isPhone } from 'src/app/common/constants';

const COLUMN_GAP = isPhone ? 18 : 28;
const FONT_SIZE = isPhone ? 14 : 16;

export const weekPickerStyles: Record<string, CSSProperties> = {
  container: {
    display: 'flex',
    alignItems: 'center',
    columnGap: COLUMN_GAP,
    overflowX: 'auto',
  },
  weekPicker: {
    width: '60%',
    fontSize: FONT_SIZE,
  },
  yearPicker: {
    flex: 1,
    fontSize: FONT_SIZE,
  },
  highlight: {
    backgroundColor: '#F5F5F5',
    borderRadius: 10,
    zIndex: -1,
  },
};
