import { FC } from 'react';
import { makeStyles } from 'tss-react/mui';
import PruIcon from 'src/app/common/components/PruIcon';

type IconProps = {
  disabled?: boolean;
};

const useStyles = makeStyles()((theme) => ({
  iconSize: {
    fontSize: 18,
  },
  editIcon: {
    color: '#38527E',
  },
  viewMoreIcon: {
    color: '#333333',
  },
}));

export const EditIcon: FC<IconProps> = () => {
  const { classes } = useStyles();
  return <PruIcon icon="edit1" customClasses={`${classes.iconSize} ${classes.editIcon}`} />;
};

export const ArrowRightIcon: FC<IconProps> = ({ disabled }) => {
  const { classes } = useStyles();
  return <PruIcon icon="arrow" customClasses={`${classes.iconSize} ${!disabled ? classes.viewMoreIcon : ''}`} />;
};
