import React, { FC } from 'react';
import { makeStyles } from 'tss-react/mui';

type ItemCardProps = {
  item: any;
  image: any;
  name: string;
  caption?: string;
  labels?: string[];
  selected?: boolean;
  selectOrder?: number;
  disableImage?: boolean;
  disableText?: boolean;
  disableSelect?: boolean;
  handleSelect?: (item: any, selectOrder: number) => void;
};

const CONTAINER_HEIGHT = 100;
const TEXT_ONLY_CONTAINER_HEIGHT = 74;
const IMG_ONLY_CONTAINER_HEIGHT = 124;

const CONTAINER_MARGIN_BOTTOM = 16;
const IMG_ONLY_CONTAINER_MARGIN_BOTTOM = 8;
const BORDER_RADIUS = 10;

const IMAGE_CONTAINER_WIDTH = 100;
const ORDER_SIZE = 24;

const useStyles = makeStyles()((theme) => ({
  container: {
    backgroundColor: theme.palette.common.white,
    minHeight: CONTAINER_HEIGHT,
    marginBottom: CONTAINER_MARGIN_BOTTOM,
    borderRadius: BORDER_RADIUS,
    padding: 8,
    position: 'relative',
  },
  textOnlyContainer: {
    backgroundColor: theme.palette.common.white,
    minHeight: TEXT_ONLY_CONTAINER_HEIGHT,
    marginBottom: CONTAINER_MARGIN_BOTTOM,
    borderRadius: BORDER_RADIUS,
    padding: '12px 16px',
    position: 'relative',
  },
  imgOnlyContainer: {
    backgroundColor: theme.palette.common.white,
    minHeight: IMG_ONLY_CONTAINER_HEIGHT,
    marginBottom: IMG_ONLY_CONTAINER_MARGIN_BOTTOM,
    borderRadius: BORDER_RADIUS,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
  unselected: {
    boxShadow: '0px 4px 16px 0px #00000014',
  },
  selected: {
    border: '2px solid #E8192C',
  },
  contentContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  borderCompensator: {
    margin: -2,
  },
  imgContainer: {
    width: IMAGE_CONTAINER_WIDTH,
    boxSizing: 'border-box',
  },
  img: {
    maxHeight: 84,
    objectFit: 'contain',
  },
  textContainer: {
    width: `calc(100% - ${IMAGE_CONTAINER_WIDTH}px)`,
  },
  title: {
    fontSize: 16,
    fontWeight: 600,
  },
  caption: {
    fontSize: 14,
    color: '#BABABA',
  },
  labelContainer: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    columnGap: 8,
    rowGap: 6,
  },
  label: {
    backgroundColor: '#E0FBFF',
    color: '#24B1C7',
    padding: '4px 8px',
    borderRadius: 4,
    whiteSpace: 'nowrap',
  },
  order: {
    display: 'flex',
    justifyContent: 'center',
    position: 'absolute',
    top: -1,
    right: -1,
    width: ORDER_SIZE,
    height: ORDER_SIZE,
    backgroundColor: '#E8192C',
    borderRadius: '0 12px',
  },
  orderFont: {
    color: 'white',
    fontSize: 16,
  },
}));

const ItemCard: FC<ItemCardProps> = ({
  item,
  image,
  name,
  caption,
  labels,
  selected,
  selectOrder,
  disableImage,
  disableText,
  disableSelect,
  handleSelect,
}) => {
  const { classes } = useStyles();

  return (
    <div
      className={`
        ${disableImage ? classes.textOnlyContainer : disableText ? classes.imgOnlyContainer : classes.container}
        ${disableSelect || !selected ? classes.unselected : classes.selected}
      `}
      onClick={() => {
        if (!disableSelect && handleSelect && selectOrder !== undefined) {
          handleSelect(item, selectOrder);
        }
      }}
    >
      <div className={`${classes.contentContainer} ${selected ? classes.borderCompensator : ''}`}>
        {!disableImage && (
          <div className={!disableText ? classes.imgContainer : ''}>
            {image ? <img src={image} alt="" width={84} className={classes.img} /> : <></>}
          </div>
        )}
        {!disableText && (
          <div className={!disableImage ? classes.textContainer : ''}>
            <div className={classes.title}>{name}</div>
            {caption && <div className={classes.caption}>{caption}</div>}
            {labels && (
              <div className={classes.labelContainer}>
                {labels.map((label) => (
                  <span className={classes.label}>{label}</span>
                ))}
              </div>
            )}
          </div>
        )}
      </div>
      {!disableSelect && selected && selectOrder !== undefined && (
        <div className={classes.order}>
          <span className={classes.orderFont}>{selectOrder + 1}</span>
        </div>
      )}
    </div>
  );
};

export default ItemCard;
