import { FC, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import { NOT_FOUND_ERROR_PAGE_PATH } from 'src/app/common/constants';
import { LayoutSplashScreen } from 'src/app/layout';
import { useLang } from 'src/app/i18n';
import { isPhone } from 'src/app/common/constants';
import { AGENT_PROFILE_PATH } from 'src/app/modules/AgentProfile/constants';
import {
  AgentProfileItem,
  PromotionListWithLang,
  PromotionItem,
  LocationState,
  FromPageEnum,
} from 'src/app/modules/AgentProfile/types/agent-profile-types';
import { calculateItemWidth } from 'src/app/common/utils/common-utils';
import { itemListToIdList } from 'src/app/modules/AgentProfile/common/utils/list-utils';
import { localeMapToPromotionBackend } from 'src/app/modules/AgentProfile/common/utils/locale-utils';
import ItemCard from 'src/app/modules/AgentProfile/common/components/ItemCard';
import Footer, { FOOTER_CONTAINER_HEIGHT } from 'src/app/modules/AgentProfile/common/components/Footer';
import { getAgentPromotions, updateAgentProfile, UpdateProfileBody } from '../../network/agentProfileCrud';
import { takeUIClickEvent } from '../../../../common/ga/ga';
import { useGAScreenViewEvent } from '../../../../common/ga/hook/useGAScreenViewEvent';

const SCREEN_HEIGHT = 100;
const CONTAINER_PADDING = 16;

const SECTION_CONTAINER_HEIGHT = `calc(
  100% -
  ${FOOTER_CONTAINER_HEIGHT}px
)`;

const PROMOTION_NUM_PER_ROW = isPhone ? 1 : 2;
const PROMOTION_COLUMN_GAP = isPhone ? 0 : 18;

const useStyles = makeStyles()((theme) => ({
  container: {
    height: `${SCREEN_HEIGHT}vh`,
  },
  sectionContainer: {
    backgroundColor: theme.palette.common.white,
    maxHeight: SECTION_CONTAINER_HEIGHT,
    padding: CONTAINER_PADDING,
    paddingBottom: 0,
    overflow: 'scroll',
  },
  caption: {
    color: '#999999',
    fontSize: 16,
    fontWeight: 600,
    marginBottom: 16,
  },
  promotionListContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    columnGap: PROMOTION_COLUMN_GAP,
  },
  promotionContainer: {
    width: calculateItemWidth(PROMOTION_COLUMN_GAP, PROMOTION_NUM_PER_ROW),
  },
}));

const AgentProfileEditPromotionPage: FC<RouteComponentProps> = ({ history, location }) => {
  const { classes } = useStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const lang = useLang();
  const promotionMappedLocale = localeMapToPromotionBackend[lang] || lang;
  const promotionMappedLocale2 = Object.keys(localeMapToPromotionBackend).filter(
    (locale) => locale !== promotionMappedLocale,
  )[0];
  const dispatch = useDispatch();

  const receivedLocationState = location.state as LocationState;
  useGAScreenViewEvent(
    {
      module: 'AgentProfile',
      feature: 'AgentProfileSite',
      journey: receivedLocationState?.from === FromPageEnum.CREATE ? 'create_agent_profile' : 'edit_agent_profile',
      stage: 'edit_promotions',
      screen_name: 'PromotionPageScreen',
    },
    true,
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [agentProfile, setAgentProfile] = useState<AgentProfileItem>();
  const [selectedPromotions, setSelectedPromotions] = useState<PromotionItem[]>([]);
  const [promotionList, setPromotionList] = useState<PromotionItem[]>([]);

  // for data in second locale
  const [promotionList2, setPromotionList2] = useState<PromotionItem[]>([]);
  const [selectedPromotions2, setSelectedPromotions2] = useState<PromotionItem[]>([]);

  const reloadData = async () => {
    if (receivedLocationState?.initAgentProfile) {
      setIsLoading(true);
      setAgentProfile(receivedLocationState.initAgentProfile);
      setSelectedPromotions(receivedLocationState.initAgentProfile.promotions[promotionMappedLocale] || []);
      setSelectedPromotions2(receivedLocationState.initAgentProfile.promotions[promotionMappedLocale2] || []);
      try {
        const res = await getAgentPromotions(promotionMappedLocale, dispatch);
        const res2 = await getAgentPromotions(promotionMappedLocale2, dispatch);
        setPromotionList(res.docs);
        setPromotionList2(res2.docs);
      } catch {
        setPromotionList([]);
      }
      setIsLoading(false);
    } else {
      history.push(NOT_FOUND_ERROR_PAGE_PATH);
    }
  };

  useEffect(() => {
    if ((window as any).ReactNativeWebView) {
      (window as any).ReactNativeWebView.postMessage(
        `{ "action": "loadheaderprops", "payload": { "title": "promotionsYouMayBeInterested", "backTarget": "web" } }`,
      );
    }
  }, []);

  useEffect(() => {
    reloadData();
    // eslint-disable-next-line
  }, []);

  const handleSelect = (item: PromotionItem, selectOrder: number) => {
    if (selectOrder !== -1) {
      let newSelectedPromotions = [...selectedPromotions];
      newSelectedPromotions.splice(selectOrder, 1);
      setSelectedPromotions(newSelectedPromotions);

      let newSelectedPromotions2 = [...selectedPromotions2];
      newSelectedPromotions2.splice(selectOrder, 1);
      setSelectedPromotions2(newSelectedPromotions2);
    } else if (selectedPromotions.length < 3) {
      setSelectedPromotions([...selectedPromotions, item]);

      const itemForLocale2 = promotionList2.find((promotion) => promotion.uuid === item.uuid);
      if (itemForLocale2) {
        setSelectedPromotions2([...selectedPromotions2, itemForLocale2]);
      }
    }
  };

  const handleSave = async () => {
    const body: UpdateProfileBody = {
      promotions: itemListToIdList(selectedPromotions, 'uuid'),
    };
    let redirect = '';

    takeUIClickEvent({
      stage: 'edit_promotions',
      object_name: 'Save',
      object_type: 'BUTTON',
    });

    if (receivedLocationState.from === FromPageEnum.EDIT) {
      await updateAgentProfile(body, dispatch);
      redirect = `${AGENT_PROFILE_PATH}/submit-success?noHeader`;
      history.push(redirect);
    } else if (agentProfile) {
      const initUpdateBody = receivedLocationState?.updateProfileBody ?? {};
      const newSelectedPromotionList: PromotionListWithLang = {
        [promotionMappedLocale]: selectedPromotions,
        [promotionMappedLocale2]: selectedPromotions2,
      };
      const passingLocationState: LocationState = {
        ...receivedLocationState,
        initAgentProfile: {
          ...agentProfile,
          promotions: newSelectedPromotionList,
        },
        updateProfileBody: {
          ...initUpdateBody,
          ...body,
        },
      };
      redirect = `${AGENT_PROFILE_PATH}/create?noHeader`;
      history.push(redirect, passingLocationState);
    }
  };

  return (
    <>
      {isLoading || !agentProfile ? (
        <LayoutSplashScreen />
      ) : (
        <div className={classes.container}>
          <div className={classes.sectionContainer}>
            <div className={classes.caption}>
              ({Translation(`agentProfile.ap_choose_not_more_than_three_instructions`)})
            </div>
            <div className={classes.promotionListContainer}>
              {promotionList.map((promotion) => {
                if (promotion) {
                  const selectOrder = selectedPromotions.findIndex(
                    (selectedPromotion) => selectedPromotion?.uuid === promotion.uuid,
                  );
                  return (
                    <div className={classes.promotionContainer}>
                      <ItemCard
                        item={promotion}
                        image={promotion.coverpage?.url}
                        name={promotion.name || '-'}
                        labels={promotion.tag}
                        selected={selectOrder !== -1}
                        selectOrder={selectOrder}
                        handleSelect={handleSelect}
                      />
                    </div>
                  );
                } else {
                  return <></>;
                }
              })}
            </div>
          </div>
          <Footer handleClick={handleSave} />
        </div>
      )}
    </>
  );
};

export default AgentProfileEditPromotionPage;
