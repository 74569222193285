import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import Select, { components } from 'react-select';
import { useStyle } from './styles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

type ISelectProps = {
  options: any[];
  onSelect: (item: any) => void;
  initialValue?: any[];
  disabled?: boolean;
};

let officesTmp = [];
let indexTmp = -1;

const SelectBoxOffices = forwardRef(({ options, onSelect, initialValue, disabled }: ISelectProps, ref: any) => {
  const { classes } = useStyle();
  const [selectedItem, setSelectedItem] = useState(initialValue ? initialValue : []);
  const optionRefs: any = useRef([]);
  const selectRef: any = useRef(null);

  useEffect(() => {
    if (selectRef?.current?.focus) {
      setTimeout(() => selectRef.current?.focus(), 100);
    }
  }, [selectedItem]);

  useImperativeHandle(
    ref,
    () => {
      return {
        unselect: (offices: any) => {
          setSelectedItem(offices);
        },
      };
    },
    [],
  );

  return (
    <React.Fragment>
      <Select
        ref={selectRef}
        isSearchable={false}
        isDisabled={disabled}
        className={`w-50`}
        closeMenuOnSelect={false}
        isMulti
        components={{
          Option: ({ data }) => (
            <div
              ref={(ref) => optionRefs.current.push(ref)}
              className={`${classes.d_flex_center} ${classes.itemStyle} ${
                selectedItem.indexOf(data.value) !== -1 ? classes.bgSelected : ''
              }`}
              onClick={(e) => {
                indexTmp = selectedItem.indexOf(data.value);
                if (indexTmp === -1) {
                  officesTmp = [...selectedItem, data.value];
                  setSelectedItem(officesTmp);
                  onSelect(officesTmp);
                } else {
                  officesTmp = [...selectedItem];
                  officesTmp.splice(indexTmp, 1);
                  setSelectedItem(officesTmp);
                  onSelect(officesTmp);
                }
              }}
            >
              <div className={`${classes.d_flex_center} ${classes.flex_fill}`}>
                <span className={classes.optionTxt}>{data.label}</span>
              </div>
              <div className={`${classes.d_flex_center}`}>
                {selectedItem.indexOf(data.value) !== -1 && <CheckCircleIcon style={{ color: 'red' }} />}
              </div>
            </div>
          ),
          Control: ({ children, ...props }) => (
            <components.Control {...props}>
              <div className={`${classes.contentItem}`}>{children}</div>
            </components.Control>
          ),
        }}
        options={options}
        placeholder={'Please select'}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary25: 'rgba(0, 0, 0, 0.04)',
            primary: 'rgba(0, 0, 0, 0.1)',
          },
        })}
      />
    </React.Fragment>
  );
});

export default React.memo(SelectBoxOffices);
