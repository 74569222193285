import { IntlShape } from "react-intl";
import { ModuleHierachy } from "src/app/common/module/types";
import { REVIEW_WEB_FORM_PATH, REVIEW_WEB_FORM_TITLE } from "./constants";
import ReviewWebFormRoutes from "./pages/ReviewWebFormRoutes";

export const reviewWebFormModuleConfig = (permissions: string[], intl: IntlShape): ModuleHierachy => {
  const Translation = (id: string) => intl.formatMessage({ id });
  return {
    title: Translation(REVIEW_WEB_FORM_TITLE),
    path: REVIEW_WEB_FORM_PATH,
    namespace: 'review-web-form',
    disableMenu: true,
    disableRoleControl: true,
    component: ReviewWebFormRoutes,
    enableRead: true,
    enableCreate: true,
    enableUpdate: true,
  }
}