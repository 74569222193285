import React, { FC } from 'react';
import { Switch, Redirect, RouteComponentProps } from 'react-router-dom';
import PruRoute from 'src/app/common/components/PruRoute';
import { NOT_FOUND_ERROR_PAGE_PATH } from 'src/app/common/constants';
import { SUMMARY_DASHBOARD_PATH } from '../constants';
import { SummaryListingPage } from './Summary/SummaryListingPage';
import { GAData } from '../../../common/ga/redux/gaSlice';

const summaryDashboardPath = `${SUMMARY_DASHBOARD_PATH}`;

const summaryPageGDData: GAData = {
  module: 'PRULeads',
  feature: 'InsuranceSummary',
  journey: 'isr_dashboard',
  stage: 'view',
  screen_name: 'SummaryPage',
};

const SummaryDashboardRoutes: FC<RouteComponentProps> = () => {
  return (
    <Switch>
      <PruRoute
        exact
        path={`${summaryDashboardPath}/:resultId`}
        component={SummaryListingPage}
        gaData={summaryPageGDData}
      />
      <Redirect to={NOT_FOUND_ERROR_PAGE_PATH} />
    </Switch>
  );
};

export default SummaryDashboardRoutes;
