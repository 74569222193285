import { createSlice } from '@reduxjs/toolkit';

export interface LayoutInitialState {
  isAsideMenuCollapse?: boolean;
  showMobileSideMenu?: boolean;
  showUserInfoHeader?: boolean;
  showMobileBreadcrumbs?: boolean;
}

const initialState: LayoutInitialState = {};

export const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    toggleIsAsideMenuCollapse(state) {
      state.isAsideMenuCollapse = !state.isAsideMenuCollapse;
    },
    toggleShowMobileSideMenu(state) {
      state.showMobileSideMenu = !state.showMobileSideMenu;
    },
    toggleShowUserInfoHeader(state) {
      state.showUserInfoHeader = !state.showUserInfoHeader;
    },
    toggleShowMobileBreadcrumbs(state) {
      state.showMobileBreadcrumbs = !state.showMobileBreadcrumbs;
    },
  },
});

export const {
  toggleIsAsideMenuCollapse,
  toggleShowMobileSideMenu,
  toggleShowUserInfoHeader,
  toggleShowMobileBreadcrumbs,
} = layoutSlice.actions;
