import { FC } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import SalesLibraryListingPage from './List';
import { RECRUIT_BASE_PATH, RECRUIT_LIBRARY_BASE_PATH, RESOURCE_BASE_PATH } from '../../../constants';
import { ModulePermissionProps } from '../../../../../common/module/types';
import { EModuleTypes } from '../constants';

export const SystemConfig = {
  filterName: 'component.filterTitle.recruit-table',
  blockName: 'component.formTitle.recruit-table',
  blockType: 'resource',
  moduleType: EModuleTypes.Recruit,
  createPagePath: `${RESOURCE_BASE_PATH}${RECRUIT_BASE_PATH}${RECRUIT_LIBRARY_BASE_PATH}/create`,
  editPageBasePath: `${RESOURCE_BASE_PATH}${RECRUIT_BASE_PATH}${RECRUIT_LIBRARY_BASE_PATH}/edit`,
  viewPageBasePath: `${RESOURCE_BASE_PATH}${RECRUIT_BASE_PATH}${RECRUIT_LIBRARY_BASE_PATH}/view`,
  performancePagePath: `${RESOURCE_BASE_PATH}${RECRUIT_BASE_PATH}${RECRUIT_LIBRARY_BASE_PATH}/performance`,
};

const RecruitLibraryListPage: FC<RouteComponentProps & ModulePermissionProps> = (props) => {
  return <SalesLibraryListingPage {...props} {...SystemConfig} />;
};

export default RecruitLibraryListPage;
