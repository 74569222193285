import { useState, useEffect, CSSProperties } from 'react';
import { ValueOf } from 'src/app/common/types/common-types';
import { getDayStart, getDayEnd, getWeekStart, getWeekEnd } from 'src/app/common/utils/time-utils';
import {
  DashboardFilterDef,
  DialogState,
  FilterDisplayFormatEnum,
  FilterTypeEnum,
  OptionToDateFilterEnum,
} from '../types/dashboard-filter-types';
import FilterDialog from './components/FilterDialog';
import FilterText from './components/FilterText';
import FilterOption from './components/FilterOption';
import FilterChip from './components/FilterChip';
import FilterBox from './components/FilterBox';

type DashboardFilterProps<T> = {
  filterDef: DashboardFilterDef<T>[];
  initialFilterState: T;
  displayFormat?: FilterDisplayFormatEnum;
  style?: CSSProperties;
  onChangeFilter: (newFilterState: T) => void;
  onOpen?: () => void;
};

const initialDialogState: DialogState = {
  open: false,
  filterContent: undefined,
};

const DashboardFilter = <T extends Record<string, unknown>>({
  filterDef,
  initialFilterState,
  displayFormat = FilterDisplayFormatEnum.DEFAULT,
  style,
  onChangeFilter,
  onOpen,
}: DashboardFilterProps<T>) => {
  const [dialogState, setDialogState] = useState<DialogState>(initialDialogState);
  const [filterState, setFilterState] = useState<T>(initialFilterState);

  useEffect(() => {
    setFilterState(initialFilterState);
  }, [initialFilterState]);

  const handleSetDialogState = (newDialogState: DialogState) => {
    setDialogState(newDialogState);
    if (newDialogState.open && onOpen) {
      onOpen();
    }
  };

  const handleConfirm = () => {
    onChangeFilter(filterState);
    setDialogState(initialDialogState);
  };

  const handleClose = () => {
    setDialogState(initialDialogState);
    handleReset();
  };

  const handleReset = () => {
    setFilterState(initialFilterState);
  };

  const handleSelectFilter = (filter: DashboardFilterDef<T>, value: ValueOf<T>, isConfirm?: boolean) => {
    const newFilterState: T = { ...filterState, [filter.keyIndex]: value };
    switch (filter.type) {
      case FilterTypeEnum.OPTION_TO_DATE:
        const now = new Date();
        switch (value) {
          case OptionToDateFilterEnum.TODAY:
            newFilterState[filter.dateFromKey] = getDayStart(now) as ValueOf<T>;
            newFilterState[filter.dateToKey] = getDayEnd(now) as ValueOf<T>;
            break;
          case OptionToDateFilterEnum.THIS_WEEK:
            newFilterState[filter.dateFromKey] = getWeekStart(now, true) as ValueOf<T>;
            newFilterState[filter.dateToKey] = getWeekEnd(now, true) as ValueOf<T>;
            break;
          // case OptionToDateFilterEnum.LAST_WEEK:
          //   const lastWeek = moment(now).subtract(1, 'weeks').toDate();
          //   newFilterState[filter.dateFromKey] = getWeekStart(lastWeek, true) as ValueOf<T>;
          //   newFilterState[filter.dateToKey] = getWeekEnd(lastWeek, true) as ValueOf<T>;
          //   break;
          case OptionToDateFilterEnum.CUSTOM:
            newFilterState[filter.dateFromKey] = getWeekStart(
              filterState[filter.dateFromKey] as Date,
              true,
            ) as ValueOf<T>;
            newFilterState[filter.dateToKey] = getWeekEnd(filterState[filter.dateToKey] as Date, true) as ValueOf<T>;
            break;
          default:
            break;
        }
        break;
      default:
        break;
    }
    setFilterState(newFilterState);
    if (isConfirm) {
      onChangeFilter(newFilterState);
    }
  };

  const renderFilterResult = () => {
    switch (displayFormat) {
      case FilterDisplayFormatEnum.DEFAULT:
        return (
          <FilterText
            filterDef={filterDef}
            initialFilterState={initialFilterState}
            setDialogState={handleSetDialogState}
          />
        );
      case FilterDisplayFormatEnum.SELECT_GROUP_OPTION:
        return (
          <FilterOption
            filterDef={filterDef}
            initialFilterState={initialFilterState}
            setDialogState={handleSetDialogState}
          />
        );
      case FilterDisplayFormatEnum.CHIP_TAB:
        return <FilterChip filterDef={filterDef} filterState={filterState} handleSelectFilter={handleSelectFilter} />;
      case FilterDisplayFormatEnum.BOX_TAB:
        return (
          <FilterBox
            filterDef={filterDef}
            filterState={filterState}
            handleSelectFilter={handleSelectFilter}
            setDialogState={handleSetDialogState}
          />
        );
    }
  };

  return (
    <>
      {dialogState.open && dialogState.filterContent && (
        <FilterDialog
          open={dialogState.open}
          initialFilterContent={dialogState.filterContent}
          filterDef={filterDef}
          filterState={filterState}
          setFilterState={setFilterState}
          onConfirm={handleConfirm}
          onClose={handleClose}
          onReset={handleReset}
          handleSelectFilter={handleSelectFilter}
        />
      )}
      <div style={style}>{renderFilterResult()}</div>
    </>
  );
};

export default DashboardFilter;
