import { Dispatch } from 'react';
import { apiClient, apiErrorHandler, AGENT_URL } from 'src/app/common/network';

export type addSummaryParams = {
  comments: object[];
};

export const getSummaryDashboardDetail = async (
  resultId: string,
  lang: string,
  dispatch?: Dispatch<any>,
): Promise<any> => {
  const url = `${AGENT_URL}/agents/me/insurance-summaries/results/${resultId}/dashboard?lang=${lang}`;
  return apiClient
    .get<any>(url, {
      headers: {
        lang: lang,
      },
    })
    .then((result: any) => {
      return result.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const saveSummaryCommentsData = async (
  resultId: string,
  params: addSummaryParams,
  dispatch?: Dispatch<any>,
): Promise<string> => {
  const url = `${AGENT_URL}/agents/me/insurance-summaries/results/${resultId}/dashboard`;
  return apiClient
    .post<any>(url, params, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => response.data)
    .catch((err) => {
      console.log(err);
      throw apiErrorHandler(err, dispatch);
    });
};
