import React from 'react';
import Form, { Field, useForm } from 'rc-field-form';
import { FieldProps } from 'rc-field-form/lib/Field.d';
import RefForm from 'rc-field-form/lib/index.d';

const Error = ({ children, label }: { children: React.ReactNode[]; label?: React.ReactNode }) => (
  <ul
    style={{
      color: 'red',
      marginLeft: label ? 200 : 0,
      paddingLeft: 0,
      marginTop: 4,
      listStyle: 'none',
    }}
  >
    {((children && children.length && [children[0]]) || []).map((error: React.ReactNode, index: number) => (
      <li key={index}>{error}</li>
    ))}
  </ul>
);

interface LabelFieldProps extends FieldProps {
  label?: React.ReactNode;
  noStyle?: boolean;
  required?: boolean;
  style?: any;
  hideError?: boolean;
  childStyle?: any;
}

type RefFormType = typeof RefForm;

export interface NewRefForm extends RefFormType {
  Item: React.FunctionComponent<LabelFieldProps>;
}

const Item: React.FunctionComponent<LabelFieldProps> = ({
  name,
  label,
  children,
  noStyle,
  required = true,
  hideError = false,
  style = {},
  childStyle,
  ...restProps
}) => {
  return (
    <Field name={name} {...restProps}>
      {(control, meta, form) => {
        const childNode =
          typeof children === 'function'
            ? children({ ...control }, meta, form)
            : React.cloneElement(children as React.ReactElement, {
                ...control,
              });
        return noStyle ? (
          childNode
        ) : (
          <div style={{ position: 'relative', width: '100%', ...style }}>
            <div style={{ display: 'flex', alignItems: 'center', ...childStyle }}>
              <>
                {label === undefined ? null : (
                  <label style={{ flex: 'none', width: 200, marginBottom: 0 }}>
                    {label}
                    {required && <span style={{ color: 'red' }}>*</span>}
                  </label>
                )}
                {childNode}
              </>
            </div>
            {hideError ? null : <Error label={label}>{meta.errors}</Error>}
          </div>
        );
      }}
    </Field>
  );
};

// const List: React.FunctionComponent<FormListProps> = ({
//   name,
//   children,
//   ...restProps
// }) => {
//   const context = React.useContext(FieldContext);
//   return (
//       <Field name={name} {...restProps}>
//         {(control, meta, form) => {
//           const childNode =
//             typeof children === 'function'
//               ? children({ ...control }, meta, form)
//               : React.cloneElement(children as React.ReactElement, {
//                   ...control
//                 });
//           return childNode;
//         }}
//       </Field>
//   );
// }

export {
  Item,
  // List,
  useForm,
};

const temp: any = Form;
temp.Item = Item;
// temp.List = List;
const NewForm: NewRefForm = temp;

export default NewForm;
