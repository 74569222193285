import React, { FC } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import SalesLibraryListingPage from './List';
import { RESOURCE_BASE_PATH, SALES_LIBRARY_BASE_PATH, SALES_MATERIAL_BASE_PATH } from '../../../constants';
import { ModulePermissionProps } from '../../../../../common/module/types';

export const SystemConfig = {
  filterName: 'component.filterTitle.sales-table',
  blockName: 'component.formTitle.sales-table',
  blockType: 'resource',
  moduleType: 'Sales',
  createPagePath: `${RESOURCE_BASE_PATH}${SALES_MATERIAL_BASE_PATH}${SALES_LIBRARY_BASE_PATH}/create`,
  editPageBasePath: `${RESOURCE_BASE_PATH}${SALES_MATERIAL_BASE_PATH}${SALES_LIBRARY_BASE_PATH}/edit`,
  viewPageBasePath: `${RESOURCE_BASE_PATH}${SALES_MATERIAL_BASE_PATH}${SALES_LIBRARY_BASE_PATH}/view`,
  performancePagePath: `${RESOURCE_BASE_PATH}${SALES_MATERIAL_BASE_PATH}${SALES_LIBRARY_BASE_PATH}/performance`,
};

const RecruitLibraryListPage: FC<RouteComponentProps & ModulePermissionProps> = (props) => {
  return <SalesLibraryListingPage {...props} {...SystemConfig} />;
};

export default RecruitLibraryListPage;
