import { IntlShape } from 'react-intl';
import { ModuleHierachy } from '../../common/module/types';
import {
  REPORT_TITLE,
  REPORT_BASE_PATH,
  EVENT_TITLE,
  EVENT_BASE_PATH,
  GP_REPORT_BASE_PATH,
  BOP_REPORT_BASE_PATH,
  GP_REPORT_TITLE,
  BOP_REPORT_TITLE,
  EVENT_BOP_REPORT_NAMESPACE,
  EVENT_GP_REPORT_NAMESPACE,
} from './constants';
import GpReportPage from './pages/Event/GpReport/GpReport.Page';
import BopReportPage from './pages/Event/BopReport/BopReport.Page';

export const reportModuleConfig = (permissions: string[], intl: IntlShape): ModuleHierachy => {
  const Translation = (id: string) => intl.formatMessage({ id });
  return {
    title: Translation(REPORT_TITLE),
    path: REPORT_BASE_PATH,
    icon: '/media/svg/icons/Communication/Chat1.svg',
    disableMenu: false,
    enableRead: true,
    child: [
      {
        title: Translation(EVENT_TITLE),
        path: `${REPORT_BASE_PATH}${EVENT_BASE_PATH}`,
        enableRead: true,
        child: [
          {
            title: Translation(GP_REPORT_TITLE),
            path: `${REPORT_BASE_PATH}${EVENT_BASE_PATH}${GP_REPORT_BASE_PATH}`,
            namespace: EVENT_GP_REPORT_NAMESPACE,
            component: GpReportPage,
            enableRead: true,
            enableCreate: true,
            enableUpdate: true,
          },
          {
            title: Translation(BOP_REPORT_TITLE),
            path: `${REPORT_BASE_PATH}${EVENT_BASE_PATH}${BOP_REPORT_BASE_PATH}`,
            namespace: EVENT_BOP_REPORT_NAMESPACE,
            component: BopReportPage,
            enableRead: true,
            enableCreate: true,
            enableUpdate: true,
          },
        ],
      },
    ],
  };
};
