import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { useIntl } from 'react-intl';
import { toAbsoluteUrl } from 'src/app/common/utils';

interface NoAccessProps {
  hasAuth: boolean;
  children: JSX.Element;
}

const NoAccess = (props: NoAccessProps) => {
  const { hasAuth, children } = props;
  const intl = useIntl();
  const { classes } = useStyles();
  const Translation = (id: string) => intl.formatMessage({ id });

  if (hasAuth) {
    return children;
  }

  return (
    <div className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-w-full tw-h-full">
      <img alt="file" src={toAbsoluteUrl('/media/svg/icons/Error/file-flies.svg')} />
      <text className={classes.title}>{Translation('agencyCampaign.detail.canNotAccess')}</text>
      <text className={classes.desc}>{Translation('agencyCampaign.detail.canNotAccessDesc')}</text>
    </div>
  );
};

export default NoAccess;

const useStyles = makeStyles()(() => ({
  title: {
    fontSize: '18px',
    fontWeight: 600,
    lineHeight: '26px',
    color: '#283542',
    textAlign: 'center',
  },
  desc: {
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '22px',
    color: '#999',
    textAlign: 'center',
  },
}));
