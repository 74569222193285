import { FC } from 'react';
import { CircularProgress } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  container: {
    width: '100%',
    height: '50vh',
    backgroundColor: '#FFFFFF',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  loading: {
    color: '#5D78FF',
  },
}));

const DashboardLoading: FC = () => {
  const { classes } = useStyles();
  return (
    <div className={classes.container}>
      <CircularProgress thickness={4.5} className={classes.loading} />
    </div>
  );
};

export default DashboardLoading;
