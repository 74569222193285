import { FC, createElement, useContext, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import { ModuleHierachy } from 'src/app/common/module/types';
import { ArrowIcon } from '../Icons';
import { ModuleContext } from 'src/app/common/module/ModuleProvider';
import { MANAGER_DASHBOARD_BASE_PATH } from 'src/app/modules/ManagerDashboard/constants';

type DashboardSideMenuProps = {
  nodeList?: ModuleHierachy[];
  isCollapse?: boolean;
  setIsCollapse?: (value: boolean) => void;
};

export const COLLAPSE_WIDTH = 57;
export const EXPAND_WIDTH = 141;

const useStyles = makeStyles()((theme) => ({
  drawer: {
    height: '100%',
    position: 'fixed',
    top: 0,
    left: 0,
    backgroundColor: '#FFFFFF',
    borderRight: '1px solid rgba(0, 0, 0, 0.12)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    zIndex: 100,
  },
  collapse: {
    width: COLLAPSE_WIDTH,
  },
  expand: {
    width: EXPAND_WIDTH,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 30,
    padding: 20,
  },
  itemContainer: {
    display: 'flex',
    alignItems: 'center',
    columnGap: 12,
    cursor: 'pointer',
  },
  default: {
    color: '#666666',
  },
  highlight: {
    color: '#EB3B4C',
  },
  icon: {
    fontSize: 16,
  },
  textContainer: {
    flexGrow: 1,
    fontSize: 12,
    fontWeight: 500,
    fontFamily: 'Arial',
  },
  collapseButton: {
    transform: 'scaleX(-1)',
    padding: 20,
  },
  expandButton: {
    textAlign: 'center',
    padding: '20px 0',
  },
}));

const DashboardSideMenu: FC<DashboardSideMenuProps> = () => {
  const { classes } = useStyles();
  const history = useHistory();
  const { pathname } = useLocation();
  const { moduleMap } = useContext(ModuleContext);
  const [isCollapse, setIsCollapse] = useState<boolean>(true);

  const dashboardNodeList = useMemo(
    () => moduleMap.find((module) => module.path === MANAGER_DASHBOARD_BASE_PATH)?.child,
    [moduleMap],
  );

  return dashboardNodeList ? (
    <div className={`${classes.drawer} ${isCollapse ? classes.collapse : classes.expand}`}>
      <div className={classes.container}>
        {dashboardNodeList?.map((node) => {
          const selected = pathname.includes(node.path);
          const color = selected ? classes.highlight : classes.default;
          return (
            <div
              key={node.title}
              className={`${classes.itemContainer} ${color}`}
              onClick={() => history.push(node.path)}
            >
              <div>
                {node.dashboardIcon &&
                  createElement(node.dashboardIcon, {
                    selected,
                    customClasses: `${classes.icon} ${color}`,
                  })}
              </div>
              {!isCollapse && <div className={classes.textContainer}>{node.title}</div>}
            </div>
          );
        })}
      </div>
      <div className={`${isCollapse ? classes.expandButton : classes.collapseButton}`}>
        <ArrowIcon onClick={() => setIsCollapse(!isCollapse)} />
      </div>
    </div>
  ) : null;
};

export default DashboardSideMenu;
