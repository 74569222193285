import { Paper, Tab, Tabs } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import * as React from 'react';
import { useIntl } from 'react-intl';
import { barProgressOption, pieOption as pieLocalOption, reverseNum } from '../../constants';
import { chartOptions } from '../../types/chart-types';
import { DashboardDetailPage } from './components/DashboardDetailPage';

type DashboardListingPageProps = {
  options: chartOptions;
};

export const DashboardListingPage: React.FC<DashboardListingPageProps> = (props) => {
  const { classes } = styles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const [tabValue, setTabValue] = React.useState(0);
  const [pieWholeOption, setPieWholeOption] = React.useState<Array<any>>();
  const [barPercent, setBarPercent] = React.useState<Record<string, string>>({});

  if (!pieWholeOption) {
    let pieOption = props.options.pieOption;

    let pietmpOption = [];

    for (var j = 0; j < pieOption.length; j++) {
      let tmpOption = JSON.parse(JSON.stringify(pieLocalOption));
      tmpOption.title[0].text =
        j === 0 ? Translation('summary.dashboard.pie.totalYear') : Translation('summary.dashboard.pie.total');
      tmpOption.title[1].text =
        j === 2 ? reverseNum(pieOption[j].total ?? 0) : '$' + reverseNum(pieOption[j].total ?? 0);
      tmpOption.title[2].text =
        j === 2
          ? (pieOption[j].total ?? 0) > 1
            ? Translation('summary.dashboard.pie.chart.policies')
            : Translation('summary.dashboard.pie.chart.policy')
          : 'HKD';

      let colors = [];
      type nameType = keyof typeof barProgressOption;
      for (var i = 0; i < pieOption[j].data.length; i++) {
        const nameList = pieOption[j].data[i].name.split(' ');
        nameList[0] = nameList[0].toLocaleLowerCase();
        colors.push(barProgressOption[nameList.join('') as nameType]?.splitLineColor[0]);
        pieOption[j].data[i].name = Translation(
          'summary.dashboard.page.category.' + pieOption[j].data[i].name.toLocaleLowerCase().split(' ').join(''),
        );
      }

      tmpOption.series[0].color = colors;
      tmpOption.series[0].data = pieOption[j].data;
      tmpOption.series[0].label.formatter = function (data: any) {
        let barObj = barPercent;
        barObj[data.name] = data.percent.toFixed(1);
        setBarPercent(barObj);
        var name = data.name.split(' ');
        name[1] = name[1] ? '\n' + name[1] : '';
        return data.percent.toFixed(1) + '%\n' + name[0] + name[1];
      };
      pietmpOption.push(tmpOption);
    }
    setPieWholeOption(pietmpOption);
  }

  return (
    <div className={classes.pageView}>
      <Paper className={classes.tabView}>
        <Tabs
          TabIndicatorProps={{ color: 'none' }}
          className={classes.tabsContainor}
          value={tabValue}
          onChange={(e, value) => setTabValue(value)}
          aria-label="simple tabs example"
        >
          <Tab className={classes.tabItem} label={Translation('summary.dashboard.page.tab.premium')} />
          <Tab className={classes.tabItem} label={Translation('summary.dashboard.page.tab.sumInsured')} />
          <Tab className={classes.tabItem} label={Translation('summary.dashboard.page.tab.quantity')} />
        </Tabs>
      </Paper>
      {tabValue === 0 && props.options.pieOption[0] && pieWholeOption && (
        <DashboardDetailPage
          pieChart={pieWholeOption[0]}
          barCharts={props.options.tabData[0]}
          percentage={barPercent}
          type={true}
        ></DashboardDetailPage>
      )}
      {tabValue === 1 && props.options.pieOption[1] && pieWholeOption && (
        <DashboardDetailPage
          pieChart={pieWholeOption[1]}
          barCharts={props.options.tabData[1]}
          percentage={barPercent}
          type={true}
        ></DashboardDetailPage>
      )}
      {tabValue === 2 && props.options.pieOption[2] && pieWholeOption && (
        <DashboardDetailPage
          pieChart={pieWholeOption[2]}
          barCharts={props.options.tabData[2]}
          percentage={barPercent}
          type={false}
        ></DashboardDetailPage>
      )}
    </div>
  );
};

const styles = makeStyles()(() => ({
  pageView: {
    backgroundColor: '#FFFFFF',
  },
  tabView: {
    padding: 16,
    boxShadow: 'none !important',
  },
  tabsContainor: {
    fontWeight: 600,
    backgroundColor: '#F5F5F5',
    borderRadius: 10,
    padding: 3,
    color: '#999999',
    minHeight: 30,
  },
  tabItem: {
    flex: 1,
    fontFamily: 'sans-serif',
    minHeight: 30,
    fontSize: 14,
    fontWeight: 500,
    opacity: 1,
    maxWidth: 'none',
  },
}));
