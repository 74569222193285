export type policyDetail = {
  policyName: string;
  policyNumber: string;
  company: string;
  policyType: string; //'Investment-Linked Plan (ILP)', 'Medical', 'Term Life (Non-PAR)', 'Participating'
  policyCategory: string; //'Life', 'Savings', 'Medical', 'Critical Illness', 'Others'
  denominatedCurrency: string; //'AUD', 'CAD', 'CNY', 'GBP', 'HKD', 'INR', 'JPY', 'KHR', 'PHP', 'SGD', 'THB', 'USD', 'VND'
  annualPremium: number;
  paymentTerm: number;
  benefitTerm: number;
  sumAssured: number;
  commencementDate: string; // DD/MM/YYYY
  lifeAssured: string;
  deathBenefit: number;
  roomType: string; //'Ward Room', 'Semi-Private Room', 'Private Room', 'N/A'
};

export type dependentsDetail = {
  dependentName: string;
  dependentGender: string; // 'M','F'
  dependentAge: number;
  relationship: string; //'parents', 'spouse', 'children', 'siblings', 'others'
  dependentYear: number;
};

export type surveyItem = {
  clientNameEn: string;
  clientNameLocal: string;
  gender: string; // 'M','F'
  age: number;
  hasDependents: string; //'Yes','No'
  dependentsNumber: number; // when hasDependents='Yes'
  dependentsInformation: Array<dependentsDetail>; // when hasDependents='Yes' and dependentsNumber>0
  prospectFamilySize: number;
  prospectHouseIncome: string; //'<$50,000', '$50,001-100,000', '$100,001-150,000', '$150,001-200,000', '>$200,000'
  policies: Array<policyDetail>;
  policyLinks: Array<string>; //[ "policyLink1" ]
};

export type GetProtectionReviewWebForm = {
  template: {
    bannerData?: string;
    surveyTemplate: any;
    // tnc?: {
    //   en: string;
    //   zh: string;
    // };
    completeMessage: {
      title: {
        enUs: string;
        local: string;
      };
      content: {
        enUs: string;
        local: string;
      };
    };
  };
  inputData?: any;
  clientName?: string;
  status: ProtectionReviewActivityEnum;
  updatedAt: string;
};

export type GetWebFormProp = {
  isPreview: 'T' | 'F';
  resultId?: string;
  versionNumber?: string;
  region?: string;
};

export type WebFormPageProps = {
  template: {
    bannerData?: string;
    surveyTemplate: any;
    // tnc?: {
    //   en: string;
    //   zh: string;
    // };
    completeMessage: {
      title: {
        enUs: string;
        local: string;
      };
      content: {
        enUs: string;
        local: string;
      };
    };
  };
  inputData?: any;
  isPreview: boolean; //'T' | 'F';
  language: 'en' | 'zh';
  resultId: string;
  status: ProtectionReviewActivityEnum;
  initialPolicyCount: number;
};

export enum ProtectionReviewActivityEnum {
  FormOpened = 'FormOpened',
  FormSaved = 'FormSaved',
  FormSubmitted = 'FormSubmitted',
}

export type errorListProps = {
  personalInformation: Record<any, boolean>;
  policyInformation: Array<Record<any, boolean>>;
  // policyLinks: boolean;
};

export enum ErrorEnum {
  netWorkError = 'netWorkError',
  timeoutError = 'timeoutError',
  notFoundError = 'notFoundError',
  unknownError = 'unknownError',
}

export type ApiErrorResp = {
  statusCode: number;
  message: string;
  code: string;
};
